import {useForm} from 'antd/es/form/Form'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import RefillAddBody from './components/refill-add-body/RefillAddBody'
import RefillAddFooter from './components/refill-add-footer/RefillAddFooter'
import './style.scss'
import RefillAddHeader from './components/refill-add-header/RefillAddHeader'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import useRefill from '../../../../services/refill/refillService'
import useStore from '../../../../services/storeServices/storeService'
import useProducts from '../../../../services/products/productsService'
import useUnits from '../../../../services/units/unitsService'
import useUsers from '../../../../services/users/usersService'
import {selectedRefill} from '../../../../redux/selectors/refillSelector'
import useRawMaterials from '../../../../services/raw-materials/rawMaterialsService'

function RefillAddPage() {
  const [refillForm] = useForm()
  const navigate = useCustomNavigate()
  const {refillId} = useParams()
  const {resetSelectedRefillData} = useRefill()
  const {getStoresData} = useStore()
  const {getProductsData} = useProducts()
  const {getUnitsData} = useUnits()
  const {getUsersData} = useUsers()
  const selectedRefillData = useSelector(selectedRefill)
  const {getRawMaterialsData} = useRawMaterials()
  const [currentActionButton, setCurrentActionButton] = useState()
  const [itemsList, setItemsList] = useState([])
  const isPageReadOnly = useMemo(() => {
    if (
      (selectedRefillData && selectedRefillData.status === 'processed') ||
      (refillId && !selectedRefillData)
    ) {
      return true
    }
    return false
  }, [selectedRefillData])

  useEffect(() => {
    getStoresData()
    getProductsData()
    getUnitsData()
    getUsersData()
    getRawMaterialsData()
    return () => resetSelectedRefillData()
  }, [])

  const onCloseHandler = () => {
    navigate('/refill')
  }

  return (
    <div className="refillAdd_page">
      <RefillAddHeader onBackHandler={onCloseHandler} />
      <RefillAddBody
        setItemsList={setItemsList}
        itemsList={itemsList}
        refillForm={refillForm}
        isPageReadOnly={isPageReadOnly}
        currentActionButton={currentActionButton}
      />
      <RefillAddFooter
        setCurrentActionButton={setCurrentActionButton}
        isPageReadOnly={isPageReadOnly}
        onCloseHandler={onCloseHandler}
        refillForm={refillForm}
      />
    </div>
  )
}

export default RefillAddPage
