import {createSlice} from '@reduxjs/toolkit'
import {getHistorySuppliesByIngredient} from '../asyncServices/suppliesService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  historySuppliesByIngredient: [],
  tableSettings: {
    s_direction: 'DESC',
    s_field: 'date',
    f_createdDateFrom: null,
    f_createdDateTo: null,
    f_name: null,
    f_supplierId: null,
    f_priceOneTo: null,
    f_priceOneFrom: null,
    f_priceTotalTo: null,
    f_priceTotalFrom: null,
    f_AmountTo: null,
    f_AmountFrom: null,
    pageSize: 100,
    page: 1,
  },
  lastUpdated: null,
  isLoading: null,
  totalCount: 0,
  selectedIdIngredientHistory: null,
}

const supplyByIngredientHistory = createSlice({
  name: 'supplyByIngredient',
  initialState,
  reducers: {
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload.tableSettings,
      }
      state.lastUpdated = Date.now()
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setSelectedHistoryIngredientId: (state, action) => {
      state.selectedIdIngredientHistory = action.payload
    },
    clearSelectedIngredient: (state, action) => {
      state.historySuppliesByIngredient = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHistorySuppliesByIngredient.pending, (state) => {
      state.isLoading = 'getHistoryByIngredient'
    })
    builder.addCase(
      getHistorySuppliesByIngredient.fulfilled,
      (state, action) => {
        state.isLoading = null
        state.historySuppliesByIngredient = action.payload.supplies
        state.totalCount = action.payload.totalCount
      }
    )

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setSelectedSupplies,
  setTableSettings,
  setLastUpdated,
  setRaws,
  setSelectedId,
  setSelectedHistoryIngredientId,
  clearSelectedIngredient,
} = supplyByIngredientHistory.actions
export default supplyByIngredientHistory.reducer
