import API from '../../redux/asyncServices/apiService'

export const ordersApi = {
  getOrderById: async (id) => API.get(`/orders/${id}`),

  createOrder: async (orderData) => API.post('/orders', orderData),
  updateOrder: async (id, orderData) => API.put(`/orders/${id}`, orderData),
  deleteOrder: async (ids) => API.delete(`/orders`, {params: {ids}}),
  markBulkAsRead: async (ids) =>
    API.put(`/orders/bulk-update-delivered`, null, {params: {ids}}),

  createOrderPayment: async (paymentData) =>
    API.post(`/order-payments`, paymentData),
}
