import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getUsers = createAsyncThunk(
  'GET_USERS',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/users', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    const state = getState()
    return {users: state.users.users, totalCount: state.users.totalCount}
  }
)

export const getUser = createAsyncThunk(
  'GET_USER',
  async ({id}, {getState}) => {
    if (id) {
      try {
        const response = await API.get(`/users/${id}`)
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
        const state = getState()
        return {
          selectedUser: state.users.selectedUser,
        }
      }
    }
    return null
  }
)

export const setUser = createAsyncThunk('SET_USER', async (data) => {
  try {
    const response = await API.post('/users', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const updateUser = createAsyncThunk('UPDATE_USER', async (data) => {
  const {id} = data
  try {
    const response = await API.put(`/users/${id}`, data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const deleteUsers = createAsyncThunk(
  'DELETE_USERS',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete('/users', {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {...response.data.payload, selectedUsers: []}
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedUsers: state.users.selectedUsers,
      }
    }
    return null
  }
)
