import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

function TaskValidation() {
  const {t} = useTranslation()
  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t('This field is required.'))
      .max(250, t('Max length 250 symbols')),
    type: yup.string().required(t('This field is required.')),
    target: yup.string().required(t('This field is required.')),
    function: yup.string().required(t('This field is required.')),
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default TaskValidation
