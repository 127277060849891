import {Modal} from 'antd'
import {uiConfigValues} from '../../../constants/uiConfigValues'

import './style.scss'

export default function AntdTableActionModal({
  title,
  open,
  handleCancel,
  children = null,
  footer = null,
  fullWidth = false,
  className,
  ...props
}) {
  return (
    <Modal
      width={fullWidth ? uiConfigValues.MAX_MODAL_WIDTH : undefined}
      {...props}
      title={title}
      open={open}
      footer={footer}
      onCancel={handleCancel}
      destroyOnClose
      className={`${className || ''} ${fullWidth && 'table-modal'}`}
    >
      {children}
    </Modal>
  )
}
