export const accountingData = (state) => state.accounting.accounting
export const accountingSummary = (state) => state.accounting.summary
export const lastUpdatedAccounting = (state) => state.accounting.lastUpdated

export const accountingTableSettingsSelector = (state) =>
  state.accounting.tableSettings
export const selectedAccountingTableSettings = (state) => {
  const accountingTableSettings = state.accounting.tableSettings

  return accountingTableSettings.isStoreAccounting
    ? accountingTableSettings.store
    : accountingTableSettings.company
}
