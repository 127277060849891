export const getNormLoading = (state) => state.norm.isLoading === 'getNorm'

export const lastUpdatedNorm = (state) => state.norm.lastUpdated
export const normData = (state) => state.norm.norms
export const normTotalCount = (state) => state.norm.totalCount
export const normTableSettings = (state) => state.norm.tableSettings
export const normSelectedId = (state) => state.norm.selectedId
export const selectedNorm = (state) => state.norm.selectedNorm
export const isLoadingSelectedNorm = (state) =>
  state.norm.isLoading === 'getNormById'
