import {useEffect, useState} from 'react'
import useUsers from '../../../services/users/usersService'
import useStore from '../../../services/storeServices/storeService'
import OrdersListBody from './components/list/OrdersListBody'
import OrdersListHeader from './components/list/OrdersListHeader'

function OrdersListPage() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const {getUsersData} = useUsers()
  const {getAllStoresData} = useStore()
  useEffect(() => {
    getUsersData()
    getAllStoresData()
  }, [])
  return (
    <div>
      <OrdersListHeader
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <OrdersListBody
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </div>
  )
}

export default OrdersListPage
