import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '../../../../components/antd-components/icon'
import variables from '../../../../globalStyle/colors.scss'
import useDebounce from '../../../../hooks/useDebounce'

import RawMaterialsForm from './RawMaterialsForm'
import withAntdModal from '../../../../HOCs/withAntdModal'

import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'
import {PageHeaderWithMobileMenu} from '../../../../components'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {rawMaterialsTableSettingsSelector} from '../../../../redux/selectors/rawMaterialsSelector'
import {rawMaterialsInitialState} from '../../../../redux/slices/rawMaterialsSlice'
import ResetFiltersButton from '../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'

function RawMaterialsHeader({
  setRawMaterialTableSettings,
  deleteRawMaterialsData,
}) {
  const {t} = useTranslation()

  const selectedRawMaterials = useSelector(
    (state) => state.rawMaterials.selectedRawMaterials
  )
  const tableSettings = useSelector(rawMaterialsTableSettingsSelector)
  const {exportExcel} = useCommon()
  const rawMaterials = useSelector((state) => state.rawMaterials.rawMaterials)
  const search = useSelector((state) => state.rawMaterials.tableSettings.f_name)
  const [searchValue, setSearchValue] = useState(search)

  const AddRawMaterialsFormWithModal = withAntdModal(RawMaterialsForm, {
    okButtonText: t('Add'),
    footer: null,
    title: t('Add record'),
    icon: <PlusOutlined />,
    style: {
      width: '100%',
      textTransform: 'uppercase',
      color: variables.add,
    },
    text: t(
      'To add a raw material, enter the name, units, gross, net and click add.'
    ),
  })

  const handleSubmitDelete = () =>
    deleteRawMaterialsData({ids: selectedRawMaterials})

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: handleSubmitDelete,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const debouncedValue = useDebounce(searchValue, 500)

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `dictionary/raw`,
      tableSettings,
    })
  }

  const actionRawHeader = [
    <SearchAntdComponent
      key={1}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <AddRawMaterialsFormWithModal key={2} submitText={t('Add')} />,
    <DeleteButton
      disabled={isIncludeSelectedFilter(rawMaterials, selectedRawMaterials)}
      onClick={confirmDelete}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu(actionRawHeader)

  useEffect(() => {
    const debouncedSearchValue = !debouncedValue ? null : debouncedValue
    if (search !== debouncedSearchValue) {
      setRawMaterialTableSettings({f_name: debouncedSearchValue})
    }
  }, [debouncedValue])

  const clearFiltersHandler = () => {
    setSearchValue('')
  }

  return (
    <PageHeaderWithMobileMenu
      title="Raw"
      subTitle={
        <ResetFiltersButton
          clearFiltersHandler={clearFiltersHandler}
          currentTableSettings={tableSettings}
          initialTableSettings={rawMaterialsInitialState.tableSettings}
        />
      }
      extra={actionRawHeader}
      items={item}
    />
  )
}

export default RawMaterialsHeader
