import {useTranslation} from 'react-i18next'
import {Tabs} from 'antd'
import {storeBalanceTabs} from '../constants'
import StoreBalanceEditTable from './StoreBalanceEditTable'

const {TabPane} = Tabs

function StoreBalanceEditBody({
  tableSettings,
  setStoreBalanceTableSettings,
  handleAmountEdit,
  balanceList,
  isLoading,
  itemType,
  setItemType,
}) {
  const {t} = useTranslation()
  return (
    <div className="items_tabs">
      <Tabs
        onChange={setItemType}
        defaultActiveKey={itemType}
        key="main_items_tabs"
      >
        <TabPane tab={t('Products')} key={storeBalanceTabs.PRODUCT}>
          <StoreBalanceEditTable
            tableSettings={tableSettings}
            onSetTableSettings={setStoreBalanceTableSettings}
            balanceList={balanceList}
            handleAmountEdit={handleAmountEdit}
            isLoading={isLoading}
          />
        </TabPane>
        <TabPane tab={t('Semi-finished')} key={storeBalanceTabs.SEMI}>
          <StoreBalanceEditTable
            tableSettings={tableSettings}
            onSetTableSettings={setStoreBalanceTableSettings}
            balanceList={balanceList}
            handleAmountEdit={handleAmountEdit}
            isLoading={isLoading}
          />
        </TabPane>
        <TabPane tab={t('Raws')} key={storeBalanceTabs.RAW}>
          <StoreBalanceEditTable
            tableSettings={tableSettings}
            onSetTableSettings={setStoreBalanceTableSettings}
            balanceList={balanceList}
            handleAmountEdit={handleAmountEdit}
            isLoading={isLoading}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default StoreBalanceEditBody
