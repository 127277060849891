import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import useTableRenderItems from '../../../../../../../../services/useTableRenderItems'
import EditActionButton from '../../../../../../../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../../../../../../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const useSemiFinishedTable = ({onDelete, onEdit}) => {
  const {t} = useTranslation()
  const products = useSelector((state) => state.products.products)
  const units = useSelector((state) => state.units.units)

  const {renderNameById} = useTableRenderItems()

  const findUnit = (id) => {
    const product = products.find((item) => item.id === id)
    return !product ? product : product?.unitId
  }

  const getColumns = () => [
    {key: 'sort', width: '4%', align: 'center'},
    {
      title: t('Name'),
      dataIndex: 'rawId',
      key: 'name',
      align: 'center',
      width: '40%',
      render: (value, record) =>
        renderNameById({id: record.semiFinishedId}, products),
    },

    {
      title: t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      width: '20%',
    },
    {
      title: t('Units'),
      dataIndex: 'units',
      align: 'center',
      width: '18%',
      render: (value, record) =>
        renderNameById({id: findUnit(record.semiFinishedId)}, units),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '13%',
      render: (_, record) => (
        <div>
          <EditActionButton
            onClick={(e) => {
              e.stopPropagation()
              onEdit(record)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDelete({id: record.id, itemType: 'semiFinished'})
            }}
          />
        </div>
      ),
    },
  ]
  return {semiFinishedColumns: getColumns()}
}

export default useSemiFinishedTable
