import {createSlice} from '@reduxjs/toolkit'
import {
  getPreparationDepartmentById,
  getPreparationDepartmentsList,
} from '../asyncServices/preparationDepartmentService'
import {resetReduxStore} from '../resetStore'

export const departmentInitialState = {
  records: [],
  totalCount: 0,
  tableSettings: {
    s_field: 'createdDate',
    s_direction: 'DESC',
    page: 1,
    pageSize: 100,
    f_title: null,
    f_createdDateTo: null,
    f_createdDateFrom: null,
    f_createdById: null,
  },
  lastUpdated: null,
  isLoading: null,
  selected: null,
}

export const loadingStateOptions = {
  getList: 'getPreparationDepartmentsList',
  getSelected: 'getPreparationDepartment',
}

export const preparationDepartmentSlice = createSlice({
  name: 'preparationDepartmentSlice',
  initialState: departmentInitialState,
  reducers: {
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    setLastUpdatedNow: (state) => {
      state.lastUpdated = new Date().toISOString()
    },
    setSelectedPreparationDepartment: (state, action) => {
      state.selected = action.payload
    },
    clearDepartmentTableSettings: (state) => {
      state.tableSettings = departmentInitialState.tableSettings
      state.lastUpdated = Date.now()
    },
  },
  extraReducers: (builder) => {
    // list
    builder.addCase(getPreparationDepartmentsList.pending, (state) => {
      state.isLoading = loadingStateOptions.getList
    })
    builder.addCase(
      getPreparationDepartmentsList.fulfilled,
      (state, action) => {
        state.records = action.payload.rows
        state.totalCount = action.payload.count
        state.isLoading = null
      }
    )
    builder.addCase(getPreparationDepartmentsList.rejected, (state) => {
      state.isLoading = null
    })

    // by id
    builder.addCase(getPreparationDepartmentById.pending, (state) => {
      state.isLoading = loadingStateOptions.getSelected
    })
    builder.addCase(getPreparationDepartmentById.fulfilled, (state, action) => {
      state.selected = [action.payload]
      state.isLoading = null
    })
    builder.addCase(getPreparationDepartmentById.rejected, (state) => {
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = departmentInitialState
    })
  },
})

export const {
  setTableSettings,
  setLastUpdatedNow,
  setSelectedPreparationDepartment,
  clearDepartmentTableSettings,
} = preparationDepartmentSlice.actions
export default preparationDepartmentSlice.reducer
