import {useDispatch} from 'react-redux'

import {
  deletePrices,
  getDetailInformationByPrice,
  getPrice,
  getPrices,
  setPrice,
  updatePrice,
} from '../../redux/asyncServices/priceService'
import {
  clearPriceTableSettings,
  setLastUpdated,
  setSelectedPrices,
  setTableSettings,
} from '../../redux/slices/priceSlice'

function usePrice() {
  const dispatch = useDispatch()
  const pricesLastUpdated = () => dispatch(setLastUpdated(Date.now()))
  const getPricesData = (params) => dispatch(getPrices({params}))
  const getPriceById = ({id}) => dispatch(getPrice({id}))
  const addPrice = async ({name}) => {
    await dispatch(setPrice({name}))
    pricesLastUpdated()
  }
  const updatePriceData = async (data) => {
    await dispatch(updatePrice(data))
    pricesLastUpdated()
  }
  const selectPrices = (data) => dispatch(setSelectedPrices(data))

  const deletePricesData = async ({ids}) => {
    await dispatch(deletePrices({ids}))
    pricesLastUpdated()
  }
  const setPriceTableSettings = (tableSettings = {}) => {
    dispatch(setTableSettings({tableSettings}))
    pricesLastUpdated()
  }

  const getDetailInformationBySelectedPrice = ({id}) => {
    dispatch(getDetailInformationByPrice({id}))
    pricesLastUpdated()
  }

  const resetPriceTableSettings = () => dispatch(clearPriceTableSettings())

  return {
    getPriceById,
    pricesLastUpdated,
    getPricesData,
    addPrice,
    updatePriceData,
    selectPrices,
    deletePricesData,
    setPriceTableSettings,
    getDetailInformationBySelectedPrice,
    resetPriceTableSettings,
  }
}

export default usePrice
