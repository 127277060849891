import './style.scss'
import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import moment from 'moment/moment'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import RefillItemsTabs from './components/items-tabs/RefillItemsTabs'
import RefillForm from './components/refill-form/RefillForm'
import useRefill from '../../../../../../services/refill/refillService'
import notify from '../../../../../../components/antd-components/notification/notify'
import {REFILL_STATUS} from '../../../../../../constants'
import {selectedRefill} from '../../../../../../redux/selectors/refillSelector'

function RefillAddBody(props) {
  const {
    refillForm,
    isPageReadOnly,
    currentActionButton,
    itemsList,
    setItemsList,
  } = props
  const {t} = useTranslation()
  const {refillId} = useParams()
  const {getRefillById, addRefill, updateRefillData} = useRefill()
  const selectedRefillData = useSelector(selectedRefill)
  const products = useSelector((state) => state.products.products)

  const [activeTab, setActiveTab] = useState('product')
  const [toggleValidateForm, setToggleValidateForm] = useState(null)
  useEffect(() => {
    if (refillId) {
      getRefillById({id: refillId})
    }
  }, [refillId])

  useEffect(() => {
    if (selectedRefillData) {
      const {productsSent, semiFinishedSent, rawsSent} = selectedRefillData
      setItemsList([...productsSent, ...semiFinishedSent, ...rawsSent])
    } else {
      setItemsList([])
    }
  }, [selectedRefillData])

  useEffect(() => {
    if (toggleValidateForm) {
      refillForm.validateFields()
    }
  }, [toggleValidateForm])

  const onFormSubmit = (formData) => {
    const itemsWithEmptyFields = itemsList.filter(
      (item) =>
        Number.isNaN(Number(item.amount)) ||
        item.amount?.length === 0 ||
        !item.itemId
    )
    if (itemsWithEmptyFields.length > 0) {
      const item = itemsWithEmptyFields[0]
      setActiveTab(item.itemType)
      setToggleValidateForm(Date.now())
      return
    }
    if (itemsList.length === 0 && currentActionButton === 'send') {
      notify({
        description: t('Add at least one item to the table'),
        type: 'error',
      })
    } else {
      const {comment, storeId, deliveryDate} = formData
      const refillItems = itemsList.map((item) => {
        const {amount, itemId, itemType} = item
        return {
          itemId,
          itemType,
          amount: Number(amount),
        }
      })
      const correctRefillData = {
        storeId,
        status:
          currentActionButton === 'save'
            ? REFILL_STATUS.DRAFT
            : REFILL_STATUS.SENT,
        comment: comment || '',
        deliveryDate: deliveryDate
          ? moment(deliveryDate).utc().toISOString()
          : null,
        productsSent: refillItems,
      }
      if (refillId) {
        updateRefillData({...correctRefillData, id: refillId})
      } else {
        addRefill(correctRefillData)
      }
    }
  }

  return (
    <div className="body_wrapper">
      <div className="form_wrapper">
        <RefillForm
          refillForm={refillForm}
          itemsList={itemsList}
          isPageReadOnly={isPageReadOnly}
        />
      </div>
      <div className="tabs_wrapper">
        <RefillItemsTabs
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          isPageReadOnly={isPageReadOnly}
          onFormSubmit={onFormSubmit}
          refillForm={refillForm}
          itemsList={itemsList}
          setItemsList={setItemsList}
        />
      </div>
    </div>
  )
}

export default RefillAddBody
