import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

function MovementFormValidation() {
  const {t} = useTranslation()
  const schema = yup.object().shape({
    toStoreId: yup.string().required(t('This field is required.')),
    fromStoreId: yup.string().required(t('This field is required.')),
    comment: yup.string().nullable().max(250, t('Max length 250 symbols')),
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default MovementFormValidation
