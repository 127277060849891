import {useTranslation} from 'react-i18next'
import {Button, Tooltip} from 'antd'
import {DeleteOutlined} from '../../icon'
import './style.scss'

function DeleteButton(props) {
  const {tooltipPlacement, tooltipText, className, ...rest} = props
  const {t} = useTranslation()

  return (
    <div className="deleteButton_wrapper">
      <Tooltip placement={tooltipPlacement} title={tooltipText}>
        <Button danger className={`${className} delete_button`} {...rest}>
          <DeleteOutlined />
          {t('Delete')}
        </Button>
      </Tooltip>
    </div>
  )
}

export default DeleteButton
