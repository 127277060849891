import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getPrices = createAsyncThunk(
  'GET_PRICES',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/calculation/products', {params})

      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        products: state.prices.prices,
        totalCount: state.prices.totalCount,
      }
    }
    return null
  }
)

export const getDetailInformationByPrice = createAsyncThunk(
  'GET_DETAIL_INFORMATION_BY_PRICE',
  async ({id}) => {
    if (id) {
      try {
        const response = await API.get(`/calculation/products/prices/${id}`)
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  }
)

export const getPrice = createAsyncThunk('GET_PRICE', async ({id}) => {
  if (id) {
    try {
      const response = await API.get(`/calculation/products/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
  }
  return null
})

export const setPrice = createAsyncThunk('SET_PRICE', async (data) => {
  try {
    const response = await API.post('/calculation/products', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const updatePrice = createAsyncThunk('UPDATE_PRICE', async (data) => {
  try {
    const response = await API.put(`/products/price`, data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const deletePrices = createAsyncThunk(
  'DELETE_PRICES',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete('/calculation/products', {
        params: {ids},
      })
      if (response?.data?.payload) {
        return {
          ...response.data.payload,
          selectedStores: [],
        }
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedPrices: state.prices.selectedPrices,
      }
    }
    return null
  }
)
