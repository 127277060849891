import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import {AntdTableActionModal} from '../../../../components/antd-components/app-table'
import usePurchaseService from '../../../../services/purchase/purchaseService'
import PurchaseDetails from './purchase-details/PurchaseDetails'
import {URL_PARAMS} from '../../../../constants'
import PurchaseTable from './PurchaseTable'
import './style.scss'

function PurchaseBody() {
  const {selectedPurchase} = usePurchaseService()
  const {t} = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const closePurchaseDetailModal = () => {
    searchParams.delete(URL_PARAMS.PURCHASE_ID)
    setSearchParams(searchParams)
  }

  return (
    <>
      <PurchaseTable />
      <AntdTableActionModal
        className="modal purchaseDetailModalBody"
        title={`${t('Purchase')} # ${selectedPurchase?.checkId}`}
        handleCancel={closePurchaseDetailModal}
        open={!!searchParams.get(URL_PARAMS.PURCHASE_ID)}
        fullWidth
      >
        <PurchaseDetails />
      </AntdTableActionModal>
    </>
  )
}

export default PurchaseBody
