import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getAccountingCategory = createAsyncThunk(
  'GET_ACCOUNTING_CATEGORY',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/dictionary/accounting-categories', {
        params,
      })
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        accountingCategory: state.accountingCategory.accountingCategory,
        totalCount: state.accountingCategory.totalCount,
      }
    }
    return null
  }
)

export const setAccountingCategory = createAsyncThunk(
  'SET_ACCOUNTING_CATEGORY',
  async (data) => {
    try {
      const response = await API.post('/dictionary/accounting-categories', data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const updateAccountingCategory = createAsyncThunk(
  'UPDATE_ACCOUNTING_CATEGORY',
  async (data) => {
    const {id} = data
    try {
      const response = await API.patch(
        `/dictionary/accounting-categories/${id}`,
        data
      )
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const deleteAccountingCategory = createAsyncThunk(
  'DELETE_ACCOUNTING_CATEGORY',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete(`/dictionary/accounting-categories/`, {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {...response.data.payload}
      }
    } catch (e) {
      console.log(e)
      const state = getState()
    }
    return null
  }
)

export const getAccountingCategoryById = createAsyncThunk(
  'GET_ACCOUNTING_CATEGORY_BY_ID',
  async ({id}) => {
    if (id) {
      try {
        const response = await API.get(
          `/dictionary/accounting-categories/${id}`
        )
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  }
)
