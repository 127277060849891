import {useTranslation} from 'react-i18next'
import {Dropdown, Grid, Space, Tree} from 'antd'
import {MoreOutlined} from '../../../../components/antd-components/icon'
import './style.scss'
import LoaderComponent from '../../../../components/loaderComponent/LoaderComponent'
import AddActionButton from '../../../../components/antd-components/tableAction-buttons/add-button/AddActionButton'
import EditActionButton from '../../../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const {DirectoryTree} = Tree
const {useBreakpoint} = Grid

function CustomTreeView({
  data,
  onDelete,
  onAddSubCategory,
  onEdit,
  loading = false,
}) {
  const screens = useBreakpoint()
  const {t} = useTranslation()

  return (
    <div className="categories_wrapper">
      <LoaderComponent
        isLoadingCreator={[loading]}
        component={
          <DirectoryTree
            multiple
            defaultExpandAll
            icon={null}
            treeData={data}
            className="switchable cursorPointer parentBlock"
            selectable={false}
            /* eslint-disable-next-line react/no-unstable-nested-components */
            titleRender={(treeNodes) => (
              <div className="categories_title_render_wrapper">
                <div className="categories_title_render_children">
                  <span className="text">{treeNodes.title}</span>
                  {screens.xs ? (
                    <div>
                      <Dropdown
                        trigger={['click']}
                        overlay={
                          <div className="categories_dropdown">
                            <span className="small-text _right">
                              {treeNodes.preparationDepartment?.title ??
                                t('Without department')}
                            </span>
                            <AddActionButton
                              onClick={(e) => {
                                e.stopPropagation()
                                onAddSubCategory(treeNodes.id)
                              }}
                            />
                            <EditActionButton
                              onClick={(e) => {
                                e.stopPropagation()
                                onEdit(treeNodes.id)
                              }}
                            />
                            <DeleteActionButton
                              onClick={(e) => {
                                e.stopPropagation()
                                onDelete(treeNodes.id)
                              }}
                            />
                          </div>
                        }
                      >
                        <Space onClick={(e) => e.stopPropagation()}>
                          <MoreOutlined className="icon" />
                        </Space>
                      </Dropdown>
                    </div>
                  ) : (
                    <div className="button_wrapper">
                      <span className="small-text _right">
                        {treeNodes.preparationDepartment?.title ??
                          t('Without department')}
                      </span>
                      <AddActionButton
                        onClick={(e) => {
                          e.stopPropagation()
                          onAddSubCategory(treeNodes.id)
                        }}
                      />
                      <EditActionButton
                        onClick={(e) => {
                          e.stopPropagation()
                          onEdit(treeNodes.id)
                        }}
                      />
                      <DeleteActionButton
                        onClick={(e) => {
                          e.stopPropagation()
                          onDelete(treeNodes.id)
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          />
        }
      />
    </div>
  )
}

export default CustomTreeView
