import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {useSelector} from 'react-redux'
import TagsComponent from '../../components/antd-components/tags/TagsComponent'
import {crossedOutText, getColumnSorter} from '../../utils/helpers'
import {formattedDate} from '../../constants'
import {useDateRangeTableSettings} from '../../hooks'

import './style.scss'
import useRefill from './refillService'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DownloadActionButton from '../../components/antd-components/tableAction-buttons/download-button/DownloadActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'

const useRefillTable = ({
  handleEdit,
  downloadRefillExcel,
  getInfoSentRefill,
  handleDelete,
}) => {
  const {t} = useTranslation()

  const {setRefillTableSettings} = useRefill()

  const stores = useSelector((state) => state.stores.stores)
  const refillTableSettings = useSelector(
    (state) => state.refills.tableSettings
  )
  const isAuth = useSelector((state) => state.auth.user)
  const userType = isAuth.type

  const createdDateFilterProps = useDateRangeTableSettings({
    tableSettings: refillTableSettings,
    setTableSettings: setRefillTableSettings,
  })
  const updatedDateFilterProps = useDateRangeTableSettings({
    dateFromFilterName: 'f_updatedDateFrom',
    dateToFilterName: 'f_updatedDateTo',
    tableSettings: refillTableSettings,
    setTableSettings: setRefillTableSettings,
  })

  const getColumns = () => [
    {
      title: t('Store'),
      dataIndex: 'storeId',
      key: 'f_storeId',
      align: 'center',
      className: 'column stores',
      sorter: true,
      filteredValue: refillTableSettings.f_storeId,
      sortOrder: getColumnSorter(refillTableSettings, 'storeId'),
      filters: stores.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      render: (_, record) => {
        if (record?.store?.deletedDate) {
          return crossedOutText(record?.store?.name)
        }
        return <span>{record?.store?.name}</span>
      },
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'f_status',
      align: 'center',
      className: 'column status',
      sorter: true,
      sortOrder: getColumnSorter(refillTableSettings, 'status'),
      filteredValue: refillTableSettings.f_status,
      filters: [
        {
          text: t('sent'),
          value: 'sent',
        },
        {
          text: t('draft'),
          value: 'draft',
        },
        {
          text: t('processed'),
          value: 'processed',
        },
        {
          text: t('error'),
          value: 'error',
        },
      ],
      render: (_, record) => {
        if (record.status === 'error') {
          return (
            <TagsComponent
              keys={record.id}
              color="red"
              tagText={t(record.status)}
            />
          )
        }
        if (record.status === 'draft') {
          return (
            <TagsComponent
              keys={record.id}
              color="default"
              tagText={t(record.status)}
            />
          )
        }
        if (record.status === 'sent') {
          return (
            <TagsComponent
              keys={record.id}
              color="orange"
              tagText={t(record.status)}
            />
          )
        }
        return (
          <TagsComponent
            keys={record.id}
            color="green"
            tagText={t(record.status)}
          />
        )
      },
    },
    {
      title: t('Create date'),
      dataIndex: 'createdDate',
      className: 'column create_date',
      align: 'center',
      ...createdDateFilterProps,
      filteredValue: '',
      sorter: true,
      sortOrder: getColumnSorter(refillTableSettings, 'createdDate'),
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },
    {
      title: t('Update date'),
      dataIndex: 'updatedDate',
      className: 'column update_date',
      align: 'center',
      ...updatedDateFilterProps,
      filteredValue: '',
      sorter: true,
      sortOrder: getColumnSorter(refillTableSettings, 'updatedDate'),
      render: (value) =>
        value !== null
          ? moment(value).format(formattedDate.DateWithTime)
          : null,
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      className: 'column actions',
      fixed: 'right',
      render: (_, record) => {
        const {status} = record
        const isStoreNotDeleted = !record.store.deletedDate
        const isEditButtonVisible =
          (status === 'sent' || status === 'draft' || status === 'processed') &&
          isStoreNotDeleted
        const isDownloadButtonVisible = true
        const isDeleteButtonVisible =
          (status === 'sent' || status === 'draft') && isStoreNotDeleted
        const isShowDetailsButtonVisible = status === 'processed'

        return (
          <div>
            {isEditButtonVisible && (
              <EditActionButton
                onClick={() => {
                  handleEdit(record)
                }}
              />
            )}
            {isDownloadButtonVisible && (
              <DownloadActionButton
                onClick={() => {
                  downloadRefillExcel(record.id)
                }}
              />
            )}
            {isDeleteButtonVisible && (
              <DeleteActionButton
                onClick={() => {
                  handleDelete(record)
                }}
              />
            )}
            {isShowDetailsButtonVisible && (
              <DetailsActionButton
                onClick={() => {
                  getInfoSentRefill(record.id)
                }}
              />
            )}
          </div>
        )
      },
    },
  ]
  return {
    refillColumns:
      userType === 'admin'
        ? getColumns()
        : getColumns().filter((item) => item.dataIndex !== 'actions'),
  }
}

export default useRefillTable
