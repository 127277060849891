import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import StoreBalanceHeader from './components/StoreBalanceHeader'
import StoreBalanceBody from './components/StoreBalanceBody'
import useStore from '../../../services/storeServices/storeService'
import useStoreBalance from '../../../services/store-balance/storeBalanceService'
import {storeBalanceTabs} from './constants'
import useSearchParamsUrl from '../../../hooks/useSearchParamsUrl'
import {storeBalanceTableSettings} from '../../../redux/selectors/storeBalanceSelector'

function StoreBalancePage() {
  const {searchParams, setAutoCompleteValueInUrl} = useSearchParamsUrl()

  const [itemType, setItemType] = useState(
    searchParams.get('itemType') || storeBalanceTabs.PRODUCT
  )

  const setCurrentItemType = (newTab) => {
    setItemType(newTab)
    setAutoCompleteValueInUrl(newTab, 'itemType')
  }

  const {getStoresData} = useStore()
  const {getStoreBalanceByItemType, setStoreBalanceTableSettings} =
    useStoreBalance()

  const tableSettings = useSelector(storeBalanceTableSettings)

  useEffect(() => {
    getStoresData()
  }, [])

  useEffect(() => {
    getStoreBalanceByItemType(itemType, tableSettings)
  }, [itemType, tableSettings])

  return (
    <>
      <StoreBalanceHeader
        itemType={itemType}
        storeBalanceTableSettings={tableSettings}
        setStoreBalanceTableSettings={setStoreBalanceTableSettings}
      />

      <StoreBalanceBody
        tableSettings={tableSettings}
        setStoreBalanceTableSettings={setStoreBalanceTableSettings}
        itemType={itemType}
        setItemType={setCurrentItemType}
      />
    </>
  )
}

export default StoreBalancePage
