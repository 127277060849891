import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

function NormValidation() {
  const {t} = useTranslation()
  const schema = yup.lazy((value) => {
    if (value !== undefined) {
      return yup.object().shape({
        [Object.keys(value).toString()]: yup
          .string()
          .required(t('This field is required'))
          .max(250, t('Max length 250 symbols'))
          .nullable(),
      })
    }
    return yup.mixed().nullable()
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default NormValidation
