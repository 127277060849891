import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import SupplyAddHeader from './SupplyAddHeader'
import SupplyAddBody from './SupplyAddBody'
import useSupplies from '../../../../services/supllies/supplies'
import useRawMaterials from '../../../../services/raw-materials/rawMaterialsService'
import useUnits from '../../../../services/units/unitsService'
import useCategory from '../../../../services/category/categoryService'
import useSupplier from '../../../../services/supplier/suppliersService'
import {
  isSupplyLoading,
  selectedSupplyId,
  supplySelected,
} from '../../../../redux/selectors/supplySelector'
import {unitsLoading} from '../../../../redux/selectors/unitSelector'
import {isLoadingCategories} from '../../../../redux/selectors/categoriesSelector'
import LoaderComponent from '../../../../components/loaderComponent/LoaderComponent'
import useSuppliesHistoryByIngredient from '../../../../services/supllies/historySuppliesByIngredient/historySuppliesByIngredient'

function AddSupply() {
  const id = useSelector(selectedSupplyId)
  const selectedSupply = useSelector(supplySelected)
  const isLoadingUnits = useSelector(unitsLoading)
  const isLoadingCategoriesData = useSelector(isLoadingCategories)
  const isLoadingSupply = useSelector(isSupplyLoading)
  const header = selectedSupply?.id ? 'Update' : 'Add new supply'
  const {addSupply, updateSupplyData, getSupplyById, setSelectedSuppliesId} =
    useSupplies()
  const {clearIngredientState} = useSuppliesHistoryByIngredient()
  const {clearNewRawMaterial} = useRawMaterials()

  const {getRawMaterialsData, rawMaterialsReset} = useRawMaterials()

  const {getUnitsData, unitsReset} = useUnits()
  const {categoriesReset} = useCategory()
  const supplierProps = useSupplier()
  const {suppliersResets, getSuppliersData, newSuppliersClear} = supplierProps

  useEffect(() => {
    getUnitsData()
    getRawMaterialsData()
    getSuppliersData()

    return () => {
      unitsReset()
      rawMaterialsReset()
      categoriesReset()
      suppliersResets()
      newSuppliersClear(null)
      getSupplyById({id: null})
      setSelectedSuppliesId(null)
    }
  }, [])

  useEffect(() => {
    if (id) {
      getSupplyById({id})
    }
  }, [id])

  return (
    <LoaderComponent
      isLoadingCreator={[
        isLoadingUnits,
        isLoadingCategoriesData,
        isLoadingSupply,
      ]}
      component={
        <>
          <SupplyAddHeader header={header} />
          <SupplyAddBody
            addSupply={addSupply}
            updateSupplyData={updateSupplyData}
            selectedSupply={selectedSupply}
            clearNewRawMaterial={clearNewRawMaterial}
            clearIngredientState={clearIngredientState}
          />
        </>
      }
    />
  )
}

export default AddSupply
