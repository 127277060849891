import {Modal} from 'antd'
import React from 'react'

const {confirm} = Modal

const showConfirm = ({title, okFunction, okType, content, icon}) => {
  confirm({
    title,
    content,
    icon,
    okType,
    onOk() {
      okFunction()
    },
    onCancel() {},
  })
}
export default showConfirm
