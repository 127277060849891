import {Button, Tooltip} from 'antd'

export function IconButtonWithTooltip(props) {
  const {type, icon, title, placement, ...rest} = props
  return (
    <Tooltip placement={placement} title={title} arrowPointAtCenter>
      <Button type={type} icon={icon} {...rest} />
    </Tooltip>
  )
}
