import {useTranslation} from 'react-i18next'
import {getColumnSorter} from '../../utils/helpers'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const useSuppliersTable = ({onDelete, onEdit, suppliersTableSettings}) => {
  const {t} = useTranslation()

  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'name',
      align: 'center',
      width: '32%',
      sorter: true,
      sortOrder: getColumnSorter(suppliersTableSettings, 'name'),
    },
    {
      title: t('Phone'),
      dataIndex: 'telephone',
      align: 'center',
      width: '30%',
      sorter: true,
      sortOrder: getColumnSorter(suppliersTableSettings, 'telephone'),
    },
    {
      title: t('Address'),
      dataIndex: 'address',
      align: 'center',
      width: '31%',
      sorter: true,
      sortOrder: getColumnSorter(suppliersTableSettings, 'address'),
    },

    {
      title: t('Actions'),
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      fixed: 'right',
      width: '7%',
      render: (_, record) => (
        <div>
          <EditActionButton
            onClick={(e) => {
              e.stopPropagation()
              onEdit(record.id)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDelete(record.id)
            }}
          />
        </div>
      ),
    },
  ]

  return {suppliersColumns: getColumns()}
}

export default useSuppliersTable
