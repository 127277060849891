import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import {Form} from 'antd'
import StoreHeader from './components/StoreHeader'
import StoreBody from './components/StoreBody'

import useStore from '../../../services/storeServices/storeService'

function Store() {
  const storeProps = useStore()
  const {
    setStoreTableSettings,
    deleteStoresData,
    getStoresData,
    changeIsOpenModalWindow,
  } = storeProps
  const [form] = Form.useForm()
  const storesLastUpdated = useSelector((state) => state.stores.lastUpdated)
  const storesTableSettings = useSelector((state) => state.stores.tableSettings)

  useEffect(() => {
    getStoresData(storesTableSettings)
  }, [storesLastUpdated])

  return (
    <>
      <StoreHeader
        setStoreTableSettings={setStoreTableSettings}
        deleteStoresData={deleteStoresData}
        changeIsOpenModalWindow={changeIsOpenModalWindow}
        form={form}
      />
      <StoreBody {...storeProps} form={form} />
    </>
  )
}

export default Store
