import {useDispatch} from 'react-redux'

import {
  setFilter,
  setIsOpenEditWindow,
  setLanguage,
  setOpenModalWindow,
  setPath,
  setSideBarOpen,
} from '../redux/slices/commonSlice'
import {exportTableExcel} from '../redux/asyncServices/commonService'

function useCommon() {
  const dispatch = useDispatch()

  const changeLanguage = (value) => dispatch(setLanguage(value))
  const changePath = (value) => dispatch(setPath(value))
  const changeSideBarOpen = (value) => dispatch(setSideBarOpen(value))
  const clearTableSettings = (value) => dispatch(setFilter(value))
  const changeOpenModalWindow = (value) => dispatch(setOpenModalWindow(value))
  const changeOpenEditModalWindow = (value) =>
    dispatch(setIsOpenEditWindow(value))

  const exportExcel = ({tableName, tableSettings}) =>
    dispatch(exportTableExcel({tableSettings, tableName}))

  return {
    changeLanguage,
    changePath,
    changeSideBarOpen,
    clearTableSettings,
    changeOpenModalWindow,
    changeOpenEditModalWindow,
    exportExcel,
  }
}

export default useCommon
