import {useTranslation} from 'react-i18next'
import {AntdTableActionModal} from '../../../../../../../../../components/antd-components/app-table'
import CategoryForm from '../../category-form/CategoryForm'

function CreateCategoryModal(props) {
  const {isModalOpen, closeModalHandler} = props
  const {t} = useTranslation()
  return (
    <AntdTableActionModal
      open={isModalOpen}
      title={t('Add category')}
      handleCancel={closeModalHandler}
    >
      <CategoryForm onClose={closeModalHandler} />
    </AntdTableActionModal>
  )
}
export default CreateCategoryModal
