import {Card, Col, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import {LoadingSpinner} from '../loading-spinner'

import './style.scss'

export default function ModalHeaderMetaData({items, isLoading}) {
  const {t} = useTranslation()
  return (
    <Col xl={24} sm={24} xs={24} lg={24} className="modal-metadata-header_col">
      <Row gutter={[16, 8]} className="modal-metadata-header_row">
        {items.map((item) => (
          <Col xl={6} md={8} lg={8} sm={12} xs={12} key={item.key}>
            <Card
              bordered={false}
              className="card modal-metadata-header_card"
              key={item.key}
            >
              <div
                className="modal-metadata-header_card-content"
                key={item.key}
              >
                <span className="item_name">{`${t(item.name)}:`}</span>
                <span className="item_value">
                  {isLoading ? <LoadingSpinner size="small" /> : item.value}
                </span>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Col>
  )
}
