import {PageHeader} from 'antd'
import {useTranslation} from 'react-i18next'
import currency from 'currency.js'
import '../../style.scss'
import {TagsComponent} from '../../../../../components/antd-components/tags'

function DetailsPriceHeader({header, extraContent}) {
  const {t} = useTranslation()
  return (
    <PageHeader
      className="headerStyle"
      onBack={() => window.history.back()}
      title={header}
      extra={[
        <TagsComponent
          key={1}
          color="#708090"
          tagText={`${t('Production price')} : ${currency(extraContent)}`}
        />,
      ]}
    />
  )
}

export default DetailsPriceHeader
