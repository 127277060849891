import {Col, Row} from 'antd'

import {useTranslation} from 'react-i18next'
import '../style.scss'
import currency from 'currency.js'
import {
  convertToFractionalNumber,
  getRandomId,
  separateThousand,
} from '../../../../utils/helpers'
import variables from '../../../../globalStyle/colors.scss'
import CardKPI from '../../../../components/cardKPI/CardKPI'

function StatisticCard({income, total, outcomes, balance}) {
  const {t} = useTranslation()

  const cardItem = [
    {
      id: getRandomId(),
      name: 'Income',
      summaryValue: separateThousand(
        convertToFractionalNumber(
          currency(income?.total, {precision: 2}).value,
          2
        )
      ),

      textColor: 'rgba(0, 0, 0, 0.45)',
      contentColor: variables.success,
      footer: [
        {
          footerName: t('Cash'),
          footerValue: separateThousand(
            convertToFractionalNumber(currency(income?.cash).value, 2)
          ),

          key: getRandomId(),
        },

        {
          footerName: t('Card'),
          footerValue: separateThousand(
            convertToFractionalNumber(currency(income?.card).value, 2)
          ),
          key: getRandomId(),
        },
      ],
    },
    {
      id: getRandomId(),
      name: 'Expenses',
      summaryValue: separateThousand(
        convertToFractionalNumber(
          currency(outcomes?.total, {precision: 2}).value,
          2
        )
      ),
      textColor: 'rgba(0, 0, 0, 0.45)',
      contentColor: variables.expenses,
      footer: [
        {
          footerName: t('Cash'),
          footerValue: separateThousand(
            convertToFractionalNumber(currency(outcomes?.cash).value, 2)
          ),
          key: getRandomId(),
        },
        {
          footerName: t('Card'),
          footerValue: separateThousand(
            convertToFractionalNumber(currency(outcomes?.card).value, 2)
          ),
          key: getRandomId(),
        },
      ],
    },
    {
      id: getRandomId(),
      name: 'Sum',
      summaryValue: separateThousand(
        convertToFractionalNumber(
          currency(total?.total, {precision: 2}).value,
          2
        )
      ),
      contentColor:
        total?.total >= 0 || total?.total === undefined
          ? variables.success
          : variables.expenses,
      textColor: 'rgba(0, 0, 0, 0.45)',
      footer: [
        {
          footerName: t('Cash'),
          footerValue: separateThousand(
            convertToFractionalNumber(currency(total?.cash).value, 2)
          ),
          key: getRandomId(),
        },
        {
          footerName: t('Card'),
          footerValue: separateThousand(
            convertToFractionalNumber(currency(total?.card).value, 2)
          ),
          key: getRandomId(),
        },
      ],
    },
    {
      id: getRandomId(),
      name: 'Balance',
      summaryValue: separateThousand(
        convertToFractionalNumber(
          currency(balance?.total, {precision: 2}).value,
          2
        )
      ),
      textColor: 'rgba(0, 0, 0, 0.45)',
      contentColor:
        balance?.total >= 0 || balance?.total === undefined
          ? variables.success
          : variables.expenses,
      footer: [
        {
          footerName: t('Cash'),
          footerValue: separateThousand(
            convertToFractionalNumber(currency(balance?.cash).value, 2)
          ),
          key: getRandomId(),
        },
        {
          footerName: t('Card'),
          footerValue: separateThousand(
            convertToFractionalNumber(currency(balance?.card).value, 2)
          ),
          key: getRandomId(),
        },
      ],
    },
  ]

  return (
    <Row gutter={[24, 24]} className="row_wrapper">
      {cardItem.map((item) => (
        <Col xl={6} md={12} lg={12} sm={12} xs={24} key={item.id}>
          <CardKPI
            title={t(item.name)}
            content={item.summaryValue}
            footer={item.footer}
            textColor={item.textColor}
            contentColor={item.contentColor}
          />
        </Col>
      ))}
    </Row>
  )
}

export default StatisticCard
