import {useSelector} from 'react-redux'

function withPermission(Component) {
  return function ComponentWithPermission(props) {
    const {roles = []} = props
    const isAuth = useSelector((state) => state.auth.user)
    const userType = isAuth.type
    if (roles.includes(userType)) {
      return Component
    }
    return null
  }
}

export default withPermission
