import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

function ProductsValidationSchema() {
  const {t} = useTranslation()
  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t('This field is required.'))
      .max(250, t('Max length 250 symbols')),
    categoryId: yup.string().required(t('This field is required.')),
    unitId: yup.string().required(t('This field is required.')),
    amount: yup
      .number()
      .typeError(t('This field is required.'))
      .positive(t('Value cannot be 0 or less than zero'))
      .transform((_, value) => {
        if (String(value).includes(',')) {
          return +value.replace(/,/, '.')
        }
        return +value
      }),
  })
  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default ProductsValidationSchema
