import {useTranslation} from 'react-i18next'
import {List} from 'antd'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {useSelector} from 'react-redux'
import {PageHeaderWithMobileMenu} from '../../../../../components'
import {ButtonDefault} from '../../../../../components/antd-components/button'
import useCustomNavigate from '../../../../../hooks/useCustomNavigate'
import {ordersApi} from '../../../../../services/api/ordersApiService'
import notify from '../../../../../components/antd-components/notification/notify'
import useOrdersService from '../../../../../services/orders/ordersService'
import {checkIsResponseSuccess} from '../../../../../utils/helpers'
import showConfirm from '../../../../../components/PopupConfirm'
import './style.scss'
import AddButton from '../../../../../components/antd-components/main-buttons/add-button/AddButton'
import DownloadButton from '../../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import {orderTableSettingsSelector} from '../../../../../redux/selectors/orderSelector'
import useCommon from '../../../../../services/commonService'
import ResetFiltersButton from '../../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'
import {ordersInitialState} from '../../../../../redux/slices/orderSlice'

function OrdersListHeader({selectedRowKeys, setSelectedRowKeys}) {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const tableSettings = useSelector(orderTableSettingsSelector)
  const {fetchOrdersWithCurrentSettings, resetOrdersTableSettings} =
    useOrdersService()
  const {exportExcel} = useCommon()

  const markAsRead = async () => {
    const response = await ordersApi.markBulkAsRead(selectedRowKeys)

    if (checkIsResponseSuccess(response?.status)) {
      await fetchOrdersWithCurrentSettings().unwrap()

      setSelectedRowKeys([])
      notify({description: t('typeMessage.Success')})
    }
  }

  const handleMarkAsRead = () =>
    showConfirm({
      title: (
        <>
          {t('Are you sure you want to mark the following orders as')}
          <b> {t('Delivered').toLowerCase()}</b>?
        </>
      ),
      content: (
        <List
          className="marked-orders-list"
          dataSource={selectedRowKeys}
          renderItem={(orderId) => <List.Item># {orderId}</List.Item>}
        />
      ),
      icon: <ExclamationCircleOutlined />,
      okFunction: markAsRead,
    })

  const downloadButtonHandler = () => {
    const correctTableSettings = {...tableSettings}
    if (selectedRowKeys.length) {
      correctTableSettings.f_listId = selectedRowKeys
    }
    exportExcel({tableName: 'orders', tableSettings: correctTableSettings})
  }

  return (
    <div>
      <PageHeaderWithMobileMenu
        showHeaderMenu={false}
        title={t('Orders')}
        subTitle={
          <ResetFiltersButton
            clearFiltersHandler={resetOrdersTableSettings}
            currentTableSettings={tableSettings}
            initialTableSettings={ordersInitialState.tableSettings}
          />
        }
        extra={
          <>
            <ButtonDefault
              key={2}
              onClick={handleMarkAsRead}
              text={t('Mark delivered')}
              disabled={selectedRowKeys.length === 0}
            />
            <AddButton onClick={() => navigate('/orders/add')} />
            <DownloadButton onClick={downloadButtonHandler} />
          </>
        }
      />
    </div>
  )
}

export default OrdersListHeader
