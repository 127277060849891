import {Col, Dropdown, Grid, PageHeader, Row} from 'antd'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {MoreOutlined} from '../../../../components/antd-components/icon'

import variables from '../../../../globalStyle/colors.scss'
import {ButtonDefault} from '../../../../components/antd-components/button'

const {useBreakpoint} = Grid

function NotificationHeader({updateAllNotification}) {
  const {t} = useTranslation()
  const [visible, setVisible] = useState(false)

  const handleVisibleChange = (flag) => {
    setVisible(flag)
  }
  const notifications = useSelector(
    (state) => state.messageNotifications.notificationMessage
  )

  const screens = useBreakpoint()

  const items = [
    {
      key: '1',
      label: (
        <ButtonDefault
          key="1"
          disabled={!notifications?.length}
          onClick={() => updateAllNotification()}
          style={{color: variables.add}}
          text={t('Read all')}
        />
      ),
    },
  ]

  const ExtraContent = (
    <div key="1">
      {screens.md ? (
        <Row>
          <Col xs={2} sm={4} lg={4} xl={4}>
            <ButtonDefault
              disabled={!notifications?.length}
              key="1"
              onClick={() => updateAllNotification()}
              style={{
                color: variables.add,
              }}
              text={t('Read all')}
            />
          </Col>
        </Row>
      ) : (
        <div>
          <Dropdown
            menu={{items}}
            onOpenChange={handleVisibleChange}
            open={visible}
            key="1"
            trigger={['click']}
          >
            <MoreOutlined style={{fontSize: '170%', color: 'blue'}} />
          </Dropdown>
        </div>
      )}
    </div>
  )

  return (
    <PageHeader
      className="notification_page-header"
      ghost={false}
      onBack={() => window.history.back()}
      title={t('Notification')}
      extra={[ExtraContent]}
    />
  )
}

export default NotificationHeader
