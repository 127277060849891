import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '../../../../components/antd-components/icon'
import variables from '../../../../globalStyle/colors.scss'

import useDebounce from '../../../../hooks/useDebounce'

import SupplierForm from './SupplierForm'
import withAntdModal from '../../../../HOCs/withAntdModal'

import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'
import {PageHeaderWithMobileMenu} from '../../../../components'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {suppliersTableSettingsSelector} from '../../../../redux/selectors/supplierSelector'
import ResetFiltersButton from '../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'
import {suppliersInitialState} from '../../../../redux/slices/supplierSlice'
import useSuppliers from '../../../../services/supplier/suppliersService'

function SupplierHeader({setSupplierTableSettings, deleteSuppliersData}) {
  const {t} = useTranslation()
  const {exportExcel} = useCommon()
  const {resetSuppliersTableSettings} = useSuppliers()

  const selectedSuppliers = useSelector(
    (state) => state.suppliers.selectedSuppliers
  )
  const suppliers = useSelector((state) => state.suppliers.suppliers)
  const search = useSelector((state) => state.suppliers.tableSettings.f_name)
  const tableSettings = useSelector(suppliersTableSettingsSelector)
  const [searchValue, setSearchValue] = useState(search)

  const AddSuppliersFormWithModal = withAntdModal(SupplierForm, {
    okButtonText: t('Add'),
    footer: null,
    title: t('Add supplier'),
    icon: <PlusOutlined />,
    style: {
      textTransform: 'uppercase',
      width: '100%',
      color: variables.add,
    },
    text: t('To add a supplier, please enter a name and click add.'),
  })

  const handleSubmitDelete = () => deleteSuppliersData({ids: selectedSuppliers})

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: handleSubmitDelete,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const debouncedValue = useDebounce(searchValue, 500)

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `dictionary/suppliers`,
      tableSettings,
    })
  }

  const actionSupplierHeader = [
    <SearchAntdComponent
      key={1}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <AddSuppliersFormWithModal key={3} text={t('Add')} />,
    <DeleteButton
      disabled={isIncludeSelectedFilter(suppliers, selectedSuppliers)}
      onClick={confirmDelete}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu(actionSupplierHeader)
  useEffect(() => {
    const debouncedSearchValue = !debouncedValue ? null : debouncedValue
    if (search !== debouncedSearchValue) {
      setSupplierTableSettings({f_name: debouncedSearchValue})
    }
  }, [debouncedValue])

  const clearFiltersHandler = () => {
    setSearchValue('')
    resetSuppliersTableSettings()
  }

  return (
    <PageHeaderWithMobileMenu
      title="Suppliers"
      subTitle={
        <ResetFiltersButton
          clearFiltersHandler={clearFiltersHandler}
          currentTableSettings={tableSettings}
          initialTableSettings={suppliersInitialState.tableSettings}
        />
      }
      extra={actionSupplierHeader}
      items={item}
    />
  )
}

export default SupplierHeader
