import {useTranslation} from 'react-i18next'
import {Typography} from 'antd'
import {priceFormat} from '../../../../../utils/helpers'
import CancelButton from '../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../../components/antd-components/main-buttons/save-button/SaveButton'

const {Title} = Typography

export function OrderFormBottom({total, onCancel, form}) {
  const {t} = useTranslation()

  const saveButtonHandler = () => {
    form.submit()
  }
  return (
    <div className="order-form_footer">
      <div className="order-form_footer_meta">
        <Title level={3}>
          {t('Total')}: {priceFormat(total)}
        </Title>
      </div>
      <div className="buttons_container">
        <CancelButton onClick={onCancel} />
        <SaveButton onClick={saveButtonHandler} />
      </div>
    </div>
  )
}

export default OrderFormBottom
