import {MenuOutlined} from '@ant-design/icons'
import {DndContext} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {restrictToVerticalAxis} from '@dnd-kit/modifiers'
import {CSS} from '@dnd-kit/utilities'
import {Table} from 'antd'
import React, {useEffect, useState} from 'react'

function Row({children, ...props}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  })
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 1,
        }
      : {}),
  }
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          })
        }
        return child
      })}
    </tr>
  )
}

function DragTable({
  data,
  columns,
  updateList,
  arrayName,
  footer,
  isLoading,
  ...props
}) {
  const [dataSource, setDataSource] = useState([])
  const [pending, setPending] = useState(false)

  const onDragEnd = ({active, over}) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id)
        const overIndex = previous.findIndex((i) => i.key === over?.id)
        setPending(true)
        return arrayMove(previous, activeIndex, overIndex)
      })
    }
  }
  useEffect(() => {
    if (pending === true && dataSource) {
      updateList(dataSource, arrayName)
      setPending(false)
    }
  }, [pending && dataSource])

  useEffect(() => {
    setDataSource(data)
  }, [data])

  return (
    <DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
      <SortableContext
        items={dataSource?.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          components={{
            body: {
              row: Row,
            },
          }}
          {...props}
          rowKey="key"
          columns={columns}
          dataSource={dataSource}
          footer={footer}
          loading={isLoading}
        />
      </SortableContext>
    </DndContext>
  )
}

export default DragTable
