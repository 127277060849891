import {useDispatch} from 'react-redux'
import {
  setLastUpdated,
  setNotification,
} from '../redux/slices/notificationSlice'

function useNotifications() {
  const dispatch = useDispatch()

  const notificationLastUpdated = () => dispatch(setLastUpdated(Date.now()))

  const notification = ({message, variant = 'success'}) => {
    dispatch(setNotification({message, variant}))
    notificationLastUpdated()
  }
  return {
    notification,
  }
}

export default useNotifications
