import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Col, Form, Row} from 'antd'

import useCategory from '../../../../services/category/categoryService'
import CategoryValidationSchema from '../validation/CategoryValidationSchema'
import {
  AutoCompleteComponent,
  InputForm,
} from '../../../../components/antd-components/form'
import {
  ButtonDanger,
  ButtonDefault,
} from '../../../../components/antd-components/button'
import {formProps} from '../../../../constants'
import usePreparationDepartmentService from '../../../../services/preparationDepartment/preparationDepartmentService'
import {useGetCategoryFormOptionsByDepartmentsList} from '../components/CategoryForm'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function SubCategoryForm({
  formData = {
    id: null,
    name: '',
    type: '',
    parentId: '',
    initialParentId: '',
    preparationDepartmentId: null,
  },
  form,
  onClose,
  text,
}) {
  const {id} = formData
  const {modalProps} = formProps
  const {addCategory} = useCategory()
  const {t} = useTranslation()

  const isLoading = useSelector(
    (state) => state.categories.isLoading === 'setCategory'
  )

  const {getPreparationDepartments, preparationDepartments} =
    usePreparationDepartmentService()

  const preparationDepartmentsOptionsList =
    useGetCategoryFormOptionsByDepartmentsList(preparationDepartments)

  const schema = CategoryValidationSchema()

  const onSubmit = async ({preparationDepartmentId, ...values}) => {
    await addCategory({
      ...values,
      type: formData.type,
      parentId: id,
      initialParentId:
        formData.initialParentId === null ? id : formData.initialParentId,
      preparationDepartmentId:
        preparationDepartmentId === 'null' ? null : preparationDepartmentId,
    })
    onClose()
  }
  const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 24},
  }

  useEffect(() => {
    getPreparationDepartments()
  }, [])
  return (
    <Form
      name="basic"
      {...layout}
      form={form}
      onFinish={onSubmit}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            name="name"
            initialValue=""
            label={t('Name')}
            rules={{...schema, required: true}}
            placeholder={t('Name')}
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <AutoCompleteComponent
            label={t('Department')}
            options={preparationDepartmentsOptionsList}
            withoutSorter
            defaultValue={
              formData.preparationDepartmentId
                ? Number(formData.preparationDepartmentId)
                : null
            }
            name="preparationDepartmentId"
            placeholder={t('Department')}
            allowClear={false}
          />
        </Col>

        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>
          <Col className="button">
            <SaveButton htmlType="submit" loading={isLoading} />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default SubCategoryForm
