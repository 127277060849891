import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Form} from 'antd'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import usePriceTableAntd from '../../../../services/price/priceTableSettingsAntd'

import usePriceTableFilterService from '../../../../services/price/priceFilterTableFilter'
import {AppTable} from '../../../../components/antd-components/app-table'
import {isCheckComa} from '../../../../utils/helpers'

import '../style.scss'

function PriceBody({setPriceTableSettings, updatePriceData}) {
  const prices = useSelector((state) => state.prices.prices)
  const pricesTotalCount = useSelector((state) => state.prices.totalCount)
  const [form] = Form.useForm()
  const {
    filterPriceMaxIcon,
    filterMarginIcon,
    filterPriceIcon,
    rangePriceMax,
    rangeMargin,
    rangePrice,
  } = usePriceTableFilterService()

  const navigate = useCustomNavigate()
  const isLoading = useSelector(
    (state) => state.prices.isLoading === 'getPrices'
  )
  const priceTableSettings = useSelector((state) => state.prices.tableSettings)
  const [priceValue, setPriceValue] = useState(0)
  const [marginRequired, setMarginRequired] = useState(0)
  const [selectedPrice, setSelectedPrice] = useState([])
  const [expandedPrice, setExpandedPrice] = useState([])
  const [editingKey, setEditingKey] = useState('')
  const expandedPrices = prices.map((price) => ({
    ...price,
    isWarning: price.priceMax * (price.marginRequired / 100 + 1) > price.price,
  }))

  useEffect(() => {
    setExpandedPrice(expandedPrices)
  }, [prices])

  useEffect(() => {
    const {id, priceMax} = selectedPrice
    const findEditRow = expandedPrice?.map((item) => {
      if (item.id === id) {
        if (
          Number(priceMax) * (Number(marginRequired / 100) + 1) >
          Number(priceValue)
        ) {
          return {...item, isWarning: true}
        }
        return {...item, isWarning: false}
      }
      return item
    })
    setExpandedPrice(findEditRow)
  }, [marginRequired, priceValue])

  const isEditing = (record) => record.id === editingKey

  const handleEdit = (record) => {
    form.setFieldsValue({
      margin: '',
      price: '',
      ...record,
    })
    setSelectedPrice(record)
    setMarginRequired(record.marginRequired)
    setPriceValue(record.price)
    setEditingKey(record.id)
  }

  const onSubmitPrice = async (id) => {
    try {
      const row = await form.validateFields(['marginRequired', 'price'])
      const {price, marginRequired} = row
      updatePriceData({
        productId: id,
        price: isCheckComa(price),
        marginRequired: isCheckComa(marginRequired),
      })
      setEditingKey('')
    } catch (err) {
      console.log(err)
    }
  }

  const handleClose = () => {
    setEditingKey('')
  }

  const navigateToDetailPricePage = (id, name, priceMax) => {
    navigate('/priceDetail', {state: {id, name, priceMax}})
  }

  const {priceColumns} = usePriceTableAntd({
    handleEdit,
    editingKey,
    isEdit: isEditing,
    form,
    save: onSubmitPrice,
    handleClose,
    setPriceValue,
    setMarginRequired,
    filterPriceMaxIcon,
    filterMarginIcon,
    filterPriceIcon,
    rangePriceMax,
    rangeMargin,
    rangePrice,
    navigateToDetailPricePage,
  })
  return (
    <AppTable
      columns={priceColumns}
      dataSource={expandedPrice}
      isLoading={isLoading}
      setTableSettings={setPriceTableSettings}
      tableSettings={priceTableSettings}
      totalCount={pricesTotalCount}
      rowClassName={(record) => (record.isWarning ? 'isWarningRow sort' : null)}
      showSelectRowCheckbox={false}
    />
  )
}

export default PriceBody
