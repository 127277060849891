import {useSelector} from 'react-redux'
import {useState} from 'react'
import {Grid, Tag} from 'antd'
import {useTranslation} from 'react-i18next'
import {CloseCircleOutlined} from '@ant-design/icons'

import AccountingForm from './AccountingForm'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'
import useAccountingTable from '../../../../services/accounting/accountingTableService'

import '../style.scss'

const {useBreakpoint} = Grid

function AccountingBody({
  accountingTableSettings,
  setAccountingTableSettings,
  setDefaultStoreTableSettings,
  isStoreAccounting,
  form,
}) {
  const [selectedAccounting, setSelectedAccounting] = useState(null)
  const [isEditDialogOpen, setEditDialogOpen] = useState(false)

  const accounting = useSelector((state) => state.accounting.accounting)
  const totalCount = useSelector((state) => state.accounting.totalCount)

  const screens = useBreakpoint()
  const isLoading = useSelector(
    (state) => state.accounting.isLoading === 'getAccounting'
  )
  const {t} = useTranslation()

  const shiftId = accountingTableSettings.f_shiftId

  const handleEdit = (record) => {
    setSelectedAccounting(record)
    form.setFieldsValue({
      accountingCategoryId: record?.accountingCategory?.id,
    })
    setEditDialogOpen(true)
  }

  const handleEditClose = () => {
    setSelectedAccounting(null)
    setEditDialogOpen(false)
  }

  const handleClearShiftFilter = () => {
    console.log(
      'accountingTableSettings.f_stores[0]',
      accountingTableSettings.f_stores[0]
    )
    return setDefaultStoreTableSettings(accountingTableSettings.f_stores[0])
  }

  const {accountingColumns} = useAccountingTable({
    handleEdit,
    isStoreAccounting,
  })

  return (
    <div>
      {selectedAccounting?.id && (
        <AntdTableActionModal
          title={t('Update record')}
          handleCancel={handleEditClose}
          open={isEditDialogOpen}
        >
          <AccountingForm
            onClose={handleEditClose}
            formData={selectedAccounting}
            form={form}
          />
        </AntdTableActionModal>
      )}
      {shiftId && (
        <div className="table-tags-container">
          <Tag color="red" onClick={handleClearShiftFilter}>
            {t('Accountings for shift')}: <b>{shiftId}</b>
            <CloseCircleOutlined />
          </Tag>
        </div>
      )}
      <AppTable
        columns={accountingColumns}
        dataSource={accounting}
        setTableSettings={setAccountingTableSettings}
        tableSettings={accountingTableSettings}
        totalCount={totalCount}
        isLoading={isLoading}
        style={{
          height:
            screens?.xs || (screens.sm && !screens.xl && !screens.md)
              ? 'calc(100vh - 28em)'
              : 'calc(100vh - 19em)',
        }}
      />
    </div>
  )
}

export default AccountingBody
