import {PageHeaderWithMobileMenu} from '../../../../../components'

function DashboardPageHeader() {
  return (
    <div>
      <PageHeaderWithMobileMenu title="Dashboard" />
    </div>
  )
}
export default DashboardPageHeader
