import currency from 'currency.js'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {Col, Row} from 'antd'
import {useMemo, useState} from 'react'
import {TableDetailsReadOnly} from '../../../../../components/antd-components/app-table'
import {
  crossedOutText,
  getRandomId,
  priceFormat,
} from '../../../../../utils/helpers'
import useWriteOffDetailTableSettings from '../../../../../services/write-off-reason/write-off-confrim/writeOffDetailTableSettings'
import ListComponent from '../../../../../components/antd-components/listComponent/ListComponent'
import useTableRenderItems from '../../../../../services/useTableRenderItems'
import {formattedDate} from '../../../../../constants'
import LoaderComponent from '../../../../../components/loaderComponent/LoaderComponent'
import './style.scss'
import ItemsTabs from '../../../../../components/itemsTabs-component/ItemsTabs'

function WriteOffModalDetail() {
  const {detailWriteOffColumns} = useWriteOffDetailTableSettings()
  const {renderNameById} = useTableRenderItems()
  const {t} = useTranslation()
  const selectedWriteOff = useSelector(
    (state) => state.writeOffConfirm.selectedWriteOffReason
  )
  const users = useSelector((state) => state.users.users)
  const writeOffs = useSelector((state) => state.writeOff.writeOffReason)
  const isLoading = useSelector(
    (state) => state.writeOffConfirm.isLoading === 'getWriteOffSelectedConfirm'
  )
  const [activeTab, setActiveTab] = useState('product')

  const detailWriteOffInformation = [
    {
      key: getRandomId(),
      name: t('Update date'),
      value: moment(selectedWriteOff?.updatedDate).format(
        formattedDate.DateWithTime
      ),
    },
    {
      key: getRandomId(),
      name: t('Create date'),
      value: moment(selectedWriteOff?.createdDate).format(
        formattedDate.DateWithTime
      ),
    },
    {
      key: getRandomId(),
      name: t('Store'),
      value: selectedWriteOff?.store?.deletedDate
        ? crossedOutText(selectedWriteOff?.store?.name)
        : selectedWriteOff?.store?.name,
    },
    {
      key: getRandomId(),
      name: t('Comment'),
      value: selectedWriteOff?.comment,
    },
    {
      key: getRandomId(),
      name: t('Author'),
      value: renderNameById({id: selectedWriteOff?.createdBy}, users),
    },
    {
      key: getRandomId(),
      name: t('Reason'),
      value: renderNameById({id: selectedWriteOff?.reasonId}, writeOffs),
    },
    {
      key: getRandomId(),
      name: t('Selling sum full'),
      value: priceFormat(currency(selectedWriteOff?.totalSalePrice).value),
    },
    {
      key: getRandomId(),
      name: t('Producing sum full'),
      value: priceFormat(currency(selectedWriteOff?.producingTotalPrice).value),
    },
  ]

  const itemsList = useMemo(() => {
    if (selectedWriteOff) {
      const {productRecords, semiFinishedRecords, rawRecords} = selectedWriteOff
      return [...productRecords, ...semiFinishedRecords, ...rawRecords]
    }
    return []
  }, [selectedWriteOff])

  const tableData = useMemo(
    () => itemsList.filter((item) => item.itemType === activeTab),
    [activeTab, itemsList]
  )

  const itemsTable = (
    <TableDetailsReadOnly
      selectedById={tableData}
      columns={detailWriteOffColumns}
      scrollX="max-content"
    />
  )

  return (
    <LoaderComponent
      isLoadingCreator={[isLoading]}
      component={
        <Row gutter={[10, 10]} style={{height: 'calc(100vh - 12em)'}}>
          <Col
            xl={8}
            sm={8}
            xs={24}
            lg={8}
            style={{border: '1px dashed #F0F0F0'}}
          >
            <ListComponent dataSource={detailWriteOffInformation} />
          </Col>
          <Col
            xl={16}
            sm={16}
            xs={24}
            lg={16}
            style={{border: '1px dashed #F0F0F0'}}
          >
            <ItemsTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              itemsTable={itemsTable}
              itemsList={itemsList}
              hideEmptyTabs
            />
          </Col>
        </Row>
      }
    />
  )
}

export default WriteOffModalDetail
