import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {useState} from 'react'
import '../style.scss'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'
import showConfirm from '../../../../components/PopupConfirm'
import usePlanningTableService from '../../../../services/plunning/planningTableService'
import usePlanningService from '../../../../services/plunning/planningService'
import {
  getPlanningLoading,
  planningData,
  planningsSelected,
  planningTableSettings,
  planningTotalCount,
} from '../../../../redux/selectors/planningSelecor'
import PlanningDetail from './PlanningDetail'

function PlanningBody() {
  const {t} = useTranslation()
  const {
    planningDelete,
    getPlanningDetail,
    setPlanningTableSettings,
    setSelectedPlanning,
    getExcelReport,
  } = usePlanningService()
  const selectedPlannings = useSelector(planningsSelected)
  const plannings = useSelector(planningData)
  const isLoading = useSelector(getPlanningLoading)
  const totalCount = useSelector(planningTotalCount)
  const tableSettings = useSelector(planningTableSettings)

  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false)

  const {clearPlanningSelectedRecords} = usePlanningService()

  const getDetailNorm = (id) => {
    getPlanningDetail(id)
  }

  const handleDeleteSubmit = (id) => {
    planningDelete({ids: [id]})
  }

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleDeleteSubmit(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const downloadPlanningExcel = (id) => {
    getExcelReport(id)
  }

  const {planningsColumns} = usePlanningTableService({
    onDelete: confirmDelete,
    showDetailInformation: getDetailNorm,
    setIsOpenDetailModal,
    tableSettings,
    downloadPlanningExcel,
  })

  const handleEditClose = () => {
    setIsOpenDetailModal(false)
    clearPlanningSelectedRecords()
  }

  return (
    <div>
      <AntdTableActionModal
        title={t('Planning information')}
        handleCancel={handleEditClose}
        width={1500}
        className="planning modal"
        open={isOpenDetailModal}
      >
        <PlanningDetail />
      </AntdTableActionModal>
      <AppTable
        dataSource={plannings}
        columns={planningsColumns}
        isLoading={isLoading}
        totalCount={totalCount}
        tableSettings={tableSettings}
        setTableSettings={setPlanningTableSettings}
        setSelectedComponent={setSelectedPlanning}
        selectedRowKeys={selectedPlannings}
        showSelectRowCheckbox
      />
    </div>
  )
}

export default PlanningBody
