import {loadingStateOptions} from '../slices/preparationDepartmentSlice'

export const lastUpdatedPreparationDepartmentSelector = (state) =>
  state.preparationDepartment.lastUpdated
export const preparationDepartmentsListSelector = (state) =>
  state.preparationDepartment.records

export const selectedPreparationDepartmentSelector = (state) =>
  state.preparationDepartment.selected

export const preparationDepartmentTableSettingsSelector = (state) =>
  state.preparationDepartment.tableSettings
export const preparationDepartmentTableTotalCount = (state) =>
  state.preparationDepartment.totalCount

export const getPreparationDepartmentLoadingSelector = (state) =>
  state.preparationDepartment.isLoading === loadingStateOptions.getList
export const isLoadingUpdatePreparationDepartment = (state) =>
  state.accountingCategory.isLoading === loadingStateOptions.getSelected
