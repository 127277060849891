import {useTranslation} from 'react-i18next'
import {FormDateTimePickerAntd} from '../../index'
import {formattedDate} from '../../../../../constants'

function UpdatedDateReadOnlyInput(props) {
  const {t} = useTranslation()
  return (
    <FormDateTimePickerAntd
      datePickerWidth="100%"
      label={t('Updated date')}
      name="updatedDate"
      format={formattedDate.Date}
      initialValue={null}
      disabled
      {...props}
    />
  )
}

export default UpdatedDateReadOnlyInput
