import {useDispatch} from 'react-redux'

import {
  deleteUnits,
  getUnit,
  getUnits,
  setUnit,
  updateUnit,
} from '../../redux/asyncServices/unitsService'
import {
  clearUnitsTableSettings,
  setLastUpdated,
  setSelectedUnits,
  setTableSettings,
  unitsReducerReset,
} from '../../redux/slices/unitsSlice'

function useUnits() {
  const dispatch = useDispatch()

  const unitsLastUpdated = () => dispatch(setLastUpdated(Date.now()))

  const getUnitsData = (params) => dispatch(getUnits({params}))

  const getUnitById = ({id}) => dispatch(getUnit({id}))

  const selectUnits = (data) => dispatch(setSelectedUnits(data))

  const addUnit = async ({name}) => {
    await dispatch(setUnit({name}))
    unitsLastUpdated()
  }

  const updateUnitData = async ({id, name}) => {
    await dispatch(updateUnit({id, name}))
    unitsLastUpdated()
  }

  const deleteUnitsData = async ({ids}) => {
    await dispatch(deleteUnits({ids}))
    unitsLastUpdated()
  }

  const setUnitTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
    unitsLastUpdated()
  }

  const unitsReset = () => dispatch(unitsReducerReset())

  const resetUnitsTableSettings = () => dispatch(clearUnitsTableSettings())

  return {
    getUnitsData,
    getUnitById,
    addUnit,
    updateUnitData,
    selectUnits,
    deleteUnitsData,
    setUnitTableSettings,
    unitsReset,
    resetUnitsTableSettings,
  }
}

export default useUnits
