import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import moment from 'moment'
import useTableRenderItems from '../useTableRenderItems'
import {typeData} from '../../utils/helpers'
import {TagsComponent} from '../../components/antd-components/tags'
import {formattedDate} from '../../constants'

const useInventoryHistoryTable = () => {
  const {t} = useTranslation()

  const {renderNameById} = useTableRenderItems()

  const stores = useSelector((state) => state.stores.stores)
  const users = useSelector((state) => state.users.users)
  const getColumns = () => [
    {
      title: t('Date'),
      dataIndex: 'createdDate',
      key: 'f_store',
      width: 2,
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },

    {
      title: t('Seller'),
      dataIndex: 'userId',
      width: 4,
      render: (value) => <p>{renderNameById({id: value}, users)}</p>,
    },
    {
      title: t('Change on'),
      dataIndex: 'change_amount',
      width: 2,
    },
    {
      title: t('Balance after operation'),
      dataIndex: 'balanceAfterChange',
      width: 3,
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      width: 3,
      render: (value, record) => (
        <TagsComponent
          keys={record.id}
          color={typeData(record.type)}
          tagText={t(record.type)}
        />
      ),
    },
    {
      title: t('Comment'),
      dataIndex: 'comment',
      width: 5,
    },
  ]

  return {inventoryHistoryColumns: getColumns()}
}

export default useInventoryHistoryTable
