import {Button} from 'antd'

import './style.scss'

function ButtonDefault({
  disabled,
  onClick,
  className,
  icon,
  text,
  style,
  ...props
}) {
  return (
    <Button
      size="medium"
      {...props}
      key={2}
      className={`${className} ${disabled ? 'disabled' : ''}`}
      disabled={disabled}
      onClick={onClick}
      style={{
        ...style,
        textTransform: 'uppercase',
      }}
    >
      {icon}
      {text}
    </Button>
  )
}

export default ButtonDefault
