import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import currency from 'currency.js'
import {getColumnSorter} from '../../utils/helpers'
import {storesSelector} from '../../redux/selectors/storeSelector'

const useStoreBalanceTable = (props) => {
  const {storeBalanceTableSettings} = props

  const {t} = useTranslation()

  const stores = useSelector(storesSelector)

  const storeAmounts = stores.map(({id, name}) => ({
    title: name,
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    width: 120,
    render: (_, item) => {
      const storeBalance = item?.storeBalances?.find(
        ({storeId}) => id === storeId
      )
      return storeBalance?.amount || 0
    },
  }))

  const storeBalanceColumns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      fixed: 'left',
      sorter: true,
      sortOrder: getColumnSorter(storeBalanceTableSettings, 'name'),
      render: (value, record) => (
        <span className="store_balance_edit_text">{value}</span>
      ),
    },
    ...storeAmounts,
    {
      title: t('Total'),
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      fixed: 'right',
      width: 100,
      sorter: true,
      sortOrder: getColumnSorter(storeBalanceTableSettings, 'total'),
      render: (_, item) =>
        item.storeBalances?.length
          ? item.storeBalances
              .map(({amount}) => amount)
              .reduce((a, b) => currency(a, {precision: 6}).add(b).value, 0)
          : 0,
    },
  ]
  return {storeBalanceColumns}
}

export default useStoreBalanceTable
