import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Grid, Row} from 'antd'
import {SearchOutlined} from '@ant-design/icons'
import {PageHeaderWithMobileMenu} from '../../../../components'
import './style.scss'
import {createItemHeaderMenu} from '../../../../utils/helpers'
import {
  storesSelector,
  selectedStoreId,
  storesIsLoading,
  storeBalanceProductNameSearchValue,
} from '../../../../redux/selectors/storeSelector'
import {storeBalanceTableSettings} from '../../../../redux/selectors/storeBalanceSelector'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import {useSearch} from '../../../../hooks/useSearch'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'

const {useBreakpoint} = Grid

function StoreBalanceEditHeader({form, setStoreBalanceTableSettings}) {
  const navigate = useCustomNavigate()

  const storeId = useSelector(selectedStoreId)
  const isLoadingStore = useSelector(storesIsLoading)
  const tableSettings = useSelector(storeBalanceTableSettings)
  const storeList = useSelector(storesSelector)

  const currentSearch = useSelector(storeBalanceProductNameSearchValue)

  const {searchValue, setSearchValue} = useSearch({
    currentSearch,
    searchFilterKey: 'f_productName',
    storeBalanceTableSettings: tableSettings,
    setStoreBalanceTableSettings,
  })

  useEffect(() => {
    if (tableSettings?.f_storeId !== null && storeList?.length) {
      form.setFieldsValue({
        store: Number(tableSettings?.f_storeId?.[0]),
      })
    }
  }, [storeId, isLoadingStore, storeList])

  const screens = useBreakpoint()

  const itemsSubTitle = (
    <div key={4}>
      {screens?.xl || screens?.lg ? (
        <Row
          justify="space-between"
          className="store_balance_page_header_subtitle"
        >
          <SearchAntdComponent
            key={11}
            value={searchValue}
            onChangeText={setSearchValue}
            suffix={<SearchOutlined />}
          />
        </Row>
      ) : null}
    </div>
  )

  const mobileSubtitleItem = (
    <div key={7}>
      <SearchAntdComponent
        key={11}
        value={searchValue}
        onChangeText={setSearchValue}
        suffix={<SearchOutlined />}
      />
    </div>
  )

  const items = createItemHeaderMenu([mobileSubtitleItem])
  return (
    <PageHeaderWithMobileMenu
      title="Edit balance"
      subTitle={itemsSubTitle}
      items={items}
      onBack={() => navigate('/storeBalance')}
    />
  )
}

export default StoreBalanceEditHeader
