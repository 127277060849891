import {useTranslation} from 'react-i18next'
import {convertToFractionalNumber, getTableColumnId} from '../../utils/helpers'

const usePurchaseProductsTable = () => {
  const {t} = useTranslation()

  const getColumns = () => [
    getTableColumnId({}),
    {
      title: t('Title'),
      dataIndex: 'name',
      key: 'number',
      width: '40%',
    },
    {
      title: t('Price'),
      align: 'right',
      dataIndex: 'pricePerItem',
      width: '20%',
      render: (value) => convertToFractionalNumber(value, 2),
    },
    {
      title: t('Amount'),
      align: 'center',
      dataIndex: 'amount',
      width: '20%',
    },
    {
      title: t('Total'),
      align: 'right',
      dataIndex: 'totalPrice',
      width: '20%',
      render: (value) => convertToFractionalNumber(value, 2),
    },
  ]
  return {purchaseProductsColumns: getColumns()}
}

export default usePurchaseProductsTable
