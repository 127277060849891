import {Grid, PageHeader} from 'antd'
import {useTranslation} from 'react-i18next'
import HeaderMobileMenu from './HeaderMobileMenu'

const {useBreakpoint} = Grid

function PageHeaderWithMobileMenu({
  items,
  title,
  showBackIcon,
  children,
  hiddenExtra = null,
  showMobileMenu = null,
  subTitle,
  extra,
  onBack = null,
  showHeaderMenu = true,
  className,
  ...props
}) {
  const {t} = useTranslation()
  const screens = useBreakpoint()
  const sm = screens.sm && !screens.xl && !screens.lg

  const defaultSizeScreen = () => {
    if (showHeaderMenu) {
      if (hiddenExtra) {
        return null
      }
      if (screens.xs || (sm && showHeaderMenu)) {
        return <HeaderMobileMenu items={items} />
      }
      if (showMobileMenu) {
        return <HeaderMobileMenu items={items} />
      }
    }

    return extra
  }

  // Enable adding ReactNode to the "title"
  const headerTitle = typeof title === 'string' ? t(title) : title

  return (
    <PageHeader
      {...props}
      className={`site-page-header ${className || ''}`}
      onBack={() => (onBack ? onBack() : window.history.back())}
      title={headerTitle}
      subTitle={subTitle}
      extra={defaultSizeScreen()}
    />
  )
}

export default PageHeaderWithMobileMenu
