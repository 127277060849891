import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getPreparationDepartmentsList = createAsyncThunk(
  'GET_PREPARATION_DEPARTMENT_LIST',
  async ({params = {}}) => {
    try {
      const response = await API.get('/dictionary/preparation-department', {
        params,
      })

      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const getPreparationDepartmentById = createAsyncThunk(
  'GET_PREPARATION_DEPARTMENT_BY_ID',
  async ({id}) => {
    if (id) {
      try {
        const response = await API.get(
          `/dictionary/preparation-department/${id}`
        )
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  }
)

export const addPreparationDepartmentThunk = createAsyncThunk(
  'ADD_PREPARATION_DEPARTMENT',
  async ({title}) => {
    try {
      const response = await API.post(`/dictionary/preparation-department`, {
        title,
      })
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const updatePreparationDepartmentThunk = createAsyncThunk(
  'UPDATE_PREPARATION_DEPARTMENT',
  async ({id, title}) => {
    if (id) {
      try {
        const response = await API.put(
          `/dictionary/preparation-department/${id}`,
          {title}
        )
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  }
)

export const deletePreparationDepartments = createAsyncThunk(
  'DELETE_PREPARATION_DEPARTMENTS',
  async ({ids = []}) => {
    try {
      const response = await API.delete(`/dictionary/preparation-department`, {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {...response.data.payload}
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)
