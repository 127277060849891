import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import NotificationHeader from './components/NotificationHeader'
import NotificationBody from './components/NotificationBody'
import useNotification from '../../../services/notifications/notificationService'

function NotificationsPage() {
  const notificationProps = useNotification()
  const {
    getNotificationData,
    updateNotification,
    updateAllNotification,
    setNotificationTableSettings,
  } = notificationProps

  const notificationsLastUpdated = useSelector(
    (state) => state.messageNotifications.lastUpdated
  )
  const notificationTableSettings = useSelector(
    (state) => state.messageNotifications.tableSettings
  )

  useEffect(() => {
    getNotificationData(notificationTableSettings)
  }, [notificationsLastUpdated])

  return (
    <>
      <NotificationHeader updateAllNotification={updateAllNotification} />
      <NotificationBody
        getNotificationData={getNotificationData}
        notificationTableSettings={notificationTableSettings}
        updateNotification={updateNotification}
        setNotificationTableSettings={setNotificationTableSettings}
      />
    </>
  )
}

export default NotificationsPage
