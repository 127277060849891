import {Grid} from 'antd'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  PlusOutlined,
  SearchOutlined,
} from '../../../../components/antd-components/icon'
import '../style.scss'
import './style.scss'
import {createItemHeaderMenu} from '../../../../utils/helpers'
import {ButtonDefault} from '../../../../components/antd-components/button'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'
import {PageHeaderWithMobileMenu} from '../../../../components'
import {storeBalanceProductNameSearchValue} from '../../../../redux/selectors/storeSelector'
import {useSearch} from '../../../../hooks/useSearch'
import useUrlWithSetParamsEnCode from '../../../../hooks/useUrlWithSetParamsEnCode'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import EditButton from '../../../../components/antd-components/main-buttons/edit-button/EditButton'
import useStoreBalance from '../../../../services/store-balance/storeBalanceService'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import ResetFiltersButton from '../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'
import {storeBalanceInitialState} from '../../../../redux/slices/storeBalanceSlice'
import useSearchParamsUrl from '../../../../hooks/useSearchParamsUrl'

const {useBreakpoint} = Grid

function StoreBalanceHeader({
  setStoreBalanceTableSettings,
  storeBalanceTableSettings,
  itemType,
}) {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const {exportExcel} = useCommon()
  const screens = useBreakpoint()
  const currentSearch = useSelector(storeBalanceProductNameSearchValue)
  const {setStoreBalanceData} = useStoreBalance()
  const {changeParamsByTable} = useSearchParamsUrl()

  const {searchValue, setSearchValue} = useSearch({
    currentSearch,
    searchFilterKey: 'f_productName',
    storeBalanceTableSettings,
    setStoreBalanceTableSettings,
  })

  const {navigateToUrlWithSearchParams} = useUrlWithSetParamsEnCode(
    '/storeBalance/edit',
    {...storeBalanceTableSettings, itemType}
  )

  const navigateAddArrival = () => {
    navigate('/refill/add')
  }

  const clickToEditStoreBalance = () => {
    navigateToUrlWithSearchParams()
    setStoreBalanceData([])
  }

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `store/balance/${itemType}`,
      tableSettings: storeBalanceTableSettings,
    })
  }

  const headerStoreBalance = [
    <SearchAntdComponent
      key={11}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <ButtonDefault
      onClick={navigateAddArrival}
      type="default"
      size="medium"
      className="header_button_refill"
      key={6}
      text={t('Replenishment')}
      icon={<PlusOutlined />}
    />,
    <EditButton
      onClick={() => clickToEditStoreBalance()}
      buttonText={t('Edit balance')}
    />,
    <DownloadButton />,
  ]

  const supplierHeaderAction = [
    <ButtonDefault
      onClick={navigateAddArrival}
      className="header_button_refill"
      key={10}
      text={t('Replenishment')}
      icon={<PlusOutlined />}
    />,
    <EditButton
      onClick={() => clickToEditStoreBalance()}
      buttonText={t('Edit balance')}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]

  const item = createItemHeaderMenu(headerStoreBalance)

  const clearFiltersHandler = () => {
    setSearchValue(null)
    changeParamsByTable(
      {...storeBalanceInitialState.tableSettings, f_productName: undefined},
      setStoreBalanceTableSettings
    )
  }

  const SubTitle = (
    <div className="subTitle_wrapper">
      <ResetFiltersButton
        clearFiltersHandler={clearFiltersHandler}
        currentTableSettings={storeBalanceTableSettings}
        initialTableSettings={storeBalanceInitialState.tableSettings}
      />
      {screens.sm && !screens.xs ? (
        <SearchAntdComponent
          key={2}
          value={searchValue}
          onChangeText={setSearchValue}
          suffix={<SearchOutlined />}
        />
      ) : null}
    </div>
  )

  return (
    <div className="site-page-header-ghost-wrapper store_balance_header">
      <PageHeaderWithMobileMenu
        title="Balance"
        items={item}
        subTitle={SubTitle}
        extra={supplierHeaderAction}
      />
    </div>
  )
}

export default StoreBalanceHeader
