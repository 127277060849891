import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import moment from 'moment/moment'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import {useEffect, useMemo, useState} from 'react'
import InformationModal from '../../../../components/informationModal/InformationModal'
import {crossedOutText, getRandomId} from '../../../../utils/helpers'
import {
  isLoadingSelectedInventory,
  selectedInventory,
} from '../../../../redux/selectors/inventorySelector'
import {formattedDate, tableRowClassNames} from '../../../../constants'
import ListComponent from '../../../../components/antd-components/listComponent/ListComponent'
import {TableDetailsReadOnly} from '../../../../components/antd-components/app-table'
import useInventoryDetailTableSettings from '../../../../services/inventory/inventoryModalWindowTableService'
import ItemsTabs from '../../../../components/itemsTabs-component/ItemsTabs'
import SwitchComponent from '../../../../components/antd-components/switch/SwitchComponent'
import useInventoryService from '../../../../services/inventory/inventoryService'

function InventoryDetailModal(props) {
  const {
    isModalOpen,
    closeModalHandler,
    setIsHistoryModalOpen,
    setSelectedItemName,
  } = props
  const selectedInventoryData = useSelector(selectedInventory)
  const isLoading = useSelector(isLoadingSelectedInventory)
  const {t} = useTranslation()
  const [activeTab, setActiveTab] = useState('product')
  const [showOnlyMismatchedRecords, setShowOnlyMismatchedRecords] =
    useState(false)
  const screens = useBreakpoint()
  const {getHistoryInventory, clearSelectedInventoryData} =
    useInventoryService()
  const storeName = selectedInventoryData?.store?.name
  const date = moment(selectedInventoryData?.submissionDate).format(
    formattedDate.Date
  )

  useEffect(() => {
    if (!isModalOpen) {
      setShowOnlyMismatchedRecords(false)
      clearSelectedInventoryData()
    }
  }, [isModalOpen])

  const detailInventoryInformation = [
    {
      key: getRandomId(),
      name: t('Update date'),
      value: selectedInventoryData?.confirmationDate
        ? moment(selectedInventoryData?.confirmationDate).format(
            formattedDate.DateWithTime
          )
        : '',
    },

    {
      key: getRandomId(),
      name: t('Create date'),
      value: moment(selectedInventoryData?.submissionDate).format(
        formattedDate.DateWithTime
      ),
    },

    {
      key: getRandomId(),
      name: t('Created'),
      value: selectedInventoryData?.submittedBy?.name,
    },

    {
      key: getRandomId(),
      name: t('Confirmed'),
      value: selectedInventoryData?.confirmedBy?.name,
    },
  ]

  const getRowClassName = (record) => {
    if (!record?.equal) {
      return tableRowClassNames.IS_ERROR
    }
    return null
  }

  const openHistoryModal = (record) => {
    const {itemId, itemType} = record
    if (itemType === 'raw') {
      setSelectedItemName(record.raw.name)
    } else {
      setSelectedItemName(record.product.name)
    }
    setIsHistoryModalOpen(true)
    if (selectedInventoryData) {
      const {storeId} = selectedInventoryData
      getHistoryInventory({
        itemId,
        itemType,
        storeId,
        params: {
          f_fromDate: selectedInventoryData?.previousInventoryConfirmationDate,
          f_toDate: selectedInventoryData?.confirmationDate,
        },
      })
    }
  }

  const {detailInventoryColumns} = useInventoryDetailTableSettings({
    openHistoryModal,
    inventoryList: [],
  })

  const inventoryItemsList = useMemo(() => {
    if (selectedInventoryData) {
      const {productRecords, semiFinishedRecords, rawRecords} =
        selectedInventoryData
      return [...productRecords, ...semiFinishedRecords, ...rawRecords]
    }
    return []
  }, [selectedInventoryData])

  const currentTabData = useMemo(() => {
    if (showOnlyMismatchedRecords) {
      return inventoryItemsList.filter(
        (inventoryItem) =>
          inventoryItem.itemType === activeTab && !inventoryItem.equal
      )
    }
    return inventoryItemsList.filter(
      (inventoryItem) => inventoryItem.itemType === activeTab
    )
  }, [activeTab, inventoryItemsList, showOnlyMismatchedRecords])

  const table = (
    <TableDetailsReadOnly
      selectedById={currentTabData}
      columns={detailInventoryColumns}
      rowKey={(record) => record.productId}
      rowClassName={(record) => getRowClassName(record)}
      scrollX={1250}
      scrollY={
        screens?.xs || (screens.sm && !screens.md)
          ? 'calc(100vh - 60em)'
          : 'calc(100vh - 23em)'
      }
      isLoading={isLoading}
    />
  )

  return (
    <InformationModal
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      closeModalHandler={closeModalHandler}
      actionsElements={
        <SwitchComponent
          checkedChildren={t('Mismatched')}
          unCheckedChildren={t('All')}
          checked={showOnlyMismatchedRecords}
          onChange={(value) => {
            setShowOnlyMismatchedRecords(value)
          }}
        />
      }
      mainContentElement={
        <ItemsTabs
          hideEmptyTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          itemsTable={table}
          itemsList={inventoryItemsList}
        />
      }
      metadataElement={
        <ListComponent
          dataSource={detailInventoryInformation}
          isOneLineData={false}
        />
      }
      title={
        <div>
          {selectedInventoryData ? (
            <>
              {t('Inventory modal header')}
              {selectedInventoryData?.store?.deletedDate
                ? crossedOutText(storeName)
                : storeName}
              {t('On')} {date}
            </>
          ) : (
            t('Inventory modal header')
          )}
        </div>
      }
    />
  )
}
export default InventoryDetailModal
