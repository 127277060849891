import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {Col, Form, Row} from 'antd'
import {useEffect} from 'react'
import useCategory from '../../../../services/category/categoryService'
import CategoryValidationSchema from '../validation/CategoryValidationSchema'
import './style.scss'
import {
  AutoCompleteComponent,
  InputForm,
} from '../../../../components/antd-components/form'
import {formProps} from '../../../../constants'
import usePreparationDepartmentService from '../../../../services/preparationDepartment/preparationDepartmentService'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

export const useGetCategoryFormOptionsByDepartmentsList = (
  preparationDepartmentsList
) => {
  const {t} = useTranslation()

  return [{id: null, title: t('Without department')}]
    .concat(preparationDepartmentsList)
    .map(({id, title}) => ({
      id,
      name: title,
    }))
}

function CategoryForm({
  formData = {
    id: null,
    name: '',
    type: '',
    parentId: null,
    preparationDepartmentId: null,
    initialParentId: null,
  },
  onClose,
  text,
  form,
}) {
  const {id} = formData
  const {addCategory, updateCategoryData} = useCategory()
  const {t} = useTranslation()
  const isLoading = useSelector(
    (state) => state.categories.isLoading === 'setCategory'
  )

  const categorySchemaValidation = CategoryValidationSchema()

  const listType = [
    {id: 'product', name: t('Product')},
    {id: 'raw', name: t('Raw')},
    {id: 'menu', name: t('Menu')},
    {id: 'supplier', name: t('Supplier')},
  ]

  const {getPreparationDepartments, preparationDepartments} =
    usePreparationDepartmentService()

  const preparationDepartmentsOptionsList =
    useGetCategoryFormOptionsByDepartmentsList(preparationDepartments)

  const {modalProps} = formProps
  useEffect(() => {
    if (id) {
      form.setFieldsValue(formData)
    }
  }, [id])

  useEffect(() => {
    getPreparationDepartments()
  }, [])

  const onSubmit = async ({preparationDepartmentId, ...values}) => {
    const submitFormValues = {
      preparationDepartmentId:
        preparationDepartmentId === 'null' ? null : preparationDepartmentId,
      ...values,
    }
    if (!id) {
      await addCategory(submitFormValues, onClose)
    } else {
      await updateCategoryData({id, ...submitFormValues}, onClose)
    }
  }
  const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 24},
  }

  return (
    <Form
      name="basic"
      {...layout}
      form={form}
      onFinish={onSubmit}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            name="name"
            label={t('Name')}
            rules={{...categorySchemaValidation, required: true}}
            placeholder={t('Name')}
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <AutoCompleteComponent
            label={t('Type')}
            returnValue="name"
            options={listType}
            name="type"
            placeholder={t('Type')}
            rules={{...categorySchemaValidation, required: true}}
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <AutoCompleteComponent
            label={t('Department')}
            options={preparationDepartmentsOptionsList}
            withoutSorter
            defaultValue={
              formData.preparationDepartmentId
                ? Number(formData.preparationDepartmentId)
                : null
            }
            name="preparationDepartmentId"
            placeholder={t('Department')}
            allowClear={false}
          />
        </Col>
        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>
          <Col className="button">
            <SaveButton htmlType="submit" loading={isLoading} />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default CategoryForm
