import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {
  PlusOutlined,
  SearchOutlined,
} from '../../../../components/antd-components/icon'
import variables from '../../../../globalStyle/colors.scss'
import useDebounce from '../../../../hooks/useDebounce'

import CategoryForm from './CategoryForm'
import withAntdModal from '../../../../HOCs/withAntdModal'
import {createItemHeaderMenu} from '../../../../utils/helpers'

import {PageHeaderWithMobileMenu} from '../../../../components'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {categoriesTreeSettings} from '../../../../redux/selectors/categoriesSelector'
import {categoriesInitialState} from '../../../../redux/slices/categorySlice'
import ResetFiltersButton from '../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'
import useCategory from '../../../../services/category/categoryService'

function CategoryHeader({setSearchCategory}) {
  const {t} = useTranslation()

  const search = useSelector(
    (state) => state.categories.treeSettings.searchCategories
  )
  const {exportExcel} = useCommon()
  const [searchValue, setSearchValue] = useState(search)
  const categoriesSettings = useSelector(categoriesTreeSettings)
  const {resetCategoriesTreeSettings} = useCategory()

  const AddCategoriesFormWithModal = withAntdModal(CategoryForm, {
    okButtonText: t('Add'),
    footer: null,
    title: t('Add category'),
    icon: <PlusOutlined />,
    style: {
      textTransform: 'uppercase',
      width: '100%',
      color: variables.add,
    },
    text: t('To add a unit, please enter a name and click add.'),
  })

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `dictionary/categories`,
      tableSettings: {...categoriesSettings, f_name: search},
    })
  }

  const debouncedValue = useDebounce(searchValue, 500)
  const categoryActionHeader = [
    <SearchAntdComponent
      key={1}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <AddCategoriesFormWithModal key={3} text={t('Add')} />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]

  const item = createItemHeaderMenu(categoryActionHeader)

  useEffect(() => {
    const debouncedSearchValue = !debouncedValue ? null : debouncedValue
    if (search !== debouncedSearchValue) {
      setSearchCategory(debouncedSearchValue)
    }
  }, [debouncedValue])

  const clearFiltersHandler = () => {
    setSearchValue('')
    resetCategoriesTreeSettings()
  }

  return (
    <PageHeaderWithMobileMenu
      title="Categories"
      subTitle={
        <ResetFiltersButton
          clearFiltersHandler={clearFiltersHandler}
          currentTableSettings={categoriesSettings}
          initialTableSettings={categoriesInitialState.treeSettings}
        />
      }
      extra={categoryActionHeader}
      items={item}
    />
  )
}

export default CategoryHeader
