import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getSuppliers = createAsyncThunk(
  'GET_SUPPLIERS',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/dictionary/suppliers', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        suppliers: state.suppliers.suppliers,
        totalCount: state.suppliers.totalCount,
      }
    }
    return null
  }
)

export const getSupplier = createAsyncThunk('GET_SUPPLIER', async ({id}) => {
  if (id) {
    try {
      const response = await API.get(`/dictionary/suppliers/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
  }
  return null
})

export const setSupplier = createAsyncThunk('SET_SUPPLIER', async (data) => {
  try {
    const response = await API.post('/dictionary/suppliers', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const updateSupplier = createAsyncThunk(
  'UPDATE_SUPPLIER',
  async (data) => {
    const {id} = data
    try {
      const response = await API.put(`/dictionary/suppliers/${id}`, data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const deleteSuppliers = createAsyncThunk(
  'DELETE_SUPPLIERS',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete('/dictionary/suppliers', {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {...response.data.payload, selectedSuppliers: []}
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedSuppliers: state.suppliers.selectedSuppliers,
      }
    }
    return null
  }
)
