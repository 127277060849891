import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Form} from 'antd'
import AccountingCategoryHeader from './components/AccountingCategoryHeader'
import AccountingCategoryBody from './components/AccountingCategoryBody'
import useAccountingCategoryService from '../../../services/accountingCategory/accountingCategoryService'
import {
  accountingCategoryTableSettings,
  lastUpdatedAccountingCategory,
} from '../../../redux/selectors/accountingCategorySelector'

function AccountingCategoryPage() {
  const {getAccountingCategoryData} = useAccountingCategoryService()
  const lastUpdated = useSelector(lastUpdatedAccountingCategory)
  const tableSettings = useSelector(accountingCategoryTableSettings)
  const [form] = Form.useForm()

  useEffect(() => {
    getAccountingCategoryData(tableSettings)
  }, [lastUpdated])

  return (
    <div>
      <AccountingCategoryHeader form={form} />
      <AccountingCategoryBody form={form} />
    </div>
  )
}

export default AccountingCategoryPage
