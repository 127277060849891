import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import useTableRenderItems from '../../../../../../../../services/useTableRenderItems'
import EditActionButton from '../../../../../../../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../../../../../../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const useIngredientsTable = ({onDelete, onEdit}) => {
  const {t} = useTranslation()
  const rawMaterials = useSelector((state) => state.rawMaterials.rawMaterials)
  const units = useSelector((state) => state.units.units)
  const {renderNameById} = useTableRenderItems()

  const findUnit = (id) => {
    const rawMaterial = rawMaterials.find((item) => item.id === id)
    return !rawMaterial ? rawMaterial : rawMaterial?.unitId
  }

  const getColumns = () => [
    {
      key: 'sort',
      width: '4%',
      align: 'center',
    },
    {
      title: t('Name'),
      dataIndex: 'rawId',
      key: 'name',
      align: 'center',
      width: '21%',
      render: (value, record) => record?.ingredient?.name,
    },

    {
      title: t('Gross'),
      dataIndex: 'gross',
      align: 'center',
      width: '21%',
    },
    {
      title: t('Net'),
      dataIndex: 'net',
      align: 'center',
      width: '20%',
    },
    {
      title: t('Units'),
      dataIndex: 'units',
      align: 'center',
      width: '21%',
      render: (value, record) =>
        renderNameById({id: findUnit(record.rawId)}, units),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '13%',
      render: (_, record) => (
        <div>
          <EditActionButton
            onClick={(e) => {
              e.stopPropagation()
              onEdit(record)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDelete({id: record.id, itemType: 'ingredient'})
            }}
          />
        </div>
      ),
    },
  ]

  return {ingredientsColumns: getColumns()}
}

export default useIngredientsTable
