import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../../components/antd-components/app-table'
import {ButtonDefault} from '../../../../../components/antd-components/button'
import useOrderPaymentsTableService from '../../../../../services/orders/useOrderPaymentsTableService'
import AddOrderPaymentModal from './AddOrderPaymentModal'

import './style.scss'
import {
  currencyAddWithPrecision,
  priceFormat,
} from '../../../../../utils/helpers'
import CancelButton from '../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import {currentLanguageSelector} from '../../../../../redux/selectors/commonSelector'

function OrderPaymentsFooter({total}) {
  const {t} = useTranslation()
  return (
    <div className="order-payments_table-footer">
      <span>{t('Total')}:</span>
      <span>{priceFormat(total)}</span>
    </div>
  )
}

function OrderPaymentsModal({
  open,
  onCancel,
  orderId,
  orderPayments,
  isLoading,
  withAddPayment,
}) {
  const {t} = useTranslation()
  const language = useSelector(currentLanguageSelector)
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false)

  const handleAddPayment = () => {
    setOpenAddPaymentModal(true)
    onCancel()
  }

  const total = Array.isArray(orderPayments)
    ? orderPayments
        .map(({total}) => total)
        .reduce((prev, curr) => currencyAddWithPrecision(prev, curr, 2), 0)
    : 0

  const showCheckDetailHandler = (checkId) => {
    window.open(`/${language}/purchase?purchaseId=${checkId}`, '_blank')
  }

  const {columns} = useOrderPaymentsTableService({showCheckDetailHandler})

  return (
    <>
      <AntdTableActionModal
        className="orderPayments_modal"
        width={1100}
        open={open}
        title={`${t('Payments')} ${t('for')} ${t(
          'Order'
        ).toLowerCase()} #${orderId}`}
        handleCancel={onCancel}
        footer={
          <div className="modal_footer">
            <CancelButton className="cancel_btn" onClick={onCancel} />
            {withAddPayment && (
              <ButtonDefault
                text={`${t('Add payment')}`}
                type="primary"
                onClick={handleAddPayment}
              />
            )}
          </div>
        }
      >
        <AppTable
          columns={columns}
          dataSource={orderPayments}
          isLoading={isLoading}
          showPagination={false}
          className="order-payments_table"
          withTableWrapper={false}
          footer={() => OrderPaymentsFooter({total})}
        />
      </AntdTableActionModal>
      <AddOrderPaymentModal
        open={openAddPaymentModal}
        onCancel={() => setOpenAddPaymentModal(false)}
        orderId={orderId}
      />
    </>
  )
}

export default OrderPaymentsModal
