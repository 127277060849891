import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Form} from 'antd'
import AccountingBody from './components/AccountingBody'
import AccountingHeader from './components/AccountingHeader'
import useAccounting from '../../../services/accounting/accountingService'
import useUsers from '../../../services/users/usersService'
import StatisticCard from './components/StatisticCard'
import {
  accountingSummary,
  accountingTableSettingsSelector,
  lastUpdatedAccounting,
  selectedAccountingTableSettings,
} from '../../../redux/selectors/accountingSelector'
import useAccountingCategoryService from '../../../services/accountingCategory/accountingCategoryService'

function AccountingPage() {
  const {
    getAccountingData,
    setAccountingTableSettings,
    setDefaultStoreTableSettings,
    handleSetActiveAccountingTargetId,
    resetAccountingShiftId,
  } = useAccounting()

  const {getAccountingCategoryData} = useAccountingCategoryService()

  const usersProps = useUsers()
  const {getUsersData} = usersProps
  const [form] = Form.useForm()

  const accountingLastUpdated = useSelector(lastUpdatedAccounting)

  const summary = useSelector(accountingSummary)

  const accountingTableSettings = useSelector(selectedAccountingTableSettings)
  const {isStoreAccounting} = useSelector(accountingTableSettingsSelector)

  useEffect(() => {
    getAccountingData(accountingTableSettings)
  }, [accountingLastUpdated])

  useEffect(() => {
    // Stores are already fetched in the companies dropdown
    getUsersData()
    getAccountingCategoryData()
  }, [])

  return (
    <div>
      <AccountingHeader
        form={form}
        isStoreAccounting={isStoreAccounting}
        withAddBtn={!isStoreAccounting}
        handleSetActiveAccountingTargetId={(id) => {
          handleSetActiveAccountingTargetId(id)
          if (accountingTableSettings?.f_shiftId) resetAccountingShiftId()
        }}
      />
      <StatisticCard
        income={summary?.incomes}
        outcomes={summary?.outcomes}
        total={summary?.total}
        balance={summary?.balance}
      />
      <AccountingBody
        setAccountingTableSettings={setAccountingTableSettings}
        setDefaultStoreTableSettings={setDefaultStoreTableSettings}
        accountingTableSettings={accountingTableSettings}
        isStoreAccounting={isStoreAccounting}
        form={form}
      />
    </div>
  )
}

export default AccountingPage
