import {useTranslation} from 'react-i18next'

const useSentProductTable = () => {
  const {t} = useTranslation()

  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'itemName',
      width: '33%',
      align: 'center',
    },
    {
      title: t('Sent'),
      dataIndex: 'sentAmount',
      width: '33%',
      align: 'center',
    },
    {
      title: t('processed'),
      dataIndex: 'receivedAmount',
      width: '33%',
      align: 'center',
    },
  ]
  return {
    sentProductsColumns: getColumns(),
  }
}
export default useSentProductTable
