import {loadingStateOptions} from '../slices/incasationSlice'

export const incasationsListLoadingSelector = (state) =>
  state.incasation.isLoading === loadingStateOptions.getIncasations
export const incasationsListSelector = (state) => state.incasation.incasations
export const incasationsTotalCountSelector = (state) =>
  state.incasation.totalCount
export const incasationsTableSettingsSelector = (state) =>
  state.incasation.tableSettings
export const incasationLastUpdatedSelector = (state) =>
  state.incasation.lastUpdated

export const selectedIncasationSelector = (state) => state.incasation.selected
export const isSelectedIncasationLoadingSelector = (state) =>
  state.incasation.isLoading === loadingStateOptions.getSelected
