import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import useTableRenderItems from '../useTableRenderItems'
import {getColumnSorter} from '../../utils/helpers'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const useProductsTable = ({
  onDelete,
  onEdit,
  rangeAmount,
  filterAmountIcon,
  tableSettings,
}) => {
  const {t} = useTranslation()
  const units = useSelector((state) => state.units.units)
  const categories = useSelector((state) => state.categories.categories)

  const {renderNameById} = useTableRenderItems()

  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'f_name',
      align: 'center',
      width: '30%',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'name'),
      filteredValue: '',
    },

    {
      title: t('Category'),
      dataIndex: 'categoryId',
      key: 'f_categoryId',
      align: 'center',
      width: '30%',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'categoryId'),
      filteredValue: tableSettings.f_categoryId,
      filters: categories.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      render: (_, record) =>
        renderNameById({id: record.categoryId}, categories),
    },

    {
      title: t('Amount'),
      dataIndex: 'amount',
      align: 'center',
      width: '11%',
      filterDropdown: rangeAmount,
      filterIcon: filterAmountIcon,
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'amount'),
      filteredValue: '',
    },

    {
      title: t('Units'),
      dataIndex: 'unitId',
      key: 'f_unitId',
      align: 'center',
      width: '11%',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'unitId'),
      filteredValue: tableSettings.f_unitId,
      filters: units.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),

      render: (_, record) => renderNameById({id: record.unitId}, units),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: '8%',
      render: (_, record) => (
        <div>
          <EditActionButton
            onClick={(e) => {
              e.stopPropagation()
              onEdit(record.id)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDelete(record.id)
            }}
          />
        </div>
      ),
    },
  ]
  return {productsColumns: getColumns()}
}

export default useProductsTable
