import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  copyNorms,
  deleteNorm,
  getNormById,
  getNorms,
  setNorm,
  updateNorm,
} from '../../redux/asyncServices/normService'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import {
  changeAmountStore,
  clearDraftRecords,
  clearNormTableSettings,
  deleteRecords,
  getRecordById,
  setDraftNorms,
  setDraftRawId,
  setDraftRecords,
  setIsActive,
  setSelectedId,
  setSelectedNormForDelete,
  setTableSettings,
} from '../../redux/slices/normSlice'
import notify from '../../components/antd-components/notification/notify'

function useNormService() {
  const navigate = useCustomNavigate()
  const successAddNorm = (description) => {
    notify({
      description: t(description),
      type: 'success',
    })
    navigate('/norm')
  }

  const failedAddNorm = (description) => {
    notify({
      description: t(description),
      type: 'error',
    })
  }

  const dispatch = useDispatch()
  const {t} = useTranslation()

  const getNormData = (params) => dispatch(getNorms({params}))
  const getNormDetail = ({id, params}) => dispatch(getNormById({id, params}))
  const setNorms = (data) => dispatch(setDraftNorms(data))
  const setRecordsNorm = (data) => dispatch(setDraftRecords(data))
  const addNewNorm = (value) =>
    dispatch(setNorm(value)).then((response) =>
      response.payload
        ? successAddNorm('Success add norm')
        : failedAddNorm('Error add norm')
    )
  const setRawId = ({id, rawId}) => dispatch(setDraftRawId({id, rawId}))
  const draftRecordsClear = () => dispatch(clearDraftRecords())
  const deleteSelectedRecords = (id) => dispatch(deleteRecords(id))
  const getRowBySelectedId = (id) => dispatch(getRecordById({id}))
  const setIsActiveCheckbox = (id) => dispatch(setIsActive({id}))
  const changeAmountInput = ({id, storeId, value, name}) =>
    dispatch(changeAmountStore({id, storeId, value, name}))

  const setNormTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
  }

  const normDelete = ({ids}) => {
    dispatch(deleteNorm({ids}))
  }
  const setSelectedNorm = (id) => {
    dispatch(setSelectedNormForDelete(id))
  }

  const updateNormData = async (data) => {
    dispatch(updateNorm(data)).then((response) =>
      response.payload
        ? successAddNorm('Success update norm')
        : failedAddNorm('Error update norm')
    )
  }

  const copySelectedNorm = (ids) => {
    dispatch(copyNorms(ids)).then((response) =>
      response.payload ? successAddNorm('Success') : failedAddNorm('Error')
    )
  }
  const setSelectedNormId = (id) => {
    dispatch(setSelectedId(id))
  }
  const resetNormTableSettings = () => dispatch(clearNormTableSettings())

  return {
    getNormDetail,
    getNormData,
    draftRecordsClear,
    setNormTableSettings,
    setNorms,
    setRecordsNorm,
    changeAmountInput,
    setRawId,
    deleteSelectedRecords,
    getRowBySelectedId,
    setIsActiveCheckbox,
    addNewNorm,
    normDelete,
    updateNormData,
    setSelectedNorm,
    setSelectedNormId,
    copySelectedNorm,
    resetNormTableSettings,
  }
}

export default useNormService
