import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import '../../pages/main/accounting/style.scss'
import moment from 'moment'
import {getColumnSorter} from '../../utils/helpers'
import {preparationDepartmentTableSettingsSelector} from '../../redux/selectors/preparationDepartmentSelector'
import {formattedDate} from '../../constants'
import usePreparationDepartmentService from './preparationDepartmentService'
import {useDateRangeTableSettings} from '../../hooks'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const usePreparationDepartmentTable = ({handleEdit, handleDelete}) => {
  const {setPreparationDepartmentsTableSettings} =
    usePreparationDepartmentService()

  const tableSettings = useSelector(preparationDepartmentTableSettingsSelector)

  const {t} = useTranslation()

  const createdDateFilterProps = useDateRangeTableSettings({
    tableSettings,
    setTableSettings: setPreparationDepartmentsTableSettings,
  })

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'title',
      key: 'f_title',
      filteredValue: tableSettings.f_title,
      align: 'center',
      width: '50%',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'title'),
    },
    {
      title: t('Create date'),
      dataIndex: 'createdDate',
      key: 'f_createdDate',
      align: 'center',
      width: '30%',
      ...createdDateFilterProps,
      filteredValue: '',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'createdDate'),
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => (
        <div>
          <EditActionButton
            onClick={() => {
              handleEdit(record)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              handleDelete(record.id)
            }}
          />
        </div>
      ),
    },
  ]
  return {columns}
}

export default usePreparationDepartmentTable
