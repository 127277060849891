import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {ACCOUNTING_TYPES} from '../../../../constants'

function AccountingValidation({type}) {
  const {t} = useTranslation()
  const schema = yup.object().shape({
    amount:
      type === ACCOUNTING_TYPES.EXPENSES
        ? yup
            .number()
            .typeError(t('Value must be an number'))
            .required(t('This field is required.'))
            .negative(t('Value cannot be 0 or greater than zero'))
            .transform((_, value) => {
              if (value === null) {
                return undefined
              }
              if (String(value).includes(',')) {
                return +value.replace(/,/, '.')
              }
              return +value
            })
        : yup
            .number()
            .typeError(t('This field is required.'))
            .required(t('This field is required.'))
            .positive(t('Value cannot be 0 or less than zero'))
            .transform((_, value) => {
              if (value === null) {
                return undefined
              }
              if (String(value).includes(',')) {
                return +value.replace(/,/, '.')
              }
              return +value
            }),

    comment: yup
      .string()
      .notRequired()
      .nullable()
      .max(250, t('Max length 250 symbols')),
    storeId: yup.string().notRequired().nullable(),
    accountingCategoryId: yup
      .string()
      .required(t('This field is required.'))
      .nullable(),
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default AccountingValidation
