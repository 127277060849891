import {Col, Form, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {useEffect} from 'react'
import moment from 'moment'
import {
  AutoCompleteComponent,
  FormDateTimePickerAntd,
  InputForm,
} from '../../../../../../../../components/antd-components/form'
import {formattedDate} from '../../../../../../../../constants'
import {
  isRefillDataLoading,
  selectedRefill,
} from '../../../../../../../../redux/selectors/refillSelector'
import RefillFormValidation from './validation'
import LoadingSpinner from '../../../../../../../../components/antd-components/loading-spinner/LoadingSpinner'
import CreatedDateReadOnlyInput from '../../../../../../../../components/antd-components/form/readOnly-inputs/createdDate-input/CreatedDateReadOnlyInput'
import UpdatedDateReadOnlyInput from '../../../../../../../../components/antd-components/form/readOnly-inputs/updatedDate-input/UpdatedDateReadOnlyInput'
import CommentTextArea from '../../../../../../../../components/antd-components/form/input-form/CommentTextArea'

function RefillForm(props) {
  const {refillForm, isPageReadOnly} = props
  const {t} = useTranslation()
  const stores = useSelector((state) => state.stores.stores)
  const users = useSelector((state) => state.users.users)
  const isLoading = useSelector(isRefillDataLoading)
  const selectedRefillData = useSelector(selectedRefill)
  const {refillId} = useParams()
  const validation = RefillFormValidation()

  useEffect(() => {
    if (selectedRefillData) {
      const {deliveryDate, createdDate, updatedDate} = selectedRefillData
      refillForm.setFieldsValue({
        ...selectedRefillData,
        deliveryDate: deliveryDate ? moment(deliveryDate) : null,
        createdDate: createdDate ? moment(createdDate) : null,
        updatedDate: updatedDate ? moment(updatedDate) : null,
      })
    } else {
      refillForm.resetFields()
    }
  }, [selectedRefillData])

  return (
    <div>
      <Form form={refillForm} labelCol={{span: 24}} wrapperCol={{span: 24}}>
        <Row gutter={[10, 0]}>
          <Col span={24} xs={24} sm={24} md={12} xl={8}>
            <AutoCompleteComponent
              options={stores}
              className="fullScreenSelectStyle"
              label={t('Shop')}
              name="storeId"
              placeholder={t('Shop')}
              rules={{...validation, required: true}}
              disabled={isLoading || isPageReadOnly}
            />
          </Col>
          <Col span={24} xs={24} sm={24} md={12} xl={8}>
            <FormDateTimePickerAntd
              datePickerWidth="100%"
              label={t('Delivery date')}
              name="deliveryDate"
              placeholder={t('Delivery date')}
              format={formattedDate.Date}
              rules={{...validation, required: true}}
              disabled={isLoading || isPageReadOnly}
              initialValue={null}
            />
          </Col>
          <Col className="gutter-row" span={24} xs={24} sm={24} md={24} xl={24}>
            <CommentTextArea
              rules={validation}
              disabled={isLoading || isPageReadOnly}
            />
          </Col>
          {isLoading && (
            <Col span={24} xs={24} sm={24} md={24} xl={24}>
              <LoadingSpinner />
            </Col>
          )}
          {refillId && (
            <>
              <Col span={24} xs={24} sm={24} md={12} xl={8}>
                <AutoCompleteComponent
                  options={users}
                  label={t('Created by')}
                  name="createdBy"
                  disabled
                />
              </Col>
              <Col span={24} xs={24} sm={24} md={12} xl={8}>
                <CreatedDateReadOnlyInput />
              </Col>
              <Col span={24} xs={24} sm={24} md={12} xl={8}>
                <InputForm label={`${t('Shift')} #`} disabled name="shiftId" />
              </Col>
              <Col span={24} xs={24} sm={24} md={12} xl={8}>
                <AutoCompleteComponent
                  options={users}
                  label={t('Updated by')}
                  name="updatedBy"
                  disabled
                />
              </Col>
              <Col span={24} xs={24} sm={24} md={12} xl={8}>
                <UpdatedDateReadOnlyInput />
              </Col>
            </>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default RefillForm
