import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

function AccountingCategoryValidation() {
  const {t} = useTranslation()
  const schema = yup.object().shape({
    title: yup
      .string()
      .required(t('This field is required.'))
      .max(250, t('Max length 250 symbols')),
    credit: yup.string().required(t('This field is required.')).nullable(),
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default AccountingCategoryValidation
