import {useMemo} from 'react'
import {AppTable} from '../../../../components/antd-components/app-table'
import useEditStoreBalanceTable from '../../../../services/store-balance/store-balance-edit/storeBalanceEditTableService'

function StoreBalanceEditTable({
  onSetTableSettings,
  tableSettings,
  onSubmitUpdatedStoreBalance,
  handleAmountEdit,
  balanceList,
  isLoading,
}) {
  const storesList = useMemo(
    () =>
      balanceList[0]?.storeBalances
        ? balanceList[0].storeBalances.map(({store}) => store)
        : [],
    [balanceList]
  )

  const {editStoreBalanceColumns} = useEditStoreBalanceTable({
    storeBalanceTableSettings: tableSettings,
    onSubmit: onSubmitUpdatedStoreBalance,
    handleAmountEdit,
    storesList,
  })

  return (
    <div>
      <AppTable
        columns={editStoreBalanceColumns}
        dataSource={balanceList}
        showPagination={false}
        isLoading={isLoading}
        tableSettings={tableSettings}
        setTableSettings={onSetTableSettings}
        className="store_balance_edit_table"
      />
    </div>
  )
}

export default StoreBalanceEditTable
