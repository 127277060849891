import {createSlice} from '@reduxjs/toolkit'
import {
  deleteProducts,
  getProduct,
  getProducts,
  getProductsBalance,
  setProduct,
  updateProduct,
} from '../asyncServices/productsService'
import {resetReduxStore} from '../resetStore'

export const productsInitialState = {
  products: [],
  productsBalance: [],
  totalCount: 0,
  selectedProducts: [],
  selectedProduct: null,
  productsTableSettings: {
    s_direction: 'ASC',
    s_field: 'name',
    f_name: null,
    f_categoryId: null,
    f_unitId: null,
    pageSize: 100,
    page: 1,
    f_currentAmount: null,
    f_previousAmount: null,
  },
  semiFinishedTableSettings: {
    s_direction: 'ASC',
    s_field: 'name',
    f_name: null,
    f_categoryId: null,
    f_unitId: null,
    pageSize: 100,
    page: 1,
    f_currentAmount: null,
    f_previousAmount: null,
  },
  lastUpdated: null,
  isLoading: null,
  selectedId: null,
  type: 'final',
}

const productsSlice = createSlice({
  name: 'productsSlice',
  initialState: productsInitialState,
  reducers: {
    setSelectedProducts: (state, action) => {
      state.selectedProducts = action.payload
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    clearProductsList: (state) => {
      state.products = []
    },
    setProductsTableSettings: (state, action) => {
      state.productsTableSettings = {
        ...state.productsTableSettings,
        ...action.payload,
      }
    },
    clearProductsTableFilters: (state) => {
      state.productsTableSettings = {
        ...state.productsTableSettings,
        f_currentAmount: null,
        f_previousAmount: null,
        f_categoryId: null,
        f_unitId: null,
      }
    },
    setSemiFinishedTableSetting: (state, action) => {
      state.semiFinishedTableSettings = {
        ...state.semiFinishedTableSettings,
        ...action.payload,
      }
    },
    clearSemiFinishedTableFilters: (state) => {
      state.semiFinishedTableSettings = {
        ...state.semiFinishedTableSettings,
        f_currentAmount: null,
        f_previousAmount: null,
        f_categoryId: null,
        f_unitId: null,
      }
    },
    clearProductsTableSettings: (state) => {
      state.productsTableSettings = productsInitialState.productsTableSettings
      state.lastUpdated = Date.now()
    },
    clearSemiFinishedTableSettings: (state) => {
      state.semiFinishedTableSettings =
        productsInitialState.semiFinishedTableSettings
      state.lastUpdated = Date.now()
    },

    productsReducerReset: () => productsInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.isLoading = 'getProducts'
    })
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload.products
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getProducts.rejected, (state) => {
      state.isLoading = null
    })
    builder.addCase(getProduct.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.selectedProduct = action.payload
      state.isLoading = false
    })
    builder.addCase(getProduct.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(setProduct.pending, (state) => {
      state.isLoading = 'setProducts'
    })
    builder.addCase(setProduct.fulfilled, (state, action) => {
      state.selectedProduct = action.payload
      state.isLoading = null
    })
    builder.addCase(setProduct.rejected, (state) => {
      state.isLoading = null
    })
    builder.addCase(updateProduct.pending, (state) => {
      state.isLoading = 'updateProduct'
    })
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      state.selectedProduct = action.payload
      state.isLoading = null
    })
    builder.addCase(updateProduct.rejected, (state) => {
      state.isLoading = null
    })
    builder.addCase(deleteProducts.pending, (state) => {
      state.isLoading = 'deleteProducts'
    })
    builder.addCase(deleteProducts.fulfilled, (state, action) => {
      state.selectedProducts = action.payload.selectedProducts
      state.isLoading = null
    })
    builder.addCase(deleteProducts.rejected, (state) => {
      state.isLoading = null
    })
    builder.addCase(getProductsBalance.pending, (state) => {
      state.productsBalance = []
    })
    builder.addCase(getProductsBalance.fulfilled, (state, action) => {
      state.productsBalance = action.payload.productBalances
    })
    builder.addCase(resetReduxStore, (state) => {
      state = productsInitialState
    })
  },
})

export const {
  setSelectedProducts,
  setLastUpdated,
  productsReducerReset,
  setSelectedId,
  setType,
  setProductsTableSettings,
  clearProductsTableFilters,
  setSemiFinishedTableSetting,
  clearSemiFinishedTableFilters,
  clearProductsList,
  clearSemiFinishedTableSettings,
  clearProductsTableSettings,
} = productsSlice.actions
export default productsSlice.reducer
