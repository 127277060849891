import {Outlet} from 'react-router-dom'
import axios from 'axios'
import API from '../redux/asyncServices/apiService'
import {store} from '../redux/store'

import useAuth from '../services/authService'
import useNotifications from '../services/notificationService'
import {parseErrors} from '../utils/handleErrors'

function InterceptorsProvider() {
  const {setTokensData, apiRefreshToken, logout} = useAuth()
  const {notification} = useNotifications()

  let isRefreshing = true

  API.interceptors.request.use((config) => {
    config.headers.Authorization = store.getState().auth.accessToken
    config.headers['Accept-Language'] = store.getState().common.language
    config.baseURL = process.env.REACT_APP_PUBLIC_URL
    return config
  })

  let requestQueue = []

  const addToQueue = (callback) => {
    requestQueue.push(callback)
  }

  const executeQueue = (token) => {
    requestQueue.map((callback) => callback(token))
  }

  API.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response?.status === 400 &&
        Array.isArray(error.response.data.errors)
      ) {
        const errorMessages = parseErrors(error.response.data.errors)
        errorMessages.forEach((errorMessage) => {
          notification({
            message: errorMessage,
            variant: 'error',
          })
        })
        return Promise.reject(error)
      }

      if (
        error.config &&
        error.response?.status !== 401 &&
        error.response?.data?.message
      ) {
        return notification({
          message: error.response?.data?.message,
          variant: 'error',
        })
      }

      if (error.config && error.response?.status === 401) {
        if (isRefreshing) {
          return logout()
        }
        if (!isRefreshing) {
          isRefreshing = true

          apiRefreshToken({refreshToken: store.getState().auth.refreshToken})
            .then((response) => {
              isRefreshing = false
              setTokensData(response?.data?.payload)
              executeQueue(response.data.payload?.accessToken)
              requestQueue = []
            })
            .catch(() => {
              isRefreshing = false
              requestQueue = []
            })
        }
        return new Promise((resolve) => {
          addToQueue((token) => {
            error.config.headers.Authorization = token
            resolve(axios(error.config))
          })
        })
      }
      return Promise.reject(error)
    }
  )

  return <Outlet />
}

export default InterceptorsProvider
