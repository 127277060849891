import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import DetailPriceHeader from './DetailPriceHeader'
import DetailsPriceBody from './DetailsPriceBody'
import usePrice from '../../../../../services/price/priceService'

function DetailPrice() {
  const {state} = useLocation()
  const id = state?.id || null
  const name = state?.name || null
  const detailPriceInformation = useSelector(
    (state) => state.prices.detailInformationBySelectedPrice
  )

  const {getDetailInformationBySelectedPrice} = usePrice()

  useEffect(() => {
    getDetailInformationBySelectedPrice({id})
  }, [id])

  return (
    <div>
      <DetailPriceHeader
        header={name}
        extraContent={detailPriceInformation?.priceMax}
      />
      <DetailsPriceBody detailPriceInformation={detailPriceInformation} />
    </div>
  )
}

export default DetailPrice
