import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {ExclamationCircleOutlined} from '@ant-design/icons'

import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'
import useCommon from '../../../../services/commonService'
import {
  modalEditWindowStatus,
  modalWindowStatus,
} from '../../../../redux/selectors/commonSelector'
import showConfirm from '../../../../components/PopupConfirm'
import usePreparationDepartmentService from '../../../../services/preparationDepartment/preparationDepartmentService'
import {
  getPreparationDepartmentLoadingSelector,
  preparationDepartmentTableSettingsSelector,
  preparationDepartmentTableTotalCount,
  selectedPreparationDepartmentSelector,
} from '../../../../redux/selectors/preparationDepartmentSelector'
import PreparationDepartmentForm from './PreparationDepartmentForm'
import usePreparationDepartmentTable from '../../../../services/preparationDepartment/preparationDepartmentTableService'

function PreparationDepartmentBody({form}) {
  const {
    getPreparationDepartmentById,
    setPreparationDepartmentsTableSettings,
    setSelectedPreparationDepartmentData,
    addPreparationDepartment,
    updatePreparationDepartment,
    deletePreparationDepartmentsList,
    preparationDepartments,
  } = usePreparationDepartmentService()

  const {changeOpenModalWindow, changeOpenEditModalWindow} = useCommon()
  const tableSettings = useSelector(preparationDepartmentTableSettingsSelector)
  const totalCount = useSelector(preparationDepartmentTableTotalCount)
  const isLoading = useSelector(getPreparationDepartmentLoadingSelector)
  const selectedPreparationDepartmentData = useSelector(
    selectedPreparationDepartmentSelector
  )
  const selectedRowKeys = useSelector(selectedPreparationDepartmentSelector)
  const statusModalWindow = useSelector(modalWindowStatus)
  const statusEditModalWindow = useSelector(modalEditWindowStatus)

  const {t} = useTranslation()

  const handleEdit = (record) => {
    getPreparationDepartmentById(record.id)
    changeOpenEditModalWindow(true)
  }

  const handleCloseEdit = () => {
    changeOpenEditModalWindow(false)
  }

  const handleCloseAdd = () => {
    changeOpenModalWindow(false)
    form.resetFields()
  }

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => deletePreparationDepartmentsList([id]),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const {columns} = usePreparationDepartmentTable({
    handleEdit,
    handleDelete: confirmDelete,
  })

  const selectedToEditId = Array.isArray(selectedPreparationDepartmentData)
    ? selectedPreparationDepartmentData[0]?.id
    : null

  return (
    <div>
      {selectedToEditId && (
        <AntdTableActionModal
          handleCancel={handleCloseEdit}
          open={statusEditModalWindow}
          title={t('Update preparation department')}
        >
          <PreparationDepartmentForm
            onClose={handleCloseEdit}
            form={form}
            formData={selectedPreparationDepartmentData[0]}
            text={t('Update')}
            handleSubmit={updatePreparationDepartment}
          />
        </AntdTableActionModal>
      )}
      <AntdTableActionModal
        title={t('Add preparation department')}
        handleCancel={handleCloseAdd}
        open={statusModalWindow}
      >
        <PreparationDepartmentForm
          onClose={handleCloseAdd}
          text={t('Add')}
          form={form}
          isModalEditWindowStatus={null}
          handleSubmit={addPreparationDepartment}
        />
      </AntdTableActionModal>
      <AppTable
        columns={columns}
        dataSource={preparationDepartments}
        setTableSettings={setPreparationDepartmentsTableSettings}
        setSelectedComponent={setSelectedPreparationDepartmentData}
        tableSettings={tableSettings}
        showSelectRowCheckbox
        selectedRowKeys={selectedRowKeys}
        totalCount={totalCount}
        isLoading={isLoading}
      />
    </div>
  )
}

export default PreparationDepartmentBody
