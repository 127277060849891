import {useSelector} from 'react-redux'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {Col, Row} from 'antd'
import useTableRenderItems from '../../../../services/useTableRenderItems'
import {TableDetailsReadOnly} from '../../../../components/antd-components/app-table'
import useNormTableDetailService from '../../../../services/norm/normDetailTableService'
import {getRandomId} from '../../../../utils/helpers'
import ListComponent from '../../../../components/antd-components/listComponent/ListComponent'
import LoaderComponent from '../../../../components/loaderComponent/LoaderComponent'
import {formattedDate} from '../../../../constants'
import './style.scss'

function NormDetail() {
  const {renderNameById} = useTableRenderItems()
  const {t} = useTranslation()
  const isLoadingGetById = useSelector(
    (state) => state.norm.isLoading === 'getNormById'
  )
  const selectedNorm = useSelector((state) => state.norm.selectedNorm)
  const users = useSelector((state) => state.users.users)

  const data = selectedNorm?.products?.map((item) => {
    const storeRow = {name: item.name, id: getRandomId()}

    // eslint-disable-next-line no-restricted-syntax
    for (const storeRecord of item.records)
      storeRow[storeRecord.storeName] = storeRecord.necessaryAmount
    return storeRow
  })

  const {normDetail} = useNormTableDetailService({selectedNorm})

  const detailNorm = [
    {
      key: getRandomId(),
      name: t('Update date'),
      value: moment(selectedNorm?.updatedDate).format(
        formattedDate.DateWithTime
      ),
    },
    {
      key: getRandomId(),
      name: t('Create date'),
      value: moment(selectedNorm?.createdDate).format(
        formattedDate.DateWithTime
      ),
    },

    {
      key: getRandomId(),
      name: t('Author'),
      value: renderNameById({id: selectedNorm?.createdById}, users),
    },

    {
      key: getRandomId(),
      name: t('Name'),
      value: selectedNorm?.title,
    },
  ]

  const formProps = {
    gutter: [10, 10],
    colList: {
      xl: 8,
      sm: 12,
      xs: 24,
      lg: 12,
    },
    colTable: {
      xl: 16,
      sm: 12,
      xs: 24,
      lg: 12,
    },
  }

  return (
    <div>
      <LoaderComponent
        isLoadingCreator={[isLoadingGetById]}
        component={
          <Row gutter={formProps.gutter}>
            <Col {...formProps.colList} className="norm_detail_wrapper">
              <ListComponent
                dataSource={detailNorm}
                className="norm_detail_list"
              />
            </Col>
            <Col {...formProps.colTable} className="norm_detail_wrapper">
              <TableDetailsReadOnly
                isLoading={isLoadingGetById && selectedNorm?.length}
                selectedById={data}
                columns={normDetail}
                scrollY="calc(100vh - 16em)"
                scrollX="scroll"
              />
            </Col>
          </Row>
        }
      />
    </div>
  )
}

export default NormDetail
