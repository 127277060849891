import {useDispatch} from 'react-redux'
import {getHistorySuppliesByIngredient} from '../../../redux/asyncServices/suppliesService'

import {
  clearSelectedIngredient,
  setSelectedHistoryIngredientId,
  setTableSettings,
} from '../../../redux/slices/suppliesHistoryByIngredientSlice'

function useSuppliesHistoryByIngredient() {
  const dispatch = useDispatch()

  const getHistoryByIdIngredient = ({id, params}) => {
    dispatch(getHistorySuppliesByIngredient({id, params}))
  }

  const setSelectedIngredientId = (id) => {
    dispatch(setSelectedHistoryIngredientId(id))
  }

  const setTableSettingsHistoryByIngredient = (tableSettings = {}) => {
    dispatch(setTableSettings({tableSettings}))
  }

  const clearIngredientState = () => {
    dispatch(clearSelectedIngredient())
  }

  return {
    setSelectedIngredientId,
    getHistoryByIdIngredient,
    setTableSettingsHistoryByIngredient,
    clearIngredientState,
  }
}

export default useSuppliesHistoryByIngredient
