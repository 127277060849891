import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

function StoreBalanceValidation() {
  const {t} = useTranslation()
  const schema = yup.lazy((value) => {
    if (value !== undefined) {
      return yup.object().shape({
        comment: yup
          .string()
          .notRequired()
          .max(250, t('Max length 250 symbols')),
      })
    }
    return yup.mixed().nullable()
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default StoreBalanceValidation
