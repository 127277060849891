export const stores = (state) => state.stores.stores
export const storesSelector = (state) => state.stores.stores
export const allStoresSelector = (state) => state.stores.allStores
export const storesIsLoading = (state) => state.stores.isLoading === 'getStores'
export const isOpenModalWindow = (state) => state.stores.isOpenModalWindow
export const selectedId = (state) => state.stores.selectedId
export const selectedStoreId = (state) => state.storesBalances.selectedStoreId
export const storeBalanceProductNameSearchValue = (state) =>
  state.storesBalances.tableSettings.f_productName

export const isOpenEditModalWindow = (state) =>
  state.stores.isOpenEditModalWindow
