import {useDispatch} from 'react-redux'
import {
  deleteWriteOffReasons,
  getWriteOffReason,
  getWriteOffReasons,
  setWriteOffReason,
  updateWriteOffReason,
} from '../../redux/asyncServices/writeOfService'
import {
  clearWriteOffReasonTableSettings,
  setLastUpdated,
  setSelectedWriteOff,
  setTableSettings,
} from '../../redux/slices/writeOffReasonSlice'

const useWriteOff = () => {
  const dispatch = useDispatch()

  const writeOffReasonsLastUpdated = () => dispatch(setLastUpdated(Date.now()))

  const getWriteOffData = (params) => dispatch(getWriteOffReasons({params}))

  const getWriteOffById = ({id}) => dispatch(getWriteOffReason({id}))

  const selectedWriteOff = (data) => dispatch(setSelectedWriteOff(data))

  const setWriteOffData = async ({name}) => {
    await dispatch(setWriteOffReason({name}))
    writeOffReasonsLastUpdated()
  }

  const updateWriteOffData = async ({id, name}) => {
    await dispatch(updateWriteOffReason({id, name}))
    writeOffReasonsLastUpdated()
  }

  const deleteWriteOffReasonData = async ({ids}) => {
    await dispatch(deleteWriteOffReasons({ids}))
    writeOffReasonsLastUpdated()
  }

  const setWriteOffReasonTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
    writeOffReasonsLastUpdated()
  }

  const resetWriteOffReasonTableSettings = () =>
    dispatch(clearWriteOffReasonTableSettings())

  return {
    getWriteOffData,
    setWriteOffData,
    updateWriteOffData,
    selectedWriteOff,
    getWriteOffById,
    deleteWriteOffReasonData,
    setWriteOffReasonTableSettings,
    writeOffReasonsLastUpdated,
    resetWriteOffReasonTableSettings,
  }
}

export {useWriteOff}
