import {useDispatch} from 'react-redux'
import {apiRefreshToken, authLogin} from '../redux/asyncServices/authService'
import {LOG_OUT} from '../constants'
import {setTokens} from '../redux/slices/authSlice'

function useAuth() {
  const dispatch = useDispatch()
  const login = ({phoneNumber, password}) =>
    dispatch(authLogin({phoneNumber, password}))

  const setTokensData = ({accessToken, refreshToken}) =>
    dispatch(setTokens({accessToken, refreshToken}))

  const logout = () => {
    dispatch({type: LOG_OUT})
  }

  return {
    login,
    logout,
    setTokensData,
    apiRefreshToken,
  }
}

export default useAuth
