import {Modal} from 'antd'

function ModalWithSubModal({
  openMainDialog,
  openSubDialog,
  setOpenSubDialog,
  mainModalWindow,
  subModalWindow,
  titleMain,
  titleSub,
  closeMainModal,
}) {
  return (
    <div>
      <Modal
        title={titleMain}
        open={openMainDialog}
        footer={null}
        destroyOnClose
        onCancel={() => closeMainModal(false)}
      >
        {mainModalWindow}
      </Modal>

      <Modal
        title={titleSub}
        open={openSubDialog}
        destroyOnClose
        onCancel={() => setOpenSubDialog(false)}
        footer={null}
      >
        {subModalWindow}
      </Modal>
    </div>
  )
}

export default ModalWithSubModal
