import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {getColumnSorter} from '../../utils/helpers'
import {formattedDate} from '../../constants'
import usePlanningService from './planningService'
import {useDateRangeTableSettings} from '../../hooks'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'
import DownloadActionButton from '../../components/antd-components/tableAction-buttons/download-button/DownloadActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const usePlanningTableService = ({
  onDelete,
  showDetailInformation,
  setIsOpenDetailModal,
  tableSettings,
  downloadPlanningExcel,
}) => {
  const {t} = useTranslation()

  const {setPlanningTableSettings} = usePlanningService()

  const createdDateFilterProps = useDateRangeTableSettings({
    tableSettings,
    setTableSettings: setPlanningTableSettings,
  })

  const getColumns = () => [
    {
      title: t('Norm'),
      dataIndex: 'normTitle',
      align: 'center',
      width: '40%',
      render: (_, record) => record?.storeNorm?.title,
    },
    {
      title: t('Create date'),
      dataIndex: 'createdDate',
      key: 'f_createdDateTo',
      align: 'center',
      width: '35%',
      sorter: true,
      ...createdDateFilterProps,
      filteredValue: tableSettings?.f_createdDateTo,
      sortOrder: getColumnSorter(tableSettings, 'createdDate'),
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: '25%',
      render: (_, record) => (
        <div>
          <DetailsActionButton
            onClick={(e) => {
              e.stopPropagation()
              showDetailInformation(record.id)
              setIsOpenDetailModal(true)
            }}
          />
          <DownloadActionButton
            onClick={() => {
              downloadPlanningExcel(record.id)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDelete(record.id)
            }}
          />
        </div>
      ),
    },
  ]

  return {planningsColumns: getColumns()}
}

export default usePlanningTableService
