import {useTranslation} from 'react-i18next'
import RawMaterialsForm from '../../../../../../../raw-materials/components/RawMaterialsForm'
import {AntdTableActionModal} from '../../../../../../../../../components/antd-components/app-table'

function CreateRawModal(props) {
  const {isModalOpen, closeModalHandler} = props
  const {t} = useTranslation()
  return (
    <AntdTableActionModal
      open={isModalOpen}
      title={t('Add record')}
      handleCancel={closeModalHandler}
    >
      <RawMaterialsForm
        submitText={t('Add')}
        createFromComponent
        onClose={closeModalHandler}
      />
    </AntdTableActionModal>
  )
}
export default CreateRawModal
