import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import {crossedOutText} from '../../utils/helpers'

const useNormTableDetailService = ({selectedNorm}) => {
  const {t} = useTranslation()

  const storeColumns = useMemo(
    () =>
      selectedNorm?.products?.map((item) =>
        item?.records
          ?.filter(({storeId}) => storeId !== null)
          .map((current) => ({
            title: current.storeDeletedDate
              ? crossedOutText(current.storeName)
              : current.storeName,
            dataIndex: current.storeName,
            key: current.storeId,
            width: 120,
            align: 'center',
          }))
      ) || [],
    [selectedNorm]
  )

  const columnsStore = storeColumns[0] ? storeColumns[0] : storeColumns
  const getColumns = () => [
    {
      title: t('Product'),
      dataIndex: 'name',
      key: 'f_store',
      align: 'center',
      width: 175,
      fixed: 'left',
    },
    ...columnsStore,
    {
      title: t('Total'),
      dataIndex: 'total',
      width: 120,
      align: 'center',
    },
  ]

  return {normDetail: getColumns()}
}

export default useNormTableDetailService
