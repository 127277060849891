import {useEffect} from 'react'
import {Form} from 'antd'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import useStore from '../../../../services/storeServices/storeService'
import {AutoCompleteComponent} from '../../../../components/antd-components/form'

import {ACTIVE_ACCOUNTING_COMPANY_TARGET_ID} from '../../../../redux/slices/accountingSlice'
import {crossedOutText} from '../../../../utils/helpers'
import './style.scss'

function AccountingSourceSelector({
  activeAccountingTargetId,
  setActiveAccountingTargetId,
}) {
  const {t} = useTranslation()

  const [form] = Form.useForm()
  const {stores, isLoading} = useSelector((state) => state.stores)
  const {getStoresData} = useStore()

  const isStoresLoading = isLoading === 'getStores'

  const accountableList = [
    {name: t('Company'), id: ACTIVE_ACCOUNTING_COMPANY_TARGET_ID},
    ...stores.map(({id, name, deletedDate}) => ({
      id,
      name: deletedDate ? crossedOutText(name) : name,
    })),
  ]

  useEffect(() => {
    getStoresData({withDeleted: true})
  }, [])
  return (
    <Form form={form}>
      <AutoCompleteComponent
        loading={isStoresLoading}
        options={accountableList}
        defaultValue={activeAccountingTargetId}
        onChange={setActiveAccountingTargetId}
        classNameAutoComplete="accounting-selector__input"
        classNameForm="accounting-selector__container"
        allowClear={false}
        withoutSorter
        name="storeNameSearch"
      />
    </Form>
  )
}

export default AccountingSourceSelector
