import './style.scss'
import CancelButton from '../../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../../../components/antd-components/main-buttons/save-button/SaveButton'

function ProductFooter(props) {
  const {onBackHandler, productForm, isLoading} = props
  return (
    <div className="productFooter_wrapper">
      <CancelButton onClick={onBackHandler} />
      <SaveButton
        disabled={isLoading}
        onClick={() => {
          productForm.submit()
        }}
      />
    </div>
  )
}
export default ProductFooter
