import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  deleteTasks,
  getTask,
  getTasks,
  setTask,
  updateTask,
} from '../../redux/asyncServices/tasksService'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import {
  clearTaskTableSettings,
  setIsOpenModalWindow,
  setLastUpdated,
  setSelectedCheckbox,
  setSelectedTasks,
  setTableSettings,
  tasksReducerReset,
} from '../../redux/slices/tasksSlice'
import notify from '../../components/antd-components/notification/notify'

function useTasksService() {
  const dispatch = useDispatch()

  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const successAddTasks = (description) => {
    notify({
      description: t(description),
      type: 'success',
    })
    navigate(`/tasks`)
  }

  const failedAddTasks = (description) => {
    notify({
      description: t(description),
      type: 'error',
    })
  }
  const changeIsOpenModalWindow = (value) => {
    dispatch(setIsOpenModalWindow(value))
  }
  const setSelectedTask = (value) => dispatch(setSelectedTasks(value))
  const tasksLastUpdated = () => dispatch(setLastUpdated(Date.now()))

  const getTasksData = (params) => dispatch(getTasks({params}))

  const getTaskById = ({id}) => dispatch(getTask({id}))

  const selectTasks = (data) => dispatch(setSelectedTasks(data))

  const addTask = async (data) => {
    await dispatch(setTask(data)).then((response) => {
      if (response.payload) {
        successAddTasks('Success add tasks')
        changeIsOpenModalWindow(false)
      } else {
        failedAddTasks('Error add tasks')
      }
    })
    tasksLastUpdated()
  }

  const updateTaskData = async (data) => {
    await dispatch(updateTask(data)).then((response) => {
      if (response.payload) {
        successAddTasks('Success update tasks')
        changeIsOpenModalWindow(false)
        setSelectedTask([])
      } else {
        failedAddTasks('Error update tasks')
      }
    })
    tasksLastUpdated()
  }

  const deleteTaskData = async ({ids}) => {
    await dispatch(deleteTasks({ids}))
    tasksLastUpdated()
  }

  const setTaskTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
    tasksLastUpdated()
  }

  const tasksResets = () => dispatch(tasksReducerReset())
  const setSelectedRowCheckbox = (value) => dispatch(setSelectedCheckbox(value))

  const resetTaskTableSettings = () => dispatch(clearTaskTableSettings())

  return {
    getTasksData,
    tasksResets,
    setTaskTableSettings,
    deleteTaskData,
    updateTaskData,
    addTask,
    selectTasks,
    getTaskById,
    setSelectedRowCheckbox,
    changeIsOpenModalWindow,
    setSelectedTask,
    resetTaskTableSettings,
  }
}

export default useTasksService
