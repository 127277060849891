import {useSelector} from 'react-redux'
import {useEffect} from 'react'

import SupplierHeader from './components/SupplierHeader'
import SupplierBody from './components/SupplierBody'
import useSupplier from '../../../services/supplier/suppliersService'

function Supplier() {
  const supplierProps = useSupplier()
  const {
    setSupplierTableSettings,
    deleteSuppliersData,
    newSuppliersClear,
    getSuppliersData,
  } = supplierProps

  const supplierLastUpdated = useSelector(
    (state) => state.suppliers.lastUpdated
  )
  const suppliersTableSettings = useSelector(
    (state) => state.suppliers.tableSettings
  )

  useEffect(() => {
    getSuppliersData(suppliersTableSettings)
  }, [supplierLastUpdated])

  useEffect(
    () => () => {
      newSuppliersClear(null)
    },
    []
  )

  return (
    <>
      <SupplierHeader
        setSupplierTableSettings={setSupplierTableSettings}
        deleteSuppliersData={deleteSuppliersData}
      />
      <SupplierBody
        {...supplierProps}
        setSupplierTableSettings={setSupplierTableSettings}
        suppliersTableSettings={suppliersTableSettings}
      />
    </>
  )
}

export default Supplier
