import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'
import {checkIsResponseSuccess} from '../../utils/helpers'

export const getPurchases = createAsyncThunk(
  'GET_PURCHASES',
  async ({params = {}}) => {
    try {
      const response = await API.get('/purchase', {params})

      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const getPurchase = createAsyncThunk(
  'GET_PURCHASE',
  async ({id = {}}, {rejectWithValue}) => {
    try {
      const response = await API.get(`/purchase/${id}`)
      if (checkIsResponseSuccess(response?.data?.statusCode)) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.log(e)
      rejectWithValue(e)
    }
    return null
  }
)
