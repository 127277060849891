import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {BellFilled, EyeOutlined} from '@ant-design/icons'
import {IconButtonWithTooltip} from '../../components/antd-components/button'
import TagsComponent from '../../components/antd-components/tags/TagsComponent'
import {formattedDate} from '../../constants'
import '../../pages/main/notifications/components/style.scss'
import {getColumnSorter} from '../../utils/helpers'
import {useDateRangeTableSettings} from '../../hooks'

const useNotificationTable = ({
  onSubmitUpdateRead,
  notificationTableSettings,
  setNotificationTableSettings,
}) => {
  const {t} = useTranslation()

  const dateFilterProps = useDateRangeTableSettings({
    dateFromFilterName: 'f_startDate',
    dateToFilterName: 'f_endDate',
    tableSettings: notificationTableSettings,
    setTableSettings: setNotificationTableSettings,
  })

  const getColumns = () => [
    {
      title: t('Status'),
      dataIndex: 'read',
      key: 'f_read',
      width: 150,
      align: 'center',
      sorter: true,
      filteredValue: notificationTableSettings?.f_read,
      sortOrder: getColumnSorter(notificationTableSettings, 'read'),
      filters: [
        {
          text: t('statusNotification.Read'),
          value: 'read',
        },
        {
          text: t('statusNotification.Unread'),
          value: 'unread',
        },
      ],
      render: (_, record) =>
        record.read ? (
          <IconButtonWithTooltip
            title={t('Read')}
            type="text"
            disabled
            placement="topLeft"
            icon={<EyeOutlined style={{fontSize: 20}} />}
          />
        ) : (
          <IconButtonWithTooltip
            title={t('Unread')}
            type="text"
            placement="topLeft"
            icon={<BellFilled style={{fontSize: 20, color: '#3F50B5'}} />}
            onClick={() => onSubmitUpdateRead(record)}
          />
        ),
    },
    {
      title: t('Message'),
      dataIndex: 'message',
      key: 'message',
      align: 'center',
      minWidth: 200,
      filteredValue: '',
      render: (value) => <div style={{textAlign: 'left'}}>{value}</div>,
      sortOrder: getColumnSorter(notificationTableSettings, 'message'),
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'f_type',
      minWidth: 200,
      align: 'center',
      sorter: true,
      filteredValue: notificationTableSettings.f_type,
      sortOrder: getColumnSorter(notificationTableSettings, 'type'),
      filters: [
        {
          text: t('typeMessage.Info'),
          value: 'info',
        },
        {
          text: t('typeMessage.Warn'),
          value: 'warn',
        },
        {
          text: t('typeMessage.Error'),
          value: 'error',
        },
        {
          text: t('typeMessage.Success'),
          value: 'success',
        },
      ],

      render: (_, record) => {
        if (record.type === 'warn' || record.type === 'error') {
          return (
            <TagsComponent
              keys={record.id}
              color="red"
              tagText={record.type.toUpperCase()}
            />
          )
        }
        return (
          <TagsComponent
            keys={record.id}
            color="green"
            tagText={record.type.toUpperCase()}
          />
        )
      },
    },
    {
      title: t('Date'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      minWidth: 200,
      sorter: true,
      ...dateFilterProps,
      filteredValue: '',
      sortOrder: getColumnSorter(notificationTableSettings, 'date'),
      render: (_, record) =>
        moment(record.date).format(formattedDate.DateWithTime),
    },
  ]
  return {notificationColumn: getColumns()}
}

export default useNotificationTable
