import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {ExclamationCircleOutlined} from '../../../../components/antd-components/icon'
import useUnitsTable from '../../../../services/units/unitsTableService'

import UnitsForm from './UnitsForm'
import showConfirm from '../../../../components/PopupConfirm'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'

function UnitsBody({
  selectUnits,
  setUnitTableSettings,
  deleteUnitsData,
  getUnitById,
  form,
}) {
  const {t} = useTranslation()

  const units = useSelector((state) => state.units.units)
  const unitsTableSettings = useSelector((state) => state.units.tableSettings)
  const isLoading = useSelector((state) => state.units.isLoading === 'getUnits')
  const selectedUnit = useSelector((state) => state.units.selectedUnit)
  const unitsTotalCount = useSelector((state) => state.units.totalCount)
  const selectedUnits = useSelector((state) => state.units.selectedUnits)
  const [isEditDialogOpen, setEditDialogOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const handleDeleteSubmit = (id) => {
    deleteUnitsData({ids: [id]})
  }

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleDeleteSubmit(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const onEdit = (id) => {
    setSelectedId(id)
    setEditDialogOpen(true)
  }

  const handleEditClose = () => {
    setSelectedId(null)
    setEditDialogOpen(false)
  }

  useEffect(() => {
    if (selectedUnit !== selectedId) {
      getUnitById({id: selectedId})
    }
  }, [selectedId])

  const {unitsColumns} = useUnitsTable({
    onDelete: confirmDelete,
    onEdit,
    unitsTableSettings,
  })

  return (
    <div>
      {selectedUnit?.id && (
        <AntdTableActionModal
          title={t('Update')}
          handleCancel={handleEditClose}
          open={isEditDialogOpen}
        >
          <UnitsForm
            onClose={handleEditClose}
            formData={selectedUnit}
            text={t('Update')}
            form={form}
          />
        </AntdTableActionModal>
      )}
      <AppTable
        columns={unitsColumns}
        dataSource={units}
        showSelectRowCheckbox
        setTableSettings={setUnitTableSettings}
        tableSettings={unitsTableSettings}
        totalCount={unitsTotalCount}
        isLoading={isLoading}
        setSelectedComponent={selectUnits}
        selectedRowKeys={selectedUnits}
      />
    </div>
  )
}

export default UnitsBody
