import {useLocation, useParams} from 'react-router-dom'
import {useForm} from 'antd/es/form/Form'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import ProductHeader from './components/product-header/ProductHeader'
import ProductBody from './components/product-body/ProductBody'
import ProductFooter from './components/product-footer/ProductFooter'
import './style.scss'
import useProducts from '../../../../services/products/productsService'
import useUnits from '../../../../services/units/unitsService'
import {categoriesTreeSettings} from '../../../../redux/selectors/categoriesSelector'
import useCategory from '../../../../services/category/categoryService'
import useRawMaterials from '../../../../services/raw-materials/rawMaterialsService'
import {productType} from '../../../../constants'
import useUsers from '../../../../services/users/usersService'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'

function AddProductPage() {
  const {productId} = useParams()
  const {getCategoriesData, categoriesReset} = useCategory()
  const {getRawMaterialsData, rawMaterialsReset} = useRawMaterials()
  const {getProductsData, productsReset, setTypeProductsOrSemi} = useProducts()
  const selectedProduct = useSelector((state) => state.products.selectedProduct)
  const {isLoading} = useSelector((state) => state.products)
  const {type} = useSelector((state) => state.products)
  const {getUnitsData} = useUnits()
  const {getUsersData} = useUsers()
  const {getProductById} = useProducts()
  const navigate = useCustomNavigate()
  const location = useLocation()

  const treeSettings = useSelector(categoriesTreeSettings)
  const [productForm] = useForm()
  const [currentProductData, setCurrentProductData] = useState(undefined)
  const [isRecalculateModalOpen, setIsRecalculateModalOpen] = useState(false)

  useEffect(() => {
    const correctCurrentType = location.pathname.includes('product')
      ? 'final'
      : 'semi'
    if (type !== correctCurrentType) {
      setTypeProductsOrSemi(correctCurrentType)
    }
  }, [])

  useEffect(() => {
    if (selectedProduct) {
      setCurrentProductData(selectedProduct)
    } else {
      setCurrentProductData({semiFinished: [], ingredients: []})
    }
  }, [selectedProduct])

  useEffect(() => {
    if (productId) {
      getProductById({id: productId})
    }
  }, [productId])

  useEffect(() => {
    getRawMaterialsData()
    getUnitsData()
    getUsersData()
    getCategoriesData({...treeSettings, f_type: ['product']})
    getProductsData({f_type: [productType.semi]})
    return () => {
      categoriesReset()
      rawMaterialsReset()
      productsReset()
    }
  }, [])

  const openRecalculateModalHandler = () => {
    setIsRecalculateModalOpen(true)
  }

  const onBackHandler = () => {
    if (type === 'semi') {
      navigate('/semi-finished')
    } else {
      navigate('/products')
    }
  }

  return (
    <div className="productAdd_page">
      <ProductHeader
        onBackHandler={onBackHandler}
        currentProductData={currentProductData}
        productId={productId}
        openRecalculateModalHandler={openRecalculateModalHandler}
      />
      <ProductBody
        isLoading={isLoading}
        isRecalculateModalOpen={isRecalculateModalOpen}
        setIsRecalculateModalOpen={setIsRecalculateModalOpen}
        productForm={productForm}
        productId={productId}
        currentProductData={currentProductData}
        setCurrentProductData={setCurrentProductData}
      />
      <ProductFooter
        isLoading={isLoading}
        productForm={productForm}
        onBackHandler={onBackHandler}
      />
    </div>
  )
}
export default AddProductPage
