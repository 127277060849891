import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import useStore from '../../../services/storeServices/storeService'
import RefillHeader from './components/RefillHeader'
import RefillBody from './components/RefillBody'

import useProducts from '../../../services/products/productsService'
import useRefill from '../../../services/refill/refillService'

function RefillPage() {
  const refillProps = useRefill()
  const {
    getRefillsData,
    updateRefillData,
    getRefillById,
    setRefillTableSettings,
    getExcelReport,
    refillDelete,
    setSelectedRefillId,
  } = refillProps
  const storeProps = useStore()
  const {getStoresData} = storeProps
  const productsProps = useProducts()
  const {getProductsData} = productsProps
  const selectedRefill = useSelector((state) => state.refills.selectedRefill)
  const refillsLastUpdated = useSelector((state) => state.refills.lastUpdated)
  const refillsTableSettings = useSelector(
    (state) => state.refills.tableSettings
  )

  useEffect(() => {
    getRefillsData(refillsTableSettings)
  }, [refillsLastUpdated])

  useEffect(() => {
    getStoresData()
    getProductsData()
  }, [])

  return (
    <div>
      <RefillHeader />
      <RefillBody
        getRefillsData={getRefillsData}
        selectedRefill={selectedRefill}
        getRefillById={getRefillById}
        getExcelReport={getExcelReport}
        refillsTableSettings={refillsTableSettings}
        updateRefillData={updateRefillData}
        setRefillTableSettings={setRefillTableSettings}
        setSelectedRefillId={setSelectedRefillId}
        refillDelete={refillDelete}
      />
    </div>
  )
}

export default RefillPage
