import {Select} from 'antd'
import './style.scss'
import {useTranslation} from 'react-i18next'

function SelectFilter(props) {
  const {
    setTableSettings,
    filterName,
    options,
    multiple,
    placeholder,
    value,
    ...rest
  } = props
  const {t} = useTranslation()

  console.log('REST', rest)
  const selectValueChangeHandler = (selectedValue) => {
    const correctValue = selectedValue?.length ? selectedValue : []
    setTableSettings({[filterName]: correctValue})
  }

  return (
    <Select
      value={value}
      allowClear
      placeholder={t(placeholder)}
      className="select_filter"
      mode={multiple ? 'multiple' : undefined}
      options={options}
      onChange={selectValueChangeHandler}
      optionFilterProp="label"
      {...rest}
    />
  )
}

export default SelectFilter
