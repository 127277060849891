import {Checkbox, Form} from 'antd'

function CheckboxComponent({name, label, hidden, formStyle, ...rest}) {
  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      hidden={hidden}
      style={formStyle}
    >
      <Checkbox {...rest}>{label}</Checkbox>
    </Form.Item>
  )
}

export default CheckboxComponent
