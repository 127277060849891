import {useDispatch} from 'react-redux'

import {
  getInventory,
  getInventoryById,
  getInventoryHistory,
} from '../../redux/asyncServices/inventoryService'
import {
  clearInventoryHistory,
  clearInventoryTableSettings,
  clearSelectedInventory,
  setLastUpdated,
  setTableSettings,
} from '../../redux/slices/inventorySlice'

function useInventoryService() {
  const dispatch = useDispatch()

  const inventoryLastUpdated = () => dispatch(setLastUpdated(Date.now()))

  const getInventoryData = (params) => dispatch(getInventory({params}))
  const getInventoryDetail = (id) => dispatch(getInventoryById({id}))
  const clearHistoryInventory = () => dispatch(clearInventoryHistory())
  const getHistoryInventory = ({itemId, itemType, storeId, params}) =>
    dispatch(getInventoryHistory({itemId, itemType, storeId, params}))

  const setInventoryTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
    inventoryLastUpdated()
  }
  const clearSelectedInventoryData = () => dispatch(clearSelectedInventory())

  const resetInventoryTableSettings = () =>
    dispatch(clearInventoryTableSettings())

  return {
    getInventoryData,
    setInventoryTableSettings,
    getInventoryDetail,
    getHistoryInventory,
    clearHistoryInventory,
    clearSelectedInventoryData,
    resetInventoryTableSettings,
  }
}

export default useInventoryService
