import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {Form} from 'antd'
import {useSelector} from 'react-redux'
import {PageHeaderWithMobileMenu} from '../../../../components'
import {ButtonDefault} from '../../../../components/antd-components/button'
import {createItemHeaderMenu} from '../../../../utils/helpers'
import variables from '../../../../globalStyle/colors.scss'
import {InputForm} from '../../../../components/antd-components/form'
import useNormService from '../../../../services/norm/normService'
import NormValidation from '../validation/validation'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function NormAddHeader({form}) {
  const {t} = useTranslation()

  const {id} = useParams()

  const header = id ? 'Edit norm' : 'Add norm'
  const {addNewNorm, updateNormData} = useNormService()
  const navigate = useCustomNavigate()

  const navigateToNorm = () => {
    navigate('/norm')
  }
  const isLoadingSet = useSelector(
    (state) => state.norm.isLoading === 'setNorm'
  )
  const draftNormsRecords = useSelector(
    (state) => state.norm.draftNorms[0].products
  )
  const selectedNorm = useSelector((state) => state.norm.selectedNorm)
  const isLoadingUpdate = useSelector(
    (state) => state.norm.isLoading === 'updateNorm'
  )

  const onSubmitAdd = (value) => {
    const {normTitle} = value
    if (header === 'Add norm') {
      addNewNorm({
        normTitle,
        products: draftNormsRecords.map(({id, name, total, ...item}) => ({
          ...item,
          records: item.records
            .filter((current) => current.storeId !== null)
            .map((item) => ({
              storeId: item.storeId,
              amount: item.necessaryAmount || 0,
            })),
        })),
      })
    } else {
      updateNormData({
        id: selectedNorm?.id,
        normTitle,
        products: draftNormsRecords?.map(({id, name, total, ...item}) => ({
          ...item,
          records: item.records
            .filter((current) => current.storeId !== null)
            .map((item) => ({
              storeId: item.storeId,
              amount: item.necessaryAmount || 0,
            })),
        })),
      })
    }
  }

  const validation = NormValidation()
  const actionHeader = [
    <Form form={form} onFinish={onSubmitAdd} key={1}>
      <InputForm
        formClassName="form_label_after"
        formStyle={{marginBottom: 0, marginTop: 0}}
        style={{width: '300px'}}
        name="normTitle"
        rules={{...validation, required: true}}
        label={<span />}
        key={1}
        placeholder={t('Input name form')}
      />
    </Form>,

    <Form form={form} onFinish={onSubmitAdd} key={2}>
      <SaveButton
        loading={header === 'Add norm' ? isLoadingSet : isLoadingUpdate}
        htmlType="submit"
        disabled={
          !!draftNormsRecords?.filter((current) => current.records.length === 0)
            ?.length || draftNormsRecords.length === 0
        }
      />
    </Form>,
  ]
  const item = createItemHeaderMenu([...actionHeader])
  return (
    <PageHeaderWithMobileMenu
      title={header}
      extra={actionHeader}
      items={item}
      onBack={() => navigateToNorm()}
    />
  )
}

export default NormAddHeader
