import {useEffect} from 'react'
import IncasationHeader from './components/IncasationHeader'
import IncasationBody from './components/IncasationBody'
import useUsers from '../../../services/users/usersService'
import useStore from '../../../services/storeServices/storeService'

function IncasationPage() {
  const {getUsersData} = useUsers()
  const {getStoresData} = useStore()
  useEffect(() => {
    getUsersData()
    getStoresData()
  }, [])
  return (
    <div>
      <IncasationHeader />
      <IncasationBody />
    </div>
  )
}

export default IncasationPage
