import {Button, Card, Col, Form, Input} from 'antd'
import './style.scss'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {ArrowRightOutlined} from '@ant-design/icons'
import useAuth from '../../services/authService'
import AuthValidation from './validation/AuthValidation'
import {ImageComponent, LanguageMenu} from '../../components'

function LoginPage() {
  const {login} = useAuth()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {from} = location.state || {}
  const {pathname, search, state} = from || {}
  const isAuth = useSelector((state) => state.auth.isAuth)
  const isLoading = useSelector((state) => state.auth.isLoading)
  const validation = AuthValidation()

  const onFinish = (values) => {
    login(values)
  }

  useEffect(() => {
    if (isAuth) {
      navigate((pathname ?? '/') + (search ?? ''), {
        replace: true,
        state,
      })
    }
  }, [isAuth])

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div>
        <ImageComponent
          src="/FlexLogo.png"
          width={350}
          height={60}
          preview={false}
          style={{objectFit: 'contain'}}
        />

        <span className="signIn">{t('Sign in')}</span>
        <Col xl={24} xs={24} lg={24} md={24}>
          <Card
            style={{
              backgroundColor: '#F5F5F5',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Form
              labelCol={{span: 24}}
              wrapperCol={{span: 24}}
              onFinish={onFinish}
            >
              <Form.Item
                label={t('Phone')}
                name="phoneNumber"
                rules={[validation]}
                style={{marginBottom: 0, width: '250px'}}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('Password')}
                name="password"
                rules={[validation]}
                style={{marginBottom: 0, width: '250px'}}
              >
                <Input.Password />
              </Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 20,
                }}
              >
                <LanguageMenu style={{marginBottom: 15, width: '50%'}} />
              </div>
              <Form.Item style={{marginBottom: 0}}>
                <Button
                  htmlType="submit"
                  loading={isLoading}
                  style={{
                    width: '100%',
                    marginTop: 25,
                    backgroundColor: '#3F50B5',
                    color: '#fff',
                  }}
                >
                  {t('Sign in')}
                  <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </div>
    </div>
  )
}

export default LoginPage
