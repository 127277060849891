import {useTranslation} from 'react-i18next'
import {Button, Tooltip} from 'antd'
import './style.scss'

function SendButton(props) {
  const {tooltipPlacement, tooltipText, className, ...rest} = props
  const {t} = useTranslation()

  return (
    <span className="sendButton_wrapper">
      <Tooltip placement={tooltipPlacement} title={tooltipText}>
        <Button type="primary" className={`${className} send_button`} {...rest}>
          {t('Send')}
        </Button>
      </Tooltip>
    </span>
  )
}

export default SendButton
