import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getTasks = createAsyncThunk(
  'GET_TASKS',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/tasks', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {tasks: state.tasks.tasks, totalCount: state.units.totalCount}
    }
    return null
  }
)

export const getTask = createAsyncThunk('GET_TASK', async ({id}) => {
  if (id) {
    try {
      const response = await API.get(`/tasks/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
  }
  return null
})

export const setTask = createAsyncThunk('SET_TASK', async (data) => {
  try {
    const response = await API.post('/tasks', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const updateTask = createAsyncThunk('UPDATE_TASK', async (data) => {
  const {id} = data
  try {
    const response = await API.put(`/tasks/${id}`, data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const deleteTasks = createAsyncThunk(
  'DELETE_TASKS',
  async ({ids = []}, {getState}) => {
    console.log(ids)
    try {
      const response = await API.delete('/tasks', {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {...response.data.payload, selectedUnits: []}
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedUnits: state.units.selectedUnits,
      }
    }
    return null
  }
)
