import {useTranslation} from 'react-i18next'
import './style.scss'
import {useForm} from 'antd/es/form/Form'
import {useEffect} from 'react'
import {
  convertToFractionalNumber,
  getColumnSorter,
  getValueWithPrecision,
} from '../../../../../../../../utils/helpers'
import {getTableColumnSearchProps} from '../../../../../../../../utils/tableUtils'
import useProfitService from '../../../../../../../../services/profit/profitService'

const useProfitsTable = ({tableSettings}) => {
  const {t} = useTranslation()
  const {setProfitTableSettings} = useProfitService()
  const [searchForm] = useForm()

  useEffect(() => {
    const searchValueFromForm = searchForm.getFieldValue('f_productName')
    const searchValueFromTableSettings = tableSettings.f_productName
    if (!searchValueFromForm && searchValueFromTableSettings) {
      searchForm.setFieldValue('f_productName', searchValueFromTableSettings)
    }
  }, [tableSettings])

  const handleSearchReset = (currentColumn, confirm) => {
    searchForm.setFieldValue(currentColumn, undefined)
    setProfitTableSettings({[currentColumn]: null, page: 1})
    confirm({closeDropdown: true})
  }

  const searchButtonHandler = (currentColumn, confirm) => {
    const columnFilterValue = searchForm.getFieldValue(currentColumn)
    setProfitTableSettings({[currentColumn]: columnFilterValue, page: 1})
    confirm({closeDropdown: true})
  }

  const getColumns = () => [
    {
      title: t('#'),
      dataIndex: 'productId',
      key: 'productId',
      align: 'center',
      filteredValue: '',
      width: '5%',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      ...getTableColumnSearchProps({
        currentColumn: 'f_productName',
        tableSettings,
        handleReset: handleSearchReset,
        searchButtonHandler,
        placeholder: 'name',
        form: searchForm,
        t,
      }),
      key: 'name',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'name'),
      filteredValue: '',
      width: '20%',
    },
    {
      title: t('Total sales'),
      dataIndex: 'amountSales',
      key: 'amountSales',
      align: 'center',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'amountSales'),
      filteredValue: '',
      render: (value) => getValueWithPrecision({value, precision: 6}),
      width: '10%',
    },
    {
      title: t('Producing price'),
      dataIndex: 'totalProducingPricing',
      key: 'totalProducingPricing',
      align: 'right',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'totalProducingPricing'),
      filteredValue: '',
      render: (value) => convertToFractionalNumber(value),
      width: '10%',
    },
    {
      title: t('Amount sale'),
      dataIndex: 'totalSalePricing',
      key: 'totalSalePricing',
      align: 'right',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'totalSalePricing'),
      render: (value) => convertToFractionalNumber(value),
      filteredValue: '',
      width: '10%',
    },
    {
      title: t('Profit'),
      dataIndex: 'profit',
      key: 'profit',
      align: 'right',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'profit'),
      filteredValue: '',
      render: (value) => convertToFractionalNumber(value),
      width: '10%',
    },
    {
      title: t('Amount write-offs'),
      dataIndex: 'amountWriteOff',
      key: 'amountWriteOff',
      align: 'center',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'amountWriteOff'),
      filteredValue: '',
      render: (value) => getValueWithPrecision({value, precision: 6}),
      width: '10%',
    },
    {
      title: t('Write-off loss'),
      dataIndex: 'pricingWriteOffLoss',
      key: 'pricingWriteOffLoss',
      align: 'right',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'pricingWriteOffLoss'),
      filteredValue: '',
      render: (value) => convertToFractionalNumber(value),
      width: '10%',
    },
    {
      title: t('Result'),
      dataIndex: 'result',
      key: 'result',
      align: 'right',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'result'),
      filteredValue: '',
      render: (value) => convertToFractionalNumber(value),
      width: '10%',
    },
  ]

  return {profitsColumns: getColumns()}
}

export default useProfitsTable
