import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {Tag} from 'antd'
import {formattedDate} from '../../constants'
import {getPaymentMethodIcon, priceFormat} from '../../utils/helpers'

const useOrderPaymentsTableService = (props) => {
  const {showCheckDetailHandler} = props
  const {t} = useTranslation()

  const getColumns = () => [
    {
      title: '#',
      dataIndex: 'check',
      align: 'center',
      render: (check) =>
        check?.checkId ? (
          <Tag
            className="checkId_wrapper"
            color="blue"
            onClick={() => {
              showCheckDetailHandler(check.checkId)
            }}
          >
            {check.checkId}
          </Tag>
        ) : (
          '-'
        ),
    },
    {
      title: t('Create date'),
      dataIndex: 'createdDate',
      align: 'center',
      render: (_, record) =>
        moment(
          record.checkId
            ? record.check.createdDate
            : record.companyAccountingRecord.createdDate
        ).format(formattedDate.DateWithTime),
    },
    {
      title: t('Store'),
      dataIndex: 'store',
      align: 'center',
      render: (_, record) => (record.checkId ? record.check.stores.name : '-'),
    },
    {
      title: t('Author'),
      dataIndex: 'createdBy',
      align: 'center',
      render: (_, record) =>
        record.checkId
          ? record.check.createdBy?.name
          : record.companyAccountingRecord?.createdBy?.name,
    },
    {
      title: t('Payment method'),
      dataIndex: 'isCash',
      align: 'center',
      render: (_, record) => {
        if (record.checkId) {
          const isCash = []

          if (record.check.cardAmount > 0) isCash.push(false)

          if (record.check.cashAmount > 0) isCash.push(true)

          return getPaymentMethodIcon({isCash})
        }

        return getPaymentMethodIcon({
          isCash: record.companyAccountingRecord.isCash,
        })
      },
    },
    {
      title: t('Total'),
      dataIndex: 'total',
      align: 'right',
      width: '85px',
      render: priceFormat,
    },
  ]

  return {columns: getColumns()}
}

export default useOrderPaymentsTableService
