import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {ExclamationCircleOutlined} from '@ant-design/icons'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'
import useAccountingCategoryTable from '../../../../services/accountingCategory/accountingCategoryTableService'
import {
  accountingCategoriesSelector,
  accountingCategoryTableSettings,
  accountingTotalCount,
  getAccountingCategoryLoading,
  selectedAccountingCategory,
  selectedInTable,
} from '../../../../redux/selectors/accountingCategorySelector'
import useAccountingCategoryService from '../../../../services/accountingCategory/accountingCategoryService'
import useCommon from '../../../../services/commonService'
import {
  modalEditWindowStatus,
  modalWindowStatus,
} from '../../../../redux/selectors/commonSelector'
import showConfirm from '../../../../components/PopupConfirm'
import AccountingCategoryForm from './AccountingCategoryForm'

function AccountingCategoryBody({form}) {
  const {
    setAccountingCategoryTableSettings,
    setSelectAccountingCategories,
    getByIdAccountingCategory,
    clearProductsSelected,
    deleteAccountingCategoryData,
  } = useAccountingCategoryService()

  const {changeOpenModalWindow, changeOpenEditModalWindow} = useCommon()
  const accountingCategories = useSelector(accountingCategoriesSelector)
  const tableSettings = useSelector(accountingCategoryTableSettings)
  const totalCount = useSelector(accountingTotalCount)
  const isLoading = useSelector(getAccountingCategoryLoading)
  const selectedAccountingCategoryData = useSelector(selectedAccountingCategory)
  const selectedRowKeys = useSelector(selectedInTable)
  const statusModalWindow = useSelector(modalWindowStatus)
  const statusEditModalWindow = useSelector(modalEditWindowStatus)

  const {t} = useTranslation()

  const handleEdit = (record) => {
    getByIdAccountingCategory(record.id)
    changeOpenEditModalWindow(true)
  }

  const handleEditClose = () => {
    clearProductsSelected(null)
    changeOpenEditModalWindow(false)
  }

  const handleAddClose = () => {
    changeOpenModalWindow(false)
    form.resetFields()
  }
  const handleDeleteSubmit = (id) => {
    deleteAccountingCategoryData({ids: [id]})
  }

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleDeleteSubmit(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const {accountingCategoryColumns} = useAccountingCategoryTable({
    handleEdit,
    onDelete: confirmDelete,
  })

  return (
    <div>
      {selectedAccountingCategoryData?.id && (
        <AntdTableActionModal
          title={t('Update category')}
          handleCancel={handleEditClose}
          open={statusEditModalWindow}
        >
          <AccountingCategoryForm
            onClose={handleEditClose}
            form={form}
            text={t('Update')}
            formData={selectedAccountingCategoryData}
          />
        </AntdTableActionModal>
      )}
      <AntdTableActionModal
        title={t('Add category')}
        handleCancel={handleAddClose}
        open={statusModalWindow}
      >
        <AccountingCategoryForm
          onClose={handleAddClose}
          text={t('Add')}
          form={form}
          isModalEditWindowStatus={null}
        />
      </AntdTableActionModal>
      <AppTable
        columns={accountingCategoryColumns}
        dataSource={accountingCategories}
        setTableSettings={setAccountingCategoryTableSettings}
        tableSettings={tableSettings}
        showSelectRowCheckbox
        setSelectedComponent={setSelectAccountingCategories}
        selectedRowKeys={selectedRowKeys}
        totalCount={totalCount}
        isLoading={isLoading}
      />
    </div>
  )
}

export default AccountingCategoryBody
