import {Button, DatePicker} from 'antd'
import {useTranslation} from 'react-i18next'
import {formattedDate} from '../../../../../constants'

import './style.scss'

function DateRangeFilter({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  onSubmitFilterDateRange,
  ...datePickerProps
}) {
  const {t} = useTranslation()

  const handleReset = () => {
    setDateTo(null)
    setDateFrom(null)
  }

  return (
    <div className="date-range-filter_container">
      <DatePicker
        {...datePickerProps}
        placeholder={t('From')}
        format={formattedDate.Date}
        value={dateFrom}
        onChange={setDateFrom}
        className="date-range-filter_item"
      />
      <DatePicker
        {...datePickerProps}
        placeholder={t('To')}
        format={formattedDate.Date}
        value={dateTo}
        onChange={setDateTo}
        className="date-range-filter_item"
      />
      <div className="date-range-filter_footer">
        <Button type="text" onClick={handleReset} size="small">
          {t('Clear')}
        </Button>
        <Button type="primary" onClick={onSubmitFilterDateRange} size="small">
          Ok
        </Button>
      </div>
    </div>
  )
}

export default DateRangeFilter
