import {t} from 'i18next'
import {Form} from 'antd'
import {useSelector} from 'react-redux'
import {useMemo} from 'react'
import {DownOutlined} from '@ant-design/icons'
import {
  getTableColumnId,
  restrictInputValueAfterDot,
} from '../../../../../../../../utils/helpers'
import AutoCompleteComponent from '../../../../../../../../components/antd-components/form/form-select-auto-complete/AutoCompleteComponent'
import AmountFieldValidation from '../../../../../../refill/refill-add-page/components/refill-add-body/components/items-table/validation/AmountFieldValidation'
import useTableRenderItems from '../../../../../../../../services/useTableRenderItems'
import ProductIdFieldValidation from '../../../../../../refill/refill-add-page/components/refill-add-body/components/items-table/validation/ProductIdFieldValidation'
import DeleteActionButton from '../../../../../../../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'
import TextInputCenter from '../../../../../../../../components/antd-components/form/input-form/TextInputCenter'
import {ITEM_TYPES} from '../../../../../../../../constants'
import {rawMaterialsBalance} from '../../../../../../../../redux/selectors/rawMaterialsSelector'
import {productsBalance} from '../../../../../../../../redux/selectors/productsSelector'

const useMovementItemsTable = (props) => {
  const {
    deleteItemHandler,
    itemsList,
    isPageReadOnly,
    selectedFromIdStore,
    onFieldValueChange,
    activeTab,
    activeItemsFields,
    setActiveItemsFields,
    activeAmountFields,
    setActiveAmountFields,
    amountFieldRefs,
  } = props

  const {renderNameById} = useTableRenderItems()
  const rawMaterialsBalanceData = useSelector(rawMaterialsBalance)
  const productsBalanceData = useSelector(productsBalance)
  const productIdFieldValidation = ProductIdFieldValidation()
  const products = useSelector((state) => state.products.products)
  const units = useSelector((state) => state.units.units)
  const stores = useSelector((state) => state.stores.stores)
  const rawMaterials = useSelector((state) => state.rawMaterials.rawMaterials)

  const getItemMaxAmountValue = ({itemType, itemId}) => {
    let correctMaxAmount = 0
    if (itemType !== ITEM_TYPES.RAW) {
      const itemBalance = productsBalanceData
        .find((product) => product.storeBalances[0].itemId === itemId) // check why this case changed
        ?.storeBalances?.find(
          (productBalanceData) => productBalanceData.itemId === itemId
        )?.amount
      correctMaxAmount = itemBalance || 0
    } else {
      const itemBalance = rawMaterialsBalanceData
        .find((raw) => raw.id === itemId)
        ?.storeBalances?.find(
          (rawBalanceData) => rawBalanceData.itemId === itemId
        )?.amount
      correctMaxAmount = itemBalance || 0
    }
    return correctMaxAmount
  }

  const updateActiveItemsFieldsList = (item) => {
    setActiveItemsFields([...activeItemsFields, item])
  }

  const updateActiveAmountFieldsList = (item) => {
    const correctActiveAmountFieldsList = activeAmountFields.map(
      (amountField) => ({...amountField, isFieldFocusActive: false})
    )
    setActiveAmountFields([
      ...correctActiveAmountFieldsList,
      {...item, isFieldFocusActive: true},
    ])
  }

  const getItemName = ({itemId, itemType}) => {
    if (itemId) {
      if (itemType === 'raw') {
        return (
          rawMaterials.find((rawItem) => rawItem.id === itemId)?.name || itemId
        )
      }
      if (itemType === 'product' || itemType === 'semi') {
        const currentItemName = products.find(
          (product) => product.id === itemId
        )?.name
        return currentItemName || itemId
      }
      return itemId
    }
    return ''
  }

  const productsTypeFinal = products
    .map((item) => item)
    .filter((item) => item.type !== 'semi')

  const productsTypeSemi = products
    .map((item) => item)
    .filter((item) => item.type !== 'final')

  const disabledOptionItems = useMemo(
    () =>
      itemsList
        .filter((item) => item.itemType === activeTab)
        .map((item) => item.itemId),
    [itemsList, activeTab]
  )
  const itemsOptionsData = useMemo(() => {
    if (activeTab === ITEM_TYPES.PRODUCT) {
      return productsTypeFinal
    }
    if (activeTab === ITEM_TYPES.SEMI_FINISHED) {
      return productsTypeSemi
    }
    if (activeTab === ITEM_TYPES.RAW) {
      return rawMaterials?.map((rawData) => ({
        id: rawData.id,
        name: rawData.name,
        value: rawData.id,
      }))
    }
    return []
  }, [activeTab, products, rawMaterials, itemsList])

  const findUnit = (id) => {
    if (activeTab !== ITEM_TYPES.RAW) {
      const product = products.find((item) => item.id === id)
      return !product ? product : product?.unitId
    }
    const raw = rawMaterials.find((item) => item.id === id)
    return !raw ? raw : raw?.unit?.id
  }

  const storeFromName = useMemo(() => {
    if (selectedFromIdStore) {
      return renderNameById({id: selectedFromIdStore}, stores)
    }
    return ''
  }, [selectedFromIdStore])

  const getColumns = [
    {
      ...getTableColumnId({dataIndex: 'itemId'}),
      render: (_, record, index) => index + 1,
      className: 'columns id_column',
    },
    {
      title: t('Name'),
      dataIndex: 'itemId',
      key: 'name',
      align: 'center',
      editable: true,
      className: 'columns item_name',
      render: (_, record) => {
        const currentId = `itemId${record.id}`
        const activeColumnData = activeItemsFields.find(
          (activeItem) => activeItem.id === currentId
        )
        const isInputVisible = activeColumnData || record.isNewItem

        return isInputVisible ? (
          <AutoCompleteComponent
            className={currentId}
            open={activeColumnData?.isOpen}
            onDropdownVisibleChange={(open) => {
              setActiveItemsFields(
                activeItemsFields.map((activeItem) => {
                  if (activeItem.id === currentId) {
                    return {...activeItem, isOpen: open}
                  }
                  return activeItem
                })
              )
            }}
            disabled={isPageReadOnly}
            initialValue={record.itemId}
            rules={productIdFieldValidation}
            options={itemsOptionsData}
            name={`itemId${record.id}`}
            disabledId={disabledOptionItems}
            formStyle={{marginBottom: 0, paddingBottom: 9}}
            placeholder={t('Products')}
            onChange={(value) => {
              onFieldValueChange(record.id, value, 'name')
            }}
          />
        ) : (
          <div
            className="nameColumn_content"
            aria-hidden="true"
            onClick={() => {
              updateActiveItemsFieldsList({
                id: currentId,
                isOpen: true,
              })
            }}
          >
            <span />
            <span>
              {getItemName({itemId: record.itemId, itemType: record.itemType})}
            </span>
            <span>
              <DownOutlined className="arrow_icon" />
            </span>
          </div>
        )
      },
    },
    {
      title: `${t('Balance')} ${storeFromName} `,
      dataIndex: 'maxAmount',
      key: 'maxAmount',
      align: 'center',
      className: 'columns balance_column',
      render: (_, record) => (
        <div className="balanceColumn_content">
          <span className="amount_wrapper">
            {getItemMaxAmountValue({
              itemId: record.itemId,
              itemType: record.itemType,
            })}
          </span>
          <span className="unit_wrapper">
            {renderNameById({id: findUnit(record.itemId)}, units)}
          </span>
        </div>
      ),
    },
    {
      title: t('Quantity'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      className: 'columns quantity_column',
      render: (_, record) => {
        const isCurrentProductPiece =
          activeTab === 'raw'
            ? false
            : products.find((product) => product.id === record.itemId)?.piece

        const currentId = `amount${record.id}`
        const activeColumnData = activeAmountFields.find(
          (activeItem) => activeItem.id === currentId
        )
        const isInputVisible = activeColumnData || record.isNewItem

        return isInputVisible ? (
          <Form.Item
            name={`amount${record.id}`}
            rules={[AmountFieldValidation(isCurrentProductPiece, t)]}
            normalize={restrictInputValueAfterDot}
            initialValue={record.amount}
          >
            <TextInputCenter
              fieldRef={(el) => {
                amountFieldRefs.current[currentId] = el
              }}
              className="amount_input"
              disabled={isPageReadOnly}
              addonAfter={
                renderNameById({id: findUnit(record.itemId)}, units) || (
                  <span style={{visibility: 'hidden'}}>Unit</span>
                )
              }
              onChange={(e) => {
                const correctValue = restrictInputValueAfterDot(e.target.value)
                onFieldValueChange(record.id, correctValue, 'amount')
              }}
            />
          </Form.Item>
        ) : (
          <div
            className="quantityColumn_content"
            aria-hidden="true"
            onClick={() => {
              updateActiveAmountFieldsList({
                id: currentId,
              })
            }}
          >
            <span className="amount"> {record.amount}</span>
            <span className="unit">
              {renderNameById({id: findUnit(record.itemId)}, units)}
            </span>
          </div>
        )
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align: 'center',
      className: 'columns action_column',
      render: (_, record) => (
        <div>
          <DeleteActionButton
            disabled={isPageReadOnly}
            onClick={() => {
              deleteItemHandler(record.id)
            }}
          />
        </div>
      ),
    },
  ]
  return {movementItemsColumns: getColumns}
}

export default useMovementItemsTable
