import {Form, Modal} from 'antd'
import {useTranslation} from 'react-i18next'
import {InputForm} from '../../../../components/antd-components/form'
import StoreBalanceValidation from '../validation/StoreBalanceValidation'

export default function StoreBalanceEditCommentModal({
  open,
  form,
  handleClose,
  handleConfirm,
}) {
  const {t} = useTranslation()

  const validationStoreBalance = StoreBalanceValidation()
  return (
    <Modal
      title={t('Confirm balance edit')}
      open={open}
      onCancel={handleClose}
      destroyOnClose
      okText={t('Confirm')}
      onOk={handleConfirm}
    >
      <Form form={form}>
        <InputForm
          placeholder={t('Comment')}
          label={t('Comment')}
          name="comment"
          rules={validationStoreBalance}
          formClassName="form"
        />
      </Form>
    </Modal>
  )
}
