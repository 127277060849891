import {Form, Input} from 'antd'
import {forwardRef, useRef} from 'react'
import {restrictInputValueAfterDot} from '../../../../utils/helpers'

import './style.scss'

const InputNumberForm = forwardRef((props, ref) => {
  const {
    name,
    rules,
    label,
    formWrapperClassName,
    className,
    initialValue,
    style,
    normalize,
    unitType = 'price',
    onChange,
    ...rest
  } = props

  const innerRef = useRef(null)

  let decimalPlaces = 2
  if (unitType === 'price') decimalPlaces = 2
  else if (unitType === 'weight') decimalPlaces = 3
  else if (unitType === 'volume') decimalPlaces = 6

  const restrictInputValueCallback = (value) => {
    const normalizedValue = normalize
      ? normalize(value)
      : restrictInputValueAfterDot(value, {
          decimalPlaces,
        })

    return normalizedValue
  }

  const handleInputChange = ({target: {value}}) => {
    if (onChange) {
      if (value === ',' || value === '.') {
        onChange('0.')
        return
      }

      const regexp = /^[+-]?\d+(?:[.,]\d+)*(?:[.,]\d*)?$/

      if (!regexp.test(value) && value !== '') return

      onChange(restrictInputValueCallback(value.replace(/,/g, '.')))
    }
  }

  return (
    <Form.Item
      name={name}
      rules={[rules]}
      label={label}
      className={formWrapperClassName}
      initialValue={initialValue}
      normalize={restrictInputValueCallback}
    >
      <Input
        {...rest}
        onChange={handleInputChange}
        // controls={false}
        className={`${className || ''} input_number`}
        ref={ref || innerRef}
      />
    </Form.Item>
  )
})

export default InputNumberForm
