import {useTranslation} from 'react-i18next'
import {Form} from 'antd'
import {useSelector} from 'react-redux'
import {AntdTableActionModal} from '../../../../../../../../../components/antd-components/app-table'
import {InputForm} from '../../../../../../../../../components/antd-components/form'
import {
  currencyDivide,
  currencyMultiply,
  restrictInputValueAfterDot,
} from '../../../../../../../../../utils/helpers'
import CancelButton from '../../../../../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../../../../../../components/antd-components/main-buttons/save-button/SaveButton'
import './style.scss'
import RecalculateValidationSchema from './validation'

function RecalculateModal(props) {
  const {
    isModalOpen,
    closeModalHandler,
    productForm,
    form,
    currentProductData,
    setCurrentProductData,
  } = props
  const {t} = useTranslation()
  const validation = RecalculateValidationSchema()
  const rawMaterials = useSelector((state) => state.rawMaterials.rawMaterials)
  const selectedProduct = useSelector((state) => state.products.selectedProduct)

  const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
  }

  const submitButtonHandler = ({productionOutputs}) => {
    const currentProductionOutputs = productForm.getFieldValue('amount')
    const newProductionOutputs = Number(
      restrictInputValueAfterDot(productionOutputs)
    )
    const updatedSemiFinishedList = currentProductData.semiFinished.map(
      (semiFinishedItem) => {
        let newSemiFinishedAmount = 0
        if (currentProductionOutputs === 1) {
          newSemiFinishedAmount = currencyMultiply(
            semiFinishedItem.amount,
            newProductionOutputs,
            6
          )
        }
        if (currentProductionOutputs > 0 && currentProductionOutputs !== 1) {
          if (semiFinishedItem.changed) {
            // if data changed -> calculate from current semiFinishedItem
            const {amount} = semiFinishedItem
            const amountForOneItem = currencyDivide(
              Number(amount),
              currentProductionOutputs,
              6
            )
            newSemiFinishedAmount = currencyMultiply(
              amountForOneItem,
              newProductionOutputs,
              6
            )
          }
          if (!semiFinishedItem.changed) {
            // else calculate from backend response (selected product)
            if (selectedProduct) {
              const {semiFinished} = selectedProduct
              const defaultItemData = semiFinished.find(
                (item) => item.id === semiFinishedItem.id
              )
              const defaultAmount = defaultItemData?.amount || 0
              newSemiFinishedAmount = currencyMultiply(
                defaultAmount,
                newProductionOutputs,
                6
              )
            }
          }
        }
        return {
          ...semiFinishedItem,
          amount: newSemiFinishedAmount,
        }
      }
    )
    const updatedIngredientsList = currentProductData.ingredients.map(
      (ingredient) => {
        let updatedGrossValue = 0
        let updatedNetValue = 0
        const {gross, rawId} = ingredient
        if (currentProductionOutputs === 1) {
          updatedGrossValue = currencyMultiply(newProductionOutputs, gross, 6)
        }
        if (currentProductionOutputs !== 1) {
          if (ingredient.changed) {
            const grossValueForOneItem = currencyDivide(
              gross,
              currentProductionOutputs,
              6
            )
            updatedGrossValue = currencyMultiply(
              grossValueForOneItem,
              newProductionOutputs,
              6
            )
          } else {
            const {rawId} = ingredient
            const currentRaw = selectedProduct.ingredients.find(
              (ingredientItem) => ingredientItem.rawId === rawId
            )
            const defaultGrossValue = currentRaw.gross || 0
            updatedGrossValue = currencyMultiply(
              defaultGrossValue,
              newProductionOutputs,
              6
            )
          }
        }
        // Calculate updated net value
        const rawGross =
          rawMaterials.find((raw) => raw.id === rawId)?.gross || 0
        const rawNet = rawMaterials.find((raw) => raw.id === rawId)?.net || 0
        const grossCoefficient = currencyDivide(rawNet, rawGross, 6)
        updatedNetValue = grossCoefficient
          ? currencyMultiply(grossCoefficient, updatedGrossValue, 6)
          : 0
        return {...ingredient, gross: updatedGrossValue, net: updatedNetValue}
      }
    )
    productForm.setFieldsValue({amount: newProductionOutputs})
    setCurrentProductData({
      ...currentProductData,
      semiFinished: updatedSemiFinishedList,
      ingredients: updatedIngredientsList,
    })
    closeModalHandler()
  }

  return (
    <AntdTableActionModal
      className="recalculate_modal"
      open={isModalOpen}
      title={t('Recalculate recipe')}
      handleCancel={closeModalHandler}
    >
      <Form form={form} {...layout} onFinish={submitButtonHandler}>
        <InputForm
          name="productionOutputs"
          label={t('Please set N of units to recalculate the current recipe')}
          formClassName="form_field"
          normalize={restrictInputValueAfterDot}
          autoComplete="off"
          rules={{...validation, required: true}}
        />
      </Form>
      <div className="modal_footer">
        <CancelButton className="footer_button" onClick={closeModalHandler} />
        <SaveButton
          className="footer_button"
          onClick={() => {
            form.submit()
          }}
        />
      </div>
    </AntdTableActionModal>
  )
}

export default RecalculateModal
