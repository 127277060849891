import {useSelector} from 'react-redux'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {Col, Row} from 'antd'
import {TableDetailsReadOnly} from '../../../../components/antd-components/app-table'
import {getRandomId} from '../../../../utils/helpers'
import ListComponent from '../../../../components/antd-components/listComponent/ListComponent'
import {
  isLoadingSelectedPlanning,
  planningSelected,
} from '../../../../redux/selectors/planningSelecor'
import usePlanningDetailTableSettings from '../../../../services/plunning/planningDetailtTableSettings'
import LoaderComponent from '../../../../components/loaderComponent/LoaderComponent'
import {formattedDate} from '../../../../constants'
import './style.scss'

function PlanningDetail() {
  const {t} = useTranslation()
  const isLoadingGetById = useSelector(isLoadingSelectedPlanning)
  const selectedPlanning = useSelector(planningSelected)

  const data = selectedPlanning?.products?.map((item) => {
    const storeRow = {name: item.name, id: getRandomId()}

    // eslint-disable-next-line no-restricted-syntax
    for (const storeRecord of item.records) {
      storeRow[storeRecord.storeName] = storeRecord.planningAmount
      storeRow[`${storeRecord.storeName}balance`] = storeRecord.storeBalance
    }

    return storeRow
  })

  const {planningDetailColumns} = usePlanningDetailTableSettings({
    selectedPlanning,
  })

  const detailNorm = [
    {
      key: getRandomId(),
      name: t('Planning date'),
      value: moment(selectedPlanning?.planningDate).format(formattedDate.Date),
    },
    {
      key: getRandomId(),
      name: t('Create date'),
      value: moment(selectedPlanning?.createdDate).format(
        formattedDate.DateWithTime
      ),
    },
    {
      key: getRandomId(),
      name: t('Update date'),
      value: moment(selectedPlanning?.updatedDate).format(
        formattedDate.DateWithTime
      ),
    },
    {
      key: getRandomId(),
      name: t('Author'),
      value: selectedPlanning?.createdBy?.name,
    },

    {
      key: getRandomId(),
      name: t('Norm name'),
      value: selectedPlanning?.storeNorm?.title,
    },
  ]

  return (
    <div>
      <LoaderComponent
        isLoadingCreator={[isLoadingGetById]}
        component={
          <Row gutter={[10, 10]}>
            <Col xl={8} sm={8} xs={8} lg={8} className="norm_detail_wrapper">
              <ListComponent dataSource={detailNorm} />
            </Col>

            <Col
              xl={16}
              sm={16}
              xs={16}
              lg={16}
              className="norm_detail_wrapper"
            >
              <TableDetailsReadOnly
                isLoading={isLoadingGetById}
                scrollY="calc(100vh - 16em)"
                selectedById={data}
                columns={planningDetailColumns}
                scrollX="scroll"
              />
            </Col>
          </Row>
        }
      />
    </div>
  )
}

export default PlanningDetail
