import {useTranslation} from 'react-i18next'
import {Tabs} from 'antd'
import StoreBalanceTable from './StoreBalanceTable'
import {storeBalanceTabs} from '../constants'

const {TabPane} = Tabs

function StoreBalanceBody({
  tableSettings,
  setStoreBalanceTableSettings,
  setItemType,
  itemType,
}) {
  const {t} = useTranslation()

  return (
    <div className="items_tabs">
      <Tabs
        onChange={setItemType}
        defaultActiveKey={itemType}
        key="main_items_tabs"
      >
        <TabPane tab={t('Products')} key={storeBalanceTabs.PRODUCT}>
          <StoreBalanceTable
            tableSettings={tableSettings}
            onSetTableSettings={setStoreBalanceTableSettings}
          />
        </TabPane>
        <TabPane tab={t('Semi-finished')} key={storeBalanceTabs.SEMI}>
          <StoreBalanceTable
            tableSettings={tableSettings}
            onSetTableSettings={setStoreBalanceTableSettings}
          />
        </TabPane>
        <TabPane tab={t('Raws')} key={storeBalanceTabs.RAW}>
          <StoreBalanceTable
            tableSettings={tableSettings}
            onSetTableSettings={setStoreBalanceTableSettings}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default StoreBalanceBody
