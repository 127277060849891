import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {getColumnSorter} from '../../utils/helpers'
import {formattedDate} from '../../constants'
import {useDateRangeTableSettings} from '../../hooks'
import useNormService from './normService'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const useNormTableService = ({
  onEdit,
  onDelete,
  showDetailInformation,
  setIsOpenDetailModal,
  tableSettings,
}) => {
  const {t} = useTranslation()

  const {setNormTableSettings} = useNormService()

  const createdDateFilterProps = useDateRangeTableSettings({
    tableSettings,
    setTableSettings: setNormTableSettings,
  })

  const normColumns = [
    {
      title: t('Name'),
      dataIndex: 'title',
      key: 'f_title',
      align: 'center',
      width: '45%',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'title'),
    },
    {
      title: t('Create date'),
      dataIndex: 'createdDate',
      key: 'f_createdDateTo',
      align: 'center',
      width: '45%',
      sorter: true,
      ...createdDateFilterProps,
      filteredValue: tableSettings.f_createdDateTo,
      sortOrder: getColumnSorter(tableSettings, 'createdDate'),
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: '10%',
      render: (_, record) => (
        <div>
          <DetailsActionButton
            onClick={(e) => {
              e.stopPropagation()
              showDetailInformation(record.id)
              setIsOpenDetailModal(true)
            }}
          />
          <EditActionButton
            onClick={(e) => {
              e.stopPropagation()
              onEdit(record.id)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDelete(record.id)
            }}
          />
        </div>
      ),
    },
  ]

  return {normColumns}
}

export default useNormTableService
