import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'
import {checkIsResponseSuccess} from '../../utils/helpers'

export const writeOffConfirm = createAsyncThunk(
  'CONFIRM_WRITE_OF_REASON',
  async (id) => {
    try {
      const response = await API.put(`/store/products/write-offs/${id}/confirm`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const writeOffRejected = createAsyncThunk(
  'REJECT_WRITE_OF_REASON',
  async (id) => {
    try {
      const response = await API.put(`/store/products/write-offs/${id}/reject`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const getWriteOffReasonConfirm = createAsyncThunk(
  'GET_WRITE_OFF_REASON_CONFIRM',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/store/products/write-offs', {params})
      if (checkIsResponseSuccess(response.status)) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedWriteOffReason: state.writeOff.selectedWriteOffReason,
      }
    }

    return null
  }
)

export const getWriteOffById = createAsyncThunk(
  'GET_WRITE_OFF_BY_ID',
  async (id) => {
    try {
      const response = await API.get(`/store/products/write-offs/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)
