import {useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {AppTable} from '../../../../../components/antd-components/app-table'
import useOrdersTable from '../../../../../services/orders/useOrdersTableService'
import {
  isOrdersListLoadingSelector,
  lastOrderUpdated,
  ordersListSelector,
  ordersTotalCountSelector,
  orderTableSettingsSelector,
} from '../../../../../redux/selectors/orderSelector'
import useOrdersService from '../../../../../services/orders/ordersService'
import useCustomNavigate from '../../../../../hooks/useCustomNavigate'
import {ordersApi} from '../../../../../services/api/ordersApiService'
import showConfirm from '../../../../../components/PopupConfirm'
import OrderPaymentsModal from '../modals/OrderPaymentsModal'
import useOrderByIdService from '../../../../../services/orders/useOrderByIdService'
import {paymentStatus, tableRowClassNames} from '../../../../../constants'

import './style.scss'

function OrdersListBody({selectedRowKeys, setSelectedRowKeys}) {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const lastUpdate = useSelector(lastOrderUpdated)

  const [showPaymentOrderId, setShowPaymentOrderId] = useState(null)
  const {order, isOrderLoading, getOrder, downloadOrder} = useOrderByIdService()

  useEffect(() => {
    if (showPaymentOrderId) getOrder(showPaymentOrderId)
  }, [showPaymentOrderId])

  const ordersList = useSelector(ordersListSelector)
  const totalCount = useSelector(ordersTotalCountSelector)
  const tableSettings = useSelector(orderTableSettingsSelector)
  const isLoading = useSelector(isOrdersListLoadingSelector)
  const {fetchOrdersWithCurrentSettings, setOrdersTableSettingsAndRefetchList} =
    useOrdersService()

  const handleOpenViewPayments = setShowPaymentOrderId

  const {columns} = useOrdersTable({
    onEdit: (orderId) => navigate(`/orders/edit/${orderId}`),
    onViewPayments: handleOpenViewPayments,
    onDelete: (orderId) =>
      showConfirm({
        title: t('Confirm delete'),
        okFunction: async () => {
          await ordersApi.deleteOrder([orderId])
          fetchOrdersWithCurrentSettings()
        },
        okType: 'danger',
        icon: <ExclamationCircleOutlined />,
      }),
    onDownloadOrder: downloadOrder,
  })

  const getRowClassName = (record) => {
    if (record?.paymentStatus !== paymentStatus.PAID) {
      return tableRowClassNames.IS_ERROR
    }
    return null
  }

  useEffect(() => {
    fetchOrdersWithCurrentSettings()
  }, [lastUpdate])

  return (
    <div>
      <AppTable
        dataSource={ordersList}
        columns={columns}
        totalCount={totalCount}
        tableSettings={tableSettings}
        setTableSettings={setOrdersTableSettingsAndRefetchList}
        isLoading={isLoading}
        showSelectRowCheckbox
        setSelectedComponent={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
        rowClassName={getRowClassName}
      />
      <OrderPaymentsModal
        open={!!showPaymentOrderId}
        isLoading={isOrderLoading}
        onCancel={() => setShowPaymentOrderId(null)}
        orderPayments={order?.orderPayments}
        orderId={order?.id}
        withAddPayment={
          order?.paymentDetails?.paymentStatus !== paymentStatus.PAID
        }
      />
    </div>
  )
}

export default OrdersListBody
