import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {PageHeaderWithMobileMenu} from '../../../../components'
import {inventoryTableSettings} from '../../../../redux/selectors/inventorySelector'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import ResetFiltersButton from '../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'
import {inventoryInitialState} from '../../../../redux/slices/inventorySlice'
import useInventoryService from '../../../../services/inventory/inventoryService'

function InventoryHeader() {
  const {t} = useTranslation()
  const tableSettings = useSelector(inventoryTableSettings)
  const {resetInventoryTableSettings} = useInventoryService()
  const {exportExcel} = useCommon()

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `inventories`,
      tableSettings,
    })
  }

  const actionInventoryHeader = [
    <DownloadButton onClick={downloadButtonHandler} />,
  ]

  return (
    <div>
      <PageHeaderWithMobileMenu
        showHeaderMenu={false}
        title={t('Inventory')}
        subTitle={
          <ResetFiltersButton
            clearFiltersHandler={resetInventoryTableSettings}
            currentTableSettings={tableSettings}
            initialTableSettings={inventoryInitialState.tableSettings}
          />
        }
        extra={actionInventoryHeader}
      />
    </div>
  )
}

export default InventoryHeader
