import {useMemo} from 'react'
import {Col, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {
  AutoCompleteComponent,
  FormDateTimePickerAntd,
  InputForm,
  InputNumberForm,
  SwitchForm,
  TextAreaForm,
} from '../../../../../components/antd-components/form'
import {storesSelector} from '../../../../../redux/selectors/storeSelector'
import {formattedDate} from '../../../../../constants'

import './style.scss'
import Validation from './validation'

function OrderGeneralDataForm() {
  const {t} = useTranslation()

  const validationRules = Validation()

  const stores = useSelector(storesSelector)

  const deliveryTypeOptions = useMemo(
    () => [
      {id: 'self_pickup', name: t('Self pick-up')},
      {id: 'courier', name: t('Courier')},
    ],
    [t]
  )

  return (
    <>
      <Row gutter={8}>
        <Col span={8}>
          <InputForm
            name="name"
            label={t('Customer')}
            placeholder={t('Customer')}
            rules={{...validationRules, required: true}}
          />
        </Col>
        <Col span={8}>
          <InputForm
            name="phone"
            label={t('Phone')}
            placeholder="+380980000000"
          />
        </Col>
        <Col span={8}>
          <InputForm
            name="externalId"
            label={t('External id')}
            rules={validationRules}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={8}>
          <AutoCompleteComponent
            options={stores?.map((store) => ({
              id: store?.id,
              name: store?.name,
              value: store?.id,
            }))}
            name="deliveryFrom"
            placeholder={t('Delivery from')}
            label={t('Delivery from')}
          />
        </Col>
        <Col span={8}>
          <FormDateTimePickerAntd
            format={formattedDate.DateWithTime}
            label={t('Delivery date')}
            type="date"
            showTime={{minuteStep: 30}}
            name="deliveryDate"
            className="order-form_date-picker"
            allowClear={false}
            rules={{required: true}}
            disabledLastDates
          />
        </Col>
        <Col span={8}>
          <FormDateTimePickerAntd
            format={formattedDate.DateWithTime}
            label={t('Delivery date to')}
            type="date"
            showTime={{minuteStep: 30}}
            name="deliveryDateTo"
            className="order-form_date-picker"
            allowClear={false}
            disabledLastDates
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={8}>
          <AutoCompleteComponent
            options={deliveryTypeOptions}
            name="deliveryType"
            allowClear={false}
            label={t('Delivery type')}
            rules={{required: true}}
          />
        </Col>
        <Col span={8}>
          <InputNumberForm
            placeholder={t('Only positive number')}
            name="deliveryPrice"
            label={t('Delivery price')}
            controls={false}
            onWheel={(e) => e.target.blur()}
            unitType="price"
            rules={validationRules}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextAreaForm
            autoSize={{minRows: 2, maxRows: 3}}
            placeholder={t('Address')}
            label={t('Address')}
            name="deliveryAddress"
            rules={validationRules}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SwitchForm label={t('Delivered')} name="delivered" />
        </Col>
      </Row>
    </>
  )
}

export default OrderGeneralDataForm
