import {createSlice} from '@reduxjs/toolkit'
import {t} from 'i18next'
import {authLogin} from '../asyncServices/authService'
import notify from '../../components/antd-components/notification/notify'

const initialState = {
  isAuth: false,
  user: null,
  accessToken: null,
  refreshToken: null,
  isLoading: false,
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setTokens: (state, action) => {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authLogin.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(authLogin.fulfilled, (state, action) => {
      state.isAuth = action.payload.isAuth
      state.user = action.payload.user
      state.accessToken = action.payload.accessToken
      state.isLoading = false
    })
    builder.addCase(authLogin.rejected, (state, action) => {
      const {message} = action.payload
      const errorMessage = message ? t(message) : t('Invalid phone or password')
      notify({
        description: errorMessage,
        type: 'error',
      })
      state.isLoading = false
    })
  },
})

export const {setTokens} = authSlice.actions
export default authSlice.reducer
