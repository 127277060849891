import ProfitsPageHeader from './components/page-header/ProfitsPageHeader'
import ProfitsPageBody from './components/page-body/ProfitsPageBody'

function ProfitsPage() {
  return (
    <div>
      <ProfitsPageHeader />
      <ProfitsPageBody />
    </div>
  )
}

export default ProfitsPage
