import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import {AppTable} from '../../../../../../../../components/antd-components/app-table'
import {
  isProfitsLoadingSelector,
  profitsListSelector,
  profitsTableSettingsSelector,
  profitsTotalCountSelector,
} from '../../../../../../../../redux/selectors/profitsSelector'
import useProfitsTable from './profitsTableConfig'
import {tableRowClassNames} from '../../../../../../../../constants'
import './style.scss'
import useProfitService from '../../../../../../../../services/profit/profitService'

function ProfitsTable() {
  const profitsItems = useSelector(profitsListSelector)
  const tableSettings = useSelector(profitsTableSettingsSelector)
  const isLoading = useSelector(isProfitsLoadingSelector)
  const totalCount = useSelector(profitsTotalCountSelector)
  const {setProfitTableSettings, getProfitsList} = useProfitService()

  useEffect(() => {
    if (!isLoading) {
      getProfitsList(tableSettings)
    }
  }, [tableSettings])

  const {profitsColumns} = useProfitsTable({tableSettings})

  const getRowClassName = (record) => {
    if (record?.result <= 0) {
      return tableRowClassNames.IS_ERROR
    }
    return 'default_row'
  }

  return (
    <AppTable
      className="profits_table"
      scroll={{x: 'max-content', y: true}}
      dataSource={profitsItems}
      columns={profitsColumns}
      isLoading={isLoading}
      totalCount={totalCount}
      tableSettings={tableSettings}
      setTableSettings={setProfitTableSettings}
      rowClassName={getRowClassName}
    />
  )
}
export default ProfitsTable
