import {useEffect, useState} from 'react'
import {Col, Form, PageHeader, Row} from 'antd'
import moment from 'moment'
import '../style.scss'
import currency from 'currency.js'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {PlusOutlined} from '../../../../components/antd-components/icon'
import variables from '../../../../globalStyle/colors.scss'
import {
  convertToBooleanByLength,
  convertToFractionalNumber,
  getFormValueByName,
  getRandomId,
} from '../../../../utils/helpers'
import SupplyAddForm from './SupplyAddForm'
import useRawSuppliesTableAntd from '../../../../services/supllies/antdRawTableService'
import SuppliesValidation from '../validation/SuppliesValidation'

import withAntdModal from '../../../../HOCs/withAntdModal'
import RawMaterialsForm from '../../raw-materials/components/RawMaterialsForm'
import {
  isLoadingRawMaterials,
  newCreatedRawMaterial,
} from '../../../../redux/selectors/rawMaterialsSelector'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'
import {formattedDate} from '../../../../constants'
import HistorySupplyBody from '../history-supplies/HistorySupplyBody'
import useTableRenderItems from '../../../../services/useTableRenderItems'
import useSuppliesHistoryByIngredient from '../../../../services/supllies/historySuppliesByIngredient/historySuppliesByIngredient'
import AddButton from '../../../../components/antd-components/main-buttons/add-button/AddButton'

function SupplyAddBody({
  addSupply,
  selectedSupply,
  updateSupplyData,
  clearNewRawMaterial,
  clearIngredientState,
}) {
  const [rawsList, setRawsList] = useState([])
  const [priceOneConfig, setPriceOneConfig] = useState({
    value: null,
    isActive: false,
  })
  const [amountConfig, setAmountConfig] = useState({
    value: null,
    isActive: false,
  })
  const [totalPriceConfig, setTotalPriceConfig] = useState({
    value: null,
    isActive: false,
  })
  const [selectedRecordId, setSelectedRecordId] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [rawId, setRawId] = useState(null)
  // const [isEmptyRaw, setIsEmptyRaw] = useState(false)
  // const [isNewRow, setIsNewRow] = useState(false)
  const [selectedInput, setSelectedInput] = useState({
    amountField: '',
    priceOneField: '',
    priceTotalField: '',
  })
  const {renderNameById} = useTableRenderItems()

  const newRawMaterial = useSelector(newCreatedRawMaterial)
  const isLoadingRaw = useSelector(isLoadingRawMaterials)
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const [isOpenModalWindow, setIsOpenModalWindow] = useState(false)
  const [recordName, setRecordName] = useState('false')
  const suppliesValidation = SuppliesValidation()
  const rawMaterials = useSelector((state) => state.rawMaterials.rawMaterials)
  const isActiveSendButton = convertToBooleanByLength(rawsList, 0)
  const getAmountByForm = getFormValueByName(`amount${selectedRecordId}`, form)
  const getPriceOneByForm = getFormValueByName(
    `priceOne${selectedRecordId}`,
    form
  )
  const getTotalPricedByForm = getFormValueByName(
    `priceTotal${selectedRecordId}`,
    form
  )
  const {setSelectedIngredientId} = useSuppliesHistoryByIngredient()
  useEffect(() => {
    if (selectedSupply !== null) {
      setRawsList(selectedSupply.raws)
    } else {
      setRawsList([])
    }
  }, [selectedSupply])

  useEffect(() => {
    setTotalPriceConfig({
      value: currency(amountConfig.value).multiply(priceOneConfig.value),
      ...totalPriceConfig,
    })
  }, [priceOneConfig.value, amountConfig.value])

  const calculateTotalPrice = () =>
    rawsList
      .map((item) => item.priceTotal)
      .reduce((prev, curr) => currency(prev).add(curr).value, 0) || 0

  const handleEdit = (value) => {
    const {priceOne, priceTotal, amount, id} = value
    const changedProductId = rawsList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          ...value,
          priceOne: Number(currency(priceOne).value),
          priceTotal: Number(currency(priceTotal).value),
          amount: Number(amount),
        }
      }
      return item
    })
    setRawsList(changedProductId)
  }

  const handleAdd = () => {
    const newData = {
      id: getRandomId(),
      priceOne: '',
      amount: '',
      rawId: newRawMaterial ? newRawMaterial.id : '',
      priceTotal: '',
      statusRow: 'new',
    }
    setRawsList([...rawsList, newData])
    if (newRawMaterial) {
      clearNewRawMaterial()
    }
  }

  useEffect(() => {
    if (!isLoadingRaw) {
      if (newRawMaterial) {
        const isRowWithNewRawMaterialExist = rawsList.find(
          (raw) => raw.rawId === newRawMaterial.id
        )
        if (!isRowWithNewRawMaterialExist) {
          handleAdd()
        }
      }
    }
  }, [newRawMaterial, isLoadingRaw])

  const handleDelete = (id) => {
    const newData = rawsList.filter((item) => item.id !== id)
    setRawsList(newData)
    clearNewRawMaterial()
  }

  const onSubmitAddSupply = (values) => {
    const {date, supplierId, cardPayment} = values
    const rawsWithoutCustomField = rawsList.map(
      ({id, amount, statusRow, priceOne, priceTotal, ...item}) => ({
        ...item,
        amount: amount || 0,
        priceOne: currency(priceOne, {precision: 2}).value,
        priceTotal: currency(priceTotal, {precision: 2}).value,
      })
    )
    addSupply({
      date: moment(date).format(formattedDate.DateWithTimeForPicker),
      supplierId: Number(supplierId),
      total: calculateTotalPrice(),
      cardPayment,
      raws: rawsWithoutCustomField,
    })
    setRawsList([])
  }
  const onSubmitUpdateSupply = (values) => {
    const {date, supplierId, cardPayment} = values

    updateSupplyData({
      id: selectedSupply.id,
      date,
      supplierId: Number(supplierId),
      total: calculateTotalPrice(),
      cardPayment,
      raws: rawsList.map(
        ({amount, priceOne, priceTotal, statusRow, id, ...item}) => ({
          ...item,
          amount: amount || 0,
          priceOne: currency(priceOne, {precision: 2}).value,
          priceTotal: currency(priceTotal, {precision: 2}).value,
        })
      ),
    })
  }

  const getDetailInModalWindow = (record) => {
    setIsOpenModalWindow(true)
    setRecordName(renderNameById({id: record.rawId}, rawMaterials))
    setSelectedIngredientId(record.rawId)
  }

  const {rawSuppliesColumnsAntd} = useRawSuppliesTableAntd({
    handleEdit,
    handleDelete,
    setPriceOneConfig,
    setAmountConfig,
    setTotalPriceConfig,
    priceOneConfig,
    totalPriceConfig,
    amountConfig,
    selectedRecordId,
    rules: suppliesValidation,
    form,
    getDetailInModalWindow,
    isEdit,
    rawsList,
    newRawMaterial,
    setRawId,
    rawId,
    setIsEdit,
    isLoadingRaw,
    setSelectedRecordId,
    selectedInput,
    setSelectedInput,
    getAmountByForm,
    getTotalPricedByForm,
    getPriceOneByForm,
  })

  const footerTableText = () => (
    <div className="table_footer">
      <div>{t('Total')}:</div>
      <div>{convertToFractionalNumber(calculateTotalPrice(), 2)}</div>
    </div>
  )

  const AddRawMaterialsFormWithModal = withAntdModal(RawMaterialsForm, {
    okButtonText: t('Add new raw'),
    footer: null,
    title: t('Add record'),
    icon: <PlusOutlined />,
    style: {
      textTransform: 'uppercase',
      color: variables.add,
    },
  })

  const SubTitle = (
    <Row style={{width: '375px'}}>
      <Col xs={24} sm={9} lg={9} xl={9}>
        <AddButton onClick={() => handleAdd(true)} />
      </Col>
      <Col xs={24} sm={10} lg={10} xl={10}>
        <AddRawMaterialsFormWithModal
          submitText={t('Add')}
          createFromComponent
        />
      </Col>
    </Row>
  )

  const onCloseModalWindow = () => {
    setIsOpenModalWindow(false)
    clearIngredientState()
  }

  return (
    <div>
      <SupplyAddForm
        onSubmit={selectedSupply?.id ? onSubmitUpdateSupply : onSubmitAddSupply}
        {...(selectedSupply ? {formData: selectedSupply} : {})}
        isActiveSendButton={isActiveSendButton}
        form={form}
      />
      <div>
        <PageHeader title={t('Raw')} subTitle={SubTitle} />
        <AntdTableActionModal
          title={`${t('History supplies')} ${':'} ${recordName}`}
          handleCancel={onCloseModalWindow}
          open={isOpenModalWindow}
          width={2000}
          style={{top: 10}}
          className=" modal"
        >
          <HistorySupplyBody />
        </AntdTableActionModal>
        <Form
          form={form}
          onFinish={
            selectedSupply?.id ? onSubmitUpdateSupply : onSubmitAddSupply
          }
        >
          <AppTable
            bordered
            dataSource={rawsList}
            columns={rawSuppliesColumnsAntd}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => {
                setSelectedRecordId(record.id)
              },
            })}
            className="supplyAdd_table"
            style={{height: 'calc(100vh - 20em)'}}
            scroll={{y: 'calc(100vh - 25em)', x: 600}}
            showPagination={false}
            loading={isLoadingRaw}
            footer={() => footerTableText()}
          />
        </Form>
      </div>
    </div>
  )
}

export default SupplyAddBody
