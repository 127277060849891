import {useTranslation} from 'react-i18next'
import currency from 'currency.js'
import DetailsActionButton from '../../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'

const useIngredientsPriceDetailTable = ({getDetailInModalWindow}) => {
  const {t} = useTranslation()

  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'rawId',
      key: 'name',
      align: 'center',
      width: 30,
      render: (value, record) => record.ingredient.name,
    },
    {
      title: t('Gross'),
      dataIndex: 'gross',
      align: 'center',
      width: 30,
    },
    {
      title: t('Net'),
      dataIndex: 'net',
      align: 'center',
      width: 30,
    },

    {
      title: t('Price'),
      dataIndex: 'priceMax',
      key: 'priceMax',
      align: 'center',
      width: 30,
      render: (value, record) => record.ingredient.priceMax.toFixed(2),
    },
    {
      title: t('Sum'),
      dataIndex: 'priceOne',
      key: 'priceOne',
      align: 'center',
      width: 30,
      render: (value, record) =>
        currency(record.ingredient.priceMax)
          .multiply(record.gross)
          .value?.toFixed(2),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 20,
      render: (_, record) => (
        <div>
          <DetailsActionButton
            onClick={(e) => {
              e.stopPropagation()
              getDetailInModalWindow(record)
            }}
          />
        </div>
      ),
    },
  ]

  return {ingredientsColumns: getColumns()}
}

export default useIngredientsPriceDetailTable
