import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'
import {checkIsResponseSuccess} from '../../utils/helpers'
import {resetReduxStore} from '../resetStore'

export const authLogin = createAsyncThunk(
  'AUTH_LOGIN',
  async (data, {rejectWithValue, dispatch}) => {
    try {
      const response = await API.post('/auth/login/admin', data)
      dispatch(resetReduxStore())
      if (checkIsResponseSuccess(response?.data?.statusCode)) {
        return {...response.data.payload, isAuth: true}
      }
      return rejectWithValue({
        response,
      })
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  }
)

export async function apiRefreshToken({refreshToken}) {
  return API.get('/auth/refresh', {
    headers: {
      'refresh-token': refreshToken,
    },
  })
}
