import {useSelector} from 'react-redux'
import {PageHeaderWithMobileMenu} from '../../../../components'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {purchasesTableSettingsSelector} from '../../../../redux/selectors/purchasesSelector'
import {purchasesInitialState} from '../../../../redux/slices/purchaseSlice'
import ResetFiltersButton from '../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'
import usePurchaseService from '../../../../services/purchase/purchaseService'

function PurchaseHeader() {
  const {exportExcel} = useCommon()
  const {resetPurchaseTableSettings} = usePurchaseService()
  const tableSettings = useSelector(purchasesTableSettingsSelector)

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `purchase`,
      tableSettings,
    })
  }

  const actionPurchaseHeader = [
    <DownloadButton onClick={downloadButtonHandler} />,
  ]

  return (
    <PageHeaderWithMobileMenu
      title="Purchases"
      subTitle={
        <ResetFiltersButton
          clearFiltersHandler={resetPurchaseTableSettings}
          currentTableSettings={tableSettings}
          initialTableSettings={purchasesInitialState.tableSettings}
        />
      }
      showHeaderMenu={false}
      extra={actionPurchaseHeader}
    />
  )
}

export default PurchaseHeader
