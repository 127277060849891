import {Button, Tooltip} from 'antd'

function ButtonWithTooltip({placement, title, children, className, ...rest}) {
  return (
    <Tooltip placement={placement} title={title}>
      <Button {...rest} className={className}>
        {children}
      </Button>
    </Tooltip>
  )
}

export default ButtonWithTooltip
