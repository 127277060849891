import {useDeferredValue, useEffect, useState} from 'react'
import useSearchParamsUrl from './useSearchParamsUrl'

export const useSearch = ({
  currentSearch,
  searchFilterKey,
  storeBalanceTableSettings,
  setStoreBalanceTableSettings,
}) => {
  const {params, changeSearchValue} = useSearchParamsUrl()
  const [searchValue, setSearchValue] = useState(
    params?.f_productName
      ? params?.f_productName
      : storeBalanceTableSettings?.f_productName
  )

  const deferredValue = useDeferredValue(searchValue)

  useEffect(() => {
    const debouncedSearchValue = !deferredValue ? null : deferredValue
    if (currentSearch !== debouncedSearchValue) {
      changeSearchValue(
        debouncedSearchValue,
        setStoreBalanceTableSettings,
        storeBalanceTableSettings,
        searchFilterKey
      )
    }
  }, [deferredValue])

  return {
    searchValue,
    setSearchValue,
  }
}
