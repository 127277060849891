import {Button, Col} from 'antd'
import {MoreOutlined} from '@ant-design/icons'
import DropDownMenu from './DropDownMenu'

function HeaderMobileMenu({items}) {
  return (
    <Col>
      <DropDownMenu items={items}>
        <Button type="link">
          <MoreOutlined style={{fontSize: '20px'}} />
        </Button>
      </DropDownMenu>
    </Col>
  )
}

export default HeaderMobileMenu
