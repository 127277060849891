import DashboardPageHeader from './components/page-header/DashboardPageHeader'
import DashboardPageBody from './components/page-body/DashboardPageBody'

function DashboardPage() {
  return (
    <div>
      <DashboardPageHeader />
      <DashboardPageBody />
    </div>
  )
}

export default DashboardPage
