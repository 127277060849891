import {Form, TreeSelect} from 'antd'

function TreeSelectForm({
  name,
  rules = {},
  label,
  formStyle,
  formClassName,
  value,
  ...rest
}) {
  return (
    <Form.Item
      name={name}
      rules={[rules]}
      label={label}
      style={formStyle}
      className={formClassName}
    >
      <TreeSelect value={value} {...rest} />
    </Form.Item>
  )
}

export default TreeSelectForm
