import {createSearchParams} from 'react-router-dom'
import {objectWithoutNullField} from '../utils/helpers'
import useCustomNavigate from './useCustomNavigate'

const useUrlWithSetParamsEnCode = (pathName, paramsValue) => {
  const navigate = useCustomNavigate()

  const navigateToUrlWithSearchParams = () => {
    navigate({
      pathname: pathName,
      search: `${createSearchParams({
        ...objectWithoutNullField(paramsValue),
      })}`,
    })
  }
  return {
    navigateToUrlWithSearchParams,
  }
}

export default useUrlWithSetParamsEnCode
