import {useSelector} from 'react-redux'
import {Button} from 'antd'
import {useTranslation} from 'react-i18next'
import {cutString, getRandomId} from '../../../../utils/helpers'
import {BellFilled} from '../../../../components/antd-components/icon'
import useNotification from '../../../../services/notifications/notificationService'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'

function NotificationContent() {
  const notifications =
    useSelector((state) => state.messageNotifications.notificationMessage) || []

  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const unreadMessage = notifications?.filter((item) => item.read === false)
  const firstFiveUnreadMessage = unreadMessage?.slice(0, 5)
  const {updateNotification, setSelectedNotification} = useNotification()
  const handleMenuClick = (e) => {
    navigate('/messageNotifications')
    updateNotification(e.id)
    setSelectedNotification(e.id)
  }
  const notificationItem =
    firstFiveUnreadMessage?.map((item) => ({
      key: item.id.toString(),
      label: cutString(item.message, 26),
      icon: <BellFilled />,
      onClick: () => handleMenuClick(item),
    })) || []

  const emptyLabel = [
    {
      key: getRandomId(),
      onClick: () => null,
      label: (
        <Button
          onClick={(e) => e.stopPropagation()}
          type="link"
          style={{cursor: 'default'}}
        >
          {t('No unread message')}
        </Button>
      ),
    },
    {
      key: getRandomId(),
      onClick: () => navigate('/messageNotifications'),
      label: <Button style={{width: '100%'}}>{t('Show all')}</Button>,
    },
  ]

  const notificationItems = [...notificationItem].length
    ? [
        ...notificationItem,
        {
          key: getRandomId(),
          onClick: () => navigate('/messageNotifications'),
          label: <Button style={{width: '100%'}}>{t('Show all')}</Button>,
        },
      ]
    : [...emptyLabel]

  return {
    notificationItems,
  }
}

export default NotificationContent
