import {Form, Select} from 'antd'
import './style.scss'
import {forwardRef} from 'react'

const {Option} = Select

const AutoCompleteComponent = forwardRef((props, ref) => {
  const {
    options,
    name,
    label,
    rules,
    disabledId,
    style,
    disabled,
    returnValue,
    initialValue,
    onChange,
    classNameForm,
    formStyle,
    placeholder,
    onSelect,
    className,
    defaultValue,
    classNameAutoComplete,
    allowClear = true,
    withoutSorter,
    ...rest
  } = props

  const disabledSelectedItem = (id) => {
    if (Array.isArray(disabledId)) {
      return disabledId?.find((selectedId) => selectedId === id)
    }
    return id === disabledId
  }

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[rules]}
      style={formStyle}
      className={classNameForm}
      initialValue={initialValue}
    >
      <Select
        {...rest}
        showSearch
        allowClear={allowClear}
        style={style}
        onChange={onChange}
        onSelect={onSelect}
        ref={ref}
        className={classNameAutoComplete}
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeholder}
        optionFilterProp="children"
        filterOption={(inputValue, option) => {
          if (typeof option?.children === 'string') {
            return (
              option?.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            )
          }
          return (
            option?.children?.props?.children
              ?.toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          )
        }}
        filterSort={
          withoutSorter
            ? false
            : (optionA, optionB) =>
                optionA?.children
                  ?.toLowerCase()
                  ?.localeCompare(optionB.children.toLowerCase())
        }
      >
        {options?.map(({id, name, label}) => (
          <Option
            key={id}
            value={returnValue === 'name' ? label : id}
            disabled={disabledSelectedItem(id)}
          >
            {name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
})

export default AutoCompleteComponent
