import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import {
  AntdTableActionModal,
  TableDetailsReadOnly,
} from '../../../../components/antd-components/app-table'
import {
  inventoryHistory,
  isLoadingHistoryInventory,
} from '../../../../redux/selectors/inventorySelector'
import useInventoryHistoryTable from '../../../../services/inventory/InventoryHistoryTableSettigns'
import useInventoryService from '../../../../services/inventory/inventoryService'

function InventoryHistoryModal(props) {
  const {
    isModalOpen,
    closeModalHandler,
    selectedItemName,
    setSelectedItemName,
  } = props
  const {t} = useTranslation()
  const {clearHistoryInventory} = useInventoryService()
  const historyInventory = useSelector(inventoryHistory)
  const isLoadingHistory = useSelector(isLoadingHistoryInventory)
  const {inventoryHistoryColumns} = useInventoryHistoryTable()

  useEffect(() => {
    if (!isModalOpen) {
      clearHistoryInventory()
      setSelectedItemName('')
    }
  }, [isModalOpen])

  return (
    <AntdTableActionModal
      open={isModalOpen}
      title={`${t(`History change balance`)}  ${selectedItemName}`}
      handleCancel={closeModalHandler}
      className="informationModal_wrapper antdBody modal"
      fullWidth
      centered
    >
      <TableDetailsReadOnly
        columns={inventoryHistoryColumns}
        selectedById={historyInventory}
        scrollY="calc(100vh - 14em)"
        scrollX={1000}
        isLoading={isLoadingHistory}
      />
    </AntdTableActionModal>
  )
}
export default InventoryHistoryModal
