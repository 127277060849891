import './style.scss'

function DashboardPageBody() {
  const dashboardLink = localStorage.getItem('dashboardLink')
  return (
    <div className="dashboardPage_body">
      <div className="iframe_wrapper">
        <iframe
          className="dashboard_iframe"
          title="dashboard_iframe"
          src={dashboardLink || ''}
        />
      </div>
    </div>
  )
}
export default DashboardPageBody
