import {Table} from 'antd'
import './style.scss'

function TableDetailsReadOnly({
  selectedById,
  columns,
  isLoading,
  className,
  scrollX = true,
  scrollY = 'calc(100vh - 25em)',
  isScrollYHidden = false,
  rowKey = (record) => record.id,
  ...props
}) {
  return (
    <div className="table_wrapper">
      <Table
        {...props}
        dataSource={selectedById}
        columns={columns}
        rowKey={rowKey}
        scroll={{
          x: scrollX,
          y: isScrollYHidden ? undefined : scrollY,
        }}
        className={`table ${className}`}
        bordered
        loading={isLoading}
        pagination={false}
      />
    </div>
  )
}

export default TableDetailsReadOnly
