import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {Col, Form, Row} from 'antd'
import variables from '../../../../globalStyle/colors.scss'
import {products} from '../../../../redux/selectors/productsSelector'
import {rawMaterialsList} from '../../../../redux/selectors/rawMaterialsSelector'
import {
  AutoCompleteComponent,
  InputForm,
} from '../../../../components/antd-components/form'
import {
  ButtonDanger,
  ButtonDefault,
} from '../../../../components/antd-components/button'

import {dataFunction, taskType} from '../../../../constants'
import useProducts from '../../../../services/products/productsService'
import useRawMaterials from '../../../../services/raw-materials/rawMaterialsService'
import {ToolTipComponent} from '../../../../components'
import TaskValidation from '../validation/TaskValidation'
import useTasksService from '../../../../services/tasks/taskService'
import {
  isLoadingSelectedTasks,
  setTasksLoading,
} from '../../../../redux/selectors/tasksSelector'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function TaskForm({onClose, formData, storeId}) {
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const [type, setType] = useState('')
  const {getProductsData, productsClearData} = useProducts()
  const {getRawMaterialsData, clearRawMaterialsList} = useRawMaterials()
  const {addTask, updateTaskData} = useTasksService()
  const productsList = useSelector(products)
  const loaderAddTask = useSelector(setTasksLoading)
  const rawMaterials = useSelector(rawMaterialsList)
  const loaderSelectedTask = useSelector(isLoadingSelectedTasks)
  const validation = TaskValidation()
  const onChangeType = (id) => {
    if (id) {
      if (id === 'ingredient') {
        getRawMaterialsData()
        productsClearData()
        setType('ingredient')
        form.resetFields(['target'])
      }
      if (id === 'semi') {
        getProductsData({f_type: ['semi']})
        clearRawMaterialsList()
        setType('semi')
        form.resetFields(['target'])
      }
    } else {
      clearRawMaterialsList()
      productsClearData()
      form.resetFields(['target'])
    }
  }

  useEffect(() => {
    if (formData?.id) {
      form.setFieldsValue(formData)
      form.setFieldsValue({
        target: formData?.targetRaw || formData?.targetSemi,
      })
      if (formData?.targetRaw) {
        setType('ingredient')
        getRawMaterialsData()
      }
      if (formData?.targetSemi) {
        setType('semi')
        getProductsData({f_type: ['semi']})
      }
    }
  }, [formData?.id])

  const onSubmitAdd = (values) => {
    const {type, target, name} = values
    addTask({
      type,
      name,
      function: values.function,
      targetRaw: type === 'ingredient' ? target : null,
      targetSemi: type === 'semi' ? target : null,
    })
  }

  const onSubmitUpdate = (values) => {
    const {type, target, name} = values
    updateTaskData({
      id: formData?.id,
      type,
      name,
      function: values.function,
      targetRaw: type === 'ingredient' ? target : null,
      targetSemi: type === 'semi' ? target : null,
    })
  }

  const showToolTip = !!(productsList.length || rawMaterials.length)

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
  }
  return (
    <Form
      form={form}
      onFinish={formData?.id ? onSubmitUpdate : onSubmitAdd}
      {...layout}
    >
      <Row gutter={24}>
        <Col className="gutter-row" span={24} xs={24} sm={20} md={20} xl={20}>
          <InputForm
            placeholder={t('Name')}
            name="name"
            rules={{...validation, required: true}}
            label={t('Name')}
          />
        </Col>
        <Col className="gutter-row" span={24} xs={24} sm={20} md={20} xl={20}>
          <AutoCompleteComponent
            options={taskType.map((item) => ({
              id: item.id,
              name: t(item.name),
            }))}
            disabled={!!storeId}
            name="type"
            loading={loaderSelectedTask}
            placeholder={t('Type')}
            label={t('Type')}
            onChange={(e) => onChangeType(e)}
            rules={{...validation, required: true}}
          />
        </Col>
        <Col className="gutter-row" span={24} xs={24} sm={20} md={20} xl={20}>
          <ToolTipComponent
            title={
              showToolTip ? null : t('To fill target,you must select type')
            }
          >
            <AutoCompleteComponent
              options={type === 'semi' ? productsList : rawMaterials}
              disabled={!!storeId}
              name="target"
              placeholder={t('Target')}
              label={t('Target')}
              loading={loaderSelectedTask}
              rules={{...validation, required: true}}
            />
          </ToolTipComponent>
        </Col>
        <Col className="gutter-row" span={24} xs={24} sm={20} md={20} xl={20}>
          <AutoCompleteComponent
            options={dataFunction.map((item) => ({
              id: item.id,
              name: t(item.name),
              label: item.label,
            }))}
            disabled={!!storeId}
            name="function"
            returnValue="name"
            loading={loaderSelectedTask}
            placeholder={t('Function')}
            label={t('Function')}
            rules={{...validation, required: true}}
          />
        </Col>
        <Col
          span={24}
          xs={12}
          sm={18}
          md={18}
          xl={18}
          style={{display: 'flex', justifyContent: 'flex-end'}}
        >
          <CancelButton onClick={onClose} />
        </Col>
        <Col
          span={24}
          xs={12}
          sm={6}
          md={6}
          xl={6}
          style={{display: 'flex', justifyContent: 'flex-end'}}
        >
          <SaveButton loading={loaderAddTask} htmlType="submit" />
        </Col>
      </Row>
    </Form>
  )
}

export default TaskForm
