import {useTranslation} from 'react-i18next'
import {getColumnSorter} from '../../utils/helpers'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const useUnitsTable = ({onDelete, onEdit, unitsTableSettings}) => {
  const {t} = useTranslation()

  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '93%',
      sorter: true,
      sortOrder: getColumnSorter(unitsTableSettings, 'name'),
      filteredValue: '',
    },

    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: '7%',
      render: (_, record) => (
        <div>
          <EditActionButton
            onClick={(e) => {
              e.stopPropagation()
              onEdit(record.id)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDelete(record.id)
            }}
          />
        </div>
      ),
    },
  ]

  return {unitsColumns: getColumns()}
}

export default useUnitsTable
