import {createSlice} from '@reduxjs/toolkit'
import {
  deleteStores,
  getAllStores,
  getStore,
  getStores,
  setStore,
  updateStore,
} from '../asyncServices/storeService'
import {resetReduxStore} from '../resetStore'

export const storesInitialState = {
  stores: [],
  totalCount: 0,
  allStores: [],
  allStoreTotalCount: 0,
  selectedStores: [],
  selectedStore: null,
  tableSettings: {
    s_direction: 'DESC',
    s_field: 'name',
    f_name: null,
    pageSize: 100,
    page: 1,
  },
  lastUpdated: null,
  isLoading: null,
  isOpenModalWindow: false,
  isOpenEditModalWindow: false,
  selectedId: null,
}

const storeSlice = createSlice({
  name: 'storesSlice',
  initialState: storesInitialState,
  reducers: {
    setSelectedStores: (state, action) => {
      state.selectedStores = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload.tableSettings,
      }
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setIsOpenModalWindow: (state, action) => {
      state.isOpenModalWindow = action.payload
    },
    setIsOpenEditWindow: (state, action) => {
      state.isOpenEditModalWindow = action.payload
    },
    clearSelectedProduct: (state, action) => {
      state.selectedStore = null
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload
    },
    clearStoreTableSettings: (state) => {
      state.tableSettings = storesInitialState.tableSettings
      state.lastUpdated = Date.now()
    },

    storesReducerReset: () => storesInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getStores.pending, (state) => {
      state.isLoading = getStores.typePrefix
    })
    builder.addCase(getStores.fulfilled, (state, action) => {
      state.stores = action.payload.stores
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })

    builder.addCase(getAllStores.pending, (state) => {
      state.isLoading = getAllStores.typePrefix
    })
    builder.addCase(getAllStores.fulfilled, (state, action) => {
      state.allStores = action.payload.stores
      state.allStoreTotalCount = action.payload.totalCount
      state.isLoading = null
    })

    builder.addCase(getStore.pending, (state) => {
      state.isLoading = getStore.typePrefix
    })
    builder.addCase(getStore.fulfilled, (state, action) => {
      state.selectedStore = action.payload
      state.isLoading = null
    })
    builder.addCase(setStore.pending, (state) => {
      state.isLoading = 'setStores'
    })
    builder.addCase(setStore.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(updateStore.pending, (state) => {
      state.isLoading = 'updateStore'
    })
    builder.addCase(updateStore.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(deleteStores.pending, (state) => {
      state.isLoading = 'deleteStores'
    })
    builder.addCase(deleteStores.fulfilled, (state, action) => {
      state.selectedStores = action.payload.selectedStores
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = storesInitialState
    })
  },
})

export const {
  setSelectedStores,
  setTableSettings,
  setLastUpdated,
  storesReducerReset,
  setIsOpenModalWindow,
  setIsOpenEditWindow,
  clearSelectedProduct,
  setSelectedId,
  clearStoreTableSettings,
} = storeSlice.actions
export default storeSlice.reducer
