import {useEffect, useState} from 'react'
import moment from 'moment'
import {FilterIcon} from '../../components/antd-components/app-table'
import {DateRangeFilter} from '../../components/antd-components/app-table'

function DateRangeFilterWrapper({
  visible,
  confirm,
  initialDateFrom,
  initialDateTo,
  onSubmitFilterDateRange,
}) {
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)

  useEffect(() => {
    if (visible) {
      setDateFrom(initialDateFrom ? moment(initialDateFrom) : null)
      setDateTo(initialDateTo ? moment(initialDateTo) : null)
    }
  }, [visible])

  return (
    <DateRangeFilter
      dateFrom={dateFrom}
      dateTo={dateTo}
      setDateFrom={setDateFrom}
      setDateTo={setDateTo}
      confirm={confirm}
      onSubmitFilterDateRange={() =>
        onSubmitFilterDateRange({dateFrom, dateTo}, confirm)
      }
    />
  )
}

const useDateRangeTableSettings = ({
  dateFromFilterName = 'f_createdDateFrom',
  dateToFilterName = 'f_createdDateTo',
  tableSettings,
  setTableSettings,
}) => {
  const tableSettingsDateFrom = tableSettings?.[dateFromFilterName]
  const tableSettingsDateTo = tableSettings?.[dateToFilterName]

  const onSubmitFilterDateRange = ({dateFrom, dateTo}, confirm) => {
    setTableSettings({
      ...tableSettings,
      [dateFromFilterName]: dateFrom
        ? moment(dateFrom).startOf('day').toISOString()
        : null,
      [dateToFilterName]: dateTo
        ? moment(dateTo).endOf('day').toISOString()
        : null,
    })

    confirm()
  }

  const filterDateIcon = (
    <FilterIcon isFilterActive={tableSettingsDateFrom || tableSettingsDateTo} />
  )

  const rangeDate = ({visible, confirm}) => (
    <DateRangeFilterWrapper
      initialDateFrom={tableSettingsDateFrom}
      initialDateTo={tableSettingsDateTo}
      onSubmitFilterDateRange={onSubmitFilterDateRange}
      confirm={confirm}
      visible={visible}
    />
  )

  return {filterIcon: filterDateIcon, filterDropdown: rangeDate}
}

export default useDateRangeTableSettings
