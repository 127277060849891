import {useTranslation} from 'react-i18next'
import {Col, Form, Row} from 'antd'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import useStore from '../../../../services/storeServices/storeService'
import StoresValidationSchema from '../validation/StoresValidationSchema'
import {InputForm} from '../../../../components/antd-components/form'
import {
  ButtonDanger,
  ButtonDefault,
} from '../../../../components/antd-components/button'
import {formProps} from '../../../../constants'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function StoreForm({
  formData = {
    id: null,
    name: '',
  },
  form,
  onClose,
}) {
  const {id} = formData
  const isLoading = useSelector(
    (state) => state.stores.isLoading === 'setStores'
  )
  const isLoadingUpdate = useSelector(
    (state) => state.stores.isLoading === 'updateStore'
  )

  const {addStore, updateStoreData} = useStore()
  const {t} = useTranslation()

  useEffect(() => {
    if (id) {
      form.setFieldsValue(formData)
    }
  }, [id])

  const schema = StoresValidationSchema()
  const onSubmit = async (values) => {
    if (!id) {
      await addStore(values)
    } else {
      await updateStoreData({id, ...values})
    }
  }

  return (
    <Form form={form} onFinish={onSubmit}>
      <Row gutter={24}>
        <Col className="gutter-row" {...formProps.modalProps.colScreenProps}>
          <InputForm
            placeholder={t('Name')}
            label={t('Name')}
            name="name"
            rules={{...schema, required: true}}
          />
        </Col>
        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>
          <Col className="button">
            <SaveButton
              htmlType="submit"
              loading={formData?.id ? isLoadingUpdate : isLoading}
            />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default StoreForm
