import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import PurchaseBody from './components/PurchaseBody'
import StatisticCard from './components/StatisticCard'
import useStore from '../../../services/storeServices/storeService'
import useUsers from '../../../services/users/usersService'
import PurchaseHeader from './components/PurchaseHeader'
import usePurchaseService from '../../../services/purchase/purchaseService'

function PurchasePage() {
  const {getPurchaseData} = usePurchaseService()
  const {getStoresData} = useStore()
  const {getUsersData} = useUsers()
  const {lastUpdated, tableSettings, totalCount, summary} = useSelector(
    (state) => state.purchase
  )

  useEffect(() => {
    getPurchaseData(tableSettings)
  }, [lastUpdated])

  useEffect(() => {
    getStoresData()
    getUsersData()
  }, [])

  return (
    <div>
      <PurchaseHeader />
      <StatisticCard
        calculationTotalPrice={summary?.total}
        cash={summary?.cashSum}
        cardPayment={summary?.cardPaymentSum}
        purchaseCount={totalCount}
      />
      <PurchaseBody />
    </div>
  )
}

export default PurchasePage
