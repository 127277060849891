import {useNavigate} from 'react-router-dom'
import useLanguageInUrl from './useLanguageInUrl'

const useCustomNavigate = () => {
  const navigate = useNavigate()
  const {languagePath} = useLanguageInUrl()

  return (details, opts) =>
    navigate(
      typeof details === 'string'
        ? `/${languagePath + details}`
        : {...details, pathname: `/${languagePath + details.pathname}`},
      opts
    )
}

export default useCustomNavigate
