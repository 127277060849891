import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {AppTable} from '../../../../components/antd-components/app-table'
import useHistorySupplyTable from '../../../../services/supllies/historySuppliesByIngredient/historySuppliesTableSettigns'
import {
  historyIngredientData,
  historyIngredientLastUpdated,
  historyIngredientTableSettings,
  historyIngredientTotalCount,
  isLoadingHistoryIngredient,
  selectedIngredient,
} from '../../../../redux/selectors/suppliesHistoryByIngredietSelector'
import useSuppliesHistoryByIngredient from '../../../../services/supllies/historySuppliesByIngredient/historySuppliesByIngredient'
import useSuppliers from '../../../../services/supplier/suppliersService'
import {suppliersData} from '../../../../redux/selectors/supplierSelector'
import useSupply from '../../../../services/supllies/supplies'
import useHistorySuppliesFilterService from '../../../../services/supllies/historySuppliesByIngredient/historyFilterTable'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'

function HistorySupplyBody() {
  const {getHistoryByIdIngredient, setTableSettingsHistoryByIngredient} =
    useSuppliesHistoryByIngredient()
  const {getSuppliersData} = useSuppliers()
  const selectedHistoryIngredient = useSelector(selectedIngredient)
  const isLoading = useSelector(isLoadingHistoryIngredient)
  const dataHistory = useSelector(historyIngredientData)
  const tableSettings = useSelector(historyIngredientTableSettings)
  const lastUpdated = useSelector(historyIngredientLastUpdated)
  const suppliers = useSelector(suppliersData)
  const totalCount = useSelector(historyIngredientTotalCount)
  const navigate = useCustomNavigate()
  const {setSelectedSuppliesId} = useSupply()
  const {
    filterTotalPriceIcon,
    filterPriceOneIcon,
    filterAmountIcon,
    rangeTotalPrice,
    rangeAmount,
    rangePriceOne,
  } = useHistorySuppliesFilterService()

  const data = dataHistory
    .map((item) =>
      item.raws.map((current) => ({
        ...current,
        supplierName: item.supplier.name,
        date: item.date,
      }))
    )
    .flat(1)

  const handleEdit = (supplyId) => {
    setSelectedSuppliesId(supplyId)
    navigate('/supplies/add')
  }

  const {historySupplyColumns} = useHistorySupplyTable({
    suppliers,
    filterTotalPriceIcon,
    filterPriceOneIcon,
    filterAmountIcon,
    rangeTotalPrice,
    rangeAmount,
    rangePriceOne,
    handleEdit,
  })

  useEffect(() => {
    if (selectedHistoryIngredient) {
      getHistoryByIdIngredient({
        id: selectedHistoryIngredient,
        params: tableSettings,
      })
    }
  }, [selectedHistoryIngredient, lastUpdated])

  useEffect(() => {
    getSuppliersData()
  }, [])

  return (
    <AppTable
      dataSource={data}
      columns={historySupplyColumns}
      isLoading={isLoading}
      className="hideScroll"
      tableSettings={tableSettings}
      totalCount={totalCount}
      setTableSettings={setTableSettingsHistoryByIngredient}
    />
  )
}

export default HistorySupplyBody
