import {Button} from 'antd'
import {CheckCircleOutlined} from '@ant-design/icons'
import './style.scss'

function ConfirmActionButton(props) {
  const {className, ...rest} = props

  return (
    <Button
      size="small"
      shape="circle"
      type="primary"
      icon={<CheckCircleOutlined />}
      className={`confirmAction_button ${className}`}
      {...rest}
    />
  )
}

export default ConfirmActionButton
