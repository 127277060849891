const getConstraintMessages = (constraints, resultingMessages) => {
  resultingMessages.push(...Object.values(constraints))
}

const getConstraintErrorMessages = (errors, resultingMessages) => {
  errors.forEach((error) => {
    if (Array.isArray(error.children) && error.children.length !== 0) {
      getConstraintErrorMessages(error.children, resultingMessages)
    } else if (error.constraints)
      getConstraintMessages(error.constraints, resultingMessages)
    else if (error.validationErrors)
      resultingMessages.push(...error.validationErrors)
  })
}

export const parseErrors = (errors) => {
  const resultingErrorMessages = []

  getConstraintErrorMessages(errors, resultingErrorMessages)

  return resultingErrorMessages
}
