import {useLocation} from 'react-router-dom'
import {compile, pathToRegexp} from 'path-to-regexp'
import {useSelector} from 'react-redux'

const useLanguageInUrl = () => {
  const location = useLocation()
  const defaultRoute = '/:locale(ru|en|ua)/:path*'
  const routeComponentsArray = pathToRegexp(defaultRoute).exec(
    location.pathname
  )
  const language = useSelector((state) => state.common.language)

  const generateUrl = (locale, locationProps) => {
    const ROUTE = '/:locale(ru|en|ua)/:path*'
    const definePath = compile(ROUTE)
    const routeComponents = pathToRegexp(ROUTE).exec(locationProps.pathname)

    let subPaths = null
    if (routeComponents && routeComponents[2]) {
      subPaths = routeComponents[2].split('/')
    }
    return definePath({
      locale,
      path: subPaths,
    })
  }
  const activePage = routeComponentsArray?.[2]
    ? routeComponentsArray?.[2]
    : null
  const languagePath = routeComponentsArray?.[1]
    ? routeComponentsArray?.[1]
    : language

  return {
    generateUrl,
    languagePath,
    activePage,
  }
}

export default useLanguageInUrl
