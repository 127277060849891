import {useEffect, useRef} from 'react'
import SignatureCanvas from 'react-signature-canvas'

import './style.scss'
import {transformSignatureFromAxisTuples} from '../../utils/signaturePag'

export default function SignaturePad({value}) {
  const signatureCanvasRef = useRef(null)

  useEffect(() => {
    const canvas = signatureCanvasRef.current
    if (canvas) signatureCanvasRef.current.off()
    if (value && signatureCanvasRef.current) {
      signatureCanvasRef.current.fromData(
        transformSignatureFromAxisTuples(value)
      )
    }
  }, [value, signatureCanvasRef.current])

  return (
    <SignatureCanvas
      ref={signatureCanvasRef}
      canvasProps={{
        className: 'signature-canvas-custom',
      }}
    />
  )
}
