import {useDispatch} from 'react-redux'
import {
  clearProfitTableSettings,
  setTableSettings,
} from '../../redux/slices/profitsSlice'
import {getProfits} from '../../redux/asyncServices/reportsService'

function useProfitService() {
  const dispatch = useDispatch()
  const setProfitTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
  }

  const resetProfitTableSettings = () => dispatch(clearProfitTableSettings())

  const getProfitsList = (params) => {
    dispatch(getProfits({params}))
  }
  return {
    setProfitTableSettings,
    resetProfitTableSettings,
    getProfitsList,
  }
}

export default useProfitService
