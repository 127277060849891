import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../../components/antd-components/app-table'
import useWriteOffConfirmTableService from '../../../../../services/write-off-reason/write-off-confrim/writeOffConfirmTableService'
import {useWriteOffConfirm} from '../../../../../services/write-off-reason/write-off-confrim/writeOffConfrimService'
import WriteOffModalDetail from './WriteOffModalDetail'
import {
  ButtonDanger,
  ButtonDefault,
} from '../../../../../components/antd-components/button'
import useCommon from '../../../../../services/commonService'

import '../style.scss'

function WriteOffConfirmBody() {
  const {
    setWriteOffReasonConfirmTableSettings,
    confirmWriteOff,
    getSelectedWriteOff,
    setSelectedId,
    rejectWriteOff,
  } = useWriteOffConfirm()

  const writeOffConfirm = useSelector(
    (state) => state.writeOffConfirm.writeOffReasonConfirm
  )
  const selectedWriteOff = useSelector(
    (state) => state.writeOffConfirm.selectedWriteOffReason
  )
  const {t} = useTranslation()
  const {changeOpenModalWindow} = useCommon()
  const writeOffConfirmTableSettings = useSelector(
    (state) => state.writeOffConfirm.tableSettings
  )
  const totalCount = useSelector((state) => state.writeOffConfirm.totalCount)
  const openModalWindow = useSelector((state) => state.common.isOpenModalWindow)

  const isLoading =
    useSelector((state) => state.writeOffConfirm.isLoading) ===
    'getWriteOffListConfirm'

  const isLoadingSelectedWriteOff =
    useSelector((state) => state.writeOffConfirm.isLoading) ===
    'getWriteOffSelectedConfirm'

  const isLoadingConfirm =
    useSelector((state) => state.writeOffConfirm.isLoading) ===
    'setWriteOffConfirm'

  const isLoadingReject =
    useSelector((state) => state.writeOffConfirm.isLoading) ===
    'setWriteOffRejected'

  const writeOffSubmitConfirm = (id) => {
    confirmWriteOff(id)
  }

  const getSelectedWriteOffById = (id) => {
    getSelectedWriteOff(id)
    changeOpenModalWindow(true)
  }

  const handleEditClose = () => {
    changeOpenModalWindow(false)
    setSelectedId(null)
  }

  const isStorePresent = Boolean(!selectedWriteOff?.store?.deletedDate)

  const actionButton = [
    <ButtonDanger
      disabled={!isStorePresent}
      text={t('Reject')}
      onClick={() => rejectWriteOff(selectedWriteOff?.id)}
      loading={isLoadingReject}
      key={1}
    />,
    <ButtonDefault
      disabled={!isStorePresent}
      text={t('Confirm')}
      loading={isLoadingConfirm}
      onClick={() => writeOffSubmitConfirm(selectedWriteOff?.id)}
      className={isStorePresent ? 'save' : undefined}
      key={2}
    />,
  ]

  const statusWriteOff = (status) => {
    if (status === 'rejected') {
      return [
        <ButtonDefault
          text={t('rejected')}
          disabled
          className="danger"
          key={3}
        />,
      ]
    }
    if (status === 'approved') {
      return [
        <ButtonDefault
          text={t('approved')}
          loading={isLoadingConfirm}
          disabled
          className="save"
          key={4}
        />,
      ]
    }
    return actionButton
  }

  const {writeOffConfirmColumns} = useWriteOffConfirmTableService({
    writeOffConfirmTableSettings,
    getSelectedWriteOffById,
  })

  return (
    <div>
      <AntdTableActionModal
        title={t('Write-off detail')}
        handleCancel={handleEditClose}
        footer={
          isLoadingSelectedWriteOff
            ? null
            : statusWriteOff(selectedWriteOff?.status)
        }
        className="customCloseComponent antdBody modal writeOffModalHeight"
        open={openModalWindow}
        fullWidth
      >
        <WriteOffModalDetail />
      </AntdTableActionModal>
      <AppTable
        columns={writeOffConfirmColumns}
        dataSource={writeOffConfirm}
        tableSettings={writeOffConfirmTableSettings}
        totalCount={totalCount}
        setTableSettings={setWriteOffReasonConfirmTableSettings}
        isLoading={isLoading}
      />
    </div>
  )
}

export default WriteOffConfirmBody
