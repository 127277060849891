import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {useMemo} from 'react'
import {planningSelected} from '../../redux/selectors/planningSelecor'
import {crossedOutText} from '../../utils/helpers'

const usePlanningDetailTableSettings = () => {
  const {t} = useTranslation()
  const selectedPlanning = useSelector(planningSelected)

  const storeColumns = useMemo(
    () =>
      selectedPlanning?.products?.[0]?.records
        .filter(({storeId}) => storeId !== null)
        .map((item) => [
          {
            title: `${item.storeName} ${t('Balance')}`,
            dataIndex: `${item.storeName}balance`,
            key: item.storeId,
            align: 'center',
            width: 150,
          },
          {
            title: item.storeDeletedDate
              ? crossedOutText(item.storeName)
              : item.storeName,
            dataIndex: item.storeName,
            key: item.storeId,
            align: 'center',
            width: 150,
          },
        ])
        .flat() || [],
    [selectedPlanning]
  )

  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'f_store',
      align: 'center',
      width: 200,
      fixed: 'left',
    },
    ...storeColumns,
    {
      title: t('Total'),
      dataIndex: 'total',
      align: 'center',
      width: 120,
    },
  ]

  return {planningDetailColumns: getColumns()}
}

export default usePlanningDetailTableSettings
