import React from 'react'
import {Card, Space, Typography} from 'antd'
import './style.scss'

const {Title} = Typography

function CardKPI({
  title,
  content,
  footer,
  textColor = null,
  contentColor = null,
}) {
  return (
    <div>
      <Card className="kpi_card card">
        <p
          className="card-text general text bold title"
          style={{color: textColor}}
        >
          {title}
        </p>
        <Space direction="horizontal">
          <Title level={3} style={{color: contentColor}} className="text title">
            {content}
          </Title>
        </Space>
        <div className="card-footer text">
          {footer.map((current) => (
            <ul className="card-text general" key={current.key}>
              <li className="li_element footer_text"> {current.footerName}</li>
              <span className="card-text bold text">{current.footerValue}</span>
            </ul>
          ))}
        </div>
      </Card>
    </div>
  )
}

export default CardKPI
