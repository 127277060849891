import {Col, Form, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import moment from 'moment/moment'
import {
  AutoCompleteComponent,
  InputForm,
  InputNumberForm,
  SwitchForm,
  TreeSelectForm,
} from '../../../../../../../../components/antd-components/form'
import './style.scss'
import CreatedDateReadOnlyInput from '../../../../../../../../components/antd-components/form/readOnly-inputs/createdDate-input/CreatedDateReadOnlyInput'
import UpdatedDateReadOnlyInput from '../../../../../../../../components/antd-components/form/readOnly-inputs/updatedDate-input/UpdatedDateReadOnlyInput'
import {
  AntdTreeSelectRecursiveValue,
  createNestedItems,
} from '../../../../../../../../utils/helpers'
import ProductsValidationSchema from './validation'
import useProducts from '../../../../../../../../services/products/productsService'
import AddActionButton from '../../../../../../../../components/antd-components/tableAction-buttons/add-button/AddActionButton'
import {LoadingSpinner} from '../../../../../../../../components/antd-components/loading-spinner'

function ProductForm(props) {
  const {
    productForm,
    productId,
    currentProductData,
    openCreateCategoryModalHandler,
    isLoading,
  } = props
  const {t} = useTranslation()
  const units = useSelector((state) => state.units.units)
  const users = useSelector((state) => state.users.users)
  const categories = useSelector((state) => state.categories.categories)
  const categoryWithChild = createNestedItems(categories)
  const selectedProduct = useSelector((state) => state.products.selectedProduct)
  const validation = ProductsValidationSchema()
  const {type} = useSelector((state) => state.products)
  const {updateProductData, addProduct} = useProducts()

  useEffect(() => {
    if (selectedProduct) {
      const {createdDate, updatedDate} = selectedProduct
      productForm.setFieldsValue({
        ...selectedProduct,
        createdDate: createdDate ? moment(createdDate) : null,
        updatedDate: updatedDate ? moment(updatedDate) : null,
      })
    } else {
      productForm.resetFields()
    }
  }, [selectedProduct])

  const formSubmitHandler = (formData) => {
    const semiFinishedList = currentProductData.semiFinished.map(
      (semiFinishedItem) => {
        const {semiFinishedId, amount} = semiFinishedItem
        return {semiFinishedId, amount: Number(amount)}
      }
    )
    const ingredientsList = currentProductData.ingredients.map((ingredient) => {
      const {rawId, gross, net} = ingredient
      return {rawId, gross, net}
    })
    const productData = {
      ...formData,
      amount: Number(formData.amount),
      type,
      semiFinished: semiFinishedList,
      ingredients: ingredientsList,
    }
    if (selectedProduct) {
      updateProductData({...productData, id: selectedProduct.id})
    } else {
      addProduct(productData)
    }
  }

  const categoryFieldLabel = (
    <div className="category_label">
      <span>{t('Category')}</span>
      <AddActionButton
        className="categoryLabel_icon"
        onClick={openCreateCategoryModalHandler}
      />
    </div>
  )

  return (
    <div className="form_wrapper">
      <Form
        form={productForm}
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        onFinish={formSubmitHandler}
      >
        <Row gutter={[10, 0]}>
          <Col span={12} xs={24} sm={12} md={12} xl={12}>
            <InputForm
              disabled={isLoading}
              placeholder={t('Title')}
              name="name"
              label={t('Title')}
              rules={{...validation, required: true}}
            />
          </Col>
          <Col span={12} xs={24} sm={12} md={12} xl={12}>
            <TreeSelectForm
              disabled={isLoading}
              placeholder={t('Category')}
              name="categoryId"
              allowClear
              showSearch
              label={categoryFieldLabel}
              formClassName="content form_label_after"
              onDropdownVisibleChange={false}
              rules={validation}
              filterTreeNode={(inputValue, treeNode) =>
                treeNode.title
                  .toLowerCase()
                  .indexOf(inputValue.trim().toLowerCase()) >= 0
              }
              treeData={categoryWithChild.map((item) =>
                AntdTreeSelectRecursiveValue(item)
              )}
            />
          </Col>
          <Col span={12} xs={24} sm={12} md={12} xl={12}>
            <AutoCompleteComponent
              options={units}
              label={t('Units')}
              name="unitId"
              placeholder={t('Units')}
              rules={{...validation, required: true}}
              disabled={isLoading}
            />
          </Col>
          <Col span={12} xs={24} sm={12} md={12} xl={12}>
            <SwitchForm
              formClassName="content form_label_after"
              label={t('Pricing')}
              name="piece"
              unCheckedChildren={t('by Weight')}
              checkedChildren={t('by Piece')}
              initialValue={false}
              size="large"
              rules={{required: true}}
              disabled={isLoading}
            />
          </Col>
          <Col span={12} xs={24} sm={12} md={12} xl={12}>
            <InputNumberForm
              name="amount"
              label={t('Production outputs in units')}
              controls={false}
              onWheel={(e) => e.target.blur()}
              rules={{...validation, required: true}}
              disabled={selectedProduct || isLoading}
            />
          </Col>
          {isLoading && <LoadingSpinner />}
          <Col span={12} xs={24} sm={12} md={12} xl={12}>
            {type !== 'semi' && (
              <SwitchForm
                formClassName="content form_label_after"
                label={t('Charge from shop')}
                name="chargeFromShopBalance"
                unCheckedChildren={t('No')}
                checkedChildren={t('Yes')}
                initialValue={false}
                size="large"
              />
            )}
          </Col>
          {productId && (
            <>
              <Col span={12} xs={24} sm={12} md={12} xl={12}>
                <AutoCompleteComponent
                  options={users}
                  label={t('Created by')}
                  name="createdBy"
                  disabled
                />
              </Col>
              <Col span={12} xs={24} sm={12} md={12} xl={12}>
                <CreatedDateReadOnlyInput />
              </Col>
              <Col span={12} xs={24} sm={12} md={12} xl={12}>
                <AutoCompleteComponent
                  options={users}
                  label={t('Updated by')}
                  name="updatedBy"
                  disabled
                />
              </Col>
              <Col span={12} xs={24} sm={12} md={12} xl={12}>
                <UpdatedDateReadOnlyInput />
              </Col>
            </>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default ProductForm
