import {Dropdown, Grid, Menu, Row, Space} from 'antd'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {DownOutlined} from '@ant-design/icons'
import MobileMenu from './mobileMenu/MobileMenu'
import UserNavbarMenu from './UserNavbarMenu'
import BadgeComponent from './BadgeComponent'
import {BellFilled} from './antd-components/icon'
import './style.scss'
import NotificationContent from '../pages/main/notifications/components/NotificationContent'
import {cutString, getRandomId} from '../utils/helpers'
import BuildVersionLabelComponent from './BuildVersionLabelComponent'

const {useBreakpoint} = Grid

function AppNavbar() {
  const screens = useBreakpoint()
  const count = useSelector((state) => state.messageNotifications.count)
  const [visibleProfileMenu, setVisibleProfileMenu] = useState(false)
  const user = useSelector((state) => state.auth.user)
  const {notificationItems} = NotificationContent()
  const {userProfileMenu} = UserNavbarMenu()

  const handleVisibleChange = (flag) => {
    setVisibleProfileMenu(flag)
  }

  const notificationMenuProps = {
    items: notificationItems,
  }

  const userProfileMenuProps = {
    items: userProfileMenu,
  }

  const items = [
    {
      key: getRandomId(),
      label: (
        <Dropdown
          menu={notificationMenuProps}
          overlayClassName="header_dropdown_overlay"
          trigger={['click']}
        >
          <Space>
            <BadgeComponent count={count}>
              <BellFilled className="icon_style" />
            </BadgeComponent>
          </Space>
        </Dropdown>
      ),
    },

    {
      key: getRandomId(),
      label: (
        <Dropdown
          menu={userProfileMenuProps}
          trigger={['click']}
          overlayClassName="header_dropdown_overlay"
        >
          <Space className="header_dropdown_children">
            {cutString(user.name, 15)}
            <DownOutlined />
          </Space>
        </Dropdown>
      ),
    },
  ]

  return (
    <div className="header">
      {screens.xs ? (
        <Row>
          <BuildVersionLabelComponent
            branchName={process.env.REACT_APP_GIT_BRANCH_NAME}
            dateBuild={process.env.REACT_APP_GIT_DATE_BUILD_BRANCH}
          />
          <div className="mobile_menu_wrapper">
            <MobileMenu
              visible={visibleProfileMenu}
              count={count}
              handleVisibleChange={handleVisibleChange}
            />
          </div>
        </Row>
      ) : (
        <Row>
          <BuildVersionLabelComponent
            branchName={process.env.REACT_APP_GIT_BRANCH_NAME}
            dateBuild={process.env.REACT_APP_GIT_DATE_BUILD_BRANCH}
          />

          <div className="header_menu_wrapper">
            <Menu
              theme="dark"
              items={items}
              mode="horizontal"
              className="header_menu_style_selected_parent header_menu_style header_menu_style_hover header_menu_style_selected"
            />
          </div>
        </Row>
      )}
    </div>
  )
}

export default AppNavbar
