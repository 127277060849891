import {useTranslation} from 'react-i18next'
import {Button, Tooltip} from 'antd'

function ApplyButton(props) {
  const {t} = useTranslation()
  const {tooltipPlacement, tooltipText, className, ...rest} = props

  return (
    <div className="editButton_wrapper">
      <Tooltip placement={tooltipPlacement} title={tooltipText}>
        <Button type="primary" className={`${className} edit_button`} {...rest}>
          {t('Apply')}
        </Button>
      </Tooltip>
    </div>
  )
}

export default ApplyButton
