import {useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import RangeInputComponent from '../../components/antd-components/app-table/RangeInputComponent'
import useProducts from './productsService'
import {FilterIcon} from '../../components/antd-components/app-table'

const useProductsTableFilterService = (type) => {
  const {productsTableSettings, semiFinishedTableSettings} = useSelector(
    (state) => state.products
  )
  const {setProductTableSettings, setSemiFinishedTableSettings} = useProducts()

  const [previousAmount, setPreviousAmount] = useState(null)
  const [currentAmount, setCurrentAmount] = useState(null)

  const currentTableSettings =
    type === 'final' ? productsTableSettings : semiFinishedTableSettings

  const setTableSettingsHandler =
    type === 'final' ? setProductTableSettings : setSemiFinishedTableSettings

  useEffect(() => {
    setCurrentAmount(currentTableSettings.f_currentAmount)
    setPreviousAmount(currentTableSettings.f_previousAmount)
  }, [currentTableSettings])

  const onSubmitFilterAmount = (confirm) => {
    setTableSettingsHandler({
      ...currentTableSettings,
      f_previousAmount: previousAmount,
      f_currentAmount: currentAmount,
    })
    confirm()
  }
  const filterAmountIcon = (
    <FilterIcon isFilterActive={previousAmount || currentAmount} />
  )

  const rangeAmount = ({confirm}) => (
    <RangeInputComponent
      amountTo={currentAmount}
      amountFrom={previousAmount}
      setAmountTo={setCurrentAmount}
      setAmountFrom={setPreviousAmount}
      onSubmitFilterAmount={() => onSubmitFilterAmount(confirm)}
    />
  )

  return {
    rangeAmount,
    filterAmountIcon,
    setPreviousAmount,
    setCurrentAmount,
  }
}

export default useProductsTableFilterService
