import {useSelector} from 'react-redux'
import {createItemHeaderMenu} from '../../../../utils/helpers'
import withPermission from '../../../../HOCs/PermissionWrapper'
import {PageHeaderWithMobileMenu} from '../../../../components'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import './stylle.scss'
import AddButton from '../../../../components/antd-components/main-buttons/add-button/AddButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {refillTableSettings} from '../../../../redux/selectors/refillSelector'
import ResetFiltersButton from '../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'
import {refillInitialState} from '../../../../redux/slices/refillSlice'
import useRefill from '../../../../services/refill/refillService'

function RefillHeader() {
  const navigate = useCustomNavigate()
  const {exportExcel} = useCommon()
  const {resetRefillTableSettings} = useRefill()
  const tableSettings = useSelector(refillTableSettings)

  const navigateAddRefill = () => {
    navigate('/refill/add')
  }

  const AddRefillWithPermission = withPermission(
    <AddButton className="button" onClick={navigateAddRefill} />
  )

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `store/products/arrival`,
      tableSettings,
    })
  }

  const actionRefillHeader = [
    <AddRefillWithPermission roles={['admin']} key={15} />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu(actionRefillHeader)

  return (
    <PageHeaderWithMobileMenu
      title="Refill"
      subTitle={
        <ResetFiltersButton
          clearFiltersHandler={resetRefillTableSettings}
          currentTableSettings={tableSettings}
          initialTableSettings={refillInitialState.tableSettings}
        />
      }
      items={item}
      showHeaderMenu={false}
      extra={actionRefillHeader}
    />
  )
}

export default RefillHeader
