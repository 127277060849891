import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useEffect, useState} from 'react'
import {
  ExclamationCircleOutlined,
  SearchOutlined,
} from '../../../../components/antd-components/icon'
import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import {PageHeaderWithMobileMenu} from '../../../../components'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'

import useDebounce from '../../../../hooks/useDebounce'
import useCommon from '../../../../services/commonService'
import {
  preparationDepartmentsListSelector,
  preparationDepartmentTableSettingsSelector,
  selectedPreparationDepartmentSelector,
} from '../../../../redux/selectors/preparationDepartmentSelector'
import usePreparationDepartmentService from '../../../../services/preparationDepartment/preparationDepartmentService'
import AddButton from '../../../../components/antd-components/main-buttons/add-button/AddButton'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import ResetFiltersButton from '../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'
import {departmentInitialState} from '../../../../redux/slices/preparationDepartmentSlice'

function PreparationDepartmentHeader({form}) {
  const {t} = useTranslation()

  const {changeOpenModalWindow, exportExcel} = useCommon()

  const {
    setPreparationDepartmentsTableSettings,
    deletePreparationDepartmentsList,
  } = usePreparationDepartmentService()
  const requestSearchValue = useSelector(
    preparationDepartmentTableSettingsSelector
  ).f_title
  const tableSettings = useSelector(preparationDepartmentTableSettingsSelector)
  const [searchValue, setSearchValue] = useState(requestSearchValue)
  const selectedPreparationDepartments = useSelector(
    selectedPreparationDepartmentSelector
  )
  const {resetDepartmentTableSettings} = usePreparationDepartmentService()
  const preparationDepartments = useSelector(preparationDepartmentsListSelector)

  const handleSubmitDelete = () => {
    deletePreparationDepartmentsList(selectedPreparationDepartments)
  }

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleSubmitDelete(),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const debouncedValue = useDebounce(searchValue, 500)

  const onClickOpenModalWindow = () => {
    form.resetFields()
    changeOpenModalWindow(true)
  }

  useEffect(() => {
    const debouncedSearchValue = !debouncedValue ? null : debouncedValue
    if (requestSearchValue !== debouncedSearchValue) {
      setPreparationDepartmentsTableSettings({f_title: debouncedSearchValue})
    }
  }, [debouncedValue])

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `dictionary/preparation-department`,
      tableSettings,
    })
  }

  const ActionButton = [
    <SearchAntdComponent
      key={1}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <AddButton onClick={onClickOpenModalWindow} />,
    <DeleteButton
      onClick={confirmDelete}
      disabled={isIncludeSelectedFilter(
        preparationDepartments,
        selectedPreparationDepartments
      )}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu(ActionButton)

  const clearFiltersHandler = () => {
    setSearchValue('')
    resetDepartmentTableSettings()
  }

  return (
    <PageHeaderWithMobileMenu
      title={t('Preparation department')}
      subTitle={
        <ResetFiltersButton
          clearFiltersHandler={clearFiltersHandler}
          currentTableSettings={tableSettings}
          initialTableSettings={departmentInitialState.tableSettings}
        />
      }
      items={item}
      extra={ActionButton}
    />
  )
}

export default PreparationDepartmentHeader
