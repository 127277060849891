import {Button} from 'antd'
import {useTranslation} from 'react-i18next'
import {LoginOutlined} from '@ant-design/icons'

import useAuth from '../services/authService'
import LanguageMenu from './LanguageMenu'

function UserNavbarMenu() {
  const {logout} = useAuth()
  const {t} = useTranslation()

  const userProfileMenu = [
    {
      key: '1',
      label: <LanguageMenu style={{marginBottom: 15, width: '100%'}} />,
    },
    {
      key: '2',
      label: (
        <Button onClick={() => logout()} style={{width: '100%'}}>
          {t('Logout')}
          <LoginOutlined />
        </Button>
      ),
    },
  ]

  return {userProfileMenu}
}

export default UserNavbarMenu
