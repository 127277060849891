import React from 'react'
import {useSelector} from 'react-redux'
import useNotificationTable from '../../../../services/notifications/notificationTableService'
import {AppTable} from '../../../../components/antd-components/app-table'

import './style.scss'

function NotificationBody({
  setNotificationTableSettings,
  notificationTableSettings,
  updateNotification,
}) {
  const notifications = useSelector(
    (state) => state.messageNotifications.notificationMessage
  )
  const totalCount = useSelector(
    (state) => state.messageNotifications.totalCount
  )
  const isLoading = useSelector(
    (state) => state.messageNotifications.isLoading === 'getNotifications'
  )

  const notificationSelectedId = useSelector(
    (state) => state.messageNotifications.selectedNotificationId
  )

  const changeClassName = (record) => {
    if (record.read === false) {
      if (record.id === notificationSelectedId) {
        return 'showFullTextSelectNotification unreadMessage disabledRowHover'
      }
      return 'unreadMessage sort'
    }
    return 'readMessage'
  }

  const onSubmitUpdateRead = (value) => {
    updateNotification(value.id)
  }

  const {notificationColumn} = useNotificationTable({
    onSubmitUpdateRead,
    notificationTableSettings,
    setNotificationTableSettings,
  })

  return (
    <AppTable
      rowClassName={(record) => changeClassName(record)}
      totalCount={totalCount}
      tableSettings={notificationTableSettings}
      setTableSettings={setNotificationTableSettings}
      className="disabledRowHover notificationTableHeight emptyHeight hideScroll "
      isLoading={isLoading}
      columns={notificationColumn}
      dataSource={notifications}
    />
  )
}

export default NotificationBody
