import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'
import {checkIsResponseSuccess} from '../../utils/helpers'

export const getRawMaterials = createAsyncThunk(
  'GET_RAW_MATERIALS',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/dictionary/raw', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        raws: state.rawMaterials.rawMaterials,
        totalCount: state.rawMaterials.totalCount,
      }
    }
    return null
  }
)

export const getRawBalance = createAsyncThunk(
  'GET_RAW_BALANCE',
  async ({params = {}}, {rejectWithValue}) => {
    try {
      const response = await API.get(`/store/products/balance-raw/`, {params})
      if (checkIsResponseSuccess(response.data.statusCode)) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getRawMaterial = createAsyncThunk(
  'GET_RAW_MATERIAL',
  async ({id}, {getState}) => {
    if (id) {
      try {
        const response = await API.get(`/dictionary/raw/${id}`)
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
        const state = getState()
        return {
          selectedRawMaterial: state.rawMaterials.selectedRawMaterial,
        }
      }
    }
    return null
  }
)

export const setRawMaterial = createAsyncThunk(
  'SET_RAW_MATERIAL',
  async (data) => {
    try {
      const response = await API.post('/dictionary/raw', data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const updateRawMaterial = createAsyncThunk(
  'UPDATE_RAW_MATERIAL',
  async (data) => {
    const {id} = data
    try {
      const response = await API.put(`/dictionary/raw/${id}`, data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const deleteRawMaterials = createAsyncThunk(
  'DELETE_RAW_MATERIALS',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete('/dictionary/raw', {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {...response.data.payload, selectedRawMaterials: []}
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedRawMaterials: state.rawMaterials.selectedRawMaterials,
      }
    }
    return null
  }
)
