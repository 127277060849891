import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'
import {serverRequestAsyncWrapper} from '../../utils/wrappers'

export const getStores = createAsyncThunk(
  'GET_STORES',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/dictionary/stores', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        stores: state.stores.stores,
        totalCount: state.stores.totalCount,
      }
    }
    return null
  }
)

// All stores, including deleted are fetched
export const getAllStores = createAsyncThunk(
  'GET_ALL_STORES',
  serverRequestAsyncWrapper(async () =>
    API.get('/dictionary/stores', {params: {withDeleted: true}})
  )
)

export const getStore = createAsyncThunk('GET_STORE', async ({id}) => {
  if (id) {
    try {
      const response = await API.get(`/dictionary/stores/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
  }
  return null
})

export const setStore = createAsyncThunk('SET_STORE', async (data) => {
  try {
    const response = await API.post('/dictionary/stores', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const updateStore = createAsyncThunk('UPDATE_STORE', async (data) => {
  const {id} = data
  try {
    const response = await API.put(`/dictionary/stores/${id}`, data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const deleteStores = createAsyncThunk(
  'DELETE_STORES',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete('/dictionary/stores', {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {
          ...response.data.payload,
          selectedStores: [],
        }
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedStores: state.stores.selectedStores,
      }
    }
    return null
  }
)
