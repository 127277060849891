import * as yup from 'yup'

function AmountFieldValidation(isCurrentProductPiece, t) {
  const schema = yup.lazy((value) => {
    if (value !== undefined) {
      return yup.object().shape({
        [Object.keys(value).toString()]: isCurrentProductPiece
          ? yup
              .number()
              .positive()
              .min(0.000001, t('Only positive number'))
              .integer(t('Value must be an integer'))
              .nullable()
              .typeError(t('This field is required.'))
          : yup
              .number()
              .positive()
              .min(0.000001, t('Only positive number'))
              .nullable()
              .typeError(t('This field is required.')),
      })
    }
    return yup.mixed().nullable()
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default AmountFieldValidation
