import {useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import './style.scss'
import {AppTable} from '../../../../components/antd-components/app-table'
import {
  getInventoryLoading,
  inventoryData,
  inventoryTableSettings,
  inventoryTotalCount,
  lastUpdatedInventory,
} from '../../../../redux/selectors/inventorySelector'
import useInventoryTable from '../../../../services/inventory/inventoryTableService'
import useInventoryService from '../../../../services/inventory/inventoryService'
import InventoryDetailModal from './InventoryDetailModal'
import InventoryHistoryModal from './InventoryHistoryModal'

function InventoryBody() {
  const inventory = useSelector(inventoryData)
  const totalCount = useSelector(inventoryTotalCount)
  const tableSettings = useSelector(inventoryTableSettings)
  const isLoading = useSelector(getInventoryLoading)
  const lastUpdated = useSelector(lastUpdatedInventory)
  const [isEditDialogOpen, setEditDialogOpen] = useState(false)
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const [selectedItemName, setSelectedItemName] = useState('')
  const [showOnlyMismatchedRecords, setShowOnlyMismatchedRecords] =
    useState(false)
  const {setInventoryTableSettings, getInventoryData, getInventoryDetail} =
    useInventoryService()

  useEffect(() => {
    if (!isEditDialogOpen && showOnlyMismatchedRecords) {
      setShowOnlyMismatchedRecords(false)
    }
  }, [isEditDialogOpen])

  const getSelectedInventory = (id) => {
    setEditDialogOpen(true)
    getInventoryDetail(id)
  }

  const {inventoryColumns} = useInventoryTable({
    tableSettings,
    getSelectedInventory,
  })

  useEffect(() => {
    getInventoryData(tableSettings)
  }, [lastUpdated])

  const handleEditClose = () => {
    setEditDialogOpen(false)
  }

  const closeHistoryModalHandler = () => {
    setIsHistoryModalOpen(false)
  }

  return (
    <div>
      <InventoryDetailModal
        isModalOpen={isEditDialogOpen}
        closeModalHandler={handleEditClose}
        setIsHistoryModalOpen={setIsHistoryModalOpen}
        setSelectedItemName={setSelectedItemName}
      />
      <InventoryHistoryModal
        selectedItemName={selectedItemName}
        closeModalHandler={closeHistoryModalHandler}
        isModalOpen={isHistoryModalOpen}
        setSelectedItemName={setSelectedItemName}
      />
      <AppTable
        dataSource={inventory}
        columns={inventoryColumns}
        totalCount={totalCount}
        tableSettings={tableSettings}
        setTableSettings={setInventoryTableSettings}
        isLoading={isLoading}
      />
    </div>
  )
}

export default InventoryBody
