import {Button} from 'antd'

function ButtonDanger({
  disabled,
  onClick,
  text,
  icon,
  style,
  colorType,
  ...props
}) {
  return (
    <Button
      {...props}
      size="medium"
      key={2}
      danger
      disabled={disabled}
      onClick={onClick}
      style={{
        ...style,
        textTransform: 'uppercase',
      }}
    >
      {text}
      {icon}
    </Button>
  )
}

export default ButtonDanger
