import {useEffect} from 'react'
import InventoryHeader from './components/InventoryHeader'
import InventoryBody from './components/InventoryBody'
import useUsers from '../../../services/users/usersService'
import useStore from '../../../services/storeServices/storeService'

function InventoryPage() {
  const {getUsersData} = useUsers()
  const {getStoresData} = useStore()
  useEffect(() => {
    getUsersData()
    getStoresData()
  }, [])
  return (
    <div>
      <InventoryHeader />
      <InventoryBody />
    </div>
  )
}

export default InventoryPage
