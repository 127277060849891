export const isStoreBalanceLoadingSelector = (state) =>
  state.storesBalances.isLoading === 'getStoreBalance'

export const storeBalanceSelector = (state) =>
  state.storesBalances.storesBalance
export const selectedStoreId = (state) => state.storesBalances.selectedStoreId
export const lastUpdated = (state) => state.storesBalances.lastUpdated

export const storeBalanceTableSettings = (state) =>
  state.storesBalances.tableSettings
