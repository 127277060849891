import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Col, Form, Row} from 'antd'
import moment from 'moment'
import OrderGeneralDataForm from './OrderGeneralDataForm'
import OrderProductsForm from './OrderProductsForm'
import useProducts from '../../../../../services/products/productsService'
import OrderFormHeader from './OrderFormHeader'
import OrderFormBottom from './OrderFormBottom'
import OrderMetaDataBlock from './OrderMetaDataBlock'
import useCustomNavigate from '../../../../../hooks/useCustomNavigate'
import useStore from '../../../../../services/storeServices/storeService'
import notify from '../../../../../components/antd-components/notification/notify'
import {
  allStoresSelector,
  storesSelector,
} from '../../../../../redux/selectors/storeSelector'
import {currencyAddWithPrecision} from '../../../../../utils/helpers'

import './style.scss'

function OrderForm({order, onSubmit}) {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()

  const [form] = Form.useForm()
  const deliveryPrice = Form.useWatch('deliveryPrice', form)
  const [isFormLoading, setIsFormLoading] = useState(false)

  // The antd form doesn't allow to store values if they don't have input in the app, so we store total in separate state
  const [total, setTotal] = useState(0)

  const orderProducts = Form.useWatch('orderProducts', form)
  useEffect(() => {
    const correctDeliveryPrice = Number.isNaN(Number(deliveryPrice))
      ? 0
      : Number(deliveryPrice)

    if (Array.isArray(orderProducts))
      setTotal(
        orderProducts
          .map(({total}) => total)
          .reduce(
            (prev, curr) => currencyAddWithPrecision(prev, curr, 2),
            correctDeliveryPrice
          )
      )
  }, [orderProducts, deliveryPrice])

  useEffect(() => {
    if (order) {
      form.setFieldsValue({
        name: order.name,
        createdById: order.createdById,
        deliveryDate: moment(order.deliveryDate),
        deliveryDateTo: order.deliveryDateTo
          ? moment(order.deliveryDateTo)
          : null,
        deliveryType: order.deliveryType,
        deliveryFrom: order.deliveryFrom,
        deliveryPrice: order.deliveryPrice,
        deliveryAddress: order.deliveryAddress,
        externalId: order.externalId,
        phone: order.phone,
        delivered: order.delivered,
        orderProducts: order.orderProducts,
      })

      setTotal(order.total || 0)
    } else {
      form.setFieldsValue({
        name: '',
        createdById: '',
        deliveryType: null,
        deliveryDate: null,
        deliveryDateTo: null,
        deliveryFrom: null,
        externalId: null,
        deliveryPrice: 0,
        deliveryAddress: null,
        phone: '',
        delivered: false,
        orderProducts: [],
      })
    }
  }, [order])

  const {getProductsData} = useProducts()
  const {getAllStoresData, getStoresData} = useStore()
  const allStores = useSelector(allStoresSelector)
  const activeStores = useSelector(storesSelector)
  useEffect(() => {
    getProductsData()
    if (allStores.length === 0) getAllStoresData()
    if (activeStores.length === 0) getStoresData()
  }, [])

  const handleSubmit = async () => {
    setIsFormLoading(true)
    const values = form.getFieldsValue()
    if (values.orderProducts.length === 0) {
      notify({
        description: t('Add at least one product'),
        type: 'error',
      })
    } else await onSubmit({...values, total})

    setIsFormLoading(false)
    return null
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      labelCol={{xs: {span: 24}}}
      className="order-form"
      disabled={isFormLoading}
    >
      <OrderFormHeader
        form={form}
        orderId={order?.id}
        orderPayments={order?.orderPayments}
        paymentDetails={order?.paymentDetails}
        total={order?.total}
      />
      <Row gutter={12} className="order-form_content">
        <Col span={12}>
          <OrderGeneralDataForm form={form} />
          {order && <OrderMetaDataBlock order={order} />}
        </Col>
        <Col span={12}>
          <OrderProductsForm form={form} />
        </Col>
      </Row>
      <OrderFormBottom
        onCancel={() => navigate('/orders')}
        total={total}
        form={form}
      />
    </Form>
  )
}

export default OrderForm
