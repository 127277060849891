import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {priceFormat} from '../../../utils/helpers'
import '../../../pages/main/write-off-reason/write-off-confirm/components/style.scss'
import './style.scss'

const useWriteOffDetailTableSettings = () => {
  const {t} = useTranslation()
  const isAuth = useSelector((state) => state.auth.user)
  const userType = isAuth.type
  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'productName',
      align: 'center',
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      align: 'center',
      className: 'columns amount',
    },

    {
      title: t('Selling price'),
      dataIndex: 'salePricePerItem',
      align: 'right',
      className: 'columns selling_price',
      render: (value) => <span>{priceFormat(value)}</span>,
    },
    {
      title: t('Selling sum'),
      dataIndex: 'totalSalePrice',
      align: 'right',
      className: 'columns selling_sum',
      render: (value) => <span>{priceFormat(value)}</span>,
    },
    {
      title: t('Producing price'),
      dataIndex: 'producingPricePerItem',
      align: 'right',
      className: 'columns producing_price',
      render: (value) => <span>{priceFormat(value)}</span>,
    },
    {
      title: t('Producing sum'),
      dataIndex: 'producingTotalPrice',
      align: 'right',
      className: 'columns producing_sum',
      render: (value) => <span>{priceFormat(value)}</span>,
    },
  ]
  return {
    detailWriteOffColumns:
      userType === 'admin'
        ? getColumns()
        : getColumns().filter(
            (item) =>
              item.dataIndex !== 'producingTotalPrice' &&
              item.dataIndex !== 'producingPricePerItem'
          ),
  }
}

export default useWriteOffDetailTableSettings
