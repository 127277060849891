import {useSelector} from 'react-redux'
import {PageHeaderWithMobileMenu} from '../../../../../components'
import DownloadButton from '../../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../../services/commonService'
import {writeOffConfirmTableSettings} from '../../../../../redux/selectors/writeOffConfirmSelector'
import {writeOffConfirmInitialState} from '../../../../../redux/slices/writeOffConfirmSlice'
import {useWriteOffConfirm} from '../../../../../services/write-off-reason/write-off-confrim/writeOffConfrimService'
import ResetFiltersButton from '../../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'

function WriteOffHeader() {
  const {exportExcel} = useCommon()
  const {resetWriteOffConfirmTableSettings} = useWriteOffConfirm()
  const tableSettings = useSelector(writeOffConfirmTableSettings)
  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `store/products/write-offs`,
      tableSettings,
    })
  }

  const actionWriteOffHeader = [
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  return (
    <PageHeaderWithMobileMenu
      title="Write-off for confirm"
      subTitle={
        <ResetFiltersButton
          clearFiltersHandler={resetWriteOffConfirmTableSettings}
          currentTableSettings={tableSettings}
          initialTableSettings={writeOffConfirmInitialState.tableSettings}
        />
      }
      showHeaderMenu={false}
      extra={actionWriteOffHeader}
    />
  )
}

export default WriteOffHeader
