import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {DollarOutlined} from '@ant-design/icons'
import {
  crossedOutText,
  getColumnSorter,
  getTableColumnId,
} from '../../utils/helpers'
import {formattedDate} from '../../constants'
import {useDateRangeTableSettings} from '../../hooks'
import useOrdersService from './ordersService'
import {orderTableSettingsSelector} from '../../redux/selectors/orderSelector'
import {allStoresSelector} from '../../redux/selectors/storeSelector'
import ActionsColumnWrapper from '../../components/antd-components/app-table/table-components/column-wrappers/ActionsColumnWrapper'
import TagsComponent from '../../components/antd-components/tags/TagsComponent'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'
import DownloadActionButton from '../../components/antd-components/tableAction-buttons/download-button/DownloadActionButton'

const useOrdersTable = ({
  onEdit,
  onDownloadOrder,
  onViewPayments,
  onDelete,
}) => {
  const {t} = useTranslation()

  const stores = useSelector(allStoresSelector)
  const users = useSelector((state) => state.users.users)
  const tableSettings = useSelector(orderTableSettingsSelector)

  const {setOrdersTableSettingsAndRefetchList} = useOrdersService()

  const createdDateFilterProps = useDateRangeTableSettings({
    tableSettings,
    setTableSettings: setOrdersTableSettingsAndRefetchList,
  })
  const deliveryDateFilterProps = useDateRangeTableSettings({
    dateFromFilterName: 'f_deliveryDateFrom',
    dateToFilterName: 'f_deliveryDateTo',
    tableSettings,
    setTableSettings: setOrdersTableSettingsAndRefetchList,
  })

  const getColumns = () => [
    getTableColumnId({width: '5%'}),
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'f_description',
      align: 'center',
      width: '25%',
    },
    {
      title: t('Delivery date'),
      align: 'center',
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      ...deliveryDateFilterProps,
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'deliveryDate'),
      render: (value) => moment(value).format(formattedDate.DateWithTime),
      width: '10%',
    },
    {
      title: t('Delivery from'),
      align: 'center',
      dataIndex: 'storeName',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'storeName'),
      key: 'f_deliveryFromId',
      filteredValue: tableSettings.f_deliveryFromId,
      filters: stores.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.deletedDate ? crossedOutText(item.name) : item.name,
      })),
      render: (_, record) => {
        if (!record.store) return '-'
        if (!record.store.deletedDate) return record.store.name
        return crossedOutText(record.store.name)
      },
      width: '10%',
    },
    {
      title: t('Status'),
      align: 'center',
      dataIndex: 'delivered',
      key: 'f_delivered',
      filteredValue: tableSettings.f_delivered,
      filters: [
        {
          key: 1,
          value: true,
          text: t('Delivered'),
        },
        {
          key: 2,
          value: false,
          text: t('In progress'),
        },
      ],
      width: '90px',
      render: (delivered, record) => (
        <TagsComponent
          color={delivered ? 'green' : 'red'}
          keys={record.id}
          tagText={delivered ? 'Delivered' : 'In progress'}
        />
      ),
    },
    {
      title: t('Customer'),
      dataIndex: 'phone',
      align: 'center',
      render: (_, record) => (
        <>
          <p>{record.name}</p>
          {!!record.phone && <p>{record.phone}</p>}
        </>
      ),
    },
    {
      title: t('Author'),
      dataIndex: 'createdBy',
      align: 'center',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'createdBy'),
      key: 'f_createdById',
      filteredValue: tableSettings.f_createdById,
      filters: users.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      render: (createdBy) => createdBy.name,
      width: '10%',
    },
    {
      title: t('Create date'),
      dataIndex: 'createdDate',
      align: 'center',
      ...createdDateFilterProps,
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'createdDate'),
      render: (value) =>
        value !== null ? moment(value).format(formattedDate.DateWithTime) : '-',
      width: '10%',
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '135px',
      render: (_, record) => (
        <ActionsColumnWrapper>
          <EditActionButton
            onClick={(e) => {
              e.stopPropagation()
              onEdit(record.id)
            }}
          />
          <DownloadActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDownloadOrder(record.id)
            }}
          />
          <DetailsActionButton
            icon={<DollarOutlined />}
            onClick={(e) => {
              e.stopPropagation()
              onViewPayments(record.id)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDelete(record.id)
            }}
          />
        </ActionsColumnWrapper>
      ),
    },
  ]

  return {columns: getColumns()}
}

export default useOrdersTable
