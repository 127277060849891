import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'
import {checkIsResponseSuccess} from '../../utils/helpers'

export const getProfits = createAsyncThunk(
  'GET_PROFITS',

  async ({params = {}}, {rejectWithValue}) => {
    try {
      const response = await API.get('/reports/profits', {params})
      if (checkIsResponseSuccess(response?.data?.statusCode)) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  }
)
