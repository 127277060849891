import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import NormBody from './components/NormBody'
import NormHeader from './components/NormHeader'
import useStore from '../../../services/storeServices/storeService'
import useNormService from '../../../services/norm/normService'
import {lastUpdatedNorm} from '../../../redux/selectors/normSelector'
import useProducts from '../../../services/products/productsService'
import useUsers from '../../../services/users/usersService'

function NormPage() {
  const {getStoresData} = useStore()
  const {getNormData} = useNormService()
  const {getProductsData} = useProducts()
  const {getUsersData} = useUsers()
  const normLastUpdated = useSelector(lastUpdatedNorm)
  const tableSettings = useSelector((state) => state.norm.tableSettings)
  useEffect(() => {
    getStoresData()
    getNormData(tableSettings)
    getProductsData({f_type: ['final']})
    getUsersData()
  }, [normLastUpdated])

  return (
    <div>
      <NormHeader />
      <NormBody />
    </div>
  )
}

export default NormPage
