import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

function RecalculateValidationSchema() {
  const {t} = useTranslation()
  const schema = yup.object().shape({
    productionOutputs: yup
      .number()
      .typeError(t('Value must be an number'))
      .required(t('This field is required.'))
      .nullable()
      .positive(t('Value cannot be 0 or less than zero'))
      .transform((_, value) => {
        if (String(value).includes(',')) {
          return +value.replace(/,/, '.')
        }
        if (!value) {
          return undefined
        }
        return +value
      }),
  })
  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default RecalculateValidationSchema
