import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'

import {ExclamationCircleOutlined} from '../../../../components/antd-components/icon'
import useProductsTable from '../../../../services/products/productsTableService'
import useProductsTableFilterService from '../../../../services/products/productsFilterTableService'
import showConfirm from '../../../../components/PopupConfirm'
import {AppTable} from '../../../../components/antd-components/app-table'

function ProductsBody({
  type,
  selectProducts,
  setTableSettings,
  deleteProductsData,
  setFilteredInfo,
  setSelectedIdProductsOrSemi,
  setTypeProductsOrSemi,
  tableSettings,
}) {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const products = useSelector((state) => state.products.products)
  const isLoading = useSelector(
    (state) => state.products.isLoading === 'getProducts'
  )

  const selectedProducts = useSelector(
    (state) => state.products.selectedProducts
  )
  const productsTotalCount = useSelector((state) => state.products.totalCount)

  const {rangeAmount, filterAmountIcon} = useProductsTableFilterService(type)

  const handleDeleteSubmit = (id) => {
    deleteProductsData({ids: [id]})
  }

  const onEdit = (id) => {
    setSelectedIdProductsOrSemi(id)
    setTypeProductsOrSemi(type)
    const navigateLink =
      type === 'semi' ? `/semi-finished/edit/${id}` : `/product/edit/${id}`
    navigate(navigateLink)
  }

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleDeleteSubmit(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const {productsColumns} = useProductsTable({
    onDelete: confirmDelete,
    onEdit,
    rangeAmount,
    filterAmountIcon,
    tableSettings,
  })

  return (
    <AppTable
      isLoading={isLoading}
      dataSource={products}
      columns={productsColumns}
      totalCount={productsTotalCount}
      showSelectRowCheckbox
      setTableSettings={setTableSettings}
      tableSettings={tableSettings}
      setSelectedComponent={selectProducts}
      setFilteredInfo={setFilteredInfo}
      selectedRowKeys={selectedProducts}
    />
  )
}

export default ProductsBody
