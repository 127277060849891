import {Row} from 'antd'
import {useTranslation} from 'react-i18next'

function BuildVersionLabelComponent({dateBuild, branchName}) {
  const {t} = useTranslation()
  return (
    <div>
      <Row className="header_config_row ">
        <div className="header_config_row_parent_block">
          <div className="header_config_row_parent_block_config_style">
            <span>
              <span className="header_config_version_span">
                {t('Date build')} : {dateBuild}
              </span>

              <span className="header_config_version_span">
                {t('Branch version')} : {branchName}
              </span>
            </span>
          </div>
        </div>
      </Row>
    </div>
  )
}

export default BuildVersionLabelComponent
