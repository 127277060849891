import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {Card, Col, Row} from 'antd'
import './style.scss'
import {convertToFractionalNumber, getRandomId} from '../../../../utils/helpers'
import {formattedDate} from '../../../../constants'
import {LoadingSpinner} from '../../../../components/antd-components/loading-spinner'
import {
  isSelectedIncasationLoadingSelector,
  selectedIncasationSelector,
} from '../../../../redux/selectors/incasationSelector'
import ListComponent from '../../../../components/antd-components/listComponent/ListComponent'
import IsCardIconIndicator from '../../../../components/table-components/IsCardIcon'
import SignaturePad from '../../../../components/signaturePad'

function IncasationModalDetail() {
  const {t} = useTranslation()
  const isLoading = useSelector(isSelectedIncasationLoadingSelector)
  const selectedIncasation = useSelector(selectedIncasationSelector)

  const detailWriteOffInformation = [
    {
      key: getRandomId(),
      name: t('Create date'),
      value: moment(selectedIncasation?.submissionDate).format(
        formattedDate.DateWithTime
      ),
    },
    {
      key: getRandomId(),
      name: t('Created'),
      value: selectedIncasation?.submittedBy?.name,
    },
    {
      key: getRandomId(),
      name: t('Confirmed'),
      value: selectedIncasation?.confirmedBy?.name || '-',
    },
  ]

  const incasationListData = useMemo(() => {
    if (selectedIncasation) {
      const {
        store,
        amount,
        isCash,
        courierFullName,
        courierSignature,
        submittedBy,
        submissionDate,
      } = selectedIncasation
      return [
        {name: t('Author'), value: submittedBy.name},
        {
          name: t('Submission date'),
          value: moment(submissionDate).format(formattedDate.DateWithTime),
        },
        {name: t('Store'), value: store.name},
        {name: t('Sum'), value: convertToFractionalNumber(amount, 2)},
        {name: t('Method'), value: IsCardIconIndicator({isCash})},
        {name: t('Courier full name'), value: courierFullName},
        {
          name: t('Courier signature'),
          value: <SignaturePad value={courierSignature} />,
          contentClassName: 'signature_wrapper',
        },
      ]
    }
    return []
  }, [selectedIncasation])

  return (
    <div>
      <Col
        xl={24}
        sm={24}
        xs={24}
        lg={24}
        style={{border: '1px dashed #F0F0F0'}}
      >
        <Row gutter={[16, 8]} style={{paddingBottom: '8px'}}>
          {detailWriteOffInformation.map((item) => (
            <Col xl={6} md={8} lg={8} sm={12} xs={12} key={item.key}>
              <Card
                bordered={false}
                className="card"
                key={item.key}
                style={{background: '#FAFAFA'}}
              >
                <div
                  style={{display: 'flex', justifyContent: 'space-between'}}
                  key={item.key}
                >
                  <span style={{color: '#262626', fontWeight: 'bold'}}>
                    {`${t(item.name)}:`}
                  </span>
                  <span style={{fontSize: '15px', color: '#262626'}}>
                    {isLoading ? <LoadingSpinner size="small" /> : item.value}
                  </span>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
      <Col
        xl={24}
        sm={24}
        xs={24}
        lg={24}
        style={{border: '1px dashed #F0F0F0'}}
      >
        <ListComponent loading={isLoading} dataSource={incasationListData} />
      </Col>
    </div>
  )
}

export default IncasationModalDetail
