import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getRefills = createAsyncThunk(
  'GET_REFILLS',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/store/products/arrival', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        products: state.prices.prices,
        totalCount: state.prices.totalCount,
      }
    }
    return null
  }
)

export const getRefill = createAsyncThunk('GET_REFILL', async ({id}) => {
  if (id) {
    try {
      const response = await API.get(`/store/products/arrival/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
  }
  return null
})

export const setRefill = createAsyncThunk('SET_REFILL', async (data) => {
  try {
    const response = await API.post('/store/products/arrival', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const updateRefill = createAsyncThunk('UPDATE_REFILL', async (data) => {
  const {id} = data
  try {
    const response = await API.put(`/store/products/arrival/${id}`, data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const updateRefillConfirm = createAsyncThunk(
  'UPDATE_REFILL_CONFIRM',
  async (data) => {
    const {id} = data
    try {
      const response = await API.put(
        `store/products/arrival/${id}/confirm`,
        data
      )
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const downloadExcel = createAsyncThunk('DOWNLOAD_EXCEL', async (id) => {
  try {
    const response = await API.get(
      `/store/products/arrival/${id}/export/xlsx`,
      {
        responseType: 'blob',
      }
    )

    if (response) {
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = response.headers['content-disposition'].split('=').pop()
      link.click()
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const deleteRefill = createAsyncThunk(
  'DELETE_REFILL',
  async (id, {getState}) => {
    try {
      const response = await API.delete(`/store/products/arrival/${id}`)
      if (response.data.message === 'OK') {
        return {
          ...response.data.payload,
        }
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)
