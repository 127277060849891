import {useTranslation} from 'react-i18next'
import './style.scss'
import {getColumnSorter} from '../../utils/helpers'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'

const useTaskTable = ({onDelete, onEdit, handleDetailOpen, tableSettings}) => {
  const {t} = useTranslation()

  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      className: 'columns task_name',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'name'),
      filteredValue: '',
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      className: 'columns task_action',
      render: (_, record) => (
        <div>
          <EditActionButton
            onClick={(e) => {
              e.stopPropagation()
              onEdit(record.id)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDelete(record.id)
            }}
          />
          <DetailsActionButton
            onClick={(e) => {
              e.stopPropagation()
              handleDetailOpen(record.id)
            }}
          />
        </div>
      ),
    },
  ]

  return {taskColumns: getColumns()}
}

export default useTaskTable
