import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'antd/es/form/Form'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import MovementAddHeader from './components/movement-add-header/MovementAddHeader'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import MovementAddFooter from './components/movement-add-footer/MovementAddFooter'
import './style.scss'
import MovementAddBody from './components/movement-add-body/MovementAddBody'
import useStore from '../../../../services/storeServices/storeService'
import useProducts from '../../../../services/products/productsService'
import useMovements from '../../../../services/movements/movementsService'
import useUnits from '../../../../services/units/unitsService'
import useUsers from '../../../../services/users/usersService'
import useStoreBalance from '../../../../services/store-balance/storeBalanceService'
import {selectedMovement} from '../../../../redux/selectors/movementSelector'

function MovementAddPage() {
  const navigate = useCustomNavigate()
  const {getStoresData} = useStore()
  const {getProductsData} = useProducts()
  const {resetSelectedMovementData} = useMovements()
  const {getUnitsData} = useUnits()
  const {getUsersData} = useUsers()
  const {getStoresBalance} = useStoreBalance()
  const [currentActionButton, setCurrentActionButton] = useState()
  const [movementForm] = useForm()
  const {movementId} = useParams()
  const [itemsList, setItemsList] = useState([])
  const selectedMovementData = useSelector(selectedMovement)
  const isSelectedMovementLoading = useSelector(
    (state) => state.movements.isLoading === 'getMovement'
  )
  useEffect(() => {
    getStoresData()
    getProductsData()
    getUnitsData()
    getUsersData()
    getStoresBalance()
    return () => resetSelectedMovementData()
  }, [])

  useEffect(() => {
    if (selectedMovementData) {
      const {productsSent, semiFinishedSent, rawsSent} = selectedMovementData
      const allItemsList = [...productsSent, ...semiFinishedSent, ...rawsSent]
      setItemsList(allItemsList)
    } else {
      setItemsList([])
    }
  }, [selectedMovementData])

  const isPageReadOnly = useMemo(() => {
    if (
      (selectedMovementData &&
        (selectedMovementData.status === 'processed' ||
          selectedMovementData.status === 'error')) ||
      (movementId && !selectedMovementData)
    ) {
      return true
    }
    return false
  }, [selectedMovementData])

  const onCloseHandler = () => {
    navigate('/movements')
  }
  return (
    <div className="movementAdd_page">
      <MovementAddHeader
        onBackHandler={onCloseHandler}
        isLoading={isSelectedMovementLoading}
      />
      <MovementAddBody
        setItemsList={setItemsList}
        itemsList={itemsList}
        isLoading={isSelectedMovementLoading}
        movementForm={movementForm}
        isPageReadOnly={isPageReadOnly}
        currentActionButton={currentActionButton}
      />
      <MovementAddFooter
        movementForm={movementForm}
        isPageReadOnly={isPageReadOnly}
        onCloseHandler={onCloseHandler}
        setCurrentActionButton={setCurrentActionButton}
      />
    </div>
  )
}

export default MovementAddPage
