import {Form, Input} from 'antd'

function InputPasswordForm({name, rules = {}, label, initialValue, ...rest}) {
  return (
    <Form.Item
      name={name}
      rules={[rules]}
      label={label}
      initialValue={initialValue}
    >
      <Input.Password {...rest} />
    </Form.Item>
  )
}

export default InputPasswordForm
