import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import SuppliesHeader from './components/SuppliesHeader'
import SuppliesBody from './components/SuppliesBody'

import useSupply from '../../../services/supllies/supplies'
import useSuppliers from '../../../services/supplier/suppliersService'
import useStore from '../../../services/storeServices/storeService'

function Supply() {
  const supplyProps = useSupply()
  const {
    setSuppliesTableSettings,
    deleteSuppliesData,
    getSuppliesData,
    setSelectedSuppliesId,
  } = supplyProps
  const {getSuppliersData} = useSuppliers()
  const {getStoresData} = useStore()

  const suppliesLastUpdated = useSelector((state) => state.supplies.lastUpdated)

  const suppliesTableSettings = useSelector(
    (state) => state.supplies.tableSettings
  )

  useEffect(() => {
    getSuppliesData(suppliesTableSettings)
  }, [suppliesLastUpdated])

  useEffect(() => {
    getSuppliersData()
    getStoresData()
  }, [])

  return (
    <>
      <SuppliesHeader
        setSuppliesTableSettings={setSuppliesTableSettings}
        deleteSuppliesData={deleteSuppliesData}
        suppliesTableSettings={suppliesTableSettings}
      />
      <SuppliesBody
        {...supplyProps}
        setSelectedSuppliesId={setSelectedSuppliesId}
        suppliesTableSettings={suppliesTableSettings}
      />
    </>
  )
}

export default Supply
