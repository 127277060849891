import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import i18n from '../i18n'

function LanguageProvider({children}) {
  const language = useSelector((state) => state.common.language)
  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  return children
}

export default LanguageProvider
