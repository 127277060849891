import {createSlice} from '@reduxjs/toolkit'
import {
  deleteTasks,
  getTask,
  getTasks,
  setTask,
  updateTask,
} from '../asyncServices/tasksService'
import {resetReduxStore} from '../resetStore'

export const taskInitialState = {
  tasks: [],
  totalCount: 0,
  selectedTask: [],
  selectedTasksInTable: [],
  tableSettings: {
    s_direction: 'ASC',
    s_field: 'name',
    f_name: null,
    pageSize: 100,
    page: 1,
  },
  lastUpdated: null,
  isLoading: null,
  isOpenModalWindow: false,
}

const tasksSlice = createSlice({
  name: 'tasksSlice',
  initialState: taskInitialState,
  reducers: {
    setSelectedTasks: (state, action) => {
      state.selectedTask = action.payload
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setSelectedCheckbox: (state, action) => {
      state.selectedTasksInTable = action.payload
    },
    setIsOpenModalWindow: (state, action) => {
      state.isOpenModalWindow = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    clearTaskTableSettings: (state) => {
      state.tableSettings = taskInitialState.tableSettings
      state.lastUpdated = Date.now()
    },
    tasksReducerReset: () => taskInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTasks.pending, (state) => {
      state.isLoading = 'getTasks'
    })
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.tasks = action.payload.tasks
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getTask.pending, (state) => {
      state.isLoading = 'getTask'
    })
    builder.addCase(getTask.fulfilled, (state, action) => {
      state.selectedTask = action.payload
      state.isLoading = null
    })
    builder.addCase(setTask.pending, (state) => {
      state.isLoading = 'setTask'
    })
    builder.addCase(setTask.fulfilled, (state) => {
      state.isLoading = null
      state.lastUpdated = Date.now()
    })
    builder.addCase(updateTask.pending, (state) => {
      state.isLoading = 'updateTask'
    })
    builder.addCase(updateTask.fulfilled, (state) => {
      state.isLoading = null
      state.lastUpdated = Date.now()
    })
    builder.addCase(deleteTasks.pending, (state) => {
      state.isLoading = 'deleteTasks'
    })
    builder.addCase(deleteTasks.fulfilled, (state, action) => {
      state.selectedUnits = action.payload.selectedUnits
      state.isLoading = null
      state.lastUpdated = Date.now()
    })

    builder.addCase(resetReduxStore, (state) => {
      state = taskInitialState
    })
  },
})

export const {
  setSelectedTasks,
  setLastUpdated,
  setTableSettings,
  tasksReducerReset,
  setSelectedCheckbox,
  setIsOpenModalWindow,
  clearTaskTableSettings,
} = tasksSlice.actions
export default tasksSlice.reducer
