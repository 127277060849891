export const getAccountingCategoryLoading = (state) =>
  state.accountingCategory.isLoading === 'getAccountingCategory'

export const lastUpdatedAccountingCategory = (state) =>
  state.accountingCategory.lastUpdated
export const accountingCategoriesSelector = (state) =>
  state.accountingCategory.list
export const storeAccountingCategoriesSelector = (state) =>
  state.accountingCategory.list.filter(({isStoreCategory}) => isStoreCategory)
export const companyAccountingCategoriesSelector = (state) =>
  state.accountingCategory.list.filter(({isStoreCategory}) => !isStoreCategory)
export const accountingTotalCount = (state) =>
  state.accountingCategory.totalCount
export const accountingCategoryTableSettings = (state) =>
  state.accountingCategory.tableSettings
export const selectedAccountingCategory = (state) =>
  state.accountingCategory.selectedAccountingCategory
export const searchValueAccountingCategory = (state) =>
  state.accountingCategory.tableSettings.f_title
export const isLoadingSelectedAccountingCategory = (state) =>
  state.accountingCategory.isLoading === 'getAccountingCategoryById'
export const isLoadingAddAccountingCategory = (state) =>
  state.accountingCategory.isLoading === 'setAccountingCategory'
export const isLoadingUpdateAccountingCategory = (state) =>
  state.accountingCategory.isLoading === 'updateAccountingCategory'
export const selectedInTable = (state) =>
  state.accountingCategory.selectedAccountingCategories
