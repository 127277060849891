import './i18n'
import {Navigate, Route, Routes} from 'react-router-dom'
import './App.scss'
import {ConfigProvider} from 'antd'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Layout} from './components'
import {
  AccountingCategoryPage,
  AccountingPage,
  AddNormPage,
  ArrivalPage,
  CategoryPage,
  CreateOrderPage,
  DashboardPage,
  DetailPricePage,
  EditOrderPage,
  InventoryPage,
  MovementAddPage,
  MovementsPage,
  NormPage,
  NotificationPage,
  OrdersListPage,
  PlanningAddPage,
  PlanningPage,
  PreparationDepartmentPage,
  PricePage,
  ProductAddPage,
  ProductsPage,
  ProfitsPage,
  PurchasePage,
  RawMaterialsPage,
  RefillAddPage,
  StoreBalanceEdit,
  StoreBalancePage,
  StorePage,
  SupplierPage,
  SupplyAdd,
  SupplyPage,
  TasksPage,
  UnitsPage,
  UsersPage,
  WriteOffConfirm,
  WriteOffPage,
} from './pages/main'

import WithNavigationPathName from './HOCs/WithNavigationPathName'
import InterceptorsProvider from './HOCs/InterceptorsProvider'
import PrivateOutlet from './HOCs/PrivateOutlet'
import SnackbarOutlet from './HOCs/SnackbarOutlet'
import {localizationAntdComponent} from './utils/helpers'
import {productType} from './constants'
import WithCheckNotification from './HOCs/WithCheckNotification'
import {LoginPage} from './pages/auth'
import useCommon from './services/commonService'
import useLanguageInUrl from './hooks/useLanguageInUrl'
import IncasationPage from './pages/main/incasations'
import ShiftsPage from './pages/main/shift'
import 'moment/locale/ru'
import 'moment/locale/uk'
import 'moment/locale/en-gb'

function App() {
  const language = useSelector((state) => state.common.language)

  const {changeLanguage} = useCommon()

  const {languagePath} = useLanguageInUrl()

  useEffect(() => {
    changeLanguage(languagePath)
  }, [])

  return (
    <ConfigProvider locale={localizationAntdComponent(language)}>
      <Routes>
        <Route element={<WithCheckNotification />}>
          <Route element={<SnackbarOutlet />}>
            <Route element={<InterceptorsProvider />}>
              <Route element={<WithNavigationPathName />}>
                <Route element={<PrivateOutlet />}>
                  <Route element={<Layout />}>
                    <Route
                      path="/"
                      element={<Navigate to={`${languagePath}/`} />}
                    />

                    <Route
                      path={`${languagePath}/`}
                      element={<DashboardPage />}
                    />

                    <Route
                      path={`${languagePath}/units`}
                      element={<UnitsPage />}
                    />

                    <Route
                      path={`${languagePath}/write-off-reason`}
                      element={<WriteOffPage />}
                    />

                    <Route
                      path={`${languagePath}/stores`}
                      element={<StorePage />}
                    />
                    <Route
                      path={`${languagePath}/users`}
                      element={<UsersPage />}
                    />
                    <Route
                      path={`${languagePath}/raw-materials`}
                      element={<RawMaterialsPage />}
                    />
                    <Route
                      path={`${languagePath}/suppliers`}
                      element={<SupplierPage />}
                    />
                    <Route
                      path={`${languagePath}/product/add`}
                      element={<ProductAddPage />}
                    />
                    <Route
                      path={`${languagePath}/semi-finished/add`}
                      element={<ProductAddPage />}
                    />
                    <Route
                      path={`${languagePath}/product/edit/:productId`}
                      element={<ProductAddPage />}
                    />
                    <Route
                      path={`${languagePath}/semi-finished/edit/:productId`}
                      element={<ProductAddPage />}
                    />
                    <Route
                      path={`${languagePath}/categories`}
                      element={<CategoryPage />}
                    />
                    <Route
                      path={`${languagePath}/products`}
                      element={<ProductsPage type={productType.final} />}
                    />
                    <Route
                      path={`${languagePath}/semi-finished`}
                      element={<ProductsPage type={productType.semi} />}
                    />
                    <Route
                      path={`${languagePath}/supplies`}
                      element={<SupplyPage />}
                    />
                    <Route
                      path={`${languagePath}/supplies/add`}
                      element={<SupplyAdd />}
                    />
                    <Route
                      element={<PricePage />}
                      path={`${languagePath}/prices`}
                    />
                    <Route
                      path={`${languagePath}/storeBalance`}
                      element={<StoreBalancePage />}
                    />
                    <Route
                      path={`${languagePath}/storeBalance/edit`}
                      element={<StoreBalanceEdit />}
                    />
                    <Route
                      path={`${languagePath}/messageNotifications`}
                      element={<NotificationPage />}
                    />
                    <Route
                      path={`${languagePath}/refill/add`}
                      element={<RefillAddPage />}
                    />
                    <Route
                      path={`${languagePath}/refill/edit/:refillId`}
                      element={<RefillAddPage />}
                    />
                    <Route
                      path={`${languagePath}/movements/add`}
                      element={<MovementAddPage />}
                    />
                    <Route
                      path={`${languagePath}/movements/edit/:movementId`}
                      element={<MovementAddPage />}
                    />
                    <Route
                      path={`${languagePath}/refill`}
                      element={<ArrivalPage />}
                    />
                    <Route
                      path={`${languagePath}/movements`}
                      element={<MovementsPage />}
                    />
                    <Route
                      path={`${languagePath}/priceDetail`}
                      element={<DetailPricePage />}
                    />
                    <Route
                      path={`${languagePath}/inventory`}
                      element={<InventoryPage />}
                    />
                    <Route
                      path={`${languagePath}/incasation`}
                      element={<IncasationPage />}
                    />
                    <Route
                      path={`${languagePath}/shift`}
                      element={<ShiftsPage />}
                    />
                    <Route
                      path={`${languagePath}/writeOff/confirm`}
                      element={<WriteOffConfirm />}
                    />
                    <Route
                      path={`${languagePath}/accounting`}
                      element={<AccountingPage />}
                    />
                    <Route
                      path={`${languagePath}/purchase`}
                      element={<PurchasePage />}
                    />
                    <Route
                      path={`${languagePath}/orders`}
                      element={<OrdersListPage />}
                    />
                    <Route
                      path={`${languagePath}/orders/add`}
                      element={<CreateOrderPage />}
                    />
                    <Route
                      path={`${languagePath}/orders/edit/:id`}
                      element={<EditOrderPage />}
                    />
                    <Route
                      path={`${languagePath}/norm`}
                      element={<NormPage />}
                    />
                    <Route
                      path={`${languagePath}/planning`}
                      element={<PlanningPage />}
                    />
                    <Route
                      path={`${languagePath}/planning/add`}
                      element={<PlanningAddPage />}
                    />
                    <Route
                      path={`${languagePath}/norm/add`}
                      element={<AddNormPage />}
                    />
                    <Route
                      path={`${languagePath}/norm/:id`}
                      element={<AddNormPage />}
                    />
                    <Route
                      path={`${languagePath}/accounting-category`}
                      element={<AccountingCategoryPage />}
                    />
                    <Route
                      path={`${languagePath}/preparation-department`}
                      element={<PreparationDepartmentPage />}
                    />
                    <Route
                      path={`${languagePath}/tasks`}
                      element={<TasksPage />}
                    />
                    <Route
                      path={`${languagePath}/profit`}
                      element={<ProfitsPage />}
                    />
                  </Route>
                </Route>
                <Route path={`${languagePath}/login`} element={<LoginPage />} />
                <Route
                  path="*"
                  element={<Navigate to={`${languagePath}/`} replace />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </ConfigProvider>
  )
}

export default App
