import {Tooltip} from 'antd'

function ToolTipComponent({placement, title, children, ...rest}) {
  return (
    <Tooltip placement={placement} title={title} {...rest}>
      {children}
    </Tooltip>
  )
}

export default ToolTipComponent
