import {useEffect} from 'react'

import {Outlet, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import useNotification from '../services/notifications/notificationService'
import useLanguageInUrl from '../hooks/useLanguageInUrl'

function WithCheckNotification() {
  const {pathname} = useLocation()
  const {getCountNotifications, getNotificationData} = useNotification()
  const notificationTableSettings = useSelector(
    (state) => state.messageNotifications.tableSettings
  )
  const {languagePath} = useLanguageInUrl()
  const notificationsLastUpdated = useSelector(
    (state) => state.messageNotifications.lastUpdated
  )

  useEffect(() => {
    if (
      pathname !== '/login' &&
      pathname !== `/${languagePath}/messageNotifications`
    ) {
      getCountNotifications()
      getNotificationData(notificationTableSettings)
    }
  }, [pathname, notificationsLastUpdated])

  return <Outlet />
}

export default WithCheckNotification
