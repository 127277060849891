import {t} from 'i18next'
import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import './style.scss'
import currency from 'currency.js'
import AutoCompleteComponent from '../../components/antd-components/form/form-select-auto-complete/AutoCompleteComponent'
import useTableRenderItems from '../useTableRenderItems'
import {
  convertToBooleanByLength,
  currencyDivide,
  currencyDivideWithRounding,
  currencyMultiplyWithRounding,
  restrictInputValueAfterDot,
  restrictPriceValueAfterDot,
} from '../../utils/helpers'
import {InputForm} from '../../components/antd-components/form'
import {rawMaterialsList} from '../../redux/selectors/rawMaterialsSelector'
import {unitsList} from '../../redux/selectors/unitSelector'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'

const useRawSuppliesTableAntd = (props) => {
  const {
    handleEdit,
    handleDelete,
    selectedRecordId,
    setSelectedRecordId,
    setPriceOneConfig,
    setAmountConfig,
    setTotalPriceConfig,
    priceOneConfig,
    totalPriceConfig,
    amountConfig,
    form,
    isEdit,
    rawId,
    rawsList = [],
    setIsEdit,
    rules,
    setRawId,
    newRawMaterial,
    selectedInput,
    setSelectedInput,
    getPriceOneByForm,
    getTotalPricedByForm,
    getAmountByForm,
    getDetailInModalWindow,
  } = props
  const rawMaterials = useSelector(rawMaterialsList)
  const units = useSelector(unitsList)
  const isEmptyAmountValue = convertToBooleanByLength(getAmountByForm, 0)
  const isEmptyPriceOneValue = convertToBooleanByLength(getPriceOneByForm, 0)
  const isEmptyTotalPriceValue = convertToBooleanByLength(
    getTotalPricedByForm,
    0
  )
  const findUnit = (id) => {
    const rawMaterial = rawMaterials.find((item) => item.id === id)
    return !rawMaterial ? rawMaterial : rawMaterial?.unitId
  }
  const unit = rawsList?.map((item) => item?.rawId)
  const {renderNameById} = useTableRenderItems()

  const amountWithPriceOne =
    isEdit && isEmptyAmountValue && isEmptyPriceOneValue
  const amountWithTotalPrice =
    isEdit && isEmptyPriceOneValue && isEmptyTotalPriceValue
  const priceOneWithTotalPrice =
    isEdit && isEmptyPriceOneValue && isEmptyTotalPriceValue

  const isAllInputsFull = (record) =>
    record.priceTotal !== '' &&
    Number(record.priceTotal) !== 0 &&
    record.priceOne !== '' &&
    Number(record.priceOne) !== 0 &&
    record.amount !== '' &&
    Number(record.amount) !== 0

  const divideTotalPriceByPriceOne = restrictInputValueAfterDot(
    currencyDivide(Number(getTotalPricedByForm), Number(getPriceOneByForm), 6)
  )

  const divideTotalPriceByAmount = restrictPriceValueAfterDot(
    currencyDivideWithRounding(
      Number(getTotalPricedByForm),
      Number(getAmountByForm),
      6,
      6
    )
  ).toString()

  const multiplyAmountByPriceOne = restrictPriceValueAfterDot(
    currencyMultiplyWithRounding(
      Number(getAmountByForm),
      Number(getPriceOneByForm),
      6,
      6
    ).toString()
  )

  const changeAmountConfig = (isActive, value = null) => {
    const amountValue = value || amountConfig
    setAmountConfig({...amountValue, isActive})
  }
  const changePriceOneConfig = (isActive, value = null) => {
    const priceOneValue = value || priceOneConfig
    setPriceOneConfig({...priceOneValue, isActive})
  }
  const changeTotalPriceConfig = (isActive, value = null) => {
    const priceTotalValue = value || totalPriceConfig
    setTotalPriceConfig({...priceTotalValue, isActive})
  }
  const changeSelectedInputPriceTotal = (value = 'priceTotal') => {
    setSelectedInput({...selectedInput, priceTotalField: value})
  }
  const changeSelectedInputPriceOne = (value = 'priceOne') => {
    setSelectedInput({...selectedInput, priceOneField: value})
  }
  const changeSelectedInputAmount = (value = 'amount') => {
    setSelectedInput({...selectedInput, amountField: value})
  }

  const handleChangeRawInSupplies = (value = '') => {
    handleEdit({
      amount: currency(getAmountByForm, {precision: 6}),
      priceTotal: getTotalPricedByForm,
      priceOne: getPriceOneByForm,
      id: selectedRecordId,
      calculationTarget: value,
    })
  }

  const setValueInSelectedInput = (selectedValue, action) => {
    if (selectedValue) {
      return action()
    }
    return action
  }

  const isSetEmptyValueToTotalPrice = isEmptyAmountValue || isEmptyPriceOneValue

  const isSetEmptyValueToAmount = isEmptyTotalPriceValue || isEmptyPriceOneValue

  const isSetEmptyValueToPriceOne = isEmptyAmountValue || isEmptyTotalPriceValue

  const setAmountFieldsValue = (value = null) => {
    const finalValue = isSetEmptyValueToAmount ? '' : value
    handleChangeRawInSupplies('amount')
    form.setFieldsValue({
      [`amount${selectedRecordId}`]: finalValue,
    })
  }

  const setPriceOneFieldsValue = (value = null) => {
    const finalValue = isSetEmptyValueToPriceOne ? '' : value
    handleChangeRawInSupplies('priceOne')
    form.setFieldsValue({
      [`priceOne${selectedRecordId}`]: finalValue,
    })
  }

  const setPriceTotalFieldsValue = (value = null) => {
    const finalValue = isSetEmptyValueToTotalPrice ? '' : value
    handleChangeRawInSupplies('priceTotal')
    form.setFieldsValue({
      [`priceTotal${selectedRecordId}`]: finalValue,
    })
  }

  useEffect(() => {
    form.setFieldsValue({
      [`rawId${rawId}`]: newRawMaterial?.id,
    })
    setRawId(null)
  }, [rawId])

  useEffect(() => {
    if (getPriceOneByForm || getAmountByForm || getTotalPricedByForm) {
      handleEdit({
        amount: currency(getAmountByForm, {precision: 6}),
        priceTotal: getTotalPricedByForm,
        priceOne: getPriceOneByForm,
        id: selectedRecordId,
      })
    }
  }, [getPriceOneByForm, getAmountByForm, getTotalPricedByForm])

  useEffect(() => {
    if (getPriceOneByForm && getAmountByForm && getTotalPricedByForm) {
      setIsEdit(true)
    }
  }, [selectedRecordId])

  useEffect(() => {
    if (amountWithPriceOne || priceOneWithTotalPrice || amountWithTotalPrice) {
      if (amountWithPriceOne) {
        setIsEdit(false)
        setValueInSelectedInput(
          Number(getTotalPricedByForm) !== 0,
          changeSelectedInputAmount
        )
      }
      if (priceOneWithTotalPrice) {
        setIsEdit(false)
        setValueInSelectedInput(
          Number(getAmountByForm) !== 0,
          changeSelectedInputAmount
        )
      }
      if (amountWithTotalPrice) {
        setIsEdit(false)
        setValueInSelectedInput(
          Number(getPriceOneByForm) !== 0,
          changeSelectedInputPriceOne
        )
      }
    } else if (!isEdit) {
      if (
        selectedInput.amountField &&
        selectedInput.priceTotalField &&
        !selectedInput.priceOneField
      ) {
        setPriceOneFieldsValue(divideTotalPriceByAmount)
      }
      if (
        selectedInput.priceOneField &&
        selectedInput.amountField &&
        !selectedInput.priceTotalField
      ) {
        setPriceTotalFieldsValue(multiplyAmountByPriceOne)
      }
      if (
        selectedInput.priceOneField &&
        selectedInput.priceTotalField &&
        !selectedInput.amountField
      ) {
        setAmountFieldsValue(divideTotalPriceByPriceOne)
      }
    } else {
      if (priceOneConfig.isActive) {
        changePriceOneConfig(false)
        changeSelectedInputAmount('')
        setPriceTotalFieldsValue(multiplyAmountByPriceOne)
      }
      if (amountConfig.isActive) {
        changeAmountConfig(false)
        changeSelectedInputPriceTotal('')
        setPriceTotalFieldsValue(multiplyAmountByPriceOne)
      }
      if (totalPriceConfig.isActive) {
        changeTotalPriceConfig(false)
        changeSelectedInputPriceOne('')
        setAmountFieldsValue(divideTotalPriceByPriceOne)
      }
    }
  }, [getTotalPricedByForm, getAmountByForm, getPriceOneByForm])

  const onChangeAmount = () => {
    changeAmountConfig(true)
  }

  const onChangePriceOne = () => {
    changeSelectedInputPriceOne()

    changePriceOneConfig(true)
  }

  const onChangeTotalPrice = () => {
    changeSelectedInputPriceTotal()

    changeTotalPriceConfig(true)
  }

  const setUnit = (id) => {
    handleEdit({
      amount: getAmountByForm,
      priceTotal: getTotalPricedByForm,
      priceOne: getPriceOneByForm,
      rawId: id,
      id: selectedRecordId,
    })
  }

  const onFocusInputAmount = (record) => {
    setSelectedRecordId(record.id)
    changeSelectedInputAmount()
    if (record.priceOne !== '' && Number(record.priceOne) !== 0) {
      setSelectedInput({
        ...selectedInput,
        priceOneField: 'priceOne',
        priceTotalField: '',
        amountField: 'amount',
      })
    } else if (record.priceTotal !== '' && Number(record.priceTotal) !== 0) {
      setSelectedInput({
        ...selectedInput,
        priceTotalField: 'priceTotal',
        priceOneField: '',
        amountField: 'amount',
      })
    }
    if (isAllInputsFull(record)) {
      setIsEdit(true)
    }
    changeAmountConfig(true)
  }

  const onFocusInputPriceOne = (record) => {
    changePriceOneConfig(true)
    if (record.amount !== '' && Number(record.amount) !== 0) {
      setSelectedInput({
        ...selectedInput,
        priceOneField: 'priceOne',
        priceTotalField: '',
        amountField: 'amount',
      })
    } else if (record.priceTotal !== '' && Number(record.priceTotal) !== 0) {
      setSelectedInput({
        ...selectedInput,
        priceTotalField: 'priceTotal',
        priceOneField: 'priceOne',
        amountField: '',
      })
    }
    if (isAllInputsFull(record)) {
      setIsEdit(true)
    }
    setSelectedRecordId(record.id)
  }

  const onFocusInputTotalPrice = (record) => {
    changeTotalPriceConfig(true)

    if (Number(record.priceOne) !== 0) {
      setSelectedInput({
        ...selectedInput,
        priceOneField: 'priceOne',
        priceTotalField: 'priceTotal',
        amountField: '',
      })
    }
    if (Number(record.amount) !== 0) {
      setSelectedInput({
        ...selectedInput,
        priceTotalField: 'priceTotal',
        priceOneField: '',
        amountField: 'amount',
      })
    }
    if (isAllInputsFull(record)) {
      setIsEdit(true)
    }
    setSelectedRecordId(record.id)
  }

  const getColumns = [
    {
      title: t('Raw'),
      dataIndex: 'rawId',
      editable: true,
      align: 'center',
      fixed: 'left',
      width: '23%',
      render: (value, record) => (
        <AutoCompleteComponent
          options={rawMaterials}
          allowClear={false}
          name={`rawId${record.id}`}
          placeholder={t('Raw')}
          disabledId={rawsList.map((item) => item.rawId)}
          initialValue={record.rawId}
          onFocus={() => setSelectedRecordId(record.id)}
          classNameForm="form_style"
          onChange={(e) => {
            setUnit(e)
            // setIsEmptyRaw(false)
          }}
          rules={rules}
        />
      ),
    },

    {
      title: t('Amount'),
      dataIndex: 'amount',
      editable: true,
      align: 'center',
      width: '23%',
      render: (value, record) => (
        <InputForm
          onFocus={() => {
            onFocusInputAmount(record)
          }}
          onBlur={() => {
            changeAmountConfig(false)
          }}
          name={`amount${record.id}`}
          rules={rules}
          type="number"
          onWheel={(e) => e.target.blur()}
          className="form_style input content_center"
          initialValue={value ? currency(value, {precision: 6}).value : value}
          formClassName="form_style"
          normalize={restrictInputValueAfterDot}
          addonAfter={renderNameById(
            {id: findUnit(record.rawId || unit)},
            units
          )}
          onChange={() => onChangeAmount()}
        />
      ),
    },

    {
      title: t('Price One'),
      dataIndex: 'priceOne',
      editable: true,
      align: 'center',
      width: '23%',
      render: (value, record) => (
        <InputForm
          onFocus={() => onFocusInputPriceOne(record)}
          onBlur={() => {
            changePriceOneConfig(false)
          }}
          onWheel={(e) => e.target.blur()}
          name={`priceOne${record.id}`}
          rules={rules}
          type="number"
          formClassName="form_style"
          normalize={restrictPriceValueAfterDot}
          className="form_style input content_center"
          initialValue={value}
          onChange={() => onChangePriceOne()}
        />
      ),
    },
    {
      title: t('Total price'),
      dataIndex: 'priceTotal',
      align: 'center',
      width: '23%',
      render: (value, record) => (
        <InputForm
          onFocus={() => {
            onFocusInputTotalPrice(record)
          }}
          onBlur={() => {
            changeTotalPriceConfig(false)
          }}
          onWheel={(e) => e.target.blur()}
          name={`priceTotal${record.id}`}
          rules={rules}
          type="number"
          normalize={restrictPriceValueAfterDot}
          className="form_style input content_center"
          initialValue={value}
          formClassName="form_style"
          onChange={() => onChangeTotalPrice()}
        />
      ),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align: 'center',
      width: '12%',
      render: (_, record) => (
        <div>
          <DeleteActionButton
            onClick={() => {
              handleDelete(record.id)
            }}
          />
          <DetailsActionButton
            onClick={() => {
              getDetailInModalWindow(record)
            }}
          />
        </div>
      ),
    },
  ]
  return {rawSuppliesColumnsAntd: getColumns}
}

export default useRawSuppliesTableAntd
