import {Dropdown} from 'antd'

function DropDownMenu({items, children}) {
  return (
    <Dropdown menu={{items}} trigger={['click']}>
      {children}
    </Dropdown>
  )
}

export default DropDownMenu
