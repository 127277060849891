import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Col, Form, Row} from 'antd'
import {InputForm} from '../../../../components/antd-components/form'
import {
  ButtonDanger,
  ButtonDefault,
} from '../../../../components/antd-components/button'
import {formProps} from '../../../../constants'
import PreparationDepartmentValidation from '../validation/PreparationDepartmentValidation'
import {isLoadingUpdatePreparationDepartment} from '../../../../redux/selectors/preparationDepartmentSelector'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function PreparationDepartmentForm({
  formData = {
    id: null,
    title: '',
  },
  form,
  onClose,
  handleSubmit,
  text,
}) {
  const {id} = formData
  const isLoadingUpdate = useSelector(isLoadingUpdatePreparationDepartment)
  const {modalProps} = formProps
  const {t} = useTranslation()

  useEffect(() => {
    if (id) {
      form.setFieldsValue(formData)
      if (formData.credit) {
        form.setFieldsValue({credit: 'expenses'})
      } else {
        form.setFieldsValue({credit: 'income'})
      }
    }
  }, [id])

  const schema = PreparationDepartmentValidation()

  const onSubmit = async (values) => {
    const {title} = values

    await handleSubmit({title, id})
  }
  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
  }

  return (
    <Form form={form} onFinish={onSubmit} {...layout}>
      <Row gutter={24}>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            placeholder={t('Name')}
            label={t('Name')}
            name="title"
            rules={{...schema, required: true}}
          />
        </Col>

        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>
          <Col className="button">
            <SaveButton
              htmlType="submit"
              loading={formData?.id ? isLoadingUpdate : false}
            />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default PreparationDepartmentForm
