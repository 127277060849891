import {useDispatch} from 'react-redux'

import {useTranslation} from 'react-i18next'
import {
  deleteRefill,
  downloadExcel,
  getRefill,
  getRefills,
  setRefill,
  updateRefill,
} from '../../redux/asyncServices/refillService'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import {
  clearRefillTableSettings,
  resetSelectedRefill,
  setLastUpdated,
  setSelectedId,
  setSelectedRefill,
  setTableSettings,
} from '../../redux/slices/refillSlice'

import notify from '../../components/antd-components/notification/notify'

function useRefill() {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const successNotify = (description) => {
    notify({
      description: t(description),
      type: 'success',
    })
    navigate('/refill')
  }

  const refillLastUpdated = () => dispatch(setLastUpdated(Date.now()))
  const getRefillsData = (params) => dispatch(getRefills({params}))
  const getRefillById = ({id}) => dispatch(getRefill({id}))
  const addRefill = async (data) => {
    await dispatch(setRefill(data)).then((response) => {
      if (response.payload) {
        notify({
          description: t('Success add refill'),
          type: 'success',
        })
        navigate(`/refill`)
        refillLastUpdated()
      }
    })
  }

  const updateRefillData = async (data) => {
    await dispatch(updateRefill(data)).then((response) => {
      if (response.payload) {
        navigate(`/refill`)
        refillLastUpdated()
      }
    })
  }
  const selectedRefill = (data) => dispatch(setSelectedRefill(data))

  const setRefillTableSettings = (tableSettings = {}) => {
    dispatch(setTableSettings({tableSettings}))
    refillLastUpdated()
  }

  const getExcelReport = (id) => {
    dispatch(downloadExcel(id))
  }

  const refillDelete = (id, storeName) => {
    dispatch(deleteRefill(id)).then((response) => {
      if (response.payload) {
        successNotify(
          `${t('The refill')} ${id} ${t('to the store')} ${storeName} ${t(
            'has been removed successfully'
          )}`
        )
      }
    })
  }
  const setSelectedRefillId = (id) => {
    dispatch(setSelectedId(id))
  }

  const resetSelectedRefillData = () => {
    dispatch(resetSelectedRefill())
  }
  const resetRefillTableSettings = () => dispatch(clearRefillTableSettings())

  return {
    getRefillById,
    getRefillsData,
    addRefill,
    updateRefillData,
    selectedRefill,
    setRefillTableSettings,
    getExcelReport,
    setSelectedRefillId,
    refillDelete,
    resetSelectedRefillData,
    resetRefillTableSettings,
  }
}

export default useRefill
