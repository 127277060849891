import {createSlice} from '@reduxjs/toolkit'
import {
  getCountNotification,
  getNotification,
  updateReadAllNotifications,
  updateReadNotification,
} from '../asyncServices/notificationMessageService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  lastUpdated: null,
  notificationMessage: [],
  tableSettings: {
    f_read: ['unread'],
    s_direction: 'ASC',
    f_startDate: null,
    f_endDate: null,
    pageSize: 100,
    s_field: 'name',
    page: 1,
    f_type: null,
  },
  selectedNotificationId: null,
  count: 0,
  isLoading: null,
}

const notificationMessageSlice = createSlice({
  name: 'notificationMessageSlice',
  initialState,
  reducers: {
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setSelectedNotificationId: (state, action) => {
      state.selectedNotificationId = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload.tableSettings,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotification.pending, (state) => {
      state.isLoading = 'getNotifications'
    })
    builder.addCase(getNotification.fulfilled, (state, action) => {
      state.notificationMessage = action?.payload?.notifications
      state.totalCount = action?.payload?.totalCount
      state.isLoading = null
    })
    builder.addCase(updateReadNotification.pending, (state) => {
      state.isLoading = 'updateReadNotification'
    })
    builder.addCase(updateReadNotification.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(updateReadAllNotifications.pending, (state) => {
      state.isLoading = 'updateReadAllNotifications'
    })
    builder.addCase(updateReadAllNotifications.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(getCountNotification.pending, (state) => {
      state.isLoading = 'getCountNotification'
    })
    builder.addCase(getCountNotification.fulfilled, (state, action) => {
      state.count = action?.payload?.totalCount
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {setLastUpdated, setTableSettings, setSelectedNotificationId} =
  notificationMessageSlice.actions
export default notificationMessageSlice.reducer
