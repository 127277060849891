import ImageComponent from '../ImageComponent'

export default function IsCardIconIndicator({isCash}) {
  return isCash ? (
    <span>
      <ImageComponent
        src="/cashIcon.png"
        height={40}
        width={45}
        preview={false}
      />
    </span>
  ) : (
    <span>
      <ImageComponent
        src="/masterCardIcon.png"
        height={40}
        width={45}
        preview={false}
      />
    </span>
  )
}
