import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {ExclamationCircleOutlined} from '../../../../components/antd-components/icon'

import StoreForm from './StoreForm'
import useStoreTableAntd from '../../../../services/storeServices/storeTableService'

import '../style.scss'
import showConfirm from '../../../../components/PopupConfirm'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'
import {
  isOpenEditModalWindow,
  isOpenModalWindow,
  selectedId,
} from '../../../../redux/selectors/storeSelector'

function StoreBody({
  deleteStoresData,
  getStoreById,
  selectStores,
  setStoreTableSettings,
  changeIsOpenModalWindow,
  changeIsOpenEditModalWindow,
  form,
  changeSelectedId,
}) {
  const {t} = useTranslation()

  const stores = useSelector((state) => state.stores.stores)

  const isLoading = useSelector(
    (state) => state.stores.isLoading === 'getStores'
  )
  const selectedStore = useSelector((state) => state.stores.selectedStore)
  const storeTotalCount = useSelector((state) => state.stores.totalCount)
  const storeTableSettings = useSelector((state) => state.stores.tableSettings)
  const selectedStores = useSelector((state) => state.stores.selectedStores)

  const isModalWindowStatus = useSelector(isOpenModalWindow)
  const isModalEditWindowStatus = useSelector(isOpenEditModalWindow)
  const selectedIdValue = useSelector(selectedId)
  const handleDeleteSubmit = (id) => {
    deleteStoresData({ids: [id || selectedStore?.id]})
  }

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleDeleteSubmit(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const onEdit = (id) => {
    changeSelectedId(id)
    changeIsOpenEditModalWindow(true)
  }

  const handleEditClose = () => {
    changeSelectedId(null)
  }

  const handleAddClose = () => {
    changeIsOpenModalWindow(false)
    form.resetFields()
  }

  useEffect(() => {
    if (selectedStore !== selectedIdValue) {
      getStoreById({id: selectedIdValue})
    }
  }, [selectedIdValue])

  const {storeAntdColumns} = useStoreTableAntd({
    confirmDelete,
    onEdit,
    storeTableSettings,
  })

  return (
    <div>
      {selectedStore?.id && (
        <AntdTableActionModal
          title={t('Update store')}
          handleCancel={handleEditClose}
          open={isModalEditWindowStatus}
        >
          <StoreForm
            onClose={handleEditClose}
            formData={selectedStore}
            text={t('Update')}
            form={form}
            isModalEditWindowStatus={isModalEditWindowStatus}
          />
        </AntdTableActionModal>
      )}
      <AntdTableActionModal
        title={t('Add store')}
        handleCancel={handleAddClose}
        open={isModalWindowStatus}
      >
        <StoreForm
          onClose={handleAddClose}
          text={t('Add')}
          form={form}
          isModalEditWindowStatus={null}
        />
      </AntdTableActionModal>
      <AppTable
        columns={storeAntdColumns}
        dataSource={stores}
        showSelectRowCheckbox
        setSelectedComponent={selectStores}
        setTableSettings={setStoreTableSettings}
        isLoading={isLoading}
        tableSettings={storeTableSettings}
        totalCount={storeTotalCount}
        selectedRowKeys={selectedStores}
      />
    </div>
  )
}

export default StoreBody
