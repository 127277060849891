import {Col, Grid, PageHeader, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import currency from 'currency.js'
import {useState} from 'react'
import useIngredientsPriceDetailTable from '../../../../../services/price/price-detail/IngredientTablePriceDetailService'
import useSemiFinishedPriceDetailTable from '../../../../../services/price/price-detail/SemiFinishedPriceDetailTableService'
import {convertToFractionalNumber} from '../../../../../utils/helpers'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../../components/antd-components/app-table'
import HistorySupplyBody from '../../../supplies/history-supplies/HistorySupplyBody'
import useSuppliesHistoryByIngredient from '../../../../../services/supllies/historySuppliesByIngredient/historySuppliesByIngredient'

import './style.scss'

const {useBreakpoint} = Grid

function DetailsPriceBody({detailPriceInformation}) {
  const {setSelectedIngredientId, clearIngredientState} =
    useSuppliesHistoryByIngredient()
  const {t} = useTranslation()
  const isLoading = useSelector(
    (state) => state.prices.isLoading === 'getDetailInformation'
  )
  const [isOpenModalWindow, setIsOpenModalWindow] = useState(false)
  const [recordName, setRecordName] = useState('false')
  const screens = useBreakpoint()
  const ingredientTotalPrice = () =>
    detailPriceInformation?.ingredients
      ?.map((item) =>
        currency(item?.ingredient?.priceMax, {precision: 6}).multiply(
          currency(item?.gross, {precision: 6})
        )
      )
      .reduce(
        (prev, curr) => currency(prev, {precision: 6}).add(curr).value,
        0
      ) || 0

  const semiTotalPrice = () =>
    detailPriceInformation?.semiFinished
      ?.map(
        (item) =>
          currency(item?.semiFinish?.priceMax, {precision: 6}).multiply(
            currency(item?.amount, {precision: 6})
          ).value
      )
      .reduce(
        (prev, curr) => currency(prev, {precision: 6}).add(curr).value,
        0
      ) || 0

  const footerSemiText = (totalPrice) => (
    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
      <p style={{fontWeight: 'bold'}}>{`${t(
        'Total price'
      )} ${convertToFractionalNumber(totalPrice, 2)}`}</p>
    </div>
  )

  const getDetailInModalWindow = (record) => {
    setIsOpenModalWindow(true)
    setRecordName(record.ingredient?.name)
    setSelectedIngredientId(record.ingredient?.id)
  }

  const onCloseModalWindow = () => {
    setIsOpenModalWindow(false)
    clearIngredientState()
  }

  const {ingredientsColumns} = useIngredientsPriceDetailTable({
    getDetailInModalWindow,
  })
  const {semiFinishedColumns} = useSemiFinishedPriceDetailTable()
  return (
    <div className="details-price-body_wrapper">
      <Row>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <PageHeader className="site-page-header" title={t('Semi-finished')} />
          <div className="table-wrapper">
            <AppTable
              dataSource={detailPriceInformation?.semiFinished}
              columns={semiFinishedColumns}
              loading={isLoading}
              rowKey="id"
              className="hideScroll"
              style={null}
              scroll={{
                y:
                  screens.md && screens.lg && screens.sm && !screens.xl
                    ? 'calc(100vh - 55em)'
                    : 'calc(100vh - 28em)',
                x: 500,
              }}
              footer={() => footerSemiText(semiTotalPrice())}
              pagination={false}
            />
          </div>
        </Col>

        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <PageHeader className="site-page-header" title={t('Ingredients')} />

          <AntdTableActionModal
            title={`${t('History supplies')} ${':'} ${recordName}`}
            handleCancel={onCloseModalWindow}
            open={isOpenModalWindow}
            className="price-modal-body modal"
            fullWidth
          >
            <HistorySupplyBody />
          </AntdTableActionModal>

          <div className="table-wrapper">
            <AppTable
              columns={ingredientsColumns}
              dataSource={detailPriceInformation?.ingredients}
              loading={isLoading}
              rowKey="id"
              footer={() => footerSemiText(ingredientTotalPrice())}
              className="hideScroll"
              style={null}
              scroll={{
                y:
                  screens.md && screens.lg && screens.sm && !screens.xl
                    ? 'calc(100vh - 55em)'
                    : 'calc(100vh - 28em)',
                x: 500,
              }}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default DetailsPriceBody
