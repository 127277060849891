import {useSelector} from 'react-redux'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'
import {PageHeaderWithMobileMenu} from '../../../../../../components'
import DownloadButton from '../../../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../../../services/commonService'
import {profitsTableSettingsSelector} from '../../../../../../redux/selectors/profitsSelector'
import DateRangeFilter from '../../../../../../components/antd-components/dateRange-filter/DateRangeFilter'
import useProfitService from '../../../../../../services/profit/profitService'
import SelectFilter from '../../../../../../components/antd-components/select-filter/SelectFilter'
import {useWriteOff} from '../../../../../../services/write-off-reason/writeOffReasonsService'
import {writeOffReasonListSelector} from '../../../../../../redux/selectors/writeOffReasonSelector'
import {cropStringToFixedLength} from '../../../../../../utils/helpers'
import './style.scss'
import {profitInitialState} from '../../../../../../redux/slices/profitsSlice'
import ResetFiltersButton from '../../../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'

function ProfitsPageHeader() {
  const {exportExcel} = useCommon()
  const tableSettings = useSelector(profitsTableSettingsSelector)
  const {setProfitTableSettings, resetProfitTableSettings} = useProfitService()
  const {getWriteOffData} = useWriteOff()
  const writeOffReasonsList = useSelector(writeOffReasonListSelector)
  const [rangePickerValue, setRangePickerValue] = useState()

  useEffect(() => {
    getWriteOffData()
  }, [])

  const downloadButtonHandler = () => {
    const correctTableSettings = {
      ...tableSettings,
      f_fromDate: tableSettings.f_fromDate
        ? moment(tableSettings.f_fromDate).toISOString()
        : null,
      f_toDate: tableSettings.f_toDate
        ? moment(tableSettings.f_toDate).toISOString()
        : null,
    }
    exportExcel({
      tableName: 'reports/profits',
      tableSettings: correctTableSettings,
    })
  }

  const headerActions = [<DownloadButton onClick={downloadButtonHandler} />]

  const getDefaultRangeFilterValue = () => {
    const dateFrom = tableSettings.f_fromDate
      ? moment(tableSettings.f_fromDate)
      : null
    const dateTo = tableSettings.f_toDate
      ? moment(tableSettings.f_toDate)
      : null
    if (!dateFrom && !dateTo) {
      return null
    }
    return [dateFrom, dateTo]
  }

  const reasonFilterOptions = useMemo(() => {
    if (writeOffReasonsList?.length) {
      return writeOffReasonsList.map((reason) => ({
        value: reason.id,
        label: cropStringToFixedLength(reason.name, 45),
      }))
    }
    return []
  }, [writeOffReasonsList])

  const clearFiltersHandler = () => {
    resetProfitTableSettings()
    setRangePickerValue([
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ])
  }

  const SubTitle = (
    <div className="profit_subTitle">
      <ResetFiltersButton
        clearFiltersHandler={clearFiltersHandler}
        currentTableSettings={tableSettings}
        initialTableSettings={profitInitialState.tableSettings}
      />
      <DateRangeFilter
        tableSettings={tableSettings}
        defaultValue={getDefaultRangeFilterValue()}
        setTableSettings={setProfitTableSettings}
        fromDateName="f_fromDate"
        toDateName="f_toDate"
        rangePickerValue={rangePickerValue}
        setRangePickerValue={setRangePickerValue}
      />
      <SelectFilter
        value={tableSettings.f_reasonId}
        multiple
        setTableSettings={setProfitTableSettings}
        filterName="f_reasonId"
        options={reasonFilterOptions}
        placeholder="Reason write-of"
        maxTagTextLength={10}
        maxTagCount="responsive"
      />
    </div>
  )

  return (
    <PageHeaderWithMobileMenu
      className="profitPage_header"
      title="Profit"
      extra={headerActions}
      subTitle={SubTitle}
    />
  )
}
export default ProfitsPageHeader
