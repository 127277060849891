import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getAccounting = createAsyncThunk(
  'GET_ACCOUNTING',
  async ({params = {}}, {getState}) => {
    const state = getState()
    try {
      const response = await API.get(
        `/accounting/${
          state.accounting.tableSettings.isStoreAccounting ? 'store' : 'company'
        }`,
        {params}
      )
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      return {
        accounting: state.accounting.accounting,
        totalCount: state.accounting.totalCount,
      }
    }
    return null
  }
)

export const setAccounting = createAsyncThunk(
  'SET_COMPANY_ACCOUNTING',
  async (data) => {
    try {
      const response = await API.post('/accounting/company', data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const updateAccounting = createAsyncThunk(
  'UPDATE_COMPANY_ACCOUNTING',
  async (data) => {
    const {id} = data
    try {
      const response = await API.put(`/accounting/company/${id}`, data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)
