import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {Col, Form, Grid, Row} from 'antd'

import {PlusCircleFilled} from '@ant-design/icons'
import SuppliesValidation from '../validation/SuppliesValidation'
import variables from '../../../../globalStyle/colors.scss'
import {
  AutoCompleteComponent,
  FormDateTimePickerAntd,
  SwitchForm,
} from '../../../../components/antd-components/form'
import {ButtonDefault} from '../../../../components/antd-components/button'
import withAntdModal from '../../../../HOCs/withAntdModal'
import SupplierForm from '../../suppliers/components/SupplierForm'
import {formattedDate} from '../../../../constants'
import './style.scss'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

const {useBreakpoint} = Grid

function SupplyAddForm({
  formData = {
    id: null,
    raws: [],
    cardPayment: false,
    supplierId: '',
    date: null,
  },
  form,
  onSubmit,
  isActiveSendButton,
}) {
  const {id} = formData
  const {t} = useTranslation()
  const suppliers = useSelector((state) => state.suppliers.suppliers)
  const isLoading = useSelector(
    (state) => state.supplies.isLoading === 'setSupplies'
  )

  const screens = useBreakpoint()
  const suppliesValidation = SuppliesValidation()
  const newSupplier = useSelector((state) => state.suppliers.newSupplier)

  const AddCategoriesFormWithModal = withAntdModal(SupplierForm, {
    footer: null,
    type: 'link',
    title: t('Add supplier'),
    okButtonText: <PlusCircleFilled style={{fontSize: 25}} />,
    buttonProps: {
      style: {
        color: '#3F50B5',
        border: '1px solid yellow',
        width: '25px',
      },
    },
    tooltipTitle: t('Add category'),
  })

  useEffect(() => {
    if (id) {
      form.setFieldsValue(formData)
      form.setFieldsValue({
        date: moment(formData?.date),
      })
    }
  }, [id])

  useEffect(() => {
    if (newSupplier) {
      suppliers?.map((item) => {
        if (item.id === newSupplier.id) {
          form.setFieldsValue({supplierId: newSupplier.id})
        }
        return []
      })
    }
  }, [newSupplier, suppliers])
  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 24},
  }
  return (
    <Form form={form} onFinish={onSubmit} {...layout}>
      <Row gutter={[0, 8]} style={{display: 'flex', alignItems: 'flex-start'}}>
        <Col
          xs={24}
          lg={8}
          md={12}
          sm={12}
          xl={6}
          style={{
            display: screens.xs || screens.md || screens.sm ? 'flex' : 'block',
            justifyContent: 'center',
          }}
        >
          <Row gutter={[24, 24]}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Col span={20}>
                <AutoCompleteComponent
                  options={suppliers}
                  name="supplierId"
                  formStyle={{marginBottom: 0}}
                  style={{width: 190}}
                  label={<span />}
                  classNameForm="content form_label_after"
                  placeholder={t('Supplier')}
                  rules={suppliesValidation}
                />
              </Col>
              <Col span={9}>
                <AddCategoriesFormWithModal
                  text={t('Add')}
                  key={1}
                  createFromComponent
                />
              </Col>
            </div>
          </Row>
        </Col>

        <Col
          className="gutter-row"
          span={24}
          xs={24}
          sm={12}
          md={10}
          lg={8}
          xl={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <SwitchForm
            name="cardPayment"
            unCheckedChildren={t('Cash')}
            checkedChildren={t('Card')}
            style={{width: 100}}
            initialValue={false}
            size="large"
          />
        </Col>
        <Col
          style={{
            display: screens.xs || screens.md || screens.sm ? 'flex' : 'block',
            justifyContent: 'center',
          }}
          className="gutter-row"
          span={24}
          xs={24}
          lg={8}
          sm={12}
          md={12}
          xl={6}
        >
          <FormDateTimePickerAntd
            name="date"
            placeholder={t('Supply date')}
            rules={suppliesValidation}
            classNameForm="content form_label_after"
            label={<span />}
            showTime={{
              format: formattedDate.Time,
            }}
            format={formattedDate.DateWithTime}
          />
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          className="gutter-row"
          span={24}
          xs={24}
          lg={24}
          sm={12}
          md={10}
          xl={6}
        >
          <SaveButton
            disabled={isActiveSendButton}
            loading={isLoading}
            htmlType="submit"
          />
        </Col>
      </Row>
    </Form>
  )
}

export default SupplyAddForm
