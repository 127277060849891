import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {
  convertToFractionalNumber,
  crossedOutText,
  getColumnSorter,
} from '../../utils/helpers'
import {
  useAmountRangeTableSetting,
  useDateRangeTableSettings,
} from '../../hooks'
import {formattedDate} from '../../constants'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'

const useShiftTableService = ({
  tableSettings,
  setShiftsTableSettings,
  handleOpenShiftRelatedAccountings,
}) => {
  const {t} = useTranslation()

  const stores = useSelector((state) => state.stores.stores)
  const users = useSelector((state) => state.users.users)

  const totalAmountFilterProps = useAmountRangeTableSetting({
    rangeFromAmountFilterName: 'f_totalAmountFrom',
    rangeToAmountFilterName: 'f_totalAmountTo',
    setTableSettings: setShiftsTableSettings,
    tableSettings,
  })

  const cashAmountFilterProps = useAmountRangeTableSetting({
    rangeFromAmountFilterName: 'f_cashAmountFrom',
    rangeToAmountFilterName: 'f_cashAmountTo',
    setTableSettings: setShiftsTableSettings,
    tableSettings,
  })

  const actualCashAmountFilterProps = useAmountRangeTableSetting({
    rangeFromAmountFilterName: 'f_actualCashAmountFrom',
    rangeToAmountFilterName: 'f_actualCashAmountTo',
    setTableSettings: setShiftsTableSettings,
    tableSettings,
  })

  const differenceFilterProps = useAmountRangeTableSetting({
    rangeFromAmountFilterName: 'f_differenceFrom',
    rangeToAmountFilterName: 'f_differenceTo',
    setTableSettings: setShiftsTableSettings,
    tableSettings,
  })

  // const {
  //   rangeAmount: differenceRangeAmount,
  //   filterAmountIcon: DifferenceAmountFilterIcon,
  // } = useAmountRangeTableSetting({
  //   rangeFromAmountFilterName: 'f_actualCashAmountFrom',
  //   rangeToAmountFilterName: 'f_actualCashAmountTo',
  //   setTableSettings: setShiftsTableSettings,
  //   tableSettings
  // })

  const cardAmountFilterProps = useAmountRangeTableSetting({
    rangeFromAmountFilterName: 'f_cardAmountFrom',
    rangeToAmountFilterName: 'f_cardAmountTo',
    setTableSettings: setShiftsTableSettings,
    tableSettings,
  })

  const openedDateFilterProps = useDateRangeTableSettings({
    dateFromFilterName: 'f_openedDateFrom',
    dateToFilterName: 'f_openedDateTo',
    tableSettings,
    setTableSettings: setShiftsTableSettings,
  })

  const closedDateFilterProps = useDateRangeTableSettings({
    dateFromFilterName: 'f_closedDateFrom',
    dateToFilterName: 'f_closedDateTo',
    tableSettings,
    setTableSettings: setShiftsTableSettings,
  })

  return [
    {
      title: t('Store'),
      dataIndex: 'storeId',
      key: 'f_storeIds',
      width: '15%',
      sorter: true,
      filteredValue: tableSettings.f_storeIds,
      sortOrder: getColumnSorter(tableSettings, 'storeId'),
      filters: stores.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      render: (_, record) => {
        if (record.store.deletedDate) {
          return crossedOutText(record.store.name)
        }
        return <span>{record.store.name}</span>
      },
    },
    {
      title: t('Opened date'),
      dataIndex: 'openedDate',
      width: '12%',
      sorter: true,
      ...openedDateFilterProps,
      filteredValue: '',
      sortOrder: getColumnSorter(tableSettings, 'openedDate'),
      render: (value) =>
        value !== null ? moment(value).format(formattedDate.DateWithTime) : '-',
    },
    {
      title: t('Author'),
      dataIndex: 'openedById',
      key: 'f_openedByIds',
      width: '12%',
      filteredValue: tableSettings.f_openedByIds,
      sortOrder: getColumnSorter(tableSettings, 'openedById'),
      filters: users.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      sorter: true,
      // render: (value) => renderNameById({ id: value }, users),
      render: (_, record) => record.openedBy.name,
    },
    {
      title: t('Closed date'),
      dataIndex: 'closedDate',
      width: '15%',
      sorter: true,
      ...closedDateFilterProps,
      filteredValue: '',
      sortOrder: getColumnSorter(tableSettings, 'closedDate'),
      render: (value) =>
        value !== null ? moment(value).format(formattedDate.DateWithTime) : '-',
    },
    {
      title: t('Confirmed'),
      dataIndex: 'closedById',
      key: 'f_closedByIds',
      width: '15%',
      filteredValue: tableSettings.f_closedByIds,
      sortOrder: getColumnSorter(tableSettings, 'closedById'),
      filters: users.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      sorter: true,
      render: (_, record) => (record.closedBy ? record.closedBy.name : '-'),
    },
    {
      title: t('Cash'),
      dataIndex: 'cashAmount',
      key: 'cashAmount',
      align: 'right',
      width: '8%',
      ...cashAmountFilterProps,
      sortOrder: getColumnSorter(tableSettings, 'cashAmount'),
      sorter: true,
      render: (value) => convertToFractionalNumber(value, 2),
    },
    {
      title: t('Card'),
      dataIndex: 'cardAmount',
      key: 'cardAmount',
      align: 'right',
      width: '8%',
      ...cardAmountFilterProps,
      sortOrder: getColumnSorter(tableSettings, 'cardAmount'),
      sorter: true,
      render: (value) => convertToFractionalNumber(value, 2),
    },
    {
      title: t('Total'),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      align: 'right',
      width: '10%',
      ...totalAmountFilterProps,
      sortOrder: getColumnSorter(tableSettings, 'totalAmount'),
      sorter: true,
      render: (value) => convertToFractionalNumber(value, 2),
    },
    {
      title: t('Cashbox'),
      dataIndex: 'actualAmount',
      key: 'actualAmount',
      align: 'right',
      width: '10%',
      ...actualCashAmountFilterProps,
      sortOrder: getColumnSorter(tableSettings, 'actualAmount'),
      sorter: true,
      render: (value) => convertToFractionalNumber(value, 2),
    },
    {
      title: t('Difference'),
      dataIndex: 'difference',
      key: 'difference',
      align: 'right',
      width: '10%',
      ...differenceFilterProps,
      sortOrder: getColumnSorter(tableSettings, 'difference'),
      sorter: true,
      render: (value) => convertToFractionalNumber(value, 2),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 55,
      render: (_, record) => (
        <div>
          <DetailsActionButton
            onClick={() =>
              handleOpenShiftRelatedAccountings({
                storeId: record.storeId,
                shiftId: record.id,
              })
            }
          />
        </div>
      ),
    },
  ]
}

export default useShiftTableService
