import './style.scss'
import {Layout} from 'antd'
import {Header} from 'antd/lib/layout/layout'
import {Content, Footer} from 'antd/es/layout/layout'
import {useEffect, useState} from 'react'
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons'
import {AntdTableActionModal} from '../antd-components/app-table'
import CancelButton from '../antd-components/main-buttons/cancel-button/CancelButton'
import {LoadingSpinner} from '../antd-components/loading-spinner'

function InformationModal(props) {
  const {
    closeModalHandler,
    isModalOpen,
    title,
    mainContentElement,
    metadataElement,
    actionsElements,
    isLoading,
  } = props
  const [isMetadataHidden, setIsMetadataHidden] = useState(false)

  useEffect(() => {
    if (!isModalOpen && isMetadataHidden) {
      setIsMetadataHidden(false)
    }
  }, [isModalOpen])

  const toggleMetadataVisible = () => {
    setIsMetadataHidden(!isMetadataHidden)
  }

  return (
    <AntdTableActionModal
      open={isModalOpen}
      handleCancel={closeModalHandler}
      className="informationModal_wrapper antdBody modal"
      fullWidth
      centered
    >
      <Layout className="informationModal_layout">
        <Header className="header">{title}</Header>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Layout style={{width: '100%'}} className="body_wrapper">
            <div className={`metadata_wrapper ${isMetadataHidden && 'hidden'}`}>
              {metadataElement}
            </div>
            <Content className="content_wrapper">
              <div className="actions_wrapper">
                {isMetadataHidden ? (
                  <MenuUnfoldOutlined
                    className="metadataVisible_icon"
                    onClick={toggleMetadataVisible}
                  />
                ) : (
                  <MenuFoldOutlined
                    className="metadataVisible_icon"
                    onClick={toggleMetadataVisible}
                  />
                )}
                {actionsElements}
              </div>
              <div className="mainContent_wrapper">{mainContentElement}</div>
            </Content>
          </Layout>
        )}
        <Footer className="footer">
          <div className="leftFooter_side">
            <CancelButton onClick={closeModalHandler} />
          </div>
          <div className="rightFooter_side">{/* <SaveButton /> */}</div>
        </Footer>
      </Layout>
    </AntdTableActionModal>
  )
}
export default InformationModal
