import {useSelector} from 'react-redux'
import useStoreBalanceTable from '../../../../services/store-balance/storeBalanceTableSettings'
import {AppTable} from '../../../../components/antd-components/app-table'
import {
  storeBalanceSelector,
  isStoreBalanceLoadingSelector,
} from '../../../../redux/selectors/storeBalanceSelector'

function StoreBalanceTable({onSetTableSettings, tableSettings}) {
  const isLoading = useSelector(isStoreBalanceLoadingSelector)
  const storeBalance = useSelector(storeBalanceSelector)

  const {storeBalanceColumns} = useStoreBalanceTable({
    storeBalanceTableSettings: tableSettings,
  })

  return (
    <AppTable
      dataSource={storeBalance}
      columns={storeBalanceColumns}
      showPagination={false}
      rowKey={(record) => record.productId}
      tableSettings={tableSettings}
      setTableSettings={onSetTableSettings}
      isLoading={isLoading}
      syncWithUrl
      className="store_balance_table"
    />
  )
}

export default StoreBalanceTable
