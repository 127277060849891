import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Form} from 'antd'
import PreparationDepartmentBody from './components/PreparationDepartmentsBody'
import PreparationDepartmentHeader from './components/PreparationDepartmentHeader'
import usePreparationDepartmentService from '../../../services/preparationDepartment/preparationDepartmentService'
import {
  lastUpdatedPreparationDepartmentSelector,
  preparationDepartmentTableSettingsSelector,
} from '../../../redux/selectors/preparationDepartmentSelector'

function PreparationDepartmentsPage() {
  const {getPreparationDepartments} = usePreparationDepartmentService()
  const lastUpdated = useSelector(lastUpdatedPreparationDepartmentSelector)
  const tableSettings = useSelector(preparationDepartmentTableSettingsSelector)
  const [form] = Form.useForm()

  useEffect(() => {
    getPreparationDepartments(tableSettings)
  }, [lastUpdated])

  return (
    <div>
      <PreparationDepartmentHeader form={form} />
      <PreparationDepartmentBody form={form} />
    </div>
  )
}

export default PreparationDepartmentsPage
