import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import {Form} from 'antd'
import CategoryHeader from './components/CategoryHeader'
import CategoryBody from './components/CategoryBody'

import useCategory from '../../../services/category/categoryService'

function Category() {
  const categoryProps = useCategory()
  const {getCategoriesData, setSearchCategory} = categoryProps

  const categoriesLastUpdated = useSelector(
    (state) => state.categories.lastUpdated
  )
  const categoriesTreeSettings = useSelector(
    (state) => state.categories.treeSettings
  )
  const [form] = Form.useForm()
  useEffect(() => {
    getCategoriesData(categoriesTreeSettings)
  }, [categoriesLastUpdated])

  return (
    <>
      <CategoryHeader setSearchCategory={setSearchCategory} />
      <CategoryBody {...categoryProps} form={form} />
    </>
  )
}

export default Category
