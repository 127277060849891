import {useTranslation} from 'react-i18next'
import {Input} from 'antd'

function SearchAntdComponent({
  value,
  onChangeText,
  placeholder = null,
  disabled,
  ...props
}) {
  const {t} = useTranslation()
  const onChange = (e) => {
    if (value !== e.target.value) {
      onChangeText(e.target.value)
    }
  }
  return (
    <div style={{paddingTop: '0'}}>
      <Input
        {...props}
        style={{width: '100%'}}
        placeholder={!placeholder ? `${t('Search')}...` : placeholder}
        value={value}
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}

export default SearchAntdComponent
