import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getSupplies = createAsyncThunk(
  'GET_SUPPLIES',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/supplies', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        supplies: state.supplies.supplies,
        totalCount: state.supplies.totalCount,
      }
    }
    return null
  }
)

export const getSupply = createAsyncThunk('GET_SUPPLY', async ({id}) => {
  if (id) {
    try {
      const response = await API.get(`/supplies/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
  }
  return null
})

export const setSupply = createAsyncThunk('SET_SUPPLIES', async (data) => {
  try {
    const response = await API.post('/supplies', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const updateSupply = createAsyncThunk('UPDATE_SUPPLY', async (data) => {
  const {id} = data
  try {
    const response = await API.put(`/supplies/${id}`, data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const deleteSupplies = createAsyncThunk(
  'DELETE_SUPPLIES',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete('/supplies', {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {
          ...response.data.payload,
          selectedSupplies: [],
        }
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedSupplies: state.supplies.selectedSupplies,
      }
    }
    return null
  }
)

export const getHistorySuppliesByIngredient = createAsyncThunk(
  'GET_HISTORY_SUPPLIES_BY_INGREDIENT',
  async ({id, params}) => {
    if (id) {
      try {
        const response = await API.get(`/supplies/history/${id}`, {params})
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  }
)
