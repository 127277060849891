import './style.scss'
import {PageHeader} from 'antd'
import {useTranslation} from 'react-i18next'
import {useMemo, useState} from 'react'
import {
  AntdTableActionModal,
  DragTable,
} from '../../../../../../../../components/antd-components/app-table'
import useIngredientsTable from './ingredientsTableService'
import AddButton from '../../../../../../../../components/antd-components/main-buttons/add-button/AddButton'
import AddIngredientForm from '../addIngredient-form/AddIngredientForm'
import useRawMaterials from '../../../../../../../../services/raw-materials/rawMaterialsService'

function IngredientsTable(props) {
  const {
    currentProductData,
    updateList,
    confirmDelete,
    setCurrentProductData,
    openCreateRawModalHandler,
    isLoading,
  } = props
  const {t} = useTranslation()
  const [isAddIngredientModalOpen, setIsAddIngredientModalOpen] = useState()
  const [selectedIngredient, setSelectedIngredient] = useState(undefined)
  const {clearNewRawMaterial} = useRawMaterials()

  const ingredientsWithFieldKey = useMemo(() => {
    if (currentProductData) {
      return currentProductData.ingredients?.map((item) => ({
        ...item,
        key: item.id,
      }))
    }
    return []
  }, [currentProductData])

  const editButtonHandler = (ingredientData) => {
    setIsAddIngredientModalOpen(true)
    setSelectedIngredient(ingredientData)
  }

  const openAddIngredientModal = () => {
    setIsAddIngredientModalOpen(true)
  }

  const closeAddIngredientModalHandler = () => {
    setIsAddIngredientModalOpen(false)
    setSelectedIngredient(undefined)
    clearNewRawMaterial(null)
  }

  const {ingredientsColumns} = useIngredientsTable({
    onDelete: confirmDelete,
    onEdit: editButtonHandler,
  })

  return (
    <div className="ingredientsTable_wrapper">
      <PageHeader
        title={t('Ingredients')}
        extra={[
          <AddButton disabled={isLoading} onClick={openAddIngredientModal} />,
        ]}
      />
      <DragTable
        isLoading={!!isLoading}
        data={ingredientsWithFieldKey}
        updateList={updateList}
        columns={ingredientsColumns}
        arrayName="ingredients"
        className="table"
        scroll={{
          y: ingredientsWithFieldKey?.length > 3 ? true : undefined,
          x: 500,
        }}
        pagination={false}
        rowClassName={(record) => record.changed && 'changed_item'}
      />
      <AntdTableActionModal
        open={isAddIngredientModalOpen}
        title={selectedIngredient ? t('Update') : t('Add record')}
        handleCancel={closeAddIngredientModalHandler}
      >
        <AddIngredientForm
          openCreateRawModalHandler={openCreateRawModalHandler}
          onClose={closeAddIngredientModalHandler}
          selectedIngredient={selectedIngredient}
          currentProductData={currentProductData}
          setCurrentProductData={setCurrentProductData}
        />
      </AntdTableActionModal>
    </div>
  )
}

export default IngredientsTable
