import {PageHeader} from 'antd'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useMemo} from 'react'
import DownloadButton from '../../../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import RecalculateButton from '../../../../../../components/antd-components/main-buttons/recalculate-button/RecalculateButton'
import './style.scss'

function ProductHeader(props) {
  const {
    productId,
    openRecalculateModalHandler,
    currentProductData,
    onBackHandler,
  } = props
  const {t} = useTranslation()
  const {type} = useSelector((state) => state.products)

  const downloadButtonHandler = () => {}

  const isRecalculateButtonDisabled = useMemo(() => {
    if (
      productId &&
      (currentProductData?.semiFinished?.length > 0 ||
        currentProductData?.ingredients?.length > 0)
    ) {
      return false
    }
    return true
  }, [productId, currentProductData])

  const pageTitle = useMemo(() => {
    const headerAdd =
      type === 'final' ? 'New product calculation' : 'New semi calculation'
    const headerEdit =
      productId && type === 'final'
        ? 'Edit product calculation'
        : 'Edit semi calculation'
    return productId ? headerEdit : headerAdd
  }, [productId, type])

  return (
    <div className="productHeader_wrapper">
      <PageHeader
        className="title_wrapper"
        onBack={onBackHandler}
        title={t(pageTitle)}
      />
      <div className="buttons_wrapper">
        <RecalculateButton
          disabled={isRecalculateButtonDisabled}
          className="button"
          onClick={openRecalculateModalHandler}
        />
        <DownloadButton
          disabled
          className="button"
          onClick={downloadButtonHandler}
        />
      </div>
    </div>
  )
}
export default ProductHeader
