import {Tabs} from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'

function ItemsTabs(props) {
  const {
    setActiveTab,
    activeTab,
    tabBarExtraContent,
    itemsTable,
    hideEmptyTabs = false,
    itemsList,
  } = props
  const {t} = useTranslation()

  useEffect(
    () => () => {
      setActiveTab('product')
    },
    []
  )

  useEffect(() => {
    if (hideEmptyTabs && itemsList?.length > 0) {
      const isProductsItemsPresent = itemsList.find(
        (item) => item.itemType === 'product'
      )
      if (!isProductsItemsPresent) {
        const isSemiItemsPresent = itemsList.find(
          (item) => item.itemType === 'semi'
        )
        if (isSemiItemsPresent) {
          setActiveTab('semi')
        } else {
          setActiveTab('raw')
        }
      }
    }
  }, [itemsList])

  const checkTabVisibility = (tabName) => {
    const tabDataLength = itemsList?.filter(
      (itemData) => itemData.itemType === tabName
    )?.length
    if (!tabDataLength && hideEmptyTabs) {
      return false
    }
    return true
  }

  return (
    <Tabs
      className="itemsTabs_wrapper"
      onChange={(selectedTab) => {
        setActiveTab(selectedTab)
      }}
      activeKey={activeTab}
      key="main_items_tabs"
      tabBarExtraContent={tabBarExtraContent}
    >
      {checkTabVisibility('product') && (
        <TabPane tab={t('Products')} key="product">
          {itemsTable}
        </TabPane>
      )}
      {checkTabVisibility('semi') && (
        <TabPane tab={t('Semi-finished')} key="semi" className="hidden_tab">
          {itemsTable}
        </TabPane>
      )}
      {checkTabVisibility('raw') && (
        <TabPane tab={t('Raws')} key="raw">
          {itemsTable}
        </TabPane>
      )}
    </Tabs>
  )
}

export default ItemsTabs
