import {useDispatch} from 'react-redux'

import {
  deleteSuppliers,
  getSupplier,
  getSuppliers,
  setSupplier,
  updateSupplier,
} from '../../redux/asyncServices/supplierService'
import {
  clearNewSupplier,
  clearSuppliersTableSettings,
  setLastUpdated,
  setSelectedSuppliers,
  setTableSettings,
  suppliersReducerReset,
} from '../../redux/slices/supplierSlice'

function useSuppliers() {
  const dispatch = useDispatch()

  const suppliersLastUpdated = () => dispatch(setLastUpdated(Date.now()))

  const getSuppliersData = (params) => dispatch(getSuppliers({params}))
  // const getSuppliersData = (params) => dispatch(getSuppliers({ params }))

  const getSupplierById = ({id}) => dispatch(getSupplier({id}))

  const selectSuppliers = (data) => dispatch(setSelectedSuppliers(data))

  const addSupplier = async (data) => {
    await dispatch(setSupplier(data))
    suppliersLastUpdated()
  }

  const updateSupplierData = async (data) => {
    await dispatch(updateSupplier(data))
    suppliersLastUpdated()
  }

  const deleteSuppliersData = async ({ids}) => {
    await dispatch(deleteSuppliers({ids}))
    suppliersLastUpdated()
  }

  const setSupplierTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
    suppliersLastUpdated()
  }

  const suppliersResets = () => dispatch(suppliersReducerReset())
  const newSuppliersClear = (data) => dispatch(clearNewSupplier(data))

  const addSupplierFromComponent = async (data) => {
    await dispatch(setSupplier(data)).then(() => getSuppliersData())
    suppliersLastUpdated()
  }

  const resetSuppliersTableSettings = () =>
    dispatch(clearSuppliersTableSettings())

  return {
    getSuppliersData,
    getSupplierById,
    addSupplier,
    updateSupplierData,
    selectSuppliers,
    deleteSuppliersData,
    setSupplierTableSettings,
    suppliersResets,
    addSupplierFromComponent,
    newSuppliersClear,
    resetSuppliersTableSettings,
  }
}

export default useSuppliers
