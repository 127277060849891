import {useSelector} from 'react-redux'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {Col} from 'antd'
import {getRandomId, UpperCaseFirst} from '../../../../utils/helpers'
import {LoadingSpinner} from '../../../../components/antd-components/loading-spinner'
import ListComponent from '../../../../components/antd-components/listComponent/ListComponent'
import {formattedDate} from '../../../../constants'
import './style.scss'
import {
  isLoadingSelectedTasks,
  selectedTask,
} from '../../../../redux/selectors/tasksSelector'
import useTableRenderItems from '../../../../services/useTableRenderItems'
import {userList} from '../../../../redux/selectors/userSelector'

function TaskDetail() {
  const {t} = useTranslation()
  const isLoadingGetById = useSelector(isLoadingSelectedTasks)
  const taskById = useSelector(selectedTask)
  const users = useSelector(userList)

  const {renderNameById} = useTableRenderItems()

  const detailTask = [
    {
      key: getRandomId(),
      name: t('Create date'),
      value: moment(taskById?.createdDate).format(formattedDate.DateWithTime),
    },
    {
      key: getRandomId(),
      name: t('Author'),
      value: renderNameById({id: taskById?.createdBy}, users),
    },
    {
      key: getRandomId(),
      name: t('Name'),
      value: taskById?.name,
    },
    {
      key: getRandomId(),
      name: t('Type'),
      value: t(UpperCaseFirst(taskById?.type)),
    },
    {
      key: getRandomId(),
      name: taskById?.targetRaw ? t('Ingredient') : t('Semi'),
      value: taskById?.targetRaw ? taskById?.raw?.name : taskById?.semi?.name,
    },
    {
      key: getRandomId(),
      name: t('Function'),
      value: t(taskById?.function),
    },
  ]

  return (
    <div>
      {isLoadingGetById ? (
        <LoadingSpinner />
      ) : (
        <Col xl={24} sm={24} xs={24} lg={24} className="list_component wrapper">
          <ListComponent dataSource={detailTask} />
        </Col>
      )}
    </div>
  )
}

export default TaskDetail
