import {Form, Switch} from 'antd'

function SwitchForm({
  name,
  label,
  hidden,
  initialValue,
  valuePropName = 'checked',
  formStyle,
  formClassName,
  ...rest
}) {
  return (
    <Form.Item
      className={formClassName}
      name={name}
      valuePropName={valuePropName}
      initialValue={initialValue}
      hidden={hidden}
      style={formStyle}
      label={label}
    >
      <Switch {...rest}>{label}</Switch>
    </Form.Item>
  )
}

export default SwitchForm
