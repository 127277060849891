import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getCategories = createAsyncThunk(
  'GET_CATEGORIES',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/dictionary/categories', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        categories: state.categories.categories,
        totalCount: state.categories.totalCount,
      }
    }
    return null
  }
)

export const getCategory = createAsyncThunk('GET_CATEGORY', async ({id}) => {
  if (id) {
    try {
      const response = await API.get(`/dictionary/categories/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
  }
  return null
})

export const setCategory = createAsyncThunk('SET_CATEGORY', async (data) => {
  try {
    const response = await API.post('/dictionary/categories', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const updateCategory = createAsyncThunk(
  'UPDATE_CATEGORY',
  async (data) => {
    const {id} = data
    try {
      const response = await API.put(`/dictionary/categories/${id}`, data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const deleteCategories = createAsyncThunk(
  'DELETE_CATEGORIES',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete('/dictionary/categories', {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {
          ...response.data.payload,
          selectedCategories: [],
        }
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedCategories: state.categories.selectedCategories,
      }
    }
    return null
  }
)
