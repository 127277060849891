import {isLoadingOrdersStateOptions} from '../slices/orderSlice'

export const selectedOrderSelector = (state) => state.orders.selected
export const isGetByIdLoadingSelector = (state) =>
  state.orders.isLoading === isLoadingOrdersStateOptions.GET_BY_ID

export const ordersListSelector = (state) => state.orders.list
export const ordersTotalCountSelector = (state) =>
  state.orders.paginationData.totalCount
export const orderTableSettingsSelector = (state) => state.orders.tableSettings
export const isOrdersListLoadingSelector = (state) =>
  state.orders.isLoading === isLoadingOrdersStateOptions.GET_ORDERS

export const lastOrderUpdated = (state) => state.orders.lastUpdated
