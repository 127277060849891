import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {AntdTableActionModal} from '../../../../../components/antd-components/app-table'
import {ButtonDefault} from '../../../../../components/antd-components/button'
import {AutoCompleteComponent} from '../../../../../components/antd-components/form'

import './style.scss'
import CancelButton from '../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../../components/antd-components/main-buttons/save-button/SaveButton'

function AddOrderProductModal({open, onCancel, onAddProduct}) {
  const {t} = useTranslation()

  const [selectedProductId, setSelectedProductId] = useState(null)
  const products = useSelector((state) => state.products.products)

  const handleAddProducts = () => {
    onAddProduct(products.find(({id}) => id === selectedProductId))

    setSelectedProductId(null)
  }

  return (
    <AntdTableActionModal
      open={open}
      handleCancel={onCancel}
      title={t('Add product to order')}
      className="orderProduct_modal"
      footer={
        <div className="modal_footer">
          <CancelButton className="cancel_btn" onClick={onCancel} />
          <SaveButton onClick={handleAddProducts} />
        </div>
      }
    >
      <div className="add-product_modal-body">
        <AutoCompleteComponent
          classNameAutoComplete="autoComplete fullScreenSelectStyle"
          autoFocus
          placeholder={t('Products')}
          label={t('Products')}
          options={products}
          value={selectedProductId}
          onChange={(newValue) => setSelectedProductId(newValue)}
        />
      </div>
    </AntdTableActionModal>
  )
}

export default AddOrderProductModal
