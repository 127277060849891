import {Tag} from 'antd'
import {useTranslation} from 'react-i18next'

function TagsComponent({keys, color, tagText, style, ...props}) {
  const {t} = useTranslation()

  return (
    <Tag color={color} key={keys} {...props}>
      <div style={style}>{t(tagText)}</div>
    </Tag>
  )
}

export default TagsComponent
