import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

const useValidationSchema = () => {
  const {t} = useTranslation()

  return yup.object().shape({
    orderProducts: yup.array().of(
      yup.object().shape({
        amount: yup
          .number()
          .min(0.001, t('Value must be equal or greater than 0.001'))
          .typeError(t('Value must be a number'))
          .transform((val, orig) => (orig === '' ? null : val))
          .nullable()
          .required(t('This field is required')),
        comment: yup.string().nullable().max(250, t('Max length 250 symbols')),
        price: yup
          .number()
          .min(0, t('Only positive number'))
          .typeError(t('Value must be a number'))
          .transform((val, orig) => (orig === '' ? null : val))
          .nullable()
          .required(t('This field is required')),
      })
    ),
  })
}

export default useValidationSchema
