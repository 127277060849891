import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Tag} from 'antd'
import {EyeTwoTone} from '@ant-design/icons'
import {PageHeaderWithMobileMenu} from '../../../../../components'
import AddOrderProductModal from '../modals/AddOrderProductModal'
import OrderPaymentsModal from '../modals/OrderPaymentsModal'
import {paymentStatus} from '../../../../../constants'
import {getRandomId, priceFormat} from '../../../../../utils/helpers'
import useCustomNavigate from '../../../../../hooks/useCustomNavigate'
import AddButton from '../../../../../components/antd-components/main-buttons/add-button/AddButton'
import DownloadButton from '../../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useOrderByIdService from '../../../../../services/orders/useOrderByIdService'

function PaymentStatusTag({paymentDetails}) {
  const {t} = useTranslation()

  let tagColor = ''
  let tagText = ''

  if (paymentDetails.paymentStatus === paymentStatus.PAID) {
    tagColor = 'green'
    tagText = t('Paid')
  } else if (paymentDetails.paymentStatus === paymentStatus.PARTIALLY) {
    tagColor = 'orange'
    tagText = `${t('Partially paid')} (${t('Due')}: ${priceFormat(
      paymentDetails.paymentDue
    )})`
  } else {
    tagColor = 'red'
    tagText = t('Unpaid')
  }

  return (
    <Tag color={tagColor} className="tag">
      {tagText}
    </Tag>
  )
}

function OrderFormHeader({orderId, orderPayments, paymentDetails, form}) {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const {downloadOrder} = useOrderByIdService()
  const [openOrderPaymentsModal, setOpenOrderPaymentsModal] = useState(false)
  const [openAddOrderProductModal, setOpenAddOrderProductModal] =
    useState(false)

  const handleAddProduct = (newProduct) => {
    const orderProducts = form.getFieldValue('orderProducts')
    const newOrderProductData = {
      amount: 1,
      price: newProduct.price,
      total: newProduct.price,
      productId: newProduct.id,
      comment: '',
      product: newProduct,
      id: `local_${getRandomId()}`,
    }
    form.setFieldsValue({
      orderProducts: Array.isArray(orderProducts)
        ? [...orderProducts, newOrderProductData]
        : [newOrderProductData],
    })

    setOpenAddOrderProductModal(false)
  }

  const downloadButtonHandler = () => {
    downloadOrder(orderId)
  }

  return (
    <div>
      <PageHeaderWithMobileMenu
        onBack={() => navigate('/orders')}
        showHeaderMenu={false}
        title={
          orderId ? (
            <div className="order-header_title-block">
              {t('Order')} #{orderId}{' '}
              {paymentDetails && (
                <PaymentStatusTag paymentDetails={paymentDetails} />
              )}
              <EyeTwoTone onClick={() => setOpenOrderPaymentsModal(true)} />
            </div>
          ) : (
            t('Create order')
          )
        }
        extra={[
          <AddButton onClick={() => setOpenAddOrderProductModal(true)} />,
          <DownloadButton
            onClick={downloadButtonHandler}
            disabled={!orderId}
          />,
        ]}
        className="order-form_header"
      />

      <AddOrderProductModal
        open={openAddOrderProductModal}
        onCancel={() => setOpenAddOrderProductModal(false)}
        onAddProduct={handleAddProduct}
      />
      <OrderPaymentsModal
        open={openOrderPaymentsModal}
        onCancel={() => setOpenOrderPaymentsModal(false)}
        orderId={orderId}
        orderPayments={orderPayments}
        withAddPayment={paymentDetails?.paymentStatus !== paymentStatus.PAID}
      />
    </div>
  )
}

export default OrderFormHeader
