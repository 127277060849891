export const newCreatedRawMaterial = (state) =>
  state.rawMaterials.newRawMaterial
export const isLoadingRawMaterials = (state) =>
  state.rawMaterials.isLoading === 'getRawMaterials'
export const rawMaterialsList = (state) => state.rawMaterials.rawMaterials

export const rawMaterialsBalance = (state) =>
  state.rawMaterials.rawMaterialsBalance
export const selectedRawMaterials = (state) =>
  state.rawMaterials.selectedRawMaterials

export const rawMaterialsTableSettingsSelector = (state) =>
  state.rawMaterials.tableSettings
