import React from 'react'
import {createRoot} from 'react-dom/client'
import './index.scss'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter} from 'react-router-dom'
import App from './App'
import {persistor, store} from './redux/store'
import LanguageProvider from './providers/LanguageProvider'
import * as serviceWorker from './serviceWorker'
import 'antd/dist/antd.min.css'

function AppWithCallbackAfterRender() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <LanguageProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LanguageProvider>
      </PersistGate>
    </Provider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<AppWithCallbackAfterRender />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
