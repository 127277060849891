import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

function Validation() {
  const {t} = useTranslation()
  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t('This field is required'))
      .nullable()
      .max(250, t('Max length 250 symbols')),
    deliveryAddress: yup
      .string()
      .max(250, t('Max length 250 symbols'))
      .nullable(),
    externalId: yup.string().max(250, t('Max length 250 symbols')).nullable(),
    deliveryPrice: yup
      .number()
      .typeError(t('Value must be an number'))
      .positive()
      .min(0, t('Only positive number'))
      .nullable()
      .transform((_, value) => {
        if (String(value).includes(',')) {
          return +value.replace(/,/, '.')
        }
        if (!value) {
          return undefined
        }
        return +value
      }),
  })

  return {
    async validator({field}, value) {
      schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default Validation
