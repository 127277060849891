export const selectedIngredient = (state) =>
  state.historySuppliesByIngredient.selectedIdIngredientHistory

export const isLoadingHistoryIngredient = (state) =>
  state.historySuppliesByIngredient.isLoading === 'getHistoryByIngredient'

export const historyIngredientData = (state) =>
  state.historySuppliesByIngredient.historySuppliesByIngredient

export const historyIngredientTableSettings = (state) =>
  state.historySuppliesByIngredient.tableSettings

export const historyIngredientLastUpdated = (state) =>
  state.historySuppliesByIngredient.lastUpdated

export const historyIngredientTotalCount = (state) =>
  state.historySuppliesByIngredient.totalCount
