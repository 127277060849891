import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {Form} from 'antd'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {PageHeaderWithMobileMenu} from '../../../../components'
import {ButtonWithTooltip} from '../../../../components/antd-components/button'
import {createItemHeaderMenu} from '../../../../utils/helpers'
import variables from '../../../../globalStyle/colors.scss'
import {AutoCompleteComponent} from '../../../../components/antd-components/form'
import useNormService from '../../../../services/norm/normService'
import {normData} from '../../../../redux/selectors/normSelector'
import FormDateTimePickerAntd from '../../../../components/antd-components/form/FormDateTimePickerAntd'
import {recordsSelectedPlanning} from '../../../../redux/selectors/planningSelecor'
import {formattedDate} from '../../../../constants'
import usePlanningService from '../../../../services/plunning/planningService'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function PlanningAddHeader({form, onSubmitAdd, filterColumnsForm}) {
  const {t} = useTranslation()
  const {state} = useLocation()
  const header = state?.header || 'Edit planning'
  const selectedPlanningRecords = useSelector(recordsSelectedPlanning)
  const {getNormDetail} = useNormService()
  const norms = useSelector(normData)
  const [selectedNormId, setSelectedNormId] = useState(undefined)
  const isLoadingSet = useSelector(
    (state) => state.planning.isLoading === 'setPlanning'
  )
  const {addPlanningTableSettings, lastUpdated} = useSelector(
    (state) => state.planning
  )
  const {resetAddPlanningTableSettings} = usePlanningService()

  const getNormById = (normId) =>
    getNormDetail({
      id: normId,
      params: {withStoreBalances: true, ...addPlanningTableSettings},
    })

  useEffect(
    () => () => {
      resetAddPlanningTableSettings()
      filterColumnsForm.resetFields()
    },
    []
  )

  useEffect(() => {
    if (selectedNormId) {
      getNormById(selectedNormId)
    }
  }, [selectedNormId, lastUpdated])

  const onSelectedNormChange = (normId) => {
    const planningDateFiledValue = form.getFieldValue('planningDate')
    form.resetFields()
    form.setFieldsValue({
      planningDate: planningDateFiledValue,
      storeNormId: normId,
    })
    resetAddPlanningTableSettings()
    filterColumnsForm.resetFields()
    setSelectedNormId(normId)
  }

  const actionHeader = [
    <Form form={form} key={11} onFinish={onSubmitAdd}>
      <AutoCompleteComponent
        options={norms?.map((item) => ({
          id: item?.id,
          name: item?.title,
          value: item?.id,
        }))}
        formStyle={{marginBottom: 0}}
        placeholder={t('Select norm')}
        style={{width: '200px'}}
        classNameForm="form_label_after"
        label={<span />}
        rules={{required: true}}
        name="storeNormId"
        onChange={onSelectedNormChange}
      />
    </Form>,
    <Form form={form} key={33} onFinish={onSubmitAdd}>
      <FormDateTimePickerAntd
        formStyle={{marginBottom: 0}}
        name="planningDate"
        initialValue={moment().add(1, 'days')}
        allowClear={false}
        disabledLastAndTodayDates
        format={formattedDate.Date}
      />
    </Form>,
    <Form form={form} key={2} onFinish={onSubmitAdd}>
      <SaveButton
        loading={isLoadingSet}
        disabled={!selectedPlanningRecords?.length}
        tooltipText={t(
          !selectedPlanningRecords?.length ? 'Select norm before save' : null
        )}
        htmlType="submit"
      />
    </Form>,
  ]
  const item = createItemHeaderMenu(actionHeader)
  return (
    <PageHeaderWithMobileMenu
      title={header}
      extra={actionHeader}
      items={item}
    />
  )
}

export default PlanningAddHeader
