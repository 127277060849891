import {useSelector} from 'react-redux'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import '../style.scss'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import useRefillTable from '../../../../services/refill/refillTableSettings'

import useSentProductTable from '../../../../services/sentProductsDetail'

import {
  AntdTableActionModal,
  AppTable,
  TableDetailsReadOnly,
} from '../../../../components/antd-components/app-table'
import showConfirm from '../../../../components/PopupConfirm'
import useTableRenderItems from '../../../../services/useTableRenderItems'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import useRefill from '../../../../services/refill/refillService'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import ItemsTabs from '../../../../components/itemsTabs-component/ItemsTabs'
import {tableRowClassNames} from '../../../../constants'

function RefillBody({
  refillsTableSettings,
  selectedRefill,
  getRefillById,
  getExcelReport,
  setRefillTableSettings,
  refillDelete,
}) {
  const {resetSelectedRefillData} = useRefill()
  const {t} = useTranslation()
  const navigate = useCustomNavigate()

  const [isEditDialogOpen, setEditDialogOpen] = useState(false)
  const {renderNameById} = useTableRenderItems()
  const refills = useSelector((state) => state.refills.refills)
  const totalCount = useSelector((state) => state.refills.totalCount)
  const stores = useSelector((state) => state.stores.stores)
  const isRefillsListLoading = useSelector(
    (state) => state.refills.isLoading === 'getRefills'
  )

  const {isLoading} = useSelector((state) => state.refills)
  const [activeTab, setActiveTab] = useState('product')

  useEffect(() => {
    if (!isEditDialogOpen && selectedRefill) {
      resetSelectedRefillData()
    }
  }, [isEditDialogOpen])

  const handleEdit = (record) => {
    navigate(`/refill/edit/${record.id}`)
  }

  const getInfoSentRefill = (id) => {
    getRefillById({id})
    setEditDialogOpen(true)
  }
  const downloadRefillExcel = (id) => {
    getExcelReport(id)
  }

  const handleDelete = (record) => {
    const storeName = renderNameById({id: record?.storeId}, stores)
    refillDelete(record.id, storeName)
  }

  const confirmDelete = (record) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleDelete(record),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const {refillColumns} = useRefillTable({
    handleEdit,
    downloadRefillExcel,
    getInfoSentRefill,
    handleDelete: confirmDelete,
  })

  const handleEditClose = () => {
    setEditDialogOpen(false)
  }

  const {sentProductsColumns} = useSentProductTable()

  const itemsList = useMemo(() => {
    if (selectedRefill) {
      const {productsSent, semiFinishedSent, rawsSent} = selectedRefill
      const {productsReceived, semiFinishedReceived, rawsReceived} =
        selectedRefill
      const itemsReceivedData = [
        ...productsReceived,
        ...semiFinishedReceived,
        ...rawsReceived,
      ]
      const itemsSentData = [...productsSent, ...semiFinishedSent, ...rawsSent]
      return itemsSentData.map((item) => {
        const {itemType, itemId, amount} = item
        const itemName =
          itemType === 'raw' ? item.raw?.name : item.product?.name
        const receivedAmount = itemsReceivedData.find(
          (receivedItem) =>
            receivedItem.itemId === item.itemId &&
            receivedItem.itemType === item.itemType
        )?.amount
        return {itemType, itemId, sentAmount: amount, receivedAmount, itemName}
      })
    }
    return []
  }, [selectedRefill])

  const itemsDataForActiveTab = useMemo(
    () => itemsList.filter((item) => item.itemType === activeTab),
    [activeTab, itemsList]
  )

  const rowClassName = (record) => {
    if (record?.receivedAmount !== record?.sentAmount) {
      return 'isWarning'
    }
    return null
  }
  const arrivalsListRowClassName = (record) => {
    if (record.hasMismatchedProducts) return tableRowClassNames.IS_ERROR

    return null
  }

  const refillDetailsTable = (
    <TableDetailsReadOnly
      isLoading={isLoading}
      selectedById={itemsDataForActiveTab}
      columns={sentProductsColumns}
      rowClassName={(record) => rowClassName(record)}
    />
  )
  return (
    <div>
      <AntdTableActionModal
        title={t('Refill information')}
        handleCancel={handleEditClose}
        width={1500}
        open={isEditDialogOpen}
        className="refillInformation_wrapper"
      >
        <ItemsTabs
          itemsList={itemsList}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          itemsTable={refillDetailsTable}
        />
        <DownloadButton
          onClick={() => getExcelReport(selectedRefill?.id)}
          className="download_btn"
        />
      </AntdTableActionModal>
      <AppTable
        columns={refillColumns}
        dataSource={refills}
        tableSettings={refillsTableSettings}
        setTableSettings={setRefillTableSettings}
        totalCount={totalCount}
        isLoading={isRefillsListLoading}
        rowClassName={arrivalsListRowClassName}
      />
    </div>
  )
}

export default RefillBody
