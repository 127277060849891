import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {Form} from 'antd'
import useRefillItemsTable from './refillItemsTableSettings'
import {TableDetailsReadOnly} from '../../../../../../../../components/antd-components/app-table'
import {isRefillDataLoading} from '../../../../../../../../redux/selectors/refillSelector'
import './style.scss'

function ItemsTable(props) {
  const {
    refillForm,
    itemsList,
    setItemsList,
    onFormSubmit,
    isPageReadOnly,
    activeTab,
  } = props
  const isLoading = useSelector(isRefillDataLoading)

  const deleteItemHandler = (itemId) => {
    setItemsList(itemsList.filter((item) => item.id !== itemId))
  }

  const onFieldValueChange = (recordId, fieldName, value) => {
    if (fieldName === 'itemId') {
      const amountFieldName = `amount${recordId}`
      const amountValue = refillForm.getFieldValue(amountFieldName)
      if (amountValue.toString().length > 0) {
        refillForm.validateFields([amountFieldName])
      }
    }
    const updatedArrivalList = itemsList.map((item) => {
      if (item.id === recordId) {
        return {...item, [fieldName]: value}
      }
      return item
    })
    setItemsList(updatedArrivalList)
  }

  const {refillItemsColumns} = useRefillItemsTable({
    deleteItemHandler,
    itemsList,
    onFieldValueChange,
    isPageReadOnly,
    activeTab,
  })

  const correctItemsList = useMemo(
    () => itemsList?.filter((item) => item.itemType === activeTab),
    [activeTab, itemsList]
  )

  return (
    <div className="refillItems_table">
      <Form form={refillForm} onFinish={onFormSubmit}>
        <TableDetailsReadOnly
          className="items_table hideScroll"
          columns={refillItemsColumns}
          selectedById={correctItemsList}
          isLoading={!!isLoading}
          isScrollYHidden={correctItemsList.length === 0}
          scrollY="calc(100vh - 22em)"
          scrollX="max-content"
        />
      </Form>
    </div>
  )
}

export default ItemsTable
