import {Card, Col, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import './style.scss'
import currency from 'currency.js'
import {useSelector} from 'react-redux'
import {getRandomId} from '../../../../utils/helpers'
import LoaderComponent from '../../../../components/loaderComponent/LoaderComponent'

function StatisticCard({
  calculationTotalPrice,
  purchaseCount,
  cardPayment,
  cash,
}) {
  const {t} = useTranslation()
  const isLoading = useSelector(
    (state) => state.purchase.isLoading === 'getIncome'
  )
  const cardItem = [
    {
      id: getRandomId(),
      name: 'Purchases',
      value: purchaseCount,
    },
    {
      id: getRandomId(),
      name: 'Card',
      value: `${currency(cardPayment)}`,
    },
    {
      id: getRandomId(),
      name: 'Cash',
      value: `${currency(cash)}`,
    },
    {
      id: getRandomId(),
      name: 'Sum',
      value: `${currency(calculationTotalPrice)}`,
    },
  ]

  return (
    <Row gutter={[10, 8]} style={{paddingBottom: '8px'}}>
      {cardItem.map((item) => (
        <Col xl={6} md={6} lg={6} sm={12} xs={12} key={item.id}>
          <Card bordered={false} className="card" key={item.id}>
            <div
              style={{display: 'flex', justifyContent: 'space-between'}}
              key={item.id}
            >
              <span className="title_card_span">{t(item.name)}</span>
              <span className="statistic_card_span">
                <LoaderComponent
                  isLoadingCreator={[isLoading]}
                  component={item.value}
                />
              </span>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default StatisticCard
