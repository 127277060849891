import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import ProductsHeader from './components/ProductsHeader'
import ProductsBody from './components/ProductsBody'
import useProducts from '../../../services/products/productsService'
import useUnits from '../../../services/units/unitsService'
import useCategory from '../../../services/category/categoryService'

function Products({type}) {
  const productsProps = useProducts()
  const {
    setProductTableSettings,
    setSemiFinishedTableSettings,
    deleteProductsData,
    getProductsData,
    setSelectedIdProductsOrSemi,
    setTypeProductsOrSemi,
  } = productsProps
  const {getUnitsData, unitsReset} = useUnits()
  const {getCategoriesData, categoriesReset} = useCategory()
  const productsLastUpdated = useSelector((state) => state.products.lastUpdated)
  const {productsTableSettings, semiFinishedTableSettings} = useSelector(
    (state) => state.products
  )

  const header = type === 'final' ? 'Products' : 'Semi-finished'

  const setCurrentTableSettingsHandler =
    type === 'final' ? setProductTableSettings : setSemiFinishedTableSettings

  const currentTableSettings =
    type === 'final' ? productsTableSettings : semiFinishedTableSettings

  useEffect(() => {
    getProductsData({...currentTableSettings, f_type: [type]})
  }, [productsLastUpdated, type])

  useEffect(() => {
    getUnitsData()
    getCategoriesData({f_type: ['product']})
    return () => {
      unitsReset()
      categoriesReset()
    }
  }, [])

  return (
    <>
      <ProductsHeader
        setProductTableSettings={setCurrentTableSettingsHandler}
        deleteProductsData={deleteProductsData}
        productsTableSettings={currentTableSettings}
        type={type}
        header={header}
        setTypeProductsOrSemi={setTypeProductsOrSemi}
      />
      <ProductsBody
        type={type}
        {...productsProps}
        tableSettings={currentTableSettings}
        setTableSettings={setCurrentTableSettingsHandler}
        setSelectedIdProductsOrSemi={setSelectedIdProductsOrSemi}
        setTypeProductsOrSemi={setTypeProductsOrSemi}
      />
    </>
  )
}

export default Products
