import {LoadingSpinner} from '../antd-components/loading-spinner'
import './style.scss'

function LoaderComponent(props) {
  const {isLoadingCreator, component, className} = props
  return (
    <div className={`${className || ''} loader_wrapper`}>
      {isLoadingCreator.includes(true) ? <LoadingSpinner /> : component}
    </div>
  )
}

export default LoaderComponent
