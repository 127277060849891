import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {ExclamationCircleOutlined} from '../../../../components/antd-components/icon'

import useSuppliesTable from '../../../../services/supllies/suppliesTableService'
import showConfirm from '../../../../components/PopupConfirm'
import {AppTable} from '../../../../components/antd-components/app-table'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'

function SuppliesBody({
  selectSupplies,
  setSuppliesTableSettings,
  deleteSuppliesData,
  suppliesTableSettings,
  setSelectedSuppliesId,
}) {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const totalCount = useSelector((state) => state.supplies.totalCount)
  const supplies = useSelector((state) => state.supplies.supplies)
  const isLoading = useSelector(
    (state) => state.supplies.isLoading === 'getSupplies'
  )
  const handleDeleteSubmit = (id) => {
    deleteSuppliesData({ids: [id]})
  }
  const selectedSupplies = useSelector(
    (state) => state.supplies.selectedSupplies
  )
  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => {
        handleDeleteSubmit(id)
      },
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const onEdit = (id) => {
    setSelectedSuppliesId(id)
    navigate('/supplies/add')
  }

  const {suppliesColumns} = useSuppliesTable({
    onDelete: confirmDelete,
    onEdit,
  })

  return (
    <AppTable
      columns={suppliesColumns}
      showSelectRowCheckbox
      setSelectedComponent={selectSupplies}
      tableSettings={suppliesTableSettings}
      setTableSettings={setSuppliesTableSettings}
      dataSource={supplies}
      isLoading={isLoading}
      totalCount={totalCount}
      selectedRowKeys={selectedSupplies}
    />
  )
}

export default SuppliesBody
