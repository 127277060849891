import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {passwordRegexp} from '../../../../constants'

function UserValidation() {
  const {t} = useTranslation()
  const schema = yup.object().shape({
    phoneNumber: yup.string().required(t('This field is required.')),
    name: yup
      .string()
      .required(t('This field is required.'))
      .max(250, t('Max length 250 symbols')),
    password: yup
      .string()
      .required(t('This field is required.'))
      .matches(passwordRegexp, t('passwordValidation')),
    type: yup.string().required(t('This field is required.')),
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default UserValidation
