import {List, Skeleton} from 'antd'
import './style.scss'

function ListComponent({dataSource, loading, isOneLineData = true, ...props}) {
  return (
    <div>
      <List
        {...props}
        loadind={loading}
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(item) => (
          <List.Item
            className={isOneLineData ? 'listItem_row' : 'listItem_column'}
          >
            <Skeleton loading={loading} active>
              {isOneLineData ? (
                <>
                  <List.Item.Meta className="title" title={`${item.name}:`} />
                  <div className="content">{item.value}</div>{' '}
                </>
              ) : (
                <>
                  <List.Item.Meta className="title" title={`${item.name}:`} />
                  <div className="content">{item.value}</div>
                </>
              )}
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  )
}

export default ListComponent
