export const getPlanningLoading = (state) =>
  state.planning.isLoading === 'getPlannings'

export const lastUpdatedPlanning = (state) => state.planning.lastUpdated
export const planningData = (state) => state.planning.plannings
export const planningTotalCount = (state) => state.planning.totalCount
export const planningTableSettings = (state) => state.planning.tableSettings
export const planningSelected = (state) => state.planning.selectedPlanning
export const createdPlanning = (state) => state.planning.createdPlanning
export const normSettings = (state) => state.planning.normSettings
export const recordsSelectedPlanning = (state) =>
  state.planning.createdPlanning[0].products
export const planningsSelected = (state) =>
  state.planning.selectedPlanningInTable
export const isLoadingSelectedPlanning = (state) =>
  state.planning.isLoading === 'getPlanningById'
