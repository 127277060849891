import {Form, Input} from 'antd'

function InputForm({
  name,
  rules = {},
  label,
  initialValue,
  formClassName,
  normalize,
  className,
  ...rest
}) {
  const {formStyle, ...props} = rest

  return (
    <Form.Item
      name={name}
      rules={[rules]}
      label={label}
      style={formStyle}
      className={formClassName}
      normalize={normalize}
      initialValue={initialValue}
    >
      <Input
        {...props}
        onClick={(e) => e.stopPropagation()}
        className={className}
      />
    </Form.Item>
  )
}

export default InputForm
