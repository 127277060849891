import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {Form} from 'antd'
import {ExclamationCircleOutlined} from '../../../../components/antd-components/icon'
import useRawMaterialsTable from '../../../../services/raw-materials/rawMaterialsTableService'

import RawMaterialsForm from './RawMaterialsForm'
import showConfirm from '../../../../components/PopupConfirm'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'

function RawMaterialsBody({
  selectRawMaterials,
  setRawMaterialTableSettings,
  deleteRawMaterialsData,
  getRawMaterialById,
  rawMaterialsTableSettings,
}) {
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const rawMaterials = useSelector((state) => state.rawMaterials.rawMaterials)
  const selectedRawMaterials = useSelector(
    (state) => state.rawMaterials.selectedRawMaterials
  )
  const isLoading = useSelector(
    (state) => state.rawMaterials.isLoading === 'getRawMaterials'
  )
  const selectedRawMaterial = useSelector(
    (state) => state.rawMaterials.selectedRawMaterial
  )
  const rawMaterialsTotalCount = useSelector(
    (state) => state.rawMaterials.totalCount
  )

  const [isEditDialogOpen, setEditDialogOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const handleDeleteSubmit = (id) => {
    deleteRawMaterialsData({ids: [id]})
  }

  const onEdit = (id) => {
    setSelectedId(id)
    setEditDialogOpen(true)
  }

  const handleEditClose = () => {
    setSelectedId(null)
    setEditDialogOpen(false)
  }

  useEffect(() => {
    if (selectedRawMaterial !== selectedId) {
      getRawMaterialById({id: selectedId})
    }
  }, [selectedId])

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleDeleteSubmit(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const {rawMaterialsColumns} = useRawMaterialsTable({
    onDelete: confirmDelete,
    onEdit,
  })

  return (
    <div>
      {selectedRawMaterial?.id && (
        <AntdTableActionModal
          title={t('Update')}
          handleCancel={handleEditClose}
          open={isEditDialogOpen}
        >
          <RawMaterialsForm
            onClose={handleEditClose}
            formData={selectedRawMaterial}
            submitText={t('Update')}
            form={form}
          />
        </AntdTableActionModal>
      )}
      <AppTable
        columns={rawMaterialsColumns}
        dataSource={rawMaterials}
        showSelectRowCheckbox
        setSelectedComponent={selectRawMaterials}
        setTableSettings={setRawMaterialTableSettings}
        tableSettings={rawMaterialsTableSettings}
        totalCount={rawMaterialsTotalCount}
        isLoading={isLoading}
        selectedRowKeys={selectedRawMaterials}
      />
    </div>
  )
}

export default RawMaterialsBody
