import {useTranslation} from 'react-i18next'
import {Button, Tooltip} from 'antd'
import './style.scss'

function SaveButton(props) {
  const {tooltipText, tooltipPlacement, className, onClick, ...rest} = props
  const {t} = useTranslation()

  return (
    <span className="saveButton_wrapper">
      <Tooltip placement={tooltipPlacement} title={tooltipText}>
        <Button
          className={`save_button ${className}`}
          type="primary"
          onClick={onClick}
          {...rest}
        >
          {t('Save')}
        </Button>
      </Tooltip>
    </span>
  )
}

export default SaveButton
