import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {phoneRegex} from '../../../constants'

function AuthValidation() {
  const {t} = useTranslation()
  const schema = yup.object().shape({
    phoneNumber: yup
      .string()
      .required(t('This field is required.'))
      .matches(phoneRegex, t('phoneValidation')),
    password: yup.string().required(t('This field is required')),
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default AuthValidation
