import {Button} from 'antd'
import {MinusOutlined, PlusOutlined} from '@ant-design/icons'

import './style.scss'

function Counter({value, increment, decrement, id = null}) {
  return (
    <div className="counter_container">
      <Button shape="circle" size="small" onClick={() => decrement(id)}>
        <MinusOutlined />
      </Button>
      {value}
      <Button
        shape="circle"
        size="small"
        onClick={() => {
          increment(id)
        }}
      >
        <PlusOutlined />
      </Button>
    </div>
  )
}

export default Counter
