import {useTranslation} from 'react-i18next'
import {useForm} from 'antd/es/form/Form'
import {useState} from 'react'
import ProductForm from './components/product-form/ProductForm'
import SemiFinishedTable from './components/semiFinished-table/SemiFinishedTable'
import IngredientsTable from './components/ingredients-table/IngredientsTable'
import './style.scss'
import showConfirm from '../../../../../../components/PopupConfirm'
import {ExclamationCircleOutlined} from '../../../../../../components/antd-components/icon'
import RecalculateModal from './components/modals/recalculate-modal/RecalculateModal'
import CreateCategoryModal from './components/modals/createCategory-modal/CreateCategoryModal'
import CreateRawModal from './components/modals/createRaw-modal/CreateRawModal'

function ProductBody(props) {
  const {
    productForm,
    productId,
    currentProductData,
    setCurrentProductData,
    isRecalculateModalOpen,
    setIsRecalculateModalOpen,
    isLoading,
  } = props
  const [recalculateForm] = useForm()
  const {t} = useTranslation()

  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] =
    useState(false)
  const [isCreateRawModalOpen, setIsCreateRawModalOpen] = useState(false)
  const updateList = (data, arrayName) => {
    setCurrentProductData({...currentProductData, [arrayName]: data})
  }

  const onSubmitDelete = ({id, itemType}) => {
    if (itemType === 'ingredient') {
      const {ingredients} = currentProductData
      setCurrentProductData({
        ...currentProductData,
        ingredients: [...ingredients.filter((item) => item.id !== id)],
      })
    } else if (itemType === 'semiFinished') {
      const {semiFinished} = currentProductData
      setCurrentProductData({
        ...currentProductData,
        semiFinished: [...semiFinished.filter((item) => item.id !== id)],
      })
    }
  }

  const confirmDelete = ({id, itemType}) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => onSubmitDelete({id, itemType}),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const closeRecalculateModalHandler = () => {
    recalculateForm.resetFields()
    setIsRecalculateModalOpen(false)
  }

  const openCreateCategoryModalHandler = () => {
    setIsCreateCategoryModalOpen(true)
  }
  const closeCreateCategoryModalHandler = () => {
    setIsCreateCategoryModalOpen(false)
  }

  const openCreateRawModalHandler = () => {
    setIsCreateRawModalOpen(true)
  }
  const closeCreateRawModalHandler = () => {
    setIsCreateRawModalOpen(false)
  }

  return (
    <div className="productBody_wrapper">
      <ProductForm
        isLoading={isLoading}
        productForm={productForm}
        productId={productId}
        currentProductData={currentProductData}
        openCreateCategoryModalHandler={openCreateCategoryModalHandler}
      />
      <SemiFinishedTable
        isLoading={isLoading}
        setCurrentProductData={setCurrentProductData}
        confirmDelete={confirmDelete}
        updateList={updateList}
        currentProductData={currentProductData}
      />
      <IngredientsTable
        isLoading={isLoading}
        openCreateRawModalHandler={openCreateRawModalHandler}
        confirmDelete={confirmDelete}
        updateList={updateList}
        currentProductData={currentProductData}
        setCurrentProductData={setCurrentProductData}
      />
      <RecalculateModal
        productForm={productForm}
        currentProductData={currentProductData}
        setCurrentProductData={setCurrentProductData}
        form={recalculateForm}
        isModalOpen={isRecalculateModalOpen}
        closeModalHandler={closeRecalculateModalHandler}
      />
      <CreateCategoryModal
        isModalOpen={isCreateCategoryModalOpen}
        closeModalHandler={closeCreateCategoryModalHandler}
      />
      <CreateRawModal
        isModalOpen={isCreateRawModalOpen}
        closeModalHandler={closeCreateRawModalHandler}
      />
    </div>
  )
}
export default ProductBody
