import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'

import {AppTable} from '../../../../components/antd-components/app-table'
import {
  shiftsLastUpdatedSelector,
  shiftsListLoadingSelector,
  shiftsListSelector,
  shiftsTableSettingsSelector,
  shiftsTotalCountSelector,
} from '../../../../redux/selectors/shiftSelector'
import useShiftsService from '../../../../services/shift/shiftService'
import useShiftsTable from '../../../../services/shift/shiftTableService'
import {
  accountingInitialState,
  setActiveAccountingTargetId,
  setStoreTableSettings,
} from '../../../../redux/slices/accountingSlice'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'

function ShiftsBody() {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()
  const shiftsList = useSelector(shiftsListSelector)
  const totalCount = useSelector(shiftsTotalCountSelector)
  const tableSettings = useSelector(shiftsTableSettingsSelector)
  const isLoading = useSelector(shiftsListLoadingSelector)
  const lastUpdated = useSelector(shiftsLastUpdatedSelector)

  const {setShiftsTableSettings, getShiftsList} = useShiftsService()

  const handleOpenShiftRelatedAccountings = ({storeId, shiftId}) => {
    dispatch(
      setStoreTableSettings({
        ...accountingInitialState.tableSettings.store,
        f_shiftId: shiftId,
      })
    )
    dispatch(setActiveAccountingTargetId(storeId))
    navigate('/accounting')
  }

  const shiftColumns = useShiftsTable({
    tableSettings,
    setShiftsTableSettings,
    handleOpenShiftRelatedAccountings,
  })

  useEffect(() => {
    getShiftsList(tableSettings)
  }, [lastUpdated])

  return (
    <div>
      <AppTable
        dataSource={shiftsList}
        columns={shiftColumns}
        totalCount={totalCount}
        tableSettings={tableSettings}
        setTableSettings={setShiftsTableSettings}
        isLoading={isLoading}
        rowClassName={(record) => {
          if (record.actualAmount < record.cashAmount) {
            return 'isWarning'
          }
          if (record.actualAmount > record.cashAmount) {
            return 'isError'
          }
          return ''
        }}
      />
    </div>
  )
}

export default ShiftsBody
