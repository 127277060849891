import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {SearchOutlined} from '../../../../components/antd-components/icon'
import useDebounce from '../../../../hooks/useDebounce'

import {createItemHeaderMenu} from '../../../../utils/helpers'

import {PageHeaderWithMobileMenu} from '../../../../components'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {priceTableSettingsSelector} from '../../../../redux/selectors/priceSelector'
import {priceInitialState} from '../../../../redux/slices/priceSlice'
import ResetFiltersButton from '../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'
import usePrice from '../../../../services/price/priceService'

function PriceHeader({setPriceTableSettings}) {
  const search = useSelector((state) => state.prices.tableSettings.f_name)
  const [searchValue, setSearchValue] = useState(search)
  const {exportExcel} = useCommon()
  const tableSettings = useSelector(priceTableSettingsSelector)
  const {resetPriceTableSettings} = usePrice()

  const debouncedValue = useDebounce(searchValue, 500)

  useEffect(() => {
    const debouncedSearchValue = !debouncedValue ? null : debouncedValue
    if (search !== debouncedSearchValue) {
      setPriceTableSettings({f_name: debouncedSearchValue})
    }
  }, [debouncedValue])

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `calculation/products`,
      tableSettings,
    })
  }

  const actionSupplies = [
    <SearchAntdComponent
      key={1}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu(actionSupplies)

  const clearFiltersHandler = () => {
    setSearchValue('')
    resetPriceTableSettings()
  }
  return (
    <PageHeaderWithMobileMenu
      title="Price"
      subTitle={
        <ResetFiltersButton
          clearFiltersHandler={clearFiltersHandler}
          currentTableSettings={tableSettings}
          initialTableSettings={priceInitialState.tableSettings}
        />
      }
      items={item}
      extra={actionSupplies}
    />
  )
}

export default PriceHeader
