import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import WriteOffHeader from './components/writeOffHeader'
import WriteOffConfirmBody from './components/WriteOffConfirmBody'
import {useWriteOffConfirm} from '../../../../services/write-off-reason/write-off-confrim/writeOffConfrimService'
import useUsers from '../../../../services/users/usersService'
import useStore from '../../../../services/storeServices/storeService'
import {useWriteOff} from '../../../../services/write-off-reason/writeOffReasonsService'

function WriteOffConfirm() {
  const {getWriteOffConfirm} = useWriteOffConfirm()
  const {getUsersData} = useUsers()
  const {getStoresData} = useStore()
  const {getWriteOffData} = useWriteOff()
  const writeOffConfirmTableSettings = useSelector(
    (state) => state.writeOffConfirm.tableSettings
  )
  const lastUpdated = useSelector((state) => state.writeOffConfirm.lastUpdated)

  useEffect(() => {
    getWriteOffConfirm(writeOffConfirmTableSettings)
  }, [lastUpdated])

  useEffect(() => {
    getUsersData()
    getStoresData()
    getWriteOffData()
  }, [])

  return (
    <div>
      <WriteOffHeader />
      <WriteOffConfirmBody />
    </div>
  )
}

export default WriteOffConfirm
