import {DatePicker} from 'antd'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import ApplyButton from '../main-buttons/apply-button/ApplyButton'
import {formattedDate} from '../../../constants'
import CancelButton from '../main-buttons/cancel-button/CancelButton'
import './style.scss'

const {RangePicker} = DatePicker
function DateRangeFilter(props) {
  const {
    setTableSettings,
    fromDateName,
    toDateName,
    defaultValue,
    rangePickerValue,
    setRangePickerValue,
  } = props
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const [isFilterChanged, setIsFilterChanged] = useState(false)
  const [filterValueBeforeChange, setFilterValueBeforeChange] = useState()

  const resetCurrentDateFilter = () => {
    setTableSettings({
      [fromDateName]: null,
      [toDateName]: null,
    })
  }

  useEffect(() => {
    if (!isFilterChanged) {
      setFilterValueBeforeChange(defaultValue)
    }
  }, [defaultValue, open])

  useEffect(() => {
    if (!open) {
      setFilterValueBeforeChange(undefined)
      setIsFilterChanged(false)
    }
  }, [open])

  const cancelButtonHandler = () => {
    setRangePickerValue(filterValueBeforeChange)
    setOpen(false)
  }

  const applyButtonHandler = () => {
    if (rangePickerValue) {
      const [dateFrom, dateTo] = rangePickerValue
      const correctDateFrom = dateFrom
        ? dateFrom.startOf('day').toISOString()
        : null
      const correctDateTo = dateTo ? dateTo.endOf('day').toISOString() : null
      setTableSettings({
        [fromDateName]: correctDateFrom,
        [toDateName]: correctDateTo,
      })
    } else {
      resetCurrentDateFilter()
    }
    setOpen(false)
  }

  const handleOpenChange = (open) => {
    if (open) {
      setOpen(open)
    }
  }

  const rangeNames = {
    THIS_WEEK: t('This week'),
    LAST_WEEK: t('Last week'),
    LAST_TWO_WEEKS: t('Last 2 weeks'),
    THIS_MONTH: t('This month'),
    LAST_MONTH: t('Last month'),
    THIS_YEAR: t('This year'),
  }

  const ranges = {
    [rangeNames.THIS_WEEK]: [moment().startOf('week'), moment().endOf('week')],
    [rangeNames.LAST_WEEK]: [
      moment().subtract(1, 'week').startOf('week'),
      moment().subtract(1, 'week').endOf('week'),
    ],
    [rangeNames.LAST_TWO_WEEKS]: [
      moment().subtract(2, 'weeks').startOf('week'),
      moment().subtract(1, 'week').endOf('week'),
    ],
    [rangeNames.THIS_MONTH]: [
      moment().startOf('month'),
      moment().endOf('month'),
    ],
    [rangeNames.LAST_MONTH]: [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
    [rangeNames.THIS_YEAR]: [moment().startOf('year'), moment().endOf('year')],
  }

  const onDateFilterChange = (dates) => {
    setRangePickerValue(dates)
    if (!isFilterChanged) {
      setIsFilterChanged(true)
    }
    if (!dates || dates.length === 0) {
      resetCurrentDateFilter()
      setOpen(false)
    }
    if (dates && dates.length === 2 && dates[0] && dates[1]) {
      const rangesArray = Object.values(ranges)
      for (let i = 0; i < rangesArray.length; i += 1) {
        const [start, end] = rangesArray[i]
        if (dates[0].isSame(start, 'day') && dates[1].isSame(end, 'day')) {
          const [dateFrom, dateTo] = dates
          const correctDateFrom = dateFrom.startOf('day').toISOString()
          const correctDateTo = dateTo.endOf('day').toISOString()
          setTableSettings({
            [fromDateName]: correctDateFrom,
            [toDateName]: correctDateTo,
          })
          setOpen(false)
        }
      }
    }
  }

  const getExtraFooter = () => (
    <div className="extraFooter_wrapper">
      <CancelButton onClick={cancelButtonHandler} />
      <ApplyButton onClick={applyButtonHandler} />
    </div>
  )

  return (
    <RangePicker
      format={formattedDate.Date}
      value={rangePickerValue}
      ranges={ranges}
      open={open}
      defaultValue={defaultValue}
      onOpenChange={handleOpenChange}
      allowEmpty={[true, true]}
      onChange={onDateFilterChange}
      renderExtraFooter={getExtraFooter}
    />
  )
}

export default DateRangeFilter
