import './style.scss'
import {Form} from 'antd'
import {useEffect, useMemo, useRef, useState} from 'react'
import {TableDetailsReadOnly} from '../../../../../../../../components/antd-components/app-table'
import useMovementItemsTable from './movementItemsTableSettings'

function MovementItemsTable(props) {
  const {
    movementForm,
    itemsList,
    setItemsList,
    onFormSubmit,
    isPageReadOnly,
    selectedFromIdStore,
    isLoading,
    activeTab,
  } = props
  const [activeColumn, setActiveColumn] = useState(null)
  const [activeItemsFields, setActiveItemsFields] = useState([])
  const [activeAmountFields, setActiveAmountFields] = useState([])
  const amountFieldRefs = useRef({})

  useEffect(() => {
    if (activeAmountFields.length) {
      const lastActiveAmountField = activeAmountFields.find(
        (field) => field.isFieldFocusActive
      )
      if (lastActiveAmountField) {
        amountFieldRefs?.current?.[lastActiveAmountField.id]?.focus()
      }
    }
  }, [activeAmountFields])
  const deleteItemHandler = (itemId) => {
    setItemsList(itemsList.filter((item) => item.id !== itemId))
  }

  const onFieldValueChange = (recordId, newValue, fieldName) => {
    if (fieldName === 'name') {
      const amountFieldName = `amount${recordId}`
      const amountValue = movementForm.getFieldValue(amountFieldName)
      if (amountValue?.toString().length > 0) {
        movementForm.validateFields([amountFieldName])
      }
      const changedItemsList = itemsList.map((item) => {
        if (item.id === recordId) {
          return {
            ...item,
            itemId: newValue,
          }
        }
        return item
      })
      setItemsList(changedItemsList)
    }
    if (fieldName === 'amount') {
      const changedItemsList = itemsList.map((item) => {
        if (item.id === recordId) {
          return {...item, amount: newValue}
        }
        return item
      })
      setItemsList(changedItemsList)
    }
  }

  const itemsListForActiveTab = useMemo(
    () => itemsList.filter((item) => item.itemType === activeTab),
    [itemsList, activeTab]
  )

  const {movementItemsColumns} = useMovementItemsTable({
    deleteItemHandler,
    itemsList,
    isPageReadOnly,
    selectedFromIdStore,
    onFieldValueChange,
    activeTab,
    activeColumn,
    setActiveColumn,
    activeItemsFields,
    setActiveItemsFields,
    activeAmountFields,
    setActiveAmountFields,
    amountFieldRefs,
  })
  return (
    <div>
      <Form form={movementForm} onFinish={onFormSubmit}>
        <TableDetailsReadOnly
          className="items_table hideScroll"
          columns={movementItemsColumns}
          selectedById={itemsListForActiveTab}
          isLoading={isLoading}
          isScrollYHidden={itemsListForActiveTab.length === 0}
          scrollY="calc(100vh - 22em)"
          scrollX="max-content"
        />
      </Form>
    </div>
  )
}

export default MovementItemsTable
