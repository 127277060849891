export const getTasksLoading = (state) => state.task.isLoading === 'getTasks'
export const setTasksLoading = (state) => state.task.isLoading === 'setTask'

export const lastUpdatedTasks = (state) => state.task.lastUpdated
export const tasksData = (state) => state.task.tasks
export const tasksTotalCount = (state) => state.task.totalCount
export const tasksTableSettings = (state) => state.task.tableSettings
export const tasksSelectedCheckbox = (state) => state.task.selectedTasksInTable
export const selectedTask = (state) => state.task.selectedTask
export const modalIsOpen = (state) => state.task.isOpenModalWindow

export const isLoadingSelectedTasks = (state) =>
  state.task.isLoading === 'getTask'
