import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const deleteMovementItem = createAsyncThunk(
  'DELETE_MOVEMENT',
  async (movementId) => {
    try {
      const response = await API.delete(
        `/store/products/move?ids[]=${movementId}`
      )
      if (response?.data?.statusCode === 200) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)
export const getMovements = createAsyncThunk(
  'GET_MOVEMENTS',

  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/store/products/move', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        movements: state.movements.movements,
        totalCount: state.movements.totalCount,
      }
    }
    return null
  }
)

export const getMovement = createAsyncThunk('GET_MOVEMENT', async ({id}) => {
  if (id) {
    try {
      const response = await API.get(`/store/products/move/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
  }
  return null
})

export const setMovement = createAsyncThunk('SET_MOVEMENT', async (data) => {
  try {
    const response = await API.post('/store/products/move', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const updateMovement = createAsyncThunk(
  'UPDATE_ARRIVAL',
  async (data) => {
    const {id} = data
    try {
      const response = await API.put(`/store/products/move/${id}`, data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const updateMovementConfirm = createAsyncThunk(
  'UPDATE_MOVEMENT_CONFIRM',
  async (data) => {
    const {id} = data
    try {
      const response = await API.put(`store/products/move/${id}/confirm`, data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)
