import {useLocation, useSearchParams} from 'react-router-dom'
import {useEffect} from 'react'
import useSetDefaultSearchQuery from './useSetDefaultSearchQuery'

const useSearchParamsUrl = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const location = useLocation()
  const {search} = location
  const decodeSearch = decodeURIComponent(search)
  const params = Object.fromEntries(new URLSearchParams(decodeSearch))
  const {setDefaultUrl} = useSetDefaultSearchQuery()

  useEffect(() => {
    setDefaultUrl(params, setSearchParams, searchParams)
  }, [])

  function onOpenModal(id) {
    const nextSearchParams = new URLSearchParams(searchParams)
    const value = id || 'add'
    if (id) {
      nextSearchParams.set('edit', value)
    } else {
      nextSearchParams.set('modal', value)
    }
    setSearchParams(nextSearchParams)
  }

  function onCloseModal() {
    const nextSearchParams = new URLSearchParams(searchParams)
    nextSearchParams.delete('edit')
    nextSearchParams.delete('modal')
    nextSearchParams.delete('name')
    setSearchParams(nextSearchParams)
  }

  const id = searchParams.get('edit')
  const add = searchParams.get('modal')
  const isOpen = Boolean(id) || Boolean(add)
  const isAddModalOpen = isOpen && add === 'add'
  const isEditModalOpen = isOpen && id !== 'add'

  const setFormValue = (name, value) => {
    const nextSearchParams = new URLSearchParams(searchParams)
    nextSearchParams.set(name, value)
    setSearchParams(nextSearchParams)
  }

  const changeParamsByTable = (tableSettingsValue, setTableSettings) => {
    const nextSearchParams = new URLSearchParams(searchParams)
    const getKeyValue = Object.entries(tableSettingsValue)
      .map(([key, value]) => ({
        key,
        value,
      }))
      .filter((value) => value.value !== null)
    nextSearchParams.delete('s_direction')
    nextSearchParams.delete('page')
    nextSearchParams.delete('f_storeId')
    getKeyValue.map((item) => nextSearchParams.set(item.key, item.value))
    setTableSettings({...tableSettingsValue})
    setSearchParams(nextSearchParams)
  }

  const changeSearchValue = (
    searchValue,
    setTableSettings,
    tableSettingsValue,
    name = 'f_name'
  ) => {
    const nextSearchParams = new URLSearchParams(searchParams)
    if (searchValue) {
      nextSearchParams.set(name, searchValue)
    } else {
      nextSearchParams.delete(name)
    }
    setSearchParams(nextSearchParams)
    setTableSettings({...tableSettingsValue, [name]: searchValue})
  }

  const setSelectedCheckBoxInTable = (ids, setSelectedComponent) => {
    const nextSearchParams = new URLSearchParams(searchParams)
    nextSearchParams.set('id', [ids])
    setSearchParams(nextSearchParams)
    setSelectedComponent(ids)
  }

  const setAutoCompleteValueInUrl = (id, name) => {
    const nextSearchParams = new URLSearchParams(searchParams)
    nextSearchParams.set(name, id)
    setSearchParams(nextSearchParams)
  }

  const setSearchLanguage = (language) => {
    const nextSearchParams = new URLSearchParams(searchParams)
    nextSearchParams.set('lang', language)
    setSearchParams(nextSearchParams)
  }

  return {
    onCloseModal,
    onOpenModal,
    isAddModalOpen,
    isEditModalOpen,
    setFormValue,
    params,
    searchParams,
    changeParamsByTable,
    changeSearchValue,
    setSelectedCheckBoxInTable,
    setAutoCompleteValueInUrl,
    setSearchParams,
    setSearchLanguage,
  }
}

export default useSearchParamsUrl
