import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Form} from 'antd'
import TasksBody from './components/TasksBody'
import TasksHeader from './components/TasksHeader'

import useTasksService from '../../../services/tasks/taskService'
import {
  lastUpdatedTasks,
  tasksTableSettings,
} from '../../../redux/selectors/tasksSelector'
import useUsers from '../../../services/users/usersService'

function TasksPage() {
  const {getTasksData} = useTasksService()
  const {getUsersData} = useUsers()
  const lastUpdated = useSelector(lastUpdatedTasks)
  const tableSettings = useSelector(tasksTableSettings)
  const [form] = Form.useForm()
  useEffect(() => {
    getTasksData(tableSettings)
  }, [lastUpdated])

  useEffect(() => {
    getUsersData()
  }, [])

  return (
    <div>
      <TasksHeader />
      <TasksBody form={form} />
    </div>
  )
}

export default TasksPage
