import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {useSelector} from 'react-redux'
import '../../../pages/main/accounting/style.scss'
import {
  convertToFractionalNumber,
  getColumnSorter,
} from '../../../utils/helpers'
import {formattedDate} from '../../../constants'
import {historyIngredientTableSettings} from '../../../redux/selectors/suppliesHistoryByIngredietSelector'
import {suppliersData} from '../../../redux/selectors/supplierSelector'
import {selectedSupplyId} from '../../../redux/selectors/supplySelector'
import {useDateRangeTableSettings} from '../../../hooks'
import useSuppliesHistoryByIngredient from './historySuppliesByIngredient'
import EditActionButton from '../../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'

const useHistorySupplyTable = ({
  filterAmountIcon,
  handleEdit,
  filterTotalPriceIcon,
  filterPriceOneIcon,
  rangeTotalPrice,
  rangeAmount,
  rangePriceOne,
}) => {
  const tableSettings = useSelector(historyIngredientTableSettings)
  const suppliers = useSelector(suppliersData)
  const {t} = useTranslation()
  const selectedSupply = useSelector(selectedSupplyId)

  const {setTableSettingsHistoryByIngredient} = useSuppliesHistoryByIngredient()

  const dateFilterProps = useDateRangeTableSettings({
    dateFromFilterName: 'f_startDate',
    dateToFilterName: 'f_endDate',
    tableSettings,
    setTableSettings: setTableSettingsHistoryByIngredient,
  })

  const getColumns = () => [
    {
      title: t('Supply date'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: '20%',
      ...dateFilterProps,
      filteredValue: '',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'date'),
      render: (value) => moment(value).format(formattedDate.Date),
    },
    {
      title: t('Suppliers'),
      dataIndex: 'supplierName',
      key: 'f_supplierId',
      align: 'center',
      width: '30%',
      filteredValue: tableSettings.f_supplierId,
      sortOrder: getColumnSorter(tableSettings, 'supplierName'),
      filters: suppliers.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      sorter: true,
    },

    {
      title: t('Amount'),
      dataIndex: 'amount',
      editable: true,
      align: 'center',
      filterDropdown: rangeAmount,
      filterIcon: filterAmountIcon,
      sorter: true,
      filteredValue: '',
      sortOrder: getColumnSorter(tableSettings, 'amount'),
      width: '15%',
    },

    {
      title: t('Price One'),
      dataIndex: 'priceOne',
      editable: true,
      align: 'right',
      width: '15%',
      filteredValue: '',
      filterDropdown: rangePriceOne,
      filterIcon: filterPriceOneIcon,
      sortOrder: getColumnSorter(tableSettings, 'priceOne'),
      sorter: true,
      render: (value) => <span>{convertToFractionalNumber(value, 2)}</span>,
    },
    {
      title: t('Total price'),
      dataIndex: 'priceTotal',
      align: 'right',
      filterDropdown: rangeTotalPrice,
      filterIcon: filterTotalPriceIcon,
      sorter: true,
      filteredValue: '',
      sortOrder: getColumnSorter(tableSettings, 'priceTotal'),
      width: '15%',
      render: (value) => <span>{convertToFractionalNumber(value, 2)}</span>,
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '7%',
      render: (_, record) => (
        <div>
          <EditActionButton
            onClick={() => {
              handleEdit(record.supplyId)
            }}
          />
        </div>
      ),
    },
  ]
  return {historySupplyColumns: getColumns()}
}

export default useHistorySupplyTable
