import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {useState} from 'react'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'
import '../../../../services/norm/normAddService/style.scss'
import useNormTableService from '../../../../services/norm/normTableService'
import showConfirm from '../../../../components/PopupConfirm'
import useNormService from '../../../../services/norm/normService'
import NormDetail from './NormDetail'
import '../style.scss'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'

function NormBody() {
  const {t} = useTranslation()
  const {
    normDelete,
    getNormDetail,
    setNormTableSettings,
    setSelectedNorm,
    setSelectedNormId,
  } = useNormService()

  const navigate = useCustomNavigate()
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false)
  const editNorm = (id) => {
    setSelectedNormId(id)
    navigate(`/norm/${id}`)
  }

  const getDetailNorm = (id) => {
    getNormDetail({id, params: {withDeletedStoresRecords: true}})
  }

  const norms = useSelector((state) => state.norm.norms)

  const totalCount = useSelector((state) => state.norm.totalCount)
  const isLoading = useSelector((state) => state.norm.isLoading === 'getNorm')
  const selectedNorms = useSelector((state) => state.norm.selectedNormInTable)
  const tableSettings = useSelector((state) => state.norm.tableSettings)

  const handleDeleteSubmit = (id) => {
    normDelete({ids: [id]})
  }

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleDeleteSubmit(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const {normColumns} = useNormTableService({
    onEdit: editNorm,
    onDelete: confirmDelete,
    showDetailInformation: getDetailNorm,
    setIsOpenDetailModal,
    tableSettings,
  })

  const handleEditClose = () => {
    setIsOpenDetailModal(false)
  }

  return (
    <div>
      <AntdTableActionModal
        title={t('Norm information')}
        className="planning modal"
        handleCancel={handleEditClose}
        open={isOpenDetailModal}
        fullWidth
      >
        <NormDetail />
      </AntdTableActionModal>
      <AppTable
        dataSource={norms}
        columns={normColumns}
        isLoading={isLoading}
        totalCount={totalCount}
        setSelectedComponent={setSelectedNorm}
        selectedRowKeys={selectedNorms}
        showSelectRowCheckbox
        tableSettings={tableSettings}
        setTableSettings={setNormTableSettings}
      />
    </div>
  )
}

export default NormBody
