import {Outlet} from 'react-router-dom'

import '../style.scss'
import './style.scss'
import {Button, Grid, Layout, Menu} from 'antd'

import {useTranslation} from 'react-i18next'
import {BarsOutlined, SearchOutlined} from '@ant-design/icons'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {navbarList} from '../../constants'
import SearchAntdComponent from '../antd-components/search-component/SearchComponent'
import AppNavBar from '../AppNavBar'
import useLanguageInUrl from '../../hooks/useLanguageInUrl'
import useCustomNavigate from '../../hooks/useCustomNavigate'

const {Header, Content, Sider} = Layout
const {useBreakpoint} = Grid

function LayoutComponent() {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const {activePage} = useLanguageInUrl()
  const [collapsedWidth, setCollapsedWidth] = useState('50')

  const user = useSelector((state) => state.auth.user)

  const screens = useBreakpoint()
  const [collapsed, setCollapsed] = useState(false)

  function renderNestedItem(nodes) {
    if (!Array.isArray(nodes.children) || !nodes.children.length) {
      return {
        ...nodes,
        label: (
          <a
            className="menu_item"
            onClick={(e) => e.preventDefault()}
            href={nodes.key}
          >
            {t(nodes.label)}
          </a>
        ),
      }
    }

    return {
      ...nodes,
      label: t(nodes.label),
      children: nodes.children.map((node) => renderNestedItem(node)),
    }
  }

  useEffect(() => {
    if (screens.sm && !screens.lg && !screens.md) {
      setCollapsed(true)
      setCollapsedWidth('50')
    }
    if (screens.md && screens.lg && screens.sm) {
      setCollapsed(false)
    }
    if (screens.xs) {
      setCollapsedWidth('0')
    }
  }, [screens])

  const navList = navbarList.map((item) => renderNestedItem(item))

  const filterTypeAdmin =
    user.type === 'admin'
      ? navList
      : navList.filter((item) => item.sellers !== 'none')

  const onItemClick = (path) => navigate(`/${path}`)
  return (
    <Layout hasSider>
      <div className="layout_wrapper">
        <Sider
          trigger={null}
          collapsible
          breakpoint="xs"
          collapsedWidth={collapsedWidth}
          collapsed={collapsed}
          className="sideBar layout_wrapper_sider"
        >
          <div className="logo">
            {!collapsed ? (
              <SearchAntdComponent />
            ) : (
              <SearchOutlined onClick={() => setCollapsed(!collapsed)} />
            )}
          </div>
          <Menu
            mode="inline"
            theme="light"
            selectedKeys={[activePage]}
            items={filterTypeAdmin}
            onClick={(e) => {
              onItemClick(e.key)
            }}
          />
        </Sider>
      </div>

      <Layout>
        <Header className="layout_header">
          <div className="layout_header_wrapper">
            <div className="layout_header_wrapper_parent_collapsed_button">
              <Button
                onClick={() => setCollapsed(!collapsed)}
                icon={
                  <BarsOutlined className="layout_header_wrapper_collapsed_button" />
                }
                type="link"
              />
            </div>
            <div className="layout_app_nav_bar_wrapper">
              <AppNavBar />
            </div>
          </div>
        </Header>
        <Content className="layout_content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default LayoutComponent
