import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

function PriceValidation() {
  const {t} = useTranslation()
  const schema = yup.object().shape({
    price: yup
      .number()
      .typeError(t('Value must be an number'))
      .required(t('This field is required.'))
      .min(0, t('Value cannot be less than zero'))
      .transform((_, value) => {
        if (String(value).includes(',')) {
          return +value.replace(/,/, '.')
        }
        if (!value || value?.trim() === '') {
          return undefined
        }
        return +value
      }),

    marginRequired: yup
      .number()
      .required(t('This field is required.'))
      .typeError(t('This field is required.'))
      .min(0, t('Value cannot be less than zero'))
      .transform((_, value) => {
        if (String(value).includes(',')) {
          return +value.replace(/,/, '.')
        }
        return +value
      }),
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default PriceValidation
