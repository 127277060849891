import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import useStore from '../../../services/storeServices/storeService'
import MovementsHeader from './components/MovementsHeader'
import MovementsBody from './components/MovementsBody'
import useProducts from '../../../services/products/productsService'
import useMovements from '../../../services/movements/movementsService'
import useUsers from '../../../services/users/usersService'
import useRawMaterials from '../../../services/raw-materials/rawMaterialsService'

function MovementsPage() {
  const storeProps = useStore()
  const {getStoresData} = storeProps
  const productsProps = useProducts()
  const {getProductsData} = productsProps
  const movementsProps = useMovements()
  const {getRawMaterialsData} = useRawMaterials()
  const {getMovementsData, setMovementTableSettings} = movementsProps
  const usersProps = useUsers()
  const {getUsersData} = usersProps
  const selectedMovement = useSelector(
    (state) => state.movements.selectedMovement
  )
  const movementsLastUpdated = useSelector(
    (state) => state.movements.lastUpdated
  )
  const movementsTableSettings = useSelector(
    (state) => state.movements.tableSettings
  )

  useEffect(() => {
    getMovementsData(movementsTableSettings)
  }, [movementsLastUpdated])

  useEffect(() => {
    getStoresData()
    getProductsData()
    getUsersData()
    getRawMaterialsData()
  }, [])

  return (
    <div>
      <MovementsHeader />
      <MovementsBody
        selectedMovement={selectedMovement}
        movementsTableSettings={movementsTableSettings}
        setMovementTableSettings={setMovementTableSettings}
      />
    </div>
  )
}

export default MovementsPage
