import {Button} from 'antd'
import {ClearOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import _ from 'lodash'

function ResetFiltersButton(props) {
  const {clearFiltersHandler, currentTableSettings, initialTableSettings} =
    props
  const {t} = useTranslation()
  const isResetButtonDisabled = useMemo(() => {
    const isEqual = _.isEqual(currentTableSettings, initialTableSettings)
    return isEqual
  }, [currentTableSettings, initialTableSettings])

  return (
    <Button
      type="link"
      title={t('Clear all filters')}
      disabled={isResetButtonDisabled}
      onClick={clearFiltersHandler}
    >
      <ClearOutlined />
    </Button>
  )
}
export default ResetFiltersButton
