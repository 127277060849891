import {useSelector} from 'react-redux'
import {useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import useMovementsTable from '../../../../services/movements/movementsTableSettings'
import {
  AntdTableActionModal,
  AppTable,
  TableDetailsReadOnly,
} from '../../../../components/antd-components/app-table'
import useSentProductTable from '../../../../services/sentProductsDetail'
import {getRandomId} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import useMovements from '../../../../services/movements/movementsService'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import {formattedDate, tableRowClassNames} from '../../../../constants'
import ModalHeaderMetaData from '../../../../components/antd-components/app-table/ModalHeaderMetaData'
import {LoadingSpinner} from '../../../../components/antd-components/loading-spinner'
import ItemsTabs from '../../../../components/itemsTabs-component/ItemsTabs'
import './style.scss'

function MovementsBody({
  movementsTableSettings,
  selectedMovement,
  setMovementTableSettings,
}) {
  const [isEditDialogOpen, setEditDialogOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('product')
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const {deleteMovement, getMovementById, resetSelectedMovementData} =
    useMovements()
  const {movements} = useSelector((state) => state.movements)
  const totalCount = useSelector((state) => state.movements.totalCount)
  const isLoading = useSelector(
    (state) => state.movements.isLoading === 'getMovements'
  )
  const isLoadingSelectedMovement = useSelector(
    (state) => state.movements.isLoading === 'getMovement'
  )

  useEffect(() => {
    if (!isEditDialogOpen) {
      resetSelectedMovementData()
    }
  }, [isEditDialogOpen])

  const itemsList = useMemo(() => {
    if (selectedMovement) {
      const {productsSent, semiFinishedSent, rawsSent} = selectedMovement
      const {productsReceived, semiFinishedReceived, rawsReceived} =
        selectedMovement
      const itemsReceivedData = [
        ...productsReceived,
        ...semiFinishedReceived,
        ...rawsReceived,
      ]
      const itemsSentData = [...productsSent, ...semiFinishedSent, ...rawsSent]
      return itemsSentData.map((item) => {
        const {itemType, itemId, amount} = item
        const itemName =
          itemType === 'raw' ? item.raw?.name : item.product?.name
        const receivedAmount = itemsReceivedData.find(
          (receivedItem) =>
            receivedItem.itemId === item.itemId &&
            receivedItem.itemType === item.itemType
        )?.amount
        return {itemType, itemId, sentAmount: amount, receivedAmount, itemName}
      })
    }
    return []
  }, [selectedMovement])

  const itemsDataForActiveTab = useMemo(
    () => itemsList.filter((item) => item.itemType === activeTab),
    [activeTab, itemsList]
  )

  const handleEdit = (record) => {
    navigate(`/movements/edit/${record.id}`)
  }

  const getInfoSentMovement = (id) => {
    getMovementById({id})
    setEditDialogOpen(true)
  }

  const handleEditClose = () => {
    setEditDialogOpen(false)
  }

  const confirmDeleteMovement = (movementId) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => deleteMovement(movementId),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const {movementsColumns} = useMovementsTable({
    handleEdit,
    getInfoSentMovement,
    confirmDeleteMovement,
  })

  const {sentProductsColumns} = useSentProductTable()

  const detailsMovementInformation = useMemo(
    () => [
      {
        key: getRandomId(),
        name: t('Update date'),
        value: moment(selectedMovement?.updatedDate).format(
          formattedDate.DateWithTime
        ),
      },
      {
        key: getRandomId(),
        name: t('Create date'),
        value: moment(selectedMovement?.createdDate).format(
          formattedDate.DateWithTime
        ),
      },
      {
        key: getRandomId(),
        name: t('Store from'),
        value: selectedMovement?.fromStore?.name,
      },
      {
        key: getRandomId(),
        name: t('Store to'),
        value: selectedMovement?.toStore?.name,
      },
      {
        key: getRandomId(),
        name: t('Created'),
        value: selectedMovement?.createdUser?.name,
      },
      {
        key: getRandomId(),
        name: t('Confirmed'),
        value: selectedMovement?.updatedUser?.name || '-',
      },
    ],
    [selectedMovement]
  )

  const movementDetailsRowClassName = (record) => {
    if (record && record.receivedAmount !== record.sentAmount) {
      return tableRowClassNames.IS_ERROR
    }
    return null
  }

  const getMovementsRowClassName = (record) => {
    if (record?.hasMismatchedProducts) {
      return tableRowClassNames.IS_ERROR
    }
    return 'default_row'
  }

  const itemsTable = (
    <TableDetailsReadOnly
      className="hideScroll"
      scrollY="calc(100vh - 25em)"
      columns={sentProductsColumns}
      isScrollYHidden={itemsDataForActiveTab.length === 0}
      selectedById={itemsDataForActiveTab}
      rowClassName={movementDetailsRowClassName}
    />
  )

  return (
    <div>
      <AntdTableActionModal
        centered
        title={t('Movement information')}
        handleCancel={handleEditClose}
        open={isEditDialogOpen}
        fullWidth
      >
        {isLoadingSelectedMovement ? (
          <LoadingSpinner />
        ) : (
          <div>
            <ModalHeaderMetaData
              items={detailsMovementInformation}
              isLoading={isLoading}
            />
            <ItemsTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              itemsTable={itemsTable}
              itemsList={itemsList}
            />
          </div>
        )}
      </AntdTableActionModal>
      <AppTable
        className="movements_table"
        columns={movementsColumns}
        dataSource={movements}
        setTableSettings={setMovementTableSettings}
        totalCount={totalCount}
        tableSettings={movementsTableSettings}
        isLoading={isLoading}
        rowClassName={getMovementsRowClassName}
      />
    </div>
  )
}

export default MovementsBody
