import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getInventory = createAsyncThunk(
  'GET_INVENTORY',
  async ({params = {}}) => {
    try {
      const response = await API.get('/inventories', {params})

      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const getInventoryById = createAsyncThunk(
  'GET_INVENTORY_BY_ID',
  async ({id}) => {
    if (id) {
      try {
        const response = await API.get(`/inventories/${id}`)
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  }
)

export const getInventoryHistory = createAsyncThunk(
  'GET_INVENTORY_HISTORY',
  async ({itemId, itemType, storeId, params}) => {
    try {
      const response = await API.get(
        `/store/balance/history/${storeId}/itemId/${itemId}/itemType/${itemType}`,
        {
          params,
        }
      )
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }

    return null
  }
)
