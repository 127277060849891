import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'
import {checkIsResponseSuccess} from '../../utils/helpers'

export const getProducts = createAsyncThunk(
  'GET_PRODUCTS',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/calculation/products', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        products: state.products.products,
        totalCount: state.products.totalCount,
      }
    }
    return null
  }
)

export const getProductsBalance = createAsyncThunk(
  'GET_PRODUCTS_BALANCE',
  async ({params = {}}, {rejectWithValue}) => {
    try {
      const response = await API.get(`/store/products/balance-product/`, {
        params,
      })
      if (checkIsResponseSuccess(response.data.statusCode)) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getProduct = createAsyncThunk('GET_PRODUCT', async ({id}) => {
  if (id) {
    try {
      const response = await API.get(`/calculation/products/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
  }
  return null
})

export const setProduct = createAsyncThunk(
  'SET_PRODUCT',
  async (data, {rejectWithValue}) => {
    try {
      const response = await API.post('/calculation/products/new', data)
      if (checkIsResponseSuccess(response?.data?.statusCode)) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  }
)

export const updateProduct = createAsyncThunk(
  'UPDATE_PRODUCT',
  async (data, {rejectWithValue}) => {
    const {id} = data
    try {
      const response = await API.put(`/calculation/products/new/${id}`, data)
      if (checkIsResponseSuccess(response?.data?.statusCode)) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  }
)

export const deleteProducts = createAsyncThunk(
  'DELETE_PRODUCTS',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete('/calculation/products', {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {...response.data.payload, selectedProducts: []}
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedProducts: state.products.selectedProducts,
      }
    }
    return null
  }
)
