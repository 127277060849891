import {useEffect, useState} from 'react'
import {Form} from 'antd'
import moment from 'moment'
import {useSelector} from 'react-redux'

import currency from 'currency.js'
import PlanningAddHeader from './PlanningAddHeader'
import PlanningAddBody from './PlanningAddBody'
import useNormService from '../../../../services/norm/normService'
import useStore from '../../../../services/storeServices/storeService'
import useProducts from '../../../../services/products/productsService'
import useStoreBalance from '../../../../services/store-balance/storeBalanceService'
import {recordsSelectedPlanning} from '../../../../redux/selectors/planningSelecor'
import usePlanningService from '../../../../services/plunning/planningService'

function PlanningAddPage() {
  const {getNormData} = useNormService()
  const {getStoresData} = useStore()
  const {getProductsData} = useProducts()
  const {getStoresBalance} = useStoreBalance()
  const {addNewPlanning} = usePlanningService()
  const selectedPlanningRecords = useSelector(recordsSelectedPlanning)
  const [formData, setFormData] = useState('')
  const [form] = Form.useForm()
  const [filterColumnsForm] = Form.useForm()

  const [awaitSelectedPlanningRecords, setAwaitSelectedPlanningRecords] =
    useState(false)

  useEffect(() => {
    getNormData()
    getStoresData()
    getProductsData({f_type: ['final']})
    getStoresBalance()
  }, [])

  const setValueInState = (value) => {
    setFormData(value)
    setAwaitSelectedPlanningRecords(true)
  }

  const submit = () => {
    const {storeNormId, planningDate} = formData
    addNewPlanning({
      storeNormId,
      planningDate: moment(planningDate),
      products: selectedPlanningRecords?.map(
        ({id, name, total, status, active, ...item}) => ({
          ...item,
          records: item.records
            .filter((current) => current.storeId !== null)
            .map((item) => ({
              storeId: item.storeId,
              storeBalance: item.balanceAmount || 0,
              planningAmount:
                currency(item.necessaryAmount, {precision: 6}).value || 0,
            })),
        })
      ),
    })
  }

  useEffect(() => {
    if (selectedPlanningRecords?.length && awaitSelectedPlanningRecords) {
      submit()
      setAwaitSelectedPlanningRecords(false)
    }
  }, [awaitSelectedPlanningRecords, selectedPlanningRecords])

  return (
    <div>
      <PlanningAddHeader
        form={form}
        onSubmitAdd={setValueInState}
        filterColumnsForm={filterColumnsForm}
      />
      <PlanningAddBody
        form={form}
        onSubmitAdd={setValueInState}
        filterColumnsForm={filterColumnsForm}
      />
    </div>
  )
}

export default PlanningAddPage
