import ItemsTable from '../items-table/ItemsTable'
import {getRandomId} from '../../../../../../../../utils/helpers'
import './style.scss'
import AddButton from '../../../../../../../../components/antd-components/main-buttons/add-button/AddButton'
import ItemsTabs from '../../../../../../../../components/itemsTabs-component/ItemsTabs'

function RefillItemsTabs(props) {
  const {
    refillForm,
    itemsList,
    setItemsList,
    onFormSubmit,
    isPageReadOnly,
    activeTab,
    setActiveTab,
  } = props
  const addItemHandler = () => {
    const emptyItem = {
      id: getRandomId(),
      itemId: '',
      itemType: activeTab,
      amount: '',
    }
    setItemsList([emptyItem, ...itemsList])
  }

  const RefillItemsTable = (
    <ItemsTable
      isPageReadOnly={isPageReadOnly}
      activeTab={activeTab}
      refillForm={refillForm}
      itemsList={itemsList}
      setItemsList={setItemsList}
      onFormSubmit={onFormSubmit}
    />
  )

  return (
    <div className="items_tabs">
      <ItemsTabs
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        itemsTable={RefillItemsTable}
        itemsList={itemsList}
        tabBarExtraContent={
          <AddButton onClick={addItemHandler} disabled={isPageReadOnly} />
        }
      />
    </div>
  )
}

export default RefillItemsTabs
