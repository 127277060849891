import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import PriceHeader from './components/PriceHeader'
import PriceBody from './components/PriceBody'

import usePrice from '../../../services/price/priceService'
import useCategory from '../../../services/category/categoryService'
import useUnits from '../../../services/units/unitsService'
import useProducts from '../../../services/products/productsService'

function Price() {
  const priceProps = usePrice()
  const {getCategoriesData} = useCategory()
  const {getUnitsData} = useUnits()
  const {getProductsData} = useProducts()
  const {
    setPriceTableSettings,
    getPricesData,
    getDetailInformationBySelectedPrice,
  } = priceProps

  const pricesLastUpdated = useSelector((state) => state.prices.lastUpdated)
  const pricesTableSettings = useSelector((state) => state.prices.tableSettings)

  useEffect(() => {
    getPricesData(pricesTableSettings)
  }, [pricesLastUpdated])

  useEffect(() => {
    getCategoriesData()
    getUnitsData()
    getProductsData()
    getDetailInformationBySelectedPrice({id: null})
  }, [])
  return (
    <>
      <PriceHeader setPriceTableSettings={setPriceTableSettings} />
      <PriceBody {...priceProps} />
    </>
  )
}

export default Price
