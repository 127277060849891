import {Input} from 'antd'

function TextInputCenter(props) {
  const {className, fieldRef, ...rest} = props
  return (
    <Input ref={fieldRef} className={`centered_input ${className}`} {...rest} />
  )
}

export default TextInputCenter
