import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Outlet} from 'react-router-dom'
import {notification} from 'antd'

function SnackbarOutlet() {
  const message = useSelector((state) => state.notification.message)
  const variant = useSelector((state) => state.notification.variant)
  const lastUpdated = useSelector((state) => state.notification.lastUpdated)

  useEffect(() => {
    if (message && variant) {
      notification.open({
        message,
        type: variant,
      })
    }
  }, [lastUpdated])

  return <Outlet />
}

export default SnackbarOutlet
