import {useTranslation} from 'react-i18next'
import {Button, Tooltip} from 'antd'
import {EditOutlined} from '@ant-design/icons'
import './style.scss'

function EditButton(props) {
  const {t} = useTranslation()
  const {tooltipPlacement, tooltipText, className, buttonText, ...rest} = props

  return (
    <div className="editButton_wrapper">
      <Tooltip placement={tooltipPlacement} title={tooltipText}>
        <Button type="primary" className={`${className} edit_button`} {...rest}>
          <EditOutlined />
          {t('Edit') ?? buttonText}
        </Button>
      </Tooltip>
    </div>
  )
}

export default EditButton
