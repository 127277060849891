import {createSlice} from '@reduxjs/toolkit'
import {
  deleteCategories,
  getCategories,
  getCategory,
  setCategory,
  updateCategory,
} from '../asyncServices/categoryService'
import {resetReduxStore} from '../resetStore'

export const categoriesInitialState = {
  categories: [],
  totalCount: 0,
  selectedCategories: [],
  selectedCategory: '',
  treeSettings: {
    s_direction: 'asc',
    pageSize: 250,
    searchCategories: null,
  },
  lastUpdated: null,
  isLoading: null,
}

const categorySlice = createSlice({
  name: 'categoriesSlice',
  initialState: categoriesInitialState,
  reducers: {
    setSelectedCategories: (state, action) => {
      state.selectedCategories = action.payload
    },
    setSearchValueCategories: (state, action) => {
      state.treeSettings.searchCategories = action.payload
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    clearCategoriesTreeSettings: (state) => {
      state.treeSettings = categoriesInitialState.treeSettings
      state.lastUpdated = Date.now()
    },
    categoriesReducerReset: () => categoriesInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.isLoading = 'getCategories'
    })
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categories = action.payload.categories
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getCategory.pending, (state) => {
      state.isLoading = 'getCategory'
    })
    builder.addCase(getCategory.fulfilled, (state, action) => {
      state.selectedCategory = action.payload
      state.isLoading = null
    })
    builder.addCase(setCategory.pending, (state) => {
      state.isLoading = 'setCategory'
    })
    builder.addCase(setCategory.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(updateCategory.pending, (state) => {
      state.isLoading = 'updateCategory'
    })
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(deleteCategories.pending, (state) => {
      state.isLoading = 'deleteCategories'
    })
    builder.addCase(deleteCategories.fulfilled, (state, action) => {
      state.selectedCategories = action.payload.selectedCategories
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = categoriesInitialState
    })
  },
})

export const {
  setSelectedCategories,
  setLastUpdated,
  setSearchValueCategories,
  categoriesReducerReset,
  clearCategoriesTreeSettings,
} = categorySlice.actions
export default categorySlice.reducer
