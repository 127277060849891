import {useTranslation} from 'react-i18next'
import {PageHeader} from 'antd'
import {useNavigate} from 'react-router-dom'

function SupplyAddHeader({header}) {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const navigateSupplies = () => {
    navigate(-1)
  }

  return (
    <PageHeader
      className="site-page-header"
      onBack={() => navigateSupplies()}
      title={t(header)}
    />
  )
}

export default SupplyAddHeader
