import ReportsTable from './components/reports-table/ProfitsTable'

function ProfitsPageBody() {
  return (
    <div>
      <ReportsTable />
    </div>
  )
}
export default ProfitsPageBody
