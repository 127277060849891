import {createAsyncThunk} from '@reduxjs/toolkit'
import {saveAs} from 'file-saver'
import API from './apiService'

export const exportTableExcel = createAsyncThunk(
  'EXPORT_HANDLER',
  async ({tableName, tableSettings}, {rejectWithValue}) => {
    try {
      const response = await API.get(`/${tableName}/export`, {
        params: tableSettings,
        responseType: 'blob',
      })
      if (response) {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        const fileName = response.headers['content-disposition']
          .split('=')
          .pop()
        saveAs(blob, fileName)
      }
      return rejectWithValue(response)
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  }
)
