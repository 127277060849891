import {checkIsResponseSuccess} from './helpers'

export const serverRequestAsyncWrapper =
  (callback) =>
  async (...params) => {
    const {rejectWithValue} = params[1]
    try {
      const response = await callback(...params)
      if (checkIsResponseSuccess(response?.data?.statusCode)) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  }
