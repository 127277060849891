import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import deepEqual from 'deep-equal'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import OrderForm from './components/form/OrderForm'
import {ordersApi} from '../../../services/api/ordersApiService'
import LoaderComponent from '../../../components/loaderComponent/LoaderComponent'
import notify from '../../../components/antd-components/notification/notify'
import useCustomNavigate from '../../../hooks/useCustomNavigate'
import {
  checkIsResponseSuccess,
  compareObjectValues,
} from '../../../utils/helpers'
import useOrderByIdService from '../../../services/orders/useOrderByIdService'
import showConfirm from '../../../components/PopupConfirm'

function EditOrderPage() {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()

  const {id} = useParams()

  const {order, isOrderLoading, getOrder} = useOrderByIdService()

  useEffect(() => {
    getOrder(id)
  }, [])

  const handleUpdateOrderData = async (values) => {
    const {
      name,
      phone,
      deliveryDate,
      deliveryDateTo,
      deliveryType,
      deliveryFrom,
      deliveryPrice,
      deliveryAddress,
      externalId,
      total,
      orderProducts,
    } = values

    try {
      const response = await ordersApi
        .updateOrder(id, {
          name,
          phone,
          deliveryDate,
          deliveryDateTo,
          deliveryType,
          deliveryFrom,
          deliveryPrice: deliveryPrice ? Number(deliveryPrice) : 0,
          deliveryAddress,
          externalId,
          total,
          orderProducts: orderProducts.map(
            ({id, productId, name, amount, price, total, comment}) => ({
              id: id.toString().includes('local') ? undefined : id,
              productId,
              name,
              amount: Number(amount),
              price: Number(price),
              total,
              comment,
            })
          ),
          delivered: values.delivered,
        })
        .then((response) => {
          if (checkIsResponseSuccess(response?.status)) {
            notify({
              description: t('Success update order'),
              type: 'success',
            })
            navigate('/orders')
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmit = async (values) => {
    const newValues = {
      ...values,
      deliveryDate: values.deliveryDate.toISOString(),
      deliveryDateTo: values.deliveryDateTo
        ? values.deliveryDateTo.toISOString()
        : null,
    }
    const areOrderDetailsChanged = !compareObjectValues(
      [
        'name',
        'phone',
        'deliveryDate',
        'deliveryDateTo',
        'deliveryType',
        'deliveryFrom',
        'deliveryPrice',
        'deliveryAddress',
        'externalId',
        'delivered',
      ],
      newValues,
      order
    )

    const checkOrdersCallback = ({amount, comment, productId, price}) => ({
      amount,
      comment,
      productId,
      price,
    })

    const areOrderProductsChanged = !deepEqual(
      order.orderProducts.map(checkOrdersCallback),
      newValues.orderProducts.map(checkOrdersCallback)
    )

    if (areOrderDetailsChanged || areOrderProductsChanged) {
      return showConfirm({
        title: t('Confirm action'),
        content: t('Order has been changed'),
        okFunction: () => handleUpdateOrderData(newValues),
        icon: <ExclamationCircleOutlined />,
      })
    }

    return navigate('/orders')
  }

  return (
    <LoaderComponent
      isLoadingCreator={[isOrderLoading]}
      component={<OrderForm order={order} onSubmit={handleSubmit} />}
    />
  )
}

export default EditOrderPage
