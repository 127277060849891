import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'
import {checkIsResponseSuccess} from '../../utils/helpers'

export const getNorms = createAsyncThunk(
  'GET_NORMS',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/store-norms', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        movements: state.norm.norm,
        totalCount: state.norm.totalCount,
      }
    }
    return null
  }
)

export const getNormById = createAsyncThunk(
  'GET_NORM',
  async ({id, params = {}}) => {
    if (id) {
      try {
        const response = await API.get(`/store-norms/${id}`, {params})
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  }
)

export const setNorm = createAsyncThunk('SET_NORM', async (data) => {
  try {
    const response = await API.post('/store-norms', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const deleteNorm = createAsyncThunk(
  'DELETE_NORM',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete(`/store-norms/`, {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {...response.data.payload}
      }
    } catch (e) {
      console.log(e)
      const state = getState()
    }
    return null
  }
)

export const updateNorm = createAsyncThunk('UPDATE_NORM', async (data) => {
  const {id} = data
  try {
    const response = await API.put(`/store-norms/${id}`, data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const copyNorms = createAsyncThunk(
  'COPY_NORMS',
  async (ids, {rejectWithValue}) => {
    try {
      const response = await API.post(`/store-norms/copy`, null, {
        params: {ids},
      })
      if (checkIsResponseSuccess(response?.data?.statusCode)) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  }
)
