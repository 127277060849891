import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

function SuppliesValidation() {
  const {t} = useTranslation()
  const schema = yup.lazy((value) => {
    if (value !== undefined) {
      return yup.object().shape({
        [Object.keys(value).toString()]: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable()
          .required(t('This field is required'))

          .min(0, t('Value cannot be less than zero')),
        rawId: yup.string().required(t('This field is required.')),
        priceOne: yup
          .number()
          .nullable()
          .required(t('This field is required.'))
          .typeError(t('This field is required.'))
          .positive(t('Value cannot be 0 or less than zero')),
        amount: yup
          .number()
          .required(t('This field is required.'))
          .typeError(t('This field is required.'))

          .positive(t('Value cannot be 0 or less than zero')),
        supplierId: yup.string().required(t('This field is required.')),
        date: yup.string().required(t('This field is required.')).nullable(),
      })
    }
    return yup.mixed().notRequired()
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default SuppliesValidation
