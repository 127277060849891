import {useTranslation} from 'react-i18next'
import {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import moment from 'moment'
import {Col, Row} from 'antd'
import {useSelector} from 'react-redux'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import usePurchaseProductsTable from '../../../../../services/purchase/purchaseProductsTableService'
import TableDetailsReadOnly from '../../../../../components/antd-components/app-table/table/TableDetailsReadOnly'
import usePurchaseService from '../../../../../services/purchase/purchaseService'
import ListComponent from '../../../../../components/antd-components/listComponent/ListComponent'
import {LoadingSpinner} from '../../../../../components/antd-components/loading-spinner'
import {
  convertToFractionalNumber,
  getPaymentMethodIcon,
  getRandomId,
} from '../../../../../utils/helpers'
import './style.scss'
import {formattedDate, URL_PARAMS} from '../../../../../constants'

function PurchaseDetails() {
  const {getPurchaseById, selectedPurchase, resetSelectedPurchase} =
    usePurchaseService()
  const {purchaseProductsColumns} = usePurchaseProductsTable()
  const {t} = useTranslation()
  const screens = useBreakpoint()
  const {isLoading} = useSelector((state) => state.purchase)
  const [searchParams] = useSearchParams()
  const [soldItems, setSoldItems] = useState([])

  useEffect(() => {
    if (selectedPurchase) {
      const soldProductsList = selectedPurchase.soldProducts.map(
        (soldProduct) => ({
          id: soldProduct.id,
          name: soldProduct.productData?.name,
          totalPrice: soldProduct.totalPrice,
          amount: soldProduct.amount,
          pricePerItem: soldProduct.pricePerItem,
        })
      )
      if (selectedPurchase.orderPayment) {
        const {orderPayment} = selectedPurchase
        const orderData = {
          id: orderPayment.orderId,
          name: orderPayment.name,
          amount: 1,
          pricePerItem: orderPayment.total,
          totalPrice: orderPayment.total,
        }
        setSoldItems([orderData, ...soldProductsList])
      } else {
        setSoldItems(soldProductsList)
      }
    } else {
      setSoldItems([])
    }
  }, [selectedPurchase])

  useEffect(() => {
    const selectedPurchaseId = searchParams.get(URL_PARAMS.PURCHASE_ID)
    if (selectedPurchase) {
      resetSelectedPurchase()
    }
    if (selectedPurchaseId) {
      getPurchaseById(selectedPurchaseId)
    }
  }, [])

  const purchaseInformationListData = [
    {
      key: getRandomId(),
      name: t('Date payment'),
      value: selectedPurchase
        ? moment(selectedPurchase?.createdDate).format(formattedDate.Date)
        : '',
      visible: true,
    },
    {
      key: getRandomId(),
      name: t('Time payment'),
      value: selectedPurchase
        ? moment(selectedPurchase?.createdDate).format(formattedDate.Time)
        : '',
      visible: true,
    },
    {
      key: getRandomId(),
      name: t('Store'),
      value: selectedPurchase?.stores?.name,
      visible: true,
      contentClassName: 'content_wrapper',
    },
    {
      key: getRandomId(),
      name: t('Seller'),
      value: selectedPurchase?.createdBy?.name,
      visible: true,
      contentClassName: 'content_wrapper',
    },
    {
      key: getRandomId(),
      name: t('Payment method'),
      value: selectedPurchase
        ? getPaymentMethodIcon({isCash: selectedPurchase.cashAmount > 0})
        : '',
      visible: true,
    },
    {
      key: getRandomId(),
      name: t('Sum'),
      value: convertToFractionalNumber(selectedPurchase?.cashAmount, 2),
      visible: selectedPurchase?.cashAmount > 0,
    },
    {
      key: getRandomId(),
      name: t('Card'),
      value: convertToFractionalNumber(selectedPurchase?.cardAmount, 2),
      visible: selectedPurchase?.cardAmount > 0,
    },
    {
      key: getRandomId(),
      name: t('Cash'),
      value: convertToFractionalNumber(selectedPurchase?.cash, 2),
      visible: selectedPurchase?.cashAmount > 0 && selectedPurchase?.cash > 0,
    },
    {
      key: getRandomId(),
      name: t('Change'),
      value: convertToFractionalNumber(selectedPurchase?.change, 2),
      visible: selectedPurchase?.cashAmount > 0 && selectedPurchase?.cash > 0,
    },
  ]

  const getTableFooter = () => (
    <div className="table_footer">
      <div>{t('Total')}:</div>
      {selectedPurchase ? (
        <div>{convertToFractionalNumber(selectedPurchase?.totalAmount, 2)}</div>
      ) : (
        0
      )}
    </div>
  )

  return (
    <div className="purchase_details">
      {isLoading === 'getPurchase' ? (
        <LoadingSpinner />
      ) : (
        <Row gutter={[10, 10]}>
          <Col
            xl={12}
            sm={12}
            xs={24}
            lg={12}
            style={{border: '1px dashed #F0F0F0'}}
          >
            <ListComponent
              titleClassName="title_list"
              loading={isLoading}
              dataSource={purchaseInformationListData.filter(
                (listItem) => listItem.visible
              )}
            />
          </Col>

          <Col
            xl={12}
            sm={12}
            xs={24}
            lg={12}
            style={{border: '1px dashed #F0F0F0'}}
          >
            <TableDetailsReadOnly
              className="products_table hideScroll"
              scroll={{
                y: screens.xl ? 'calc(100vh - 15em)' : 'calc(100vh - 32em)',
                x: 500,
              }}
              selectedById={soldItems}
              columns={purchaseProductsColumns}
              rowKey={(record) => record.id}
              footer={getTableFooter}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default PurchaseDetails
