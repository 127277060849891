import {useDispatch} from 'react-redux'

import {useTranslation} from 'react-i18next'
import {
  deleteAccountingCategory,
  getAccountingCategory,
  getAccountingCategoryById,
  setAccountingCategory,
  updateAccountingCategory,
} from '../../redux/asyncServices/accountingCategoryService'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import {
  clearAccountingCategory,
  clearAccountingCategoryTableSettings,
  clearSelectedProduct,
  setLastUpdated,
  setSelectedAccountingCategories,
  setSelectedId,
  setTableSettings,
} from '../../redux/slices/accountingCategorySlice'
import notify from '../../components/antd-components/notification/notify'

import {
  setIsOpenEditWindow,
  setOpenModalWindow,
} from '../../redux/slices/commonSlice'

function useAccountingCategoryService() {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const success = (description) => {
    notify({
      description: t(description),
      type: 'success',
    })
    navigate('/accounting-category')
  }

  const failed = (description) => {
    notify({
      description: t(description),
      type: 'error',
    })
  }

  const accountingCategoryLastUpdated = () =>
    dispatch(setLastUpdated(Date.now()))

  const getAccountingCategoryData = (params) =>
    dispatch(getAccountingCategory({params}))

  const getByIdAccountingCategory = (id) => {
    dispatch(getAccountingCategoryById({id}))
  }

  const changeIsOpenEditModalWindow = (value) => {
    dispatch(setIsOpenEditWindow(value))
  }

  const changeSelectedId = (value) => dispatch(setSelectedId(value))

  const addAccountingCategory = async (data) => {
    await dispatch(setAccountingCategory(data)).then((response) => {
      if (response.payload) {
        success('Success add category')
        dispatch(setOpenModalWindow(false))
        accountingCategoryLastUpdated()
      } else {
        failed('Error add category')
      }
    })
  }

  const updateAccountingCategoryData = async (data) => {
    await dispatch(updateAccountingCategory(data)).then((response) => {
      if (response.payload) {
        success('Success update category')
        changeIsOpenEditModalWindow(false)
        dispatch(clearSelectedProduct())
        changeSelectedId(null)
        accountingCategoryLastUpdated()
      } else {
        failed('Error update category')
      }
    })
  }
  const setSelectAccountingCategories = (data) =>
    dispatch(setSelectedAccountingCategories(data))

  const deleteAccountingCategoryData = async ({ids}) => {
    await dispatch(deleteAccountingCategory({ids})).then((response) => {
      if (response.payload) {
        success('Success delete')
        accountingCategoryLastUpdated()
      } else {
        failed('Error delete')
      }
    })
  }
  const setAccountingCategoryTableSettings = (tableSettings = {}) => {
    dispatch(setTableSettings({tableSettings}))
    accountingCategoryLastUpdated()
  }

  const clearProductsSelected = () => {
    dispatch(clearSelectedProduct())
  }

  const clearAccountingData = () => {
    dispatch(clearAccountingCategory())
  }
  const resetAccountingCategoryTableSettings = () =>
    dispatch(clearAccountingCategoryTableSettings())

  return {
    getAccountingCategoryData,
    getByIdAccountingCategory,
    setAccountingCategoryTableSettings,
    setSelectAccountingCategories,
    deleteAccountingCategoryData,
    updateAccountingCategoryData,
    addAccountingCategory,
    changeSelectedId,
    clearProductsSelected,
    clearAccountingData,
    resetAccountingCategoryTableSettings,
  }
}

export default useAccountingCategoryService
