import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {Form} from 'antd'
import UnitsHeader from './components/UnitsHeader'
import UnitsBody from './components/UnitsBody'

import useUnits from '../../../services/units/unitsService'

function Units() {
  const unitsProps = useUnits()
  const {setUnitTableSettings, deleteUnitsData, getUnitsData} = unitsProps
  const unitsLastUpdated = useSelector((state) => state.units.lastUpdated)
  const unitsTableSettings = useSelector((state) => state.units.tableSettings)
  const [form] = Form.useForm()
  useEffect(() => {
    getUnitsData(unitsTableSettings)
  }, [unitsLastUpdated])

  return (
    <>
      <UnitsHeader
        setUnitTableSettings={setUnitTableSettings}
        deleteUnitsData={deleteUnitsData}
      />
      <UnitsBody {...unitsProps} form={form} />
    </>
  )
}

export default Units
