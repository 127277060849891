export const transformSignatureToAxisTuples = (signaturePadData) => {
  const resultingArr = []

  signaturePadData.forEach((currentLine) => {
    resultingArr.push(currentLine.map(({x, y}) => [x, y]))
  })

  return resultingArr
}

export const transformSignatureFromAxisTuples = (axisTuplesLines) => {
  const resultingArr = []

  axisTuplesLines.forEach((currentLine) => {
    resultingArr.push(currentLine.map(([x, y]) => ({x, y, color: 'black'})))
  })

  return resultingArr
}
