import {Col, Row} from 'antd'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {InputForm} from '../../../../../components/antd-components/form'
import {formattedDate} from '../../../../../constants'
import './style.scss'

function OrderMetaDataBlock({order}) {
  const {t} = useTranslation()

  return (
    <>
      <Row gutter={8}>
        <Col span={8}>
          <InputForm
            label={t('Author')}
            value={order.createdBy.name}
            disabled
          />
        </Col>
        <Col span={8}>
          <InputForm
            label={t('Create date')}
            value={moment(order.createdDate).format(formattedDate.DateWithTime)}
            disabled
          />
        </Col>
        <Col span={8}>
          <InputForm
            label={t('Created in')}
            value={order.shift?.store?.name || '-'}
            disabled
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={8}>
          <InputForm
            label={t('Editor')}
            value={order.updatedBy?.name || '-'}
            disabled
          />
        </Col>
        <Col span={8}>
          <InputForm
            label={t('Update date')}
            value={moment(order.updatedDate).format(formattedDate.DateWithTime)}
            disabled
          />
        </Col>
        <Col span={8}>
          <InputForm
            label={`${t('Shift')} #`}
            value={order.shiftId || '-'}
            disabled
          />
        </Col>
      </Row>
    </>
  )
}

export default OrderMetaDataBlock
