import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '../../../../components/antd-components/icon'
import variables from '../../../../globalStyle/colors.scss'
import useDebounce from '../../../../hooks/useDebounce'
import UsersForm from './UsersForm'

import withAntdModal from '../../../../HOCs/withAntdModal'

import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'
import {PageHeaderWithMobileMenu} from '../../../../components'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import useCommon from '../../../../services/commonService'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import {usersTableSettingsSelector} from '../../../../redux/selectors/userSelector'
import ResetFiltersButton from '../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'
import {usersInitialState} from '../../../../redux/slices/usersSlice'
import useUsers from '../../../../services/users/usersService'

function UsersHeader({setUserTableSettings, deleteUsersData}) {
  const {t} = useTranslation()
  const {exportExcel} = useCommon()
  const selectedUsers = useSelector((state) => state.users.selectedUsers)
  const users = useSelector((state) => state.users.users)
  const search = useSelector((state) => state.users.tableSettings.f_name)
  const tableSettings = useSelector(usersTableSettingsSelector)
  const [searchValue, setSearchValue] = useState(search)
  const {resetUsersTableSettings} = useUsers()

  const AddUserWithModal = withAntdModal(UsersForm, {
    okButtonText: t('Add'),
    footer: null,
    title: t('Add'),
    icon: <PlusOutlined />,
    style: {
      textTransform: 'uppercase',
      width: '100%',
      color: variables.add,
    },
    text: t('To add a store, please enter a name and click add.'),
  })

  const handleSubmitDelete = () => deleteUsersData({ids: selectedUsers})

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleSubmitDelete(),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const debouncedValue = useDebounce(searchValue, 500)
  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `users`,
      tableSettings,
    })
  }

  const ActionUserHeaderMenu = [
    <SearchAntdComponent
      key={1}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <AddUserWithModal key={3} text={t('Add')} />,
    <DeleteButton
      onClick={confirmDelete}
      disabled={isIncludeSelectedFilter(users, selectedUsers)}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu(ActionUserHeaderMenu)

  useEffect(() => {
    const debouncedSearchValue = !debouncedValue ? null : debouncedValue
    if (search !== debouncedSearchValue) {
      setUserTableSettings({f_name: debouncedSearchValue})
    }
  }, [debouncedValue])

  const clearFiltersHandler = () => {
    setSearchValue('')
    resetUsersTableSettings()
  }
  return (
    <PageHeaderWithMobileMenu
      title="Users"
      subTitle={
        <ResetFiltersButton
          clearFiltersHandler={clearFiltersHandler}
          currentTableSettings={tableSettings}
          initialTableSettings={usersInitialState.tableSettings}
        />
      }
      extra={ActionUserHeaderMenu}
      items={item}
    />
  )
}

export default UsersHeader
