import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {Form} from 'antd'
import {ExclamationCircleOutlined} from '../../../../components/antd-components/icon'

import SupplierForm from './SupplierForm'
import useSuppliersTable from '../../../../services/supplier/suppliersTableService'
import showConfirm from '../../../../components/PopupConfirm'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'

function SupplierBody({
  deleteSuppliersData,
  getSupplierById,
  suppliersTableSettings,
  setSupplierTableSettings,
  selectSuppliers,
}) {
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const suppliers = useSelector((state) => state.suppliers.suppliers)
  const isLoading = useSelector(
    (state) => state.suppliers.isLoading === 'getSuppliers'
  )
  const selectedSupplier = useSelector(
    (state) => state.suppliers.selectedSupplier
  )
  const selectedSuppliers = useSelector(
    (state) => state.suppliers.selectedSuppliers
  )
  const suppliersTotalCount = useSelector((state) => state.suppliers.totalCount)
  const [isEditDialogOpen, setEditDialogOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const handleDeleteSubmit = (id) => {
    deleteSuppliersData({ids: [id]})
  }

  const onEdit = (id) => {
    setSelectedId(id)
    setEditDialogOpen(true)
  }

  const handleEditClose = () => {
    setSelectedId(null)
    setEditDialogOpen(false)
  }

  useEffect(() => {
    if (selectedSupplier !== selectedId) {
      getSupplierById({id: selectedId})
    }
  }, [selectedId])

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleDeleteSubmit(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const {suppliersColumns} = useSuppliersTable({
    onDelete: confirmDelete,
    onEdit,
    suppliersTableSettings,
  })

  return (
    <div>
      {selectedSupplier?.id && (
        <AntdTableActionModal
          title={t('Update')}
          handleCancel={handleEditClose}
          open={isEditDialogOpen}
        >
          <SupplierForm
            onClose={handleEditClose}
            formData={selectedSupplier}
            text={t('Update')}
            form={form}
          />
        </AntdTableActionModal>
      )}
      <AppTable
        columns={suppliersColumns}
        dataSource={suppliers}
        isLoading={isLoading}
        showSelectRowCheckbox
        setSelectedComponent={selectSuppliers}
        tableSettings={suppliersTableSettings}
        totalCount={suppliersTotalCount}
        setTableSettings={setSupplierTableSettings}
        selectedRowKeys={selectedSuppliers}
      />
    </div>
  )
}

export default SupplierBody
