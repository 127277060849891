import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import usePlanningAddTableService from '../../../../services/plunning/planningAddService/planningAddTableService'
import {AppTable} from '../../../../components/antd-components/app-table'
import {recordsSelectedPlanning} from '../../../../redux/selectors/planningSelecor'

import usePlanningService from '../../../../services/plunning/planningService'
import {storesIsLoading} from '../../../../redux/selectors/storeSelector'
import {isLoadingSelectedNorm} from '../../../../redux/selectors/normSelector'
import {currencyAddWithPrecision} from '../../../../utils/helpers'

import './style.scss'

function PlanningAddBody({form, onSubmitAdd, filterColumnsForm}) {
  const selectedPlanningRecords = useSelector(recordsSelectedPlanning)
  const isLoadingNormById = useSelector(isLoadingSelectedNorm)
  const isLoadingStores = useSelector(storesIsLoading)
  const {setAddPlanningTableSettings} = usePlanningService()

  const {
    clearPlanningSelectedRecords,
    changeAmountInput,
    getRowBySelectedId,
    setRawId,
  } = usePlanningService()

  useEffect(
    () => () => {
      clearPlanningSelectedRecords()
    },
    []
  )

  const data = selectedPlanningRecords?.map((item) => {
    const storeRow = {
      productId: item.productId,
      id: item.id !== undefined ? item.id : item.productId,
      totalAmount: item.records
        .filter(({storeId}) => storeId !== null)
        .map(({necessaryAmount}) => necessaryAmount)
        .reduce((prev, curr) => currencyAddWithPrecision(prev, curr), 0),
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const storeRecord of item.records) {
      storeRow[storeRecord.storeName] = storeRecord.necessaryAmount
      storeRow[`${storeRecord.storeName}balance`] = storeRecord.balanceAmount
    }
    return storeRow
  })

  const {planningAddColumns} = usePlanningAddTableService({
    changeAmountInput,
    getRowBySelectedId,
    setRawId,
    form,
    data,
    onSubmitAdd,
    filterColumnsForm,
    storesList:
      selectedPlanningRecords[0]?.records
        .filter(({storeId}) => storeId !== null)
        .map(({storeId, storeName}) => ({
          id: storeId,
          name: storeName,
        })) ?? [],
  })

  return (
    <div>
      <AppTable
        dataSource={data}
        columns={planningAddColumns}
        isLoading={isLoadingNormById || isLoadingStores}
        setTableSettings={setAddPlanningTableSettings}
        tableSettings={setAddPlanningTableSettings}
        scroll={{x: 200, y: true}}
        showPagination={false}
        typeColumn="widthWithoutPercent"
        className="hideScroll"
      />
    </div>
  )
}

export default PlanningAddBody
