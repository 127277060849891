import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import currency from 'currency.js'
import useTableRenderItems from '../../useTableRenderItems'

const useSemiFinishedPriceDetailTable = () => {
  const {t} = useTranslation()
  const products = useSelector((state) => state.products.products)
  const units = useSelector((state) => state.units.units)

  const {renderNameById} = useTableRenderItems()

  const findUnit = (id) => {
    const product = products.find((item) => item.id === id)
    return !product ? product : product?.unitId
  }

  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'rawId',
      key: 'name',
      align: 'center',
      width: 200,
      render: (value, record) => record.semiFinish.name,
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      width: 140,
      render: (_, record) => (
        <div>
          <span>
            {record.amount}{' '}
            {renderNameById({id: findUnit(record.semiFinish.unitId)}, units)}
          </span>
        </div>
      ),
    },
    {
      title: t('Price'),
      dataIndex: 'priceMax',
      key: 'priceMax',
      align: 'center',
      width: 100,
      render: (value, record) => record.semiFinish.priceMax.toFixed(2),
    },
    {
      title: t('Sum'),
      dataIndex: 'priceOne',
      key: 'priceOne',
      align: 'center',
      width: 100,
      render: (value, record) =>
        currency(
          currency(record.semiFinish?.priceMax).multiply(record.amount).value,
          {precision: 6}
        ).value,
    },
  ]
  return {semiFinishedColumns: getColumns()}
}

export default useSemiFinishedPriceDetailTable
