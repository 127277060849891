import {Form} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import '../style.scss'

function TextAreaForm({name, rules, label, classname, ...rest}) {
  return (
    <Form.Item name={name} rules={[rules]} label={label} className={classname}>
      <TextArea {...rest} />
    </Form.Item>
  )
}

export default TextAreaForm
