import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import {Col, Form, Row} from 'antd'
import {useWriteOff} from '../../../../services/write-off-reason/writeOffReasonsService'
import WriteOfReasonValidation from '../validation/WriteOfReasonValidation'
import './style.scss'
import {InputForm} from '../../../../components/antd-components/form'
import {
  ButtonDanger,
  ButtonDefault,
} from '../../../../components/antd-components/button'
import {formProps} from '../../../../constants'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function WriteOffForm({
  formData = {
    id: null,
    name: '',
  },
  onClose,
  form,
  text,
}) {
  const {id} = formData

  const {setWriteOffData, updateWriteOffData} = useWriteOff()
  const {t} = useTranslation()
  const isLoading = useSelector(
    (state) => state.writeOff.isLoading === 'setWriteOff'
  )
  const {modalProps} = formProps
  const schema = WriteOfReasonValidation()

  useEffect(() => {
    if (id) {
      form.setFieldsValue(formData)
    }
  }, [id])

  const onSubmit = async (values) => {
    if (!id) {
      await setWriteOffData(values)
    } else {
      await updateWriteOffData({id, ...values})
    }
    onClose()
  }
  const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 24},
  }
  return (
    <Form
      name="basic"
      {...layout}
      form={form}
      onFinish={onSubmit}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            name="name"
            label={t('Name')}
            rules={{...schema, required: true}}
            placeholder={t('Name')}
          />
        </Col>

        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>
          <Col className="button">
            <SaveButton htmlType="submit" loading={isLoading} />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default WriteOffForm
