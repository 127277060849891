import './style.scss'
import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PageHeader} from 'antd'
import {
  AntdTableActionModal,
  DragTable,
} from '../../../../../../../../components/antd-components/app-table'
import useSemiFinishedTable from './semiFinishedTableService'
import SemiFinishedForm from '../semiFinished-form/SemiFinishedForm'
import AddButton from '../../../../../../../../components/antd-components/main-buttons/add-button/AddButton'

function SemiFinishedTable(props) {
  const {
    currentProductData,
    updateList,
    confirmDelete,
    setCurrentProductData,
    isLoading,
  } = props
  const {t} = useTranslation()
  const [selectedSemiFinishedItem, setSelectedSemiFinishedItem] =
    useState(undefined)
  const [isSemiFinishedModalOpen, setIsSemiFinishedModalOpen] = useState(false)

  const semiFinishedWithFieldKey = useMemo(() => {
    if (currentProductData) {
      return currentProductData.semiFinished?.map((item) => ({
        ...item,
        key: item.id,
      }))
    }
    return []
  }, [currentProductData])

  const openSemiFinishedModalHandler = ({selectedSemiFinishedItem}) => {
    setSelectedSemiFinishedItem(selectedSemiFinishedItem)
    setIsSemiFinishedModalOpen(true)
  }

  const editButtonHandler = (semiFinishedData) => {
    openSemiFinishedModalHandler({selectedSemiFinishedItem: semiFinishedData})
  }

  const {semiFinishedColumns} = useSemiFinishedTable({
    onDelete: confirmDelete,
    onEdit: editButtonHandler,
  })

  const closeSemiFinishedModalHandler = () => {
    setIsSemiFinishedModalOpen(false)
    setSelectedSemiFinishedItem(undefined)
  }

  console.log('isLoading', isLoading)

  return (
    <div className="semiFinishedTable_wrapper">
      <PageHeader
        title={t('Semi-finished')}
        extra={[
          <AddButton
            disabled={isLoading}
            onClick={() => {
              openSemiFinishedModalHandler({
                selectedSemiFinishedItem: undefined,
              })
            }}
          />,
        ]}
      />
      <DragTable
        isLoading={!!isLoading}
        data={semiFinishedWithFieldKey}
        updateList={updateList}
        columns={semiFinishedColumns}
        arrayName="semiFinished"
        className="table"
        scroll={{
          y: semiFinishedWithFieldKey?.length > 3 ? true : undefined,
          x: 500,
        }}
        pagination={false}
        rowClassName={(record) => record.changed && 'changed_item'}
      />
      <AntdTableActionModal
        open={isSemiFinishedModalOpen}
        title={selectedSemiFinishedItem ? t('Update') : t('Add record')}
        handleCancel={closeSemiFinishedModalHandler}
      >
        <SemiFinishedForm
          setCurrentProductData={setCurrentProductData}
          currentProductData={currentProductData}
          onClose={closeSemiFinishedModalHandler}
          selectedSemiFinishedItem={selectedSemiFinishedItem}
        />
      </AntdTableActionModal>
    </div>
  )
}

export default SemiFinishedTable
