import * as yup from 'yup'

function PlanningValidation(piece) {
  const schema = yup.lazy((value) => {
    if (value !== undefined) {
      return yup.object().shape({
        [Object.keys(value).toString()]: piece
          ? yup
              .number()
              .integer('Має бути цілим')
              .nullable()
              .transform((value) =>
                Number.isNaN(value) || value === null || value === undefined
                  ? 0
                  : value
              )
          : yup
              .number()
              .notRequired()
              .nullable()
              .transform((value) =>
                Number.isNaN(value) || value === null || value === undefined
                  ? 0
                  : value
              ),
      })
    }

    return yup
      .number()
      .integer()
      .nullable()
      .transform((value) =>
        Number.isNaN(value) || value === null || value === undefined ? 0 : value
      )
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default PlanningValidation
