import './style.scss'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import MovementForm from './components/movement-form/MovementForm'
import useMovements from '../../../../../../services/movements/movementsService'
import MovementItemsTabs from './components/items-tabs/MovementItemsTabs'
import notify from '../../../../../../components/antd-components/notification/notify'
import {validateItemData} from '../../../../../../utils/helpers'

function MovementAddBody(props) {
  const {
    movementForm,
    isPageReadOnly,
    currentActionButton,
    isLoading,
    itemsList,
    setItemsList,
  } = props
  const {movementId} = useParams()
  const {t} = useTranslation()
  const products = useSelector((state) => state.products.products)
  const {getMovementById, updateMovementData, addMovement} = useMovements()
  const [selectedFromIdStore, setSelectedFromIdStore] = useState(null)
  const [activeTab, setActiveTab] = useState('product')
  const [toggleValidateForm, setToggleValidateForm] = useState(null)

  useEffect(() => {
    if (movementId) {
      getMovementById({id: movementId})
    }
  }, [movementId])

  useEffect(() => {
    if (toggleValidateForm) {
      movementForm.validateFields()
    }
  }, [toggleValidateForm])

  const onFormSubmit = (movementData) => {
    if (itemsList.length === 0) {
      notify({
        description: t('Add at least one item to the table'),
        type: 'error',
      })
    } else {
      const itemsWithNotValidFields = itemsList
        .map((item) => {
          const isItemValid = validateItemData(item, products)
          return {...item, isItemValid}
        })
        .filter((item) => !item.isItemValid)
      if (itemsWithNotValidFields.length > 0) {
        const item = itemsWithNotValidFields[0]
        setActiveTab(item.itemType)
        setToggleValidateForm(Date.now())
        return
      }
      const {comment, fromStoreId, toStoreId} = movementData
      const itemsForMovement = itemsList.map((item) => {
        const {itemId, itemType, amount} = item
        return {itemId, itemType, amount: Number(amount)}
      })
      const correctMovementData = {
        comment,
        fromStoreId,
        toStoreId,
        status: currentActionButton === 'save' ? 'draft' : 'sent',
        productsSent: itemsForMovement,
      }
      if (movementId) {
        updateMovementData({...correctMovementData, id: movementId})
      } else {
        addMovement(correctMovementData)
      }
    }
  }

  return (
    <div className="body_wrapper">
      <div className="form_wrapper">
        <MovementForm
          onFormSubmit={onFormSubmit}
          movementForm={movementForm}
          isPageReadOnly={isPageReadOnly}
          setSelectedFromIdStore={setSelectedFromIdStore}
          selectedFromIdStore={selectedFromIdStore}
          setItemsList={setItemsList}
          isLoading={isLoading}
        />
      </div>
      <div className="tabs_wrapper">
        <MovementItemsTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isLoading={isLoading}
          selectedFromIdStore={selectedFromIdStore}
          setItemsList={setItemsList}
          itemsList={itemsList}
          movementForm={movementForm}
          isPageReadOnly={isPageReadOnly}
          onFormSubmit={onFormSubmit}
        />
      </div>
    </div>
  )
}
export default MovementAddBody
