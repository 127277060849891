import './style.scss'
import {PageHeader, Tag} from 'antd'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import useRefill from '../../../../../../services/refill/refillService'
import {selectedRefill} from '../../../../../../redux/selectors/refillSelector'
import DownloadButton from '../../../../../../components/antd-components/main-buttons/download_button/DownloadButton'

const tagColors = {
  processed: 'green',
  sent: 'orange',
  draft: 'default',
  error: 'red',
}

function RefillAddHeader(props) {
  const {onBackHandler} = props
  const {t} = useTranslation()
  const {refillId} = useParams()
  const {getExcelReport} = useRefill()
  const selectedRefillData = useSelector(selectedRefill)

  const downloadButtonHandler = () => {
    if (refillId) {
      getExcelReport(refillId)
    }
  }

  return (
    <div className="header_wrapper">
      <PageHeader
        onBack={onBackHandler}
        title={
          <div className="header_title">
            <div>
              {refillId
                ? `${t('Edit refill')} #${refillId}`
                : `${t('New refill')}`}
            </div>
            {selectedRefillData && (
              <Tag
                color={tagColors[selectedRefillData.status]}
                className="status_tag"
              >
                {t(selectedRefillData.status)}
              </Tag>
            )}
          </div>
        }
      />
      <DownloadButton onClick={downloadButtonHandler} disabled={!refillId} />
    </div>
  )
}

export default RefillAddHeader
