import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getUnits = createAsyncThunk(
  'GET_UNITS',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/dictionary/units', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {units: state.units.units, totalCount: state.units.totalCount}
    }
    return null
  }
)

export const getUnit = createAsyncThunk('GET_UNIT', async ({id}) => {
  if (id) {
    try {
      const response = await API.get(`/dictionary/units/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
  }
  return null
})

export const setUnit = createAsyncThunk('SET_UNIT', async (data) => {
  try {
    const response = await API.post('/dictionary/units', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const updateUnit = createAsyncThunk('UPDATE_UNIT', async (data) => {
  const {id} = data
  try {
    const response = await API.put(`/dictionary/units/${id}`, data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const deleteUnits = createAsyncThunk(
  'DELETE_UNITS',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete('/dictionary/units', {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {...response.data.payload, selectedUnits: []}
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedUnits: state.units.selectedUnits,
      }
    }
    return null
  }
)
