import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import RawMaterialsHeader from './components/RawMaterialsHeader'
import RawMaterialsBody from './components/RawMaterialsBody'

import useRawMaterials from '../../../services/raw-materials/rawMaterialsService'
import useUnits from '../../../services/units/unitsService'

function RawMaterials() {
  const {getUnitsData, unitsReset} = useUnits()
  const rawMaterialsProps = useRawMaterials()
  const {
    setRawMaterialTableSettings,
    deleteRawMaterialsData,
    getRawMaterialsData,
    clearNewRawMaterial,
  } = rawMaterialsProps

  const rawMaterialsLastUpdated = useSelector(
    (state) => state.rawMaterials.lastUpdated
  )
  const rawMaterialsTableSettings = useSelector(
    (state) => state.rawMaterials.tableSettings
  )

  useEffect(() => {
    getUnitsData()
    return () => {
      unitsReset()
      clearNewRawMaterial(null)
    }
  }, [])

  useEffect(() => {
    getRawMaterialsData(rawMaterialsTableSettings)
  }, [rawMaterialsLastUpdated])

  return (
    <>
      <RawMaterialsHeader
        setRawMaterialTableSettings={setRawMaterialTableSettings}
        deleteRawMaterialsData={deleteRawMaterialsData}
      />
      <RawMaterialsBody
        {...rawMaterialsProps}
        rawMaterialsTableSettings={rawMaterialsTableSettings}
      />
    </>
  )
}

export default RawMaterials
