import {Table} from 'antd'
import {forwardRef, useEffect, useState} from 'react'
import {PageSizeOptions} from '../../../TableOptions'
import './style.scss'
import {changeSortDirectionTitle} from '../../../../utils/helpers'

import useSearchParamsUrl from '../../../../hooks/useSearchParamsUrl'

const AppTable = forwardRef((props, ref) => {
  const {
    setSelectedComponent,
    columns,
    dataSource,
    totalCount,
    isLoading,
    setTableSettings,
    tableSettings,
    className,
    typeColumn = 'widthPercent',
    scroll = {y: dataSource?.length > 4 ? true : undefined, x: 1000},
    style = {height: 'calc(100vh - 12em)', width: '100%'},
    withTableWrapper = true,
    showPagination = true,
    showSelectRowCheckbox = false,
    rowKey = (record) => record.id,
    syncWithUrl = false,
    selectedRowKeys = null,
    current,
    ...prop
  } = props
  const {changeParamsByTable, setSelectedCheckBoxInTable, params} =
    useSearchParamsUrl()
  const selectedRowId = params?.id?.split(',')
  const [pageSizeValue, setPageSizeValue] = useState(tableSettings?.pageSize)
  const [currentPage, setCurrentPage] = useState(
    params?.page ?? tableSettings?.page ?? 1
  )

  useEffect(() => {
    setPageSizeValue(showPagination && tableSettings?.pageSize)
  }, [showPagination, tableSettings?.pageSize])

  useEffect(() => {
    if (showPagination && tableSettings?.page)
      setCurrentPage(tableSettings.page)
  }, [showPagination, tableSettings?.page])

  const handleChange = (pagination, filter, sorter) => {
    const {field, order} = sorter
    const {pageSize, current} = pagination
    setPageSizeValue(pageSize)
    setCurrentPage(current)
    // This code sometimes causes the double fetch of the data
    // setTableSettings({...tableSettings, page: current})
    const changeSortTitle = changeSortDirectionTitle(order)
    const props = {
      ...tableSettings,
      s_direction: changeSortTitle,
      s_field: field,
      ...filter,
    }
    if (showPagination) {
      props.pageSize = pageSize || 100
      props.page = current
    }

    if (syncWithUrl) {
      changeParamsByTable(props, setTableSettings)
    }

    setTableSettings(props)
  }

  const rowSelectionProps = {
    selectedRowKeys: selectedRowKeys ? selectedRowKeys?.map(Number) : null,
    onChange: (selectedRowKeys) => {
      setSelectedComponent(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.default,
    }),
  }

  const rowSelectionPropsWithUrl = {
    selectedRowKeys: selectedRowId ? selectedRowId?.map(Number) : null,
    onChange: (selectedRowKeys) => {
      setSelectedCheckBoxInTable(selectedRowKeys, setSelectedComponent)
    },
    getCheckboxProps: (record) => ({
      disabled: record.default,
    }),
  }

  const rowSelection = {
    type: 'checkbox',
    columnWidth: typeColumn === 'widthPercent' ? '5%' : 30,
    ...(syncWithUrl ? rowSelectionPropsWithUrl : rowSelectionProps),
  }

  const paginationProps = {
    showSizeChanger: true,
    pageSizeOptions: PageSizeOptions,
    responsive: false,
    showLessItems: true,
    total: totalCount,
    pageSize: pageSizeValue,
    current: currentPage,
  }

  const TaleComponent = (
    <Table
      {...prop}
      bordered
      columns={columns}
      dataSource={dataSource}
      scroll={scroll}
      onChange={handleChange}
      className={`table table-wrapper background-important ${className} `}
      rowSelection={showSelectRowCheckbox ? rowSelection : null}
      rowKey={rowKey}
      pagination={showPagination ? paginationProps : false}
      loading={isLoading}
      ref={ref}
      locale={{
        triggerDesc: '',
        triggerAsc: '',
        cancelSort: '',
      }}
    />
  )

  return withTableWrapper ? (
    <div style={style} ref={ref}>
      {TaleComponent}
    </div>
  ) : (
    TaleComponent
  )
})

export default AppTable
