import {useTranslation} from 'react-i18next'
import {convertToFractionalNumber} from '../../utils/helpers'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'

const useInventoryDetailTableSettings = ({inventoryList, openHistoryModal}) => {
  const {t} = useTranslation()

  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'name',
      width: 5,
      align: 'center',
      render: (_, record) =>
        record.itemType === 'raw' ? record.raw.name : record.product.name,
    },
    {
      title: t('Start balance'),
      width: 3,
      align: 'center',
      dataIndex: 'previousBalance',
      render: (value) => (value === null ? 0 : value),
    },
    {
      title: t('Refill'),
      width: 3,
      align: 'center',
      dataIndex: 'arrivalsAmount',
      render: (value) => (value === null ? 0 : value),
    },
    {
      title: t('Movements'),
      width: 3,
      align: 'center',
      dataIndex: 'movementsSum',
      render: (value) => (value === null ? 0 : value),
    },
    {
      title: t('Sold'),
      width: 3,
      align: 'center',
      dataIndex: 'sellsAmount',
      render: (value) => (value === null ? 0 : value),
    },

    {
      title: t('Write-off reason'),
      width: 3,
      align: 'center',
      dataIndex: 'writeOffsAmount',
      render: (value) => (value === null ? 0 : value),
    },
    {
      title: t('Balance'),
      dataIndex: 'balance',
      width: 2,
      align: 'center',
      render: (value) => (value === null ? 0 : value),
    },
    {
      title: t('Transferred'),
      width: 3,
      align: 'center',
      dataIndex: 'submissionAmount',
      render: (value) => (value === null ? 0 : value),
    },
    {
      title: t('Received'),
      width: 3,
      align: 'center',
      dataIndex: 'confirmationAmount',
      render: (value) => (value === null ? '-' : value),
    },
    {
      title: t('Money result'),
      dataIndex: 'moneyResult',
      align: 'center',
      width: 3,
      render: (value) => convertToFractionalNumber(value),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 3,
      align: 'center',
      render: (_, record) => (
        <DetailsActionButton
          onClick={() => {
            // record, inventoryList?.storeId
            openHistoryModal(record)
          }}
        />
      ),
    },
  ]

  return {
    detailInventoryColumns: getColumns(),
  }
}

export default useInventoryDetailTableSettings
