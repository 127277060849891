import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useEffect, useMemo} from 'react'
import {Form} from 'antd'
import {
  AutoCompleteComponent,
  InputForm,
} from '../../../components/antd-components/form'
import './style.scss'
import NormValidation from '../../../pages/main/norm/validation/validation'
import PlanningValidation from '../../../pages/main/planning/validation/validation'
import {
  crossedOutText,
  isCheckComa,
  restrictInputValueAfterDot,
} from '../../../utils/helpers'
import DeleteActionButton from '../../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const useNormAddTableService = ({
  form,
  changeAmountInput,
  setRawId,
  confirmDelete,
  getRowBySelectedId,
  onSubmit,
  selectedNorm,
  data,
  setCreatedEmptyRaw,
  screens,
  referenceInput,
  createdNewRaw,
  isAdd,
}) => {
  const {t} = useTranslation()
  const stores = useSelector((state) => state.stores.stores)
  const products = useSelector((state) => state.products.products)
  const validation = NormValidation()

  useEffect(() => {
    if (selectedNorm) {
      form.setFieldsValue({normTitle: selectedNorm?.title})
    }
  }, [selectedNorm?.id])

  const storesList = useMemo(
    () =>
      isAdd
        ? stores
        : selectedNorm?.products?.[0].records
            .filter(({storeId}) => storeId !== null)
            .map(({storeId, storeName, storeDeletedDate}) => ({
              name: storeName,
              id: storeId,
              deletedDate: storeDeletedDate,
            })) ?? [],
    [isAdd, selectedNorm]
  )

  const storeColumns = useMemo(
    () =>
      storesList.map(({id, name, deletedDate}) => ({
        title: deletedDate ? crossedOutText(name) : name,
        dataIndex: name,
        key: id,
        align: 'center',
        className: 'columns stores',
        render: (value, record) => (
          <div>
            <Form>
              <InputForm
                formClassName="content_center"
                style={{width: '100%'}}
                name={`store${name + record.id}`}
                rules={PlanningValidation(
                  products.find((product) => product.id === record.productId)
                    ?.piece
                )}
                formStyle={{marginBottom: 0}}
                initialValue={record[name] !== 0 ? record[name] : ''}
                placeholder={0}
                controls={false}
                normalize={restrictInputValueAfterDot}
                onFocus={() => getRowBySelectedId(record.id)}
                onChange={(e) =>
                  changeAmountInput({
                    id: record.id,
                    storeId: id,
                    name,
                    value: isCheckComa(e.target.value),
                  })
                }
              />
            </Form>
          </div>
        ),
      })),
    [selectedNorm]
  )

  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'f_store',
      align: 'center',
      fixed:
        (screens.lg && screens.md && screens.sm && !screens.xl) ||
        screens.xl ||
        (screens.md && screens.sm && !screens.lg && !screens.xl)
          ? 'left'
          : null,
      className: 'columns name_column',
      render: (_, record) => (
        <Form form={form} onFinish={onSubmit}>
          <AutoCompleteComponent
            options={products}
            initialValue={record.productId}
            name={`rawId${record.id}`}
            disabledId={data?.map((item) => item.productId)}
            classNameAutoComplete="form_element autoComplete fullScreenSelectStyle"
            rules={validation}
            showAction={createdNewRaw ? ['focus'] : ['click']}
            autoFocus={!!createdNewRaw}
            ref={referenceInput}
            placeholder={t('Products')}
            classNameForm="form_style"
            onChange={(e) => {
              setCreatedEmptyRaw(false)
              setRawId({
                id: record.id,
                rawId: e,
              })
            }}
          />
        </Form>
      ),
    },
    ...storeColumns,
    {
      title: t('Total'),
      dataIndex: 'totalAmount',
      align: 'center',
      fixed:
        (screens.lg && screens.md && screens.sm && !screens.xl) || screens.xl
          ? 'right'
          : null,
      className: 'columns total_amount',
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed:
        (screens.lg && screens.md && screens.sm && !screens.xl) || screens.xl
          ? 'right'
          : null,
      className: 'columns total_amount',
      render: (_, record) => (
        <div>
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              confirmDelete(record.id)
            }}
          />
        </div>
      ),
    },
  ]

  return {normAddColumns: getColumns()}
}

export default useNormAddTableService
