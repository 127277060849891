import {useDispatch} from 'react-redux'

import {getShiftById, getShifts} from '../../redux/asyncServices/shiftService'
import {
  clearShiftTableSettings,
  setLastUpdatedNow,
  setTableSettings,
} from '../../redux/slices/shiftSlice'

function useShiftsService() {
  const dispatch = useDispatch()

  const shiftsLasUpdatedNow = () => dispatch(setLastUpdatedNow())

  const getShiftsList = (params) => dispatch(getShifts({params}))
  const getShift = (id) => dispatch(getShiftById({id}))

  const resetShiftTableSettings = () => dispatch(clearShiftTableSettings())

  const setShiftsTableSettings = async (tableSettings = {}) => {
    dispatch(setTableSettings(tableSettings))
    shiftsLasUpdatedNow()
  }

  return {
    getShiftsList,
    setShiftsTableSettings,
    getShift,
    resetShiftTableSettings,
  }
}

export default useShiftsService
