import {SearchOutlined} from '@ant-design/icons'
import {Button, Form, Input} from 'antd'
import FormItem from 'antd/es/form/FormItem'

export const getTableColumnSearchProps = (props) => {
  const {
    currentColumn,
    tableSettings,
    handleReset,
    searchButtonHandler,
    placeholder,
    form,
    t,
  } = props
  const isFilterPresent = tableSettings[currentColumn]

  const inputPlaceholder = `${t('Search')} ${t(placeholder)}`

  return {
    filterDropdown: ({_, confirm}) => (
      <div className="search_wrapper">
        <Form form={form} className="input_wrapper">
          <FormItem name={currentColumn}>
            <Input
              className="search_input"
              placeholder={inputPlaceholder}
              value={tableSettings[currentColumn]}
            />
          </FormItem>
        </Form>

        <div className="search_buttons-wrapper">
          <Button
            size="small"
            onClick={() => handleReset(currentColumn, confirm)}
            className={`reset_button ${isFilterPresent && 'active'}`}
          >
            {t('Reset')}
          </Button>
          <Button
            className="search_button"
            size="small"
            type="primary"
            onClick={() => searchButtonHandler(currentColumn, confirm)}
          >
            {t('Search')}
          </Button>
        </div>
      </div>
    ),
    filterIcon: () => (
      <SearchOutlined className={`${isFilterPresent && 'active'}`} />
    ),
  }
}
