import {useEffect} from 'react'
import ShiftsBody from './components/ShiftsBody'
import ShiftsHeader from './components/ShiftsHeader'
import useUsers from '../../../services/users/usersService'
import useStore from '../../../services/storeServices/storeService'

function ShiftsPage() {
  const {getUsersData} = useUsers()
  const {getStoresData} = useStore()
  useEffect(() => {
    getUsersData()
    getStoresData()
  }, [])
  return (
    <div>
      <ShiftsHeader />
      <ShiftsBody />
    </div>
  )
}

export default ShiftsPage
