import {Form} from 'antd'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

import './style.scss'

function StoreBalanceEditFooter({
  form,
  onReturnToTablePage,
  onSubmitUpdatedStoreBalance,
}) {
  return (
    <div className="store_balance_edit_footer">
      <CancelButton onClick={onReturnToTablePage} />
      <Form form={form} onFinish={onSubmitUpdatedStoreBalance}>
        <SaveButton htmlType="submit" />
      </Form>
    </div>
  )
}

export default StoreBalanceEditFooter
