export const getInventoryLoading = (state) =>
  state.inventory.isLoading === 'getInventory'

export const lastUpdatedInventory = (state) => state.inventory.lastUpdated
export const inventoryData = (state) => state.inventory.inventory
export const inventoryTotalCount = (state) => state.inventory.totalCount
export const inventoryTableSettings = (state) => state.inventory.tableSettings
export const selectedInventory = (state) => state.inventory.selectedInventory
export const inventoryHistory = (state) => state.inventory.inventoryHistory
export const isLoadingHistoryInventory = (state) =>
  state.inventory.isLoading === 'getInventoryHistory'
export const isLoadingSelectedInventory = (state) =>
  state.inventory.isLoading === 'getInventoryById'
