import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'
import {serverRequestAsyncWrapper} from '../../utils/wrappers'
import {ordersApi} from '../../services/api/ordersApiService'

export const getOrders = createAsyncThunk(
  'GET_ORDERS',
  serverRequestAsyncWrapper(async ({params = {}}) =>
    API.get(`/orders`, {params})
  )
)

export const getOrderById = createAsyncThunk(
  'GET_ORDER_BY_ID',
  serverRequestAsyncWrapper(ordersApi.getOrderById)
)

export const downloadOrderThunk = createAsyncThunk(
  'DOWNLOAD_ORDER',
  async (id) => {
    try {
      const response = await API.get(`/orders/${id}/export/xlsx`, {
        responseType: 'blob',
      })
      if (response) {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = response.headers['content-disposition'].split('=').pop()
        link.click()
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)
