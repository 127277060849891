import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {Col, Form, Row} from 'antd'
import moment from 'moment'
import {
  AutoCompleteComponent,
  InputForm,
} from '../../../../../../../../components/antd-components/form'
import LoadingSpinner from '../../../../../../../../components/antd-components/loading-spinner/LoadingSpinner'
import {selectedMovement} from '../../../../../../../../redux/selectors/movementSelector'
import MovementFormValidation from './validation'
import CreatedDateReadOnlyInput from '../../../../../../../../components/antd-components/form/readOnly-inputs/createdDate-input/CreatedDateReadOnlyInput'
import UpdatedDateReadOnlyInput from '../../../../../../../../components/antd-components/form/readOnly-inputs/updatedDate-input/UpdatedDateReadOnlyInput'
import CommentTextArea from '../../../../../../../../components/antd-components/form/input-form/CommentTextArea'
import useRawMaterials from '../../../../../../../../services/raw-materials/rawMaterialsService'
import useProducts from '../../../../../../../../services/products/productsService'

function MovementForm(props) {
  const {
    movementForm,
    isPageReadOnly,
    setSelectedFromIdStore,
    selectedFromIdStore,
    setItemsList,
    isLoading,
    onFormSubmit,
  } = props
  const {t} = useTranslation()
  const {getRawMaterialsBalanceData} = useRawMaterials()
  const {getProductsBalanceData} = useProducts()
  const stores = useSelector((state) => state.stores.stores)
  const users = useSelector((state) => state.users.users)
  const [selectedToIdStore, setSelectedToIdStore] = useState(null)
  const selectedMovementData = useSelector(selectedMovement)
  const {movementId} = useParams()
  const validation = MovementFormValidation()

  useEffect(() => {
    if (selectedFromIdStore) {
      getRawMaterialsBalanceData({f_storeId: [selectedFromIdStore]})
      getProductsBalanceData({f_storeId: [selectedFromIdStore]})
    }
  }, [selectedFromIdStore])

  useEffect(() => {
    if (selectedMovementData) {
      setSelectedFromIdStore(selectedMovementData.fromStoreId)
      const {createdDate, updatedDate} = selectedMovementData
      movementForm.setFieldsValue({
        ...selectedMovementData,
        createdDate: createdDate ? moment(createdDate) : null,
        updatedDate: updatedDate ? moment(updatedDate) : null,
      })
    } else {
      movementForm.resetFields()
    }
  }, [selectedMovementData])

  const onStoreFromFieldChange = (storeId) => {
    setItemsList([])
    setSelectedFromIdStore(storeId)
  }

  return (
    <div>
      <Form
        form={movementForm}
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        className="movement_form"
        onFinish={onFormSubmit}
      >
        <Row gutter={[10, 0]}>
          <Col span={24} xs={24} sm={24} md={12} xl={8}>
            <AutoCompleteComponent
              options={stores}
              className="fullScreenSelectStyle"
              label={t('From store')}
              name="fromStoreId"
              onChange={onStoreFromFieldChange}
              rules={{...validation, required: true}}
              disabledId={selectedToIdStore}
              disabled={isLoading || isPageReadOnly || movementId}
            />
          </Col>
          <Col span={24} xs={24} sm={24} md={12} xl={8}>
            <AutoCompleteComponent
              options={stores}
              className="fullScreenSelectStyle"
              label={t('To store')}
              onChange={(selectedId) => {
                setSelectedToIdStore(selectedId)
              }}
              name="toStoreId"
              rules={{...validation, required: true}}
              disabled={isLoading || isPageReadOnly || movementId}
              disabledId={selectedFromIdStore}
            />
          </Col>

          <Col className="gutter-row" span={24} xs={24} sm={24} md={24} xl={24}>
            <CommentTextArea
              rules={validation}
              disabled={isLoading || isPageReadOnly}
            />
          </Col>
          {isLoading && (
            <Col span={24} xs={24} sm={24} md={24} xl={24}>
              <LoadingSpinner />
            </Col>
          )}
          {movementId && (
            <>
              <Col span={24} xs={24} sm={24} md={12} xl={8}>
                <AutoCompleteComponent
                  options={users}
                  label={t('Created by')}
                  name="createdBy"
                  disabled
                />
              </Col>
              <Col span={24} xs={24} sm={24} md={12} xl={8}>
                <CreatedDateReadOnlyInput />
              </Col>
              <Col span={24} xs={24} sm={24} md={12} xl={8}>
                <InputForm label={`${t('Shift')} #`} disabled name="shiftId" />
              </Col>
              <Col span={24} xs={24} sm={24} md={12} xl={8}>
                <AutoCompleteComponent
                  options={users}
                  label={t('Updated by')}
                  name="updatedBy"
                  disabled
                />
              </Col>
              <Col span={24} xs={24} sm={24} md={12} xl={8}>
                <UpdatedDateReadOnlyInput />
              </Col>
            </>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default MovementForm
