import {Button, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import {PlusOutlined} from '../../icon'
import './style.scss'

function AddButton(props) {
  const {tooltipText, tooltipPlacement, className, onClick, ...rest} = props
  const {t} = useTranslation()

  return (
    <span className="addButton_wrapper">
      <Tooltip placement={tooltipPlacement} title={tooltipText}>
        <Button
          onClick={onClick}
          className={`${className} add_button`}
          {...rest}
        >
          <PlusOutlined />
          {t('Add')}
        </Button>
      </Tooltip>
    </span>
  )
}

export default AddButton
