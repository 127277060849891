import './style.scss'
import {Button} from 'antd'
import {useTranslation} from 'react-i18next'
import SendButton from '../../../../../../components/antd-components/main-buttons/send-button/SendButton'
import SaveButton from '../../../../../../components/antd-components/main-buttons/save-button/SaveButton'
import CancelButton from '../../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'

function RefillAddFooter(props) {
  const {onCloseHandler, refillForm, isPageReadOnly, setCurrentActionButton} =
    props
  const {t} = useTranslation()

  const saveButtonHandler = (buttonName) => {
    setCurrentActionButton(buttonName)
    refillForm.submit()
  }

  return (
    <div className="footer_wrapper">
      <CancelButton className="footer_button" onClick={onCloseHandler} />
      <div>
        <SaveButton
          className="footer_button"
          disabled={isPageReadOnly}
          onClick={() => {
            saveButtonHandler('save')
          }}
        />
        <SendButton
          className="footer_button"
          disabled={isPageReadOnly}
          onClick={() => {
            saveButtonHandler('send')
          }}
        />
      </div>
    </div>
  )
}

export default RefillAddFooter
