import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getWriteOffReasons = createAsyncThunk(
  'GET_WRITE_OFF_REASONS',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/dictionary/woreasons', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        writeOffReason: state.writeOff.writeOffReason,
        totalCount: state.writeOff.totalCount,
      }
    }
    return null
  }
)

export const getWriteOffReason = createAsyncThunk(
  'GET_WRITE_OFF_REASON',
  async ({id}, {getState}) => {
    if (id) {
      try {
        const response = await API.get(`/dictionary/woreasons/${id}`)
        if (response?.data?.payload) {
          return {...response.data.payload, selectedWriteOffReason: null}
        }
      } catch (e) {
        console.log(e)
        const state = getState()
        return {
          selectedWriteOffReason: state.writeOff.selectedWriteOffReason,
        }
      }
    }
    return null
  }
)

export const setWriteOffReason = createAsyncThunk(
  'SET_WRITE_OFF_REASON',
  async (data) => {
    try {
      const response = await API.post('/dictionary/woreasons', data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const updateWriteOffReason = createAsyncThunk(
  'UPDATE_WRITE_OF_REASON',
  async (data) => {
    const {id} = data
    try {
      const response = await API.put(`/dictionary/woreasons/${id}`, data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const deleteWriteOffReasons = createAsyncThunk(
  'DELETE_WRITE_OFF_REASONS',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete('/dictionary/woreasons', {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {...response.data.payload, selectedWriteOffReasons: []}
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        selectedWriteOffReasons: state.writeOff.selectedWriteOffReasons,
      }
    }
    return null
  }
)
