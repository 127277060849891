import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Form} from 'antd'
import {AntdTableActionModal} from '../../../../../components/antd-components/app-table'

import {ordersApi} from '../../../../../services/api/ordersApiService'
import {
  InputNumberForm,
  SwitchForm,
  TextAreaForm,
} from '../../../../../components/antd-components/form'
import {checkIsResponseSuccess} from '../../../../../utils/helpers'
import useOrderByIdService from '../../../../../services/orders/useOrderByIdService'
import notify from '../../../../../components/antd-components/notification/notify'
import CancelButton from '../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../../components/antd-components/main-buttons/save-button/SaveButton'
import Validation from './addOrderPaymentValidation'

function AddOrderPaymentModal({open, onCancel, orderId}) {
  const {t} = useTranslation()

  const [form] = Form.useForm()
  const [isFormLoading, setIsFormLoading] = useState(false)
  const validation = Validation()

  const {getOrder} = useOrderByIdService()

  const handleSavePayment = async () => {
    setIsFormLoading(true)
    const formData = form.getFieldsValue()
    try {
      const response = await ordersApi
        .createOrderPayment({
          orderId,
          ...formData,
          total: Number(formData.total),
        })
        .then((response) => {
          if (checkIsResponseSuccess(response?.status)) {
            notify({
              description: t('Success add order payment'),
            })
            getOrder(orderId).unwrap()
            onCancel()
          }
        })
    } catch (e) {
      console.log(e)
    } finally {
      setIsFormLoading(false)
    }
  }

  useEffect(() => {
    if (open) form.resetFields()
  }, [open])

  return (
    <AntdTableActionModal
      open={open}
      title={t('Add order payment')}
      handleCancel={onCancel}
      className="orderPayment_modal"
      footer={
        <Form
          form={form}
          onFinish={handleSavePayment}
          className="modal_footer"
          disabled={isFormLoading}
        >
          <CancelButton className="cancel_btn" onClick={onCancel} />
          <SaveButton htmlType="submit" />
        </Form>
      }
    >
      <Form
        form={form}
        labelCol={{xs: {span: 24}}}
        className="left"
        disabled={isFormLoading}
      >
        <InputNumberForm
          autoFocus
          name="total"
          label={t('Total')}
          controls={false}
          className="content"
          rules={{...validation, required: true}}
        />
        <SwitchForm
          label={t('Method')}
          checkedChildren={t('Cash')}
          unCheckedChildren={t('Card')}
          name="isCash"
          initialValue={false}
        />
        <TextAreaForm
          rows={3}
          label={t('Comment')}
          name="comment"
          rules={validation}
        />
      </Form>
    </AntdTableActionModal>
  )
}

export default AddOrderPaymentModal
