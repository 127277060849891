import './style.scss'
import CancelButton from '../../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../../../components/antd-components/main-buttons/save-button/SaveButton'
import SendButton from '../../../../../../components/antd-components/main-buttons/send-button/SendButton'

function MovementAddFooter(props) {
  const {onCloseHandler, movementForm, isPageReadOnly, setCurrentActionButton} =
    props

  const saveButtonHandler = (buttonName) => {
    setCurrentActionButton(buttonName)
    movementForm.submit()
  }

  return (
    <div className="footer_wrapper">
      <CancelButton className="footer_button" onClick={onCloseHandler} />
      <div>
        <SaveButton
          className="footer_button"
          disabled={isPageReadOnly}
          onClick={() => {
            saveButtonHandler('save')
          }}
        />
        <SendButton
          className="footer_button"
          disabled={isPageReadOnly}
          onClick={() => {
            saveButtonHandler('send')
          }}
        />
      </div>
    </div>
  )
}

export default MovementAddFooter
