import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'
import {storeBalanceTabs} from '../../pages/main/store-balance/constants'

export const getStoreBalanceByItemTypeThunk = createAsyncThunk(
  'GET_STORE_BALANCE_BY_ITEM',
  async ({itemType = storeBalanceTabs.PRODUCT, params}, {getState}) => {
    try {
      const response = await API.get(`/store/balance/${itemType}`, {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        storesBalance: state.storesBalances.storesBalance,
        totalCount: state.storesBalances.totalCount,
      }
    }
    return null
  }
)

export const getStoreBalance = createAsyncThunk(
  'GET_STORE_BALANCE',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/store/products/balance', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        storesBalance: state.storesBalances.storesBalance,
        totalCount: state.storesBalances.totalCount,
      }
    }
    return null
  }
)

export const updateStoreProducts = createAsyncThunk(
  'UPDATE_RAW_MATERIAL',
  async (data) => {
    try {
      const response = await API.put(`/store/balance`, data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)
