import {useTranslation} from 'react-i18next'
import {Col, Form, Row} from 'antd'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import './style.scss'
import {
  AutoCompleteComponent,
  InputForm,
  SwitchForm,
} from '../../../../components/antd-components/form'
import {
  ButtonDanger,
  ButtonDefault,
} from '../../../../components/antd-components/button'
import useAccountingCategoryService from '../../../../services/accountingCategory/accountingCategoryService'
import {
  isLoadingAddAccountingCategory,
  isLoadingUpdateAccountingCategory,
} from '../../../../redux/selectors/accountingCategorySelector'
import {accountingType, formProps} from '../../../../constants'
import AccountingCategoryValidation from '../validation/AccountingCategoryValidation'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function AccountingCategoryForm({
  formData = {
    id: null,
    name: '',
    isStoreCategory: false,
  },
  form,
  onClose,
  text,
}) {
  const {id} = formData
  const isLoading = useSelector(isLoadingAddAccountingCategory)
  const isLoadingUpdate = useSelector(isLoadingUpdateAccountingCategory)
  const {modalProps} = formProps
  const {addAccountingCategory, updateAccountingCategoryData} =
    useAccountingCategoryService()
  const {t} = useTranslation()

  useEffect(() => {
    if (id) {
      form.setFieldsValue(formData)
      if (formData.credit) {
        form.setFieldsValue({credit: 'expenses'})
      } else {
        form.setFieldsValue({credit: 'income'})
      }
    }
  }, [id])

  const schema = AccountingCategoryValidation()

  const onSubmit = async (values) => {
    const {credit, title, isStoreCategory} = values

    if (!id) {
      await addAccountingCategory({
        title,
        isStoreCategory,
        credit: credit === 'expenses',
      })
    } else {
      await updateAccountingCategoryData({
        id,
        title,
        credit: credit === 'expenses',
      })
    }
  }
  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
  }

  return (
    <Form form={form} onFinish={onSubmit} {...layout}>
      <Row gutter={24}>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            placeholder={t('Name')}
            label={t('Name')}
            name="title"
            rules={{...schema, required: true}}
          />
        </Col>
        {!formData.id && (
          <>
            <Col className="gutter-row" {...modalProps.colScreenProps}>
              <AutoCompleteComponent
                options={accountingType?.map((item) => ({
                  id: item?.id,
                  name: t(item?.name),
                  value: item?.id,
                }))}
                name="credit"
                placeholder={t('Type')}
                label={t('Type')}
                rules={{...schema, required: true}}
              />
            </Col>

            <Col className="gutter-row" {...modalProps.colScreenProps}>
              <SwitchForm
                name="isStoreCategory"
                label={t('Store Accounting')}
                size="large"
                initialValue={formData.isStoreCategory}
              />
            </Col>
          </>
        )}

        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>
          <Col className="button">
            <SaveButton
              htmlType="submit"
              loading={formData?.id ? isLoadingUpdate : isLoading}
            />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default AccountingCategoryForm
