import {useTranslation} from 'react-i18next'
import {TextAreaForm} from '../index'

function CommentTextArea(props) {
  const {t} = useTranslation()
  return (
    <TextAreaForm
      label={t('Comment')}
      autoSize={{minRows: 2, maxRows: 3}}
      placeholder={t('Comment')}
      name="comment"
      {...props}
    />
  )
}
export default CommentTextArea
