import {useForm} from 'antd/es/form/Form'
import {Col, Form, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import {
  AutoCompleteComponent,
  InputForm,
} from '../../../../../../../../components/antd-components/form'
import {
  getRandomId,
  restrictInputValueAfterDot,
} from '../../../../../../../../utils/helpers'
import CancelButton from '../../../../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../../../../../components/antd-components/main-buttons/save-button/SaveButton'
import {formProps} from '../../../../../../../../constants'
import './style.scss'
import SemiFinishedValidationSchema from './validation'

function SemiFinishedForm(props) {
  const {
    onClose,
    selectedSemiFinishedItem,
    setCurrentProductData,
    currentProductData,
  } = props
  const [semiFinishedForm] = useForm()
  const {t} = useTranslation()
  const {modalProps} = formProps
  const validation = SemiFinishedValidationSchema()
  const products = useSelector((state) => state.products.products)
  const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
  }
  useEffect(() => {
    if (selectedSemiFinishedItem) {
      semiFinishedForm.setFieldsValue(selectedSemiFinishedItem)
    }
  }, [selectedSemiFinishedItem])

  const onSubmit = (formData) => {
    const {amount, semiFinishedId} = formData
    if (currentProductData) {
      const {semiFinished} = currentProductData
      if (selectedSemiFinishedItem) {
        const updatedSemiFinishedList = semiFinished.map((semiItem) => {
          if (semiItem.id === selectedSemiFinishedItem.id) {
            const {changed} = semiItem
            const isDataChanged =
              Number(amount) !== semiItem.amount ||
              semiItem.semiFinishedId !== semiFinishedId
            return {
              ...selectedSemiFinishedItem,
              amount,
              semiFinishedId,
              changed: changed || isDataChanged,
            }
          }
          return semiItem
        })
        setCurrentProductData({
          ...currentProductData,
          semiFinished: updatedSemiFinishedList,
        })
      } else {
        const newItemData = {
          id: getRandomId(),
          amount,
          semiFinishedId,
          changed: true,
        }
        setCurrentProductData({
          ...currentProductData,
          semiFinished: [...currentProductData.semiFinished, newItemData],
        })
      }
    }
    onClose()
  }

  return (
    <Form
      name="basic"
      {...layout}
      form={semiFinishedForm}
      onFinish={onSubmit}
      autoComplete="off"
      className="addSemiFinished_form"
    >
      <Row gutter={24}>
        <Col {...modalProps.colScreenProps}>
          <AutoCompleteComponent
            label={t('Semi-finished')}
            options={products}
            name="semiFinishedId"
            placeholder={t('Semi-finished')}
            rules={{...validation, required: true}}
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            name="amount"
            label={t('Amount')}
            placeholder={t('Amount')}
            rules={{...validation, required: true}}
            normalize={restrictInputValueAfterDot}
            autoComplete="off"
          />
        </Col>
        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>
          <Col className="button">
            <SaveButton
              onClick={() => {
                semiFinishedForm.submit()
              }}
            />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}
export default SemiFinishedForm
