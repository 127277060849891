import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

function Validation() {
  const {t} = useTranslation()
  const schema = yup.object().shape({
    total: yup
      .number()
      .typeError(t('Value must be an number'))
      .positive()
      .min(0.01, t('Only positive number'))
      .nullable()
      .required(t('This field is required'))
      .transform((_, value) => {
        if (String(value).includes(',')) {
          return +value.replace(/,/, '.')
        }
        if (!value) {
          return undefined
        }
        return +value
      }),
    comment: yup.string().nullable().max(250, t('Max length 250 symbols')),
  })

  return {
    async validator({field}, value) {
      schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default Validation
