import './style.scss'
import {Button, PageHeader, Tag} from 'antd'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {selectedMovement} from '../../../../../../redux/selectors/movementSelector'
import DownloadButton from '../../../../../../components/antd-components/main-buttons/download_button/DownloadButton'

const tagColors = {
  processed: 'green',
  sent: 'orange',
  draft: 'default',
  error: 'red',
}

function MovementAddHeader(props) {
  const {onBackHandler, isLoading} = props
  const {t} = useTranslation()
  const {movementId} = useParams()
  const selectedMovementData = useSelector(selectedMovement)

  const downloadButtonHandler = () => {}

  return (
    <div className="header_wrapper">
      <PageHeader
        onBack={onBackHandler}
        title={
          <div className="header_title">
            <div>
              {movementId
                ? `${t('Edit movement')} #${movementId}`
                : `${t('New movement')}`}
            </div>
            {selectedMovementData && (
              <Tag
                color={tagColors[selectedMovementData.status]}
                className="status_tag"
              >
                {t(selectedMovementData.status)}
              </Tag>
            )}
          </div>
        }
      />
      <DownloadButton onClick={downloadButtonHandler} disabled />
    </div>
  )
}

export default MovementAddHeader
