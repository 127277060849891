import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {Form} from 'antd'
import UsersHeader from './components/UsersHeader'
import UsersBody from './components/UsersBody'
import useUsers from '../../../services/users/usersService'

function Units() {
  const usersProps = useUsers()
  const {setUserTableSettings, deleteUsersData, getUsersData} = usersProps
  const [form] = Form.useForm()
  const usersLastUpdated = useSelector((state) => state.users.lastUpdated)
  const usersTableSettings = useSelector((state) => state.users.tableSettings)

  useEffect(() => {
    getUsersData(usersTableSettings)
  }, [usersLastUpdated])

  return (
    <>
      <UsersHeader
        setUserTableSettings={setUserTableSettings}
        deleteUsersData={deleteUsersData}
        form={form}
      />
      <UsersBody {...usersProps} form={form} />
    </>
  )
}

export default Units
