import {useSelector} from 'react-redux'
import {useState} from 'react'
import {historyIngredientTableSettings} from '../../../redux/selectors/suppliesHistoryByIngredietSelector'
import useSuppliesHistoryByIngredient from './historySuppliesByIngredient'
import {
  FilterIcon,
  RangeInputComponent,
} from '../../../components/antd-components/app-table'

const useHistorySuppliesFilterService = () => {
  const tableSettings = useSelector(historyIngredientTableSettings)
  const {setTableSettingsHistoryByIngredient} = useSuppliesHistoryByIngredient()

  const [priceOneToFilter, setPriceOneToFilter] = useState(
    setTableSettingsHistoryByIngredient.f_priceOneTo
  )
  const [priceOneFromFilter, setPriceOneFromFilter] = useState(
    setTableSettingsHistoryByIngredient.f_priceOneFrom
  )
  const [priceTotalToFilter, setPriceTotalToFilter] = useState(
    setTableSettingsHistoryByIngredient.f_priceTotalTo
  )
  const [priceTotalFromFilter, setPriceTotalFromFilter] = useState(
    setTableSettingsHistoryByIngredient.f_priceTotalFrom
  )
  const [amountToFilter, setAmountToFilter] = useState(
    setTableSettingsHistoryByIngredient.f_amountTo
  )
  const [amountFromFilter, setAmountFromFilter] = useState(
    setTableSettingsHistoryByIngredient.f_amountFrom
  )

  const onSubmitPriceOne = (confirm) => {
    setTableSettingsHistoryByIngredient({
      ...tableSettings,
      f_priceOneTo: priceOneToFilter || null,
      f_priceOneFrom: priceOneFromFilter || null,
    })
    confirm()
  }

  const onSubmitPriceTotal = (confirm) => {
    setTableSettingsHistoryByIngredient({
      ...tableSettings,
      f_priceTotalTo: priceTotalToFilter || null,
      f_priceTotalFrom: priceTotalFromFilter || null,
    })
    confirm()
  }

  const onSubmitAmount = (confirm) => {
    setTableSettingsHistoryByIngredient({
      ...tableSettings,
      f_amountTo: amountToFilter || null,
      f_amountFrom: amountFromFilter || null,
    })
    confirm()
  }

  const filterPriceOneIcon = (
    <FilterIcon isFilterActive={priceOneToFilter || priceOneFromFilter} />
  )
  const filterTotalPriceIcon = (
    <FilterIcon isFilterActive={priceTotalToFilter || priceTotalFromFilter} />
  )
  const filterAmountIcon = (
    <FilterIcon isFilterActive={amountToFilter || amountFromFilter} />
  )

  const rangePriceOne = ({confirm}) => (
    <RangeInputComponent
      amountTo={priceOneToFilter}
      amountFrom={priceOneFromFilter}
      setAmountTo={setPriceOneToFilter}
      setAmountFrom={setPriceOneFromFilter}
      onSubmitFilterAmount={() => onSubmitPriceOne(confirm)}
    />
  )

  const rangeAmount = ({confirm}) => (
    <RangeInputComponent
      amountTo={amountToFilter}
      amountFrom={amountFromFilter}
      setAmountTo={setAmountToFilter}
      setAmountFrom={setAmountFromFilter}
      onSubmitFilterAmount={() => onSubmitAmount(confirm)}
    />
  )

  const rangeTotalPrice = ({confirm}) => (
    <RangeInputComponent
      amountTo={priceTotalToFilter}
      amountFrom={priceTotalFromFilter}
      setAmountTo={setPriceTotalToFilter}
      setAmountFrom={setPriceTotalFromFilter}
      onSubmitFilterAmount={() => onSubmitPriceTotal(confirm)}
    />
  )

  return {
    filterTotalPriceIcon,
    filterPriceOneIcon,
    filterAmountIcon,
    rangeTotalPrice,
    rangeAmount,
    rangePriceOne,
  }
}

export default useHistorySuppliesFilterService
