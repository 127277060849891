import {Form} from 'antd'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import currency from 'currency.js'
import NormAddBody from './NormAddBody'
import NormAddHeader from './NormAddHeader'
import useStore from '../../../../services/storeServices/storeService'
import useProducts from '../../../../services/products/productsService'
import useNormService from '../../../../services/norm/normService'

function AddNormPage() {
  const [form] = Form.useForm()
  const {getStoresData} = useStore()
  const {getProductsData} = useProducts()
  const {addNewNorm, updateNormData, draftRecordsClear, getNormDetail} =
    useNormService()

  const [norm, setNorm] = useState([])

  const {id: editingId} = useParams()

  const selectedNorm = useSelector((state) => state.norm.selectedNorm)
  useEffect(() => {
    getStoresData()
    getProductsData({f_type: ['final']})
    draftRecordsClear()
  }, [])

  const draftNormsRecords = useSelector(
    (state) => state.norm.draftNorms[0].products
  )

  useEffect(() => {
    if (editingId) {
      getNormDetail({id: editingId, params: {withDeletedStoresRecords: true}})
    }
  }, [editingId])

  useEffect(() => () => draftRecordsClear(), [])

  const onSubmitAdd = (value) => {
    const {normTitle} = value
    if (editingId) {
      updateNormData({
        id: selectedNorm?.id,
        normTitle,
        products: draftNormsRecords?.map(({id, name, total, ...item}) => ({
          ...item,
          records: item.records
            .filter((current) => current.storeId !== null)
            .map((item) => ({
              storeId: item.storeId,
              amount: currency(item.necessaryAmount, {precision: 6}).value || 0,
            })),
        })),
      })
    } else {
      addNewNorm({
        normTitle,
        products: draftNormsRecords.map(({id, name, total, ...item}) => ({
          ...item,
          records: item.records
            .filter((current) => current.storeId !== null)
            .map((item) => ({
              storeId: item.storeId,
              amount: currency(item.necessaryAmount, {precision: 6}).value || 0,
            })),
        })),
      })
    }
  }

  return (
    <div>
      <NormAddHeader form={form} />
      <NormAddBody
        form={form}
        norm={norm}
        setNorm={setNorm}
        onSubmit={onSubmitAdd}
      />
    </div>
  )
}

export default AddNormPage
