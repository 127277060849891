import {useState} from 'react'
import {Modal} from 'antd'
import './style.scss'
import ButtonDefault from '../components/antd-components/button/ButtonDefault'

function withAntdModal(
  Component,
  {
    okButtonText,
    buttonProps = {},
    footer,
    title,
    type,
    size,
    disabled,
    className,
    tooltipTitle = null,
    icon,
    ...style
  }
) {
  return function ComponentWithDialog(props) {
    const [visible, setVisible] = useState(false)
    const handleOpen = () => setVisible(true)
    const handleClose = () => setVisible(false)
    return (
      <div>
        {okButtonText && (
          <ButtonDefault
            onClick={handleOpen}
            type={type}
            size={size}
            disabled={disabled}
            className={className}
            style={style.style}
            text={okButtonText}
            icon={icon}
          />
        )}
        <Modal
          title={title}
          open={visible}
          footer={footer}
          destroyOnClose
          className="modalWindow"
          onCancel={handleClose}
        >
          <Component {...props} onClose={handleClose} />
        </Modal>
      </div>
    )
  }
}

export default withAntdModal
