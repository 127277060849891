import {Spin} from 'antd'
import React from 'react'
import './style.scss'

function LoadingSpinner({...props}) {
  return (
    <div className="loading">
      <Spin {...props} />
    </div>
  )
}

export default LoadingSpinner
