import {Form} from 'antd'
import {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'

import StoreBalanceEditHeader from './StoreBalanceEditHeader'
import StoreBalanceEditBody from './StoreBalanceEditBody'
import {
  isStoreBalanceLoadingSelector,
  storeBalanceSelector,
  storeBalanceTableSettings,
} from '../../../../redux/selectors/storeBalanceSelector'
import StoreBalanceValidation from '../validation/StoreBalanceValidation'
import useStoreBalance from '../../../../services/store-balance/storeBalanceService'
import {storeBalanceTabs} from '../constants'
import {getNewAmountBalanceValue} from './utils'
import StoreBalanceEditFooter from './StoreBalanceEditFooter'
import StoreBalanceEditCommentModal from './StoreBalanceEditCommentModal'
import {storeBalanceLoadingKeys} from '../../../../redux/slices/storeBalanceSlice'
import useSearchParamsUrl from '../../../../hooks/useSearchParamsUrl'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'

function StoreBalanceEditPage() {
  const {searchParams, setAutoCompleteValueInUrl} = useSearchParamsUrl()
  const navigate = useCustomNavigate()

  const [form] = Form.useForm()
  const [confirmModalOpened, setConfirmModalOpened] = useState(false)
  const [storeBalanceUpdateList, setStoreBalanceUpdateList] = useState([])
  const [itemType, setItemType] = useState(
    searchParams.get('itemType') || storeBalanceTabs.PRODUCT
  )
  const [currentTabBalanceList, setCurrentTabBalanceList] = useState([])

  const setCurrentItemType = (newTab) => {
    setItemType(newTab)
    setAutoCompleteValueInUrl(newTab, 'itemType')
  }

  const onReturnToTablePage = () => {
    navigate('/storeBalance')
  }

  const {
    updateStoreBalanceData,
    setStoreBalanceTableSettings,
    getStoreBalanceByItemType,
    setIsBalanceLoading,
  } = useStoreBalance()
  const validationStoreBalance = StoreBalanceValidation()
  const storeBalanceData = useSelector(storeBalanceSelector)
  const tableSettings = useSelector(storeBalanceTableSettings)
  const isLoading = useSelector(isStoreBalanceLoadingSelector)

  useEffect(() => {
    setIsBalanceLoading(storeBalanceLoadingKeys.getStoreBalance)
  }, [])

  useEffect(() => {
    getStoreBalanceByItemType(itemType, tableSettings)
  }, [itemType, tableSettings])

  const balancesData = useRef({
    [storeBalanceTabs.PRODUCT]: [],
    [storeBalanceTabs.SEMI]: [],
    [storeBalanceTabs.RAW]: [],
  })

  useEffect(() => {
    setCurrentTabBalanceList(
      storeBalanceData.map(({storeBalances, ...itemData}) => {
        const productDataFromFormState = balancesData.current[itemType].find(
          ({id}) => id === itemData.id
        )

        return {
          ...itemData,
          itemType,
          storeBalances: productDataFromFormState
            ? productDataFromFormState.storeBalances
            : storeBalances.map(({storeId, amount, store}) => ({
                store,
                storeId,
                amount,
                initialAmount: amount,
                changed: false,
              })),
        }
      })
    )
  }, [storeBalanceData])

  const handleAmountEdit = (newAmount, {productIndex, storeIndex}) => {
    const changedItem = currentTabBalanceList[productIndex]

    const currentItem = balancesData.current[itemType].find(
      ({id}) => id === changedItem.id
    )

    if (currentItem) {
      const changedItemBalance = changedItem.storeBalances[storeIndex]
      let currentStoreBalance = currentItem.storeBalances[storeIndex]

      // Generally we save balances for all stores for this product. Added this check for the edge case when stores list is changed
      if (changedItemBalance.storeId === currentStoreBalance.storeId) {
        currentItem.storeBalances[storeIndex] = getNewAmountBalanceValue(
          newAmount,
          currentStoreBalance
        )
      } else {
        currentStoreBalance = currentItem.storeBalances.find(
          ({storeId}) => storeId === changedItemBalance.storeId
        )

        if (currentStoreBalance) {
          currentStoreBalance = getNewAmountBalanceValue(
            newAmount,
            currentStoreBalance
          )
        } else {
          currentItem.storeBalances.push(
            getNewAmountBalanceValue(newAmount, changedItemBalance)
          )
        }
      }
    } else {
      const newBalanceItem = {
        ...changedItem,
        storeBalances: [...changedItem.storeBalances],
      }

      const currentStoreBalance = newBalanceItem.storeBalances[storeIndex]
      newBalanceItem.storeBalances[storeIndex] = getNewAmountBalanceValue(
        newAmount,
        currentStoreBalance
      )

      balancesData.current[itemType].push(newBalanceItem)
    }
  }

  const getStoreBalanceUpdatesList = () => {
    const storeBalanceUpdateList = []

    Object.values(storeBalanceTabs).forEach((balanceTab) => {
      const changedItems = balancesData.current[balanceTab].map(
        ({storeBalances, ...data}) => {
          const changedBalances = storeBalances.filter(({changed}) => changed)
          return {...data, storeBalances: changedBalances}
        }
      )

      changedItems.forEach(({storeBalances, id, itemType}) => {
        storeBalances.forEach(({storeId, amount}) => {
          storeBalanceUpdateList.push({
            store: storeId,
            items: [
              {
                itemId: id,
                itemType,
                amount: Number(amount),
              },
            ],
          })
        })
      })
    })

    return storeBalanceUpdateList
  }

  const onSubmitUpdatedStoreBalance = async () => {
    try {
      await form.validateFields()
      const {comment} = form.getFieldsValue()

      await Promise.all(
        storeBalanceUpdateList.map((balanceData) => {
          balanceData.comment = comment
          return updateStoreBalanceData(balanceData)
        })
      )

      form.resetFields(['comment'])
      onReturnToTablePage()
    } catch (e) {
      console.error('e', e)
    }
  }

  const handleSaveStoreBalanceUpdate = () => {
    const balanceUpdatesList = getStoreBalanceUpdatesList()
    if (balanceUpdatesList.length === 0) {
      onReturnToTablePage()
    } else {
      setStoreBalanceUpdateList(balanceUpdatesList)
      setConfirmModalOpened(true)
    }
  }
  return (
    <>
      <div className="store_balance_edit">
        <StoreBalanceEditHeader
          form={form}
          validationStoreBalance={validationStoreBalance}
          setStoreBalanceTableSettings={setStoreBalanceTableSettings}
        />
        <StoreBalanceEditBody
          setItemType={setCurrentItemType}
          itemType={itemType}
          balanceList={currentTabBalanceList}
          handleAmountEdit={handleAmountEdit}
          validationStoreBalance={validationStoreBalance}
          setStoreBalanceTableSettings={setStoreBalanceTableSettings}
          tableSettings={tableSettings}
          isLoading={isLoading}
        />
        <StoreBalanceEditFooter
          form={form}
          onReturnToTablePage={onReturnToTablePage}
          onSubmitUpdatedStoreBalance={handleSaveStoreBalanceUpdate}
        />
      </div>
      <StoreBalanceEditCommentModal
        open={confirmModalOpened}
        form={form}
        handleClose={() => {
          form.setFieldValue('comment', null)
          setConfirmModalOpened(false)
        }}
        handleConfirm={onSubmitUpdatedStoreBalance}
      />
    </>
  )
}

export default StoreBalanceEditPage
