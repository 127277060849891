import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getPlannings = createAsyncThunk(
  'GET_PLANNINGS',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/plannings', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        movements: state.norm.norm,
        totalCount: state.norm.totalCount,
      }
    }
    return null
  }
)

export const getPlanningById = createAsyncThunk(
  'GET_PLANNING',
  async ({id}) => {
    if (id) {
      try {
        const response = await API.get(`/plannings/${id}`)
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  }
)

export const setPlanning = createAsyncThunk('SET_PLANNING', async (data) => {
  try {
    const response = await API.post('/plannings', data)
    if (response?.data?.payload) {
      return response.data.payload
    }
  } catch (e) {
    console.log(e)
  }
  return null
})

export const deletePlanning = createAsyncThunk(
  'DELETE_PLANNING',
  async ({ids = []}, {getState}) => {
    try {
      const response = await API.delete(`/plannings`, {
        params: {ids},
      })
      if (response.data.message === 'OK') {
        return {...response.data.payload}
      }
    } catch (e) {
      console.log(e)
      const state = getState()
    }
    return null
  }
)

export const updatePlanning = createAsyncThunk(
  'UPDATE_PLANNING',
  async (data) => {
    const {id} = data
    try {
      const response = await API.put(`/store-plannings/${id}`, data)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)
export const downloadExcel = createAsyncThunk('DOWNLOAD_EXCEL', async (id) => {
  try {
    const response = await API.get(`/plannings/${id}/export`, {
      responseType: 'blob',
    })
    if (response) {
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = response.headers['content-disposition'].split('=').pop()
      link.click()
    }
  } catch (e) {
    console.log(e)
  }
  return null
})
