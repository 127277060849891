import {createSlice} from '@reduxjs/toolkit'
import moment from 'moment/moment'
import {getProfits} from '../asyncServices/reportsService'
import {getWriteOffReasons} from '../asyncServices/writeOfService'

export const profitInitialState = {
  profitsList: [],
  totalCount: 0,
  tableSettings: {
    s_direction: 'asc',
    s_field: 'name',
    f_productName: null,
    f_fromDate: moment().subtract(1, 'month').startOf('month').toISOString(),
    f_toDate: moment().subtract(1, 'month').endOf('month').toISOString(),
    f_reasonId: [],
    pageSize: 100,
    page: 1,
  },
  lastUpdated: null,
  isLoading: false,
}
const profitsSlice = createSlice({
  name: 'profitsSlice',
  initialState: profitInitialState,
  reducers: {
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    clearProfitTableSettings: (state) => {
      state.tableSettings = profitInitialState.tableSettings
      state.lastUpdated = Date.now()
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfits.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProfits.fulfilled, (state, action) => {
      state.isLoading = false
      state.profitsList = action.payload.reports
      state.totalCount = action.payload.totalCount
    })
    builder.addCase(getProfits.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getWriteOffReasons.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getWriteOffReasons.fulfilled, (state, action) => {
      if (!state.tableSettings.f_reasonId?.length) {
        const {woreasons} = action.payload
        const woreasonsIdList = woreasons.map((reason) => reason.id)
        state.tableSettings.f_reasonId = woreasonsIdList
      }
      state.isLoading = false
    })
    builder.addCase(getWriteOffReasons.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const {setTableSettings, clearProfitTableSettings} = profitsSlice.actions
export default profitsSlice.reducer
