import {useState} from 'react'
import {t} from 'i18next'
import {getColumnSorter} from '../../../utils/helpers'
import {InputNumberForm} from '../../../components/antd-components/form'
import {getNewAmountBalanceValue} from '../../../pages/main/store-balance/storeBalanceEdit/utils'

function StoreBalanceAmountInput({
  initialValue,
  productIndex,
  storeIndex,
  rules,
  handleAmountEdit,
  key,
}) {
  const [currentRecord, setCurrentRecord] = useState(initialValue)

  const handleStoreBalanceAmountChange = (newValue) => {
    const valueAfterValidation = newValue
    setCurrentRecord((prev) =>
      getNewAmountBalanceValue(valueAfterValidation, prev)
    )

    handleAmountEdit(valueAfterValidation, {
      productIndex,
      storeIndex,
    })
  }

  return (
    <InputNumberForm
      controls={false}
      rules={rules}
      value={currentRecord?.amount}
      onChange={handleStoreBalanceAmountChange}
      placeholder={0}
      formWrapperClassName="table_input"
      className={currentRecord?.changed ? 'highlighted_positive' : ''}
      unitType="volume"
      key={key}
    />
  )
}

const useEditStoreBalanceTable = (props) => {
  const {handleAmountEdit, rules, storeBalanceTableSettings, storesList} = props

  const storeAmountInputs = storesList?.map(({name}, storeIndex) => ({
    title: name,
    dataIndex: 'id',
    key: `total`,
    width: 120,
    align: 'center',
    render: (_, record, productIndex) => {
      const storeBalanceRecord = record?.storeBalances?.[storeIndex]
      return (
        <StoreBalanceAmountInput
          key={`${record.itemId}_${record.itemType}`}
          initialValue={storeBalanceRecord}
          productIndex={productIndex}
          storeIndex={storeIndex}
          handleAmountEdit={handleAmountEdit}
          rules={rules}
        />
      )
    },
  }))
  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      align: 'left',
      fixed: 'left',
      sorter: true,
      sortOrder: getColumnSorter(storeBalanceTableSettings, 'name'),
      render: (name) => <span className="store_balance_edit_text">{name}</span>,
    },
    ...storeAmountInputs,
  ]
  return {
    editStoreBalanceColumns: getColumns(),
  }
}

export default useEditStoreBalanceTable
