import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'
import useTaskTable from '../../../../services/tasks/tasksTableSettigns'
import {
  getTasksLoading,
  modalIsOpen,
  selectedTask,
  tasksData,
  tasksSelectedCheckbox,
  tasksTableSettings,
  tasksTotalCount,
} from '../../../../redux/selectors/tasksSelector'
import useTasksService from '../../../../services/tasks/taskService'
import TaskForm from './TaskForm'
import showConfirm from '../../../../components/PopupConfirm'
import {ExclamationCircleOutlined} from '../../../../components/antd-components/icon'
import TaskDetail from './TaskDetail'

function TasksBody() {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const isLoading = useSelector(getTasksLoading)
  const totalCount = useSelector(tasksTotalCount)
  const tableSettings = useSelector(tasksTableSettings)
  const selectedCheckbox = useSelector(tasksSelectedCheckbox)
  const taskSelected = useSelector(selectedTask)
  const tasks = useSelector(tasksData)
  const isModalOpen = useSelector(modalIsOpen)

  const {
    setTaskTableSettings,
    setSelectedRowCheckbox,
    deleteTaskData,
    changeIsOpenModalWindow,
    setSelectedTask,
    getTaskById,
  } = useTasksService()

  const onEdit = (id) => {
    getTaskById({id})
    changeIsOpenModalWindow(true)
  }
  const onDelete = (id) => {
    deleteTaskData({ids: [id]})
  }

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => onDelete(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const handleEditClose = () => {
    changeIsOpenModalWindow(false)
    setSelectedTask([])
  }

  const handleDetailClose = () => {
    setIsOpenEditModal(false)
    setSelectedTask([])
  }
  const handleDetailOpen = (id) => {
    getTaskById({id})
    setIsOpenEditModal(true)
  }

  const {taskColumns} = useTaskTable({
    onDelete: confirmDelete,
    onEdit,
    handleDetailOpen,
    tableSettings,
  })
  const {t} = useTranslation()
  return (
    <div>
      <AntdTableActionModal
        title={t('Task information')}
        handleCancel={handleDetailClose}
        width={400}
        open={isOpenEditModal}
      >
        <TaskDetail />
      </AntdTableActionModal>
      <AntdTableActionModal
        title={t('Update task')}
        handleCancel={handleEditClose}
        open={isModalOpen}
      >
        <TaskForm onClose={handleEditClose} formData={taskSelected} />
      </AntdTableActionModal>
      <AppTable
        scroll={{x: 'max-content', y: true}}
        dataSource={tasks}
        columns={taskColumns}
        isLoading={isLoading}
        totalCount={totalCount}
        tableSettings={tableSettings}
        setTableSettings={setTaskTableSettings}
        setSelectedComponent={setSelectedRowCheckbox}
        selectedRowKeys={selectedCheckbox}
        showSelectRowCheckbox
      />
    </div>
  )
}

export default TasksBody
