import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Col, Form, Row} from 'antd'
import './style.scss'
import {useEffect} from 'react'
import useRawMaterials from '../../../../services/raw-materials/rawMaterialsService'

import RawMaterialsValidationSchema from '../validation/RawMaterialsValidationSchema'
import {
  AutoCompleteComponent,
  InputForm,
} from '../../../../components/antd-components/form'

import {
  isCheckComa,
  restrictInputValueAfterDot,
} from '../../../../utils/helpers'
import {formProps} from '../../../../constants'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function RawMaterialsForm({
  formData = {
    id: null,
    name: '',
    unitId: '',
    gross: 1,
    net: 1,
  },
  onClose,
  submitText,
  createFromComponent = false,
  form,
}) {
  const {id} = formData
  const {addRawMaterial, updateRawMaterialData, addRawMaterialFromComponent} =
    useRawMaterials()
  const isLoadingRaw = useSelector(
    (state) => state.rawMaterials.isLoading === 'setRawMaterials'
  )
  const {modalProps} = formProps
  const {t} = useTranslation()

  const units = useSelector((state) => state.units.units)

  const schema = RawMaterialsValidationSchema()

  useEffect(() => {
    if (id) {
      form.setFieldsValue(formData)
    }
  }, [id])

  const onSubmit = async (values) => {
    const {gross, net, unitId, ...rest} = values
    if (!id) {
      if (createFromComponent) {
        await addRawMaterialFromComponent(
          {
            gross: isCheckComa(gross),
            net: isCheckComa(net),
            unitId: Number(unitId),
            ...rest,
          },
          onClose
        )
      } else {
        await addRawMaterial(
          {
            gross: isCheckComa(gross),
            net: isCheckComa(net),
            unitId: Number(unitId),
            ...rest,
          },
          onClose
        )
      }
    } else {
      await updateRawMaterialData(
        {
          id,
          gross: isCheckComa(gross),
          net: isCheckComa(net),
          unitId: Number(unitId),
          ...rest,
        },
        onClose
      )
    }
  }
  const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
  }
  return (
    <Form
      name="basic"
      {...layout}
      form={form}
      onFinish={onSubmit}
      className="raw_form"
    >
      <Row gutter={24}>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            name="name"
            label={t('userName')}
            placeholder={t('userName')}
            rules={{...schema, required: true}}
            autoComplete="off"
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <AutoCompleteComponent
            label={t('Units')}
            options={units}
            name="unitId"
            placeholder={t('Units')}
            rules={{...schema, required: true}}
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            name="gross"
            label={t('Gross')}
            placeholder={t('Gross')}
            rules={{...schema, required: true}}
            formClassName="input"
            initialValue={formData.gross}
            normalize={restrictInputValueAfterDot}
            autoComplete="off"
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            name="net"
            label={t('Net')}
            placeholder={t('Net')}
            rules={{...schema, required: true}}
            initialValue={formData.net}
            formClassName="input"
            normalize={restrictInputValueAfterDot}
            autoComplete="off"
          />
        </Col>
        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>
          <Col className="button">
            <SaveButton htmlType="submit" loading={isLoadingRaw} />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default RawMaterialsForm
