import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useMemo} from 'react'
import {Form} from 'antd'
import {InputForm} from '../../../components/antd-components/form'
import PlanningValidation from '../../../pages/main/planning/validation/validation'
import useTableRenderItems from '../../useTableRenderItems'
import {
  getColumnSorter,
  restrictInputValueAfterDot,
} from '../../../utils/helpers'
import {getTableColumnSearchProps} from '../../../utils/tableUtils'
import usePlanningService from '../planningService'

const usePlanningAddTableService = ({
  form,
  changeAmountInput,
  getRowBySelectedId,
  onSubmitAdd,
  filterColumnsForm,
  storesList,
}) => {
  const {t} = useTranslation()

  const {renderNameById} = useTableRenderItems()
  const products = useSelector((state) => state.products.products)
  const {addPlanningTableSettings} = useSelector((state) => state.planning)
  const {setAddPlanningTableSettings} = usePlanningService()

  const onFocus = (e) => {
    getRowBySelectedId(e.id)
  }

  const onChange = (item, record, e) => {
    changeAmountInput({
      id: record.id,
      storeId: item.id,
      name: item.name,
      value: e,
    })
  }

  const storeColumns = useMemo(
    () =>
      storesList
        ?.map((item) => [
          {
            title: `${item.name} ${t('Balance')}`,
            dataIndex: `${item.name}balance`,
            key: item.id,
            align: 'center',
            width: 150,
          },
          {
            title: item.name,
            dataIndex: item.name,
            align: 'center',
            key: item.id,
            width: 150,
            render: (value, record) => (
              <div>
                <Form form={form} onFinish={onSubmitAdd}>
                  <InputForm
                    style={{width: '100%'}}
                    formClassName="content_center"
                    name={`store${item.name + record.id}`}
                    formStyle={{marginBottom: 0}}
                    initialValue={
                      record[item.name] !== 0 ? record[item.name] : ''
                    }
                    placeholder={0}
                    normalize={restrictInputValueAfterDot}
                    rules={PlanningValidation(
                      products.find(
                        (product) => product.id === record.productId
                      )?.piece
                    )}
                    onFocus={() => onFocus(record)}
                    onChange={(e) => onChange(item, record, e.target.value)}
                  />
                </Form>
              </div>
            ),
          },
        ])
        .flat(),
    [storesList]
  )

  const handleSearchReset = (currentColumn, confirm) => {
    filterColumnsForm.setFieldValue(currentColumn, undefined)
    setAddPlanningTableSettings({
      ...addPlanningTableSettings,
      [currentColumn]: null,
    })
    confirm({closeDropdown: true})
  }

  const searchButtonHandler = (currentColumn, confirm) => {
    const columnFilterValue = filterColumnsForm.getFieldValue(currentColumn)
    setAddPlanningTableSettings({
      ...addPlanningTableSettings,
      [currentColumn]: columnFilterValue,
    })
    confirm({closeDropdown: true})
  }

  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'f_name',
      align: 'center',
      sorter: true,
      sortOrder: getColumnSorter(addPlanningTableSettings, 'name'),
      width: 200,
      ...getTableColumnSearchProps({
        currentColumn: 'f_name',
        tableSettings: addPlanningTableSettings,
        handleReset: handleSearchReset,
        searchButtonHandler,
        placeholder: 'Name',
        form: filterColumnsForm,
        t,
      }),
      fixed: 'left',
      render: (_, record) => renderNameById({id: record.productId}, products),
    },
    ...storeColumns,
    {
      title: t('Total'),
      dataIndex: 'totalAmount',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (value) => restrictInputValueAfterDot(value.toString()),
    },
  ]

  return {planningAddColumns: getColumns()}
}

export default usePlanningAddTableService
