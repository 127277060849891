import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Form} from 'antd'
import useTableRenderItems from '../useTableRenderItems'
import {getColumnSorter, restrictPriceValueAfterDot} from '../../utils/helpers'
import InputForm from '../../components/antd-components/form/input-form/InputForm'
import PriceValidation from '../../pages/main/price/validation/PriceValidation'
import SaveActionButton from '../../components/antd-components/tableAction-buttons/save-button/SaveActionButton'
import CancelActionButton from '../../components/antd-components/tableAction-buttons/cancel-button/CancelActionButton'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'

const usePriceTableAntd = ({
  filterPriceMaxIcon,
  filterMarginIcon,
  filterPriceIcon,
  rangePriceMax,
  rangeMargin,
  rangePrice,
  handleEdit,
  editingKey,
  isEdit,
  save,
  form,
  handleClose,
  navigateToDetailPricePage,
  setMarginRequired,
  setPriceValue,
}) => {
  const {t} = useTranslation()

  const categories = useSelector((state) => state.categories.categories)
  const pricesTableSettings = useSelector((state) => state.prices.tableSettings)

  const {renderNameById} = useTableRenderItems()
  const validation = PriceValidation()
  const getColumns = () => [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '20%',
      sorter: true,
      filteredValue: pricesTableSettings.name,
      sortOrder: getColumnSorter(pricesTableSettings, 'name'),
    },
    {
      title: t('Category'),
      dataIndex: 'categoryId',
      key: 'f_categoryId',
      align: 'center',
      width: '20%',
      sorter: true,
      sortOrder: getColumnSorter(pricesTableSettings, 'categoryId'),
      filteredValue: pricesTableSettings.f_categoryId,
      filters: categories.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      render: (_, record) =>
        renderNameById({id: record.categoryId}, categories),
    },
    {
      title: t('Producing Max $'),
      dataIndex: 'priceMax',
      align: 'center',
      width: '20%',
      sorter: true,
      sortOrder: getColumnSorter(pricesTableSettings, 'priceMax'),
      filterDropdown: rangePriceMax,
      filteredValue: '',
      filterIcon: filterPriceMaxIcon,
      render: (value) => <span>{Number(value).toFixed(2)}</span>,
    },
    {
      title: t('Margin'),
      dataIndex: 'marginRequired',
      align: 'center',
      width: '20%',
      sorter: true,
      sortOrder: getColumnSorter(pricesTableSettings, 'marginRequired'),
      filterDropdown: rangeMargin,
      filterIcon: filterMarginIcon,
      filteredValue: '',
      render: (_, record) => {
        if (editingKey === record.id) {
          return (
            <Form form={form}>
              <InputForm
                initialValue={record.margin}
                name="marginRequired"
                normalize={restrictPriceValueAfterDot}
                style={{textAlign: 'center'}}
                formStyle={{marginBottom: 0}}
                rules={validation}
                onChange={(e) => setMarginRequired(e.target.value)}
              />
            </Form>
          )
        }
        return (
          <span style={{marginLeft: '12px'}}>
            {Number(record.marginRequired).toFixed(2)}
          </span>
        )
      },
    },
    {
      title: t('Price'),
      dataIndex: 'price',
      align: 'center',
      width: '10%',
      sorter: true,
      sortOrder: getColumnSorter(pricesTableSettings, 'price'),
      filterDropdown: rangePrice,
      filterIcon: filterPriceIcon,
      filteredValue: '',
      render: (_, record) => {
        if (editingKey === record.id) {
          return (
            <Form form={form}>
              <InputForm
                initialValue={record.price}
                name="price"
                normalize={restrictPriceValueAfterDot}
                style={{textAlign: 'center'}}
                formStyle={{marginBottom: 0}}
                onChange={(e) => setPriceValue(e.target.value)}
                rules={validation}
              />
            </Form>
          )
        }
        return (
          <span style={{marginLeft: '12px'}}>
            {Number(record.price).toFixed(2)}
          </span>
        )
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '7%',
      render: (_, record) => {
        const editable = isEdit(record)
        return editable ? (
          <div>
            <SaveActionButton
              onClick={() => {
                save(record.id)
              }}
            />
            <CancelActionButton onClick={handleClose} />
          </div>
        ) : (
          <div>
            <EditActionButton
              onClick={() => {
                handleEdit(record)
              }}
            />
            <DetailsActionButton
              onClick={() => {
                navigateToDetailPricePage(
                  record.id,
                  record.name,
                  record.priceMax
                )
              }}
            />
          </div>
        )
      },
    },
  ]
  return {
    priceColumns: getColumns(),
  }
}

export default usePriceTableAntd
