import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Col, Form, Row} from 'antd'
import {useEffect} from 'react'
import useSupplier from '../../../../services/supplier/suppliersService'
import SuppliersValidationSchema from '../validation/SuppliersValidationSchema'
import {
  InputForm,
  TextAreaForm,
} from '../../../../components/antd-components/form'
import {
  ButtonDanger,
  ButtonDefault,
} from '../../../../components/antd-components/button'
import {formProps} from '../../../../constants'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function SupplierForm({
  formData = {
    id: null,
    name: '',
  },
  onClose,
  text,
  form,
  createFromComponent,
}) {
  const {id} = formData

  const {addSupplier, updateSupplierData, addSupplierFromComponent} =
    useSupplier()
  const {t} = useTranslation()
  const isLoading = useSelector(
    (state) => state.suppliers.isLoading === 'setSupplier'
  )
  const {modalProps} = formProps
  const schema = SuppliersValidationSchema()

  useEffect(() => {
    if (id) {
      form.setFieldsValue(formData)
    }
  }, [id])

  const onSubmit = async (values) => {
    if (createFromComponent) {
      await addSupplierFromComponent(values)
    } else if (!id) {
      await addSupplier(values)
    } else {
      await updateSupplierData({id, ...values})
    }
    onClose()
  }
  const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 24},
  }

  return (
    <Form
      name="basic"
      {...layout}
      form={form}
      onFinish={onSubmit}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            name="name"
            label={t('Name')}
            rules={{...schema, required: true}}
            placeholder={t('Name')}
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            label={t('Phone')}
            name="telephone"
            rules={[]}
            maxLength={250}
            placeholder={t('Phone')}
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <TextAreaForm
            name="address"
            label={t('Address')}
            rules={schema}
            placeholder={t('Address')}
          />
        </Col>

        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>
          <Col className="button">
            <SaveButton htmlType="submit" loading={isLoading} />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default SupplierForm
