import {Grid, PageHeader} from 'antd'
import {t} from 'i18next'
import '../../../../services/norm/normAddService/style.scss'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'
import {AppTable} from '../../../../components/antd-components/app-table'
import useNormAddTableService from '../../../../services/norm/normAddService/normAddTableSettings'
import {ExclamationCircleOutlined} from '../../../../components/antd-components/icon'
import {
  currencyAddWithPrecision,
  getRandomId,
  scrollToAndFocus,
} from '../../../../utils/helpers'
import useNormService from '../../../../services/norm/normService'
import showConfirm from '../../../../components/PopupConfirm'
import AddButton from '../../../../components/antd-components/main-buttons/add-button/AddButton'

const {useBreakpoint} = Grid

function NormAddBody({form, onSubmit}) {
  const {
    setRecordsNorm,
    setRawId,
    setNameForm,
    changeAmountInput,
    deleteSelectedRecords,
    getRowBySelectedId,
    setIsActiveCheckbox,
    draftRecordsClear,
    setSelectedNorm,
  } = useNormService()

  const {id} = useParams()

  const draftNormsRecords = useSelector(
    (state) => state.norm.draftNorms[0].products
  )
  const screens = useBreakpoint()
  const selectedNorms = useSelector((state) => state.norm.selectedNormInTable)
  const selectedNorm = useSelector((state) => state.norm.selectedNorm)
  const [createdEmptyRaw, setCreatedEmptyRaw] = useState(false)
  const [createdNewRaw, setCreatedNewRaw] = useState(false)

  const storesIsLoading = useSelector(
    (state) => state.stores.isLoading === 'getStores'
  )
  const productsIsLoading = useSelector(
    (state) => state.products.isLoading === 'getProducts'
  )
  const normIsLoading = useSelector(
    (state) => state.norm.isLoading === 'getNormById'
  )

  const referenceOnRow = useRef()
  const referenceInput = useRef()

  const data = draftNormsRecords?.map((item) => {
    const storeRow = {
      productId: item.productId,
      name: item.name,
      id: item.id !== undefined ? item.id : item.productId,

      totalAmount: item.records
        .filter(
          (current) =>
            current.storeId !== null || current.storeDeletedDate === null
        )
        .map((items) => items.necessaryAmount)
        .reduce((prev, curr) => currencyAddWithPrecision(prev, curr), 0),
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const storeRecord of item.records)
      storeRow[storeRecord.storeName] = storeRecord.necessaryAmount

    return storeRow
  })

  useEffect(
    () => () => {
      draftRecordsClear()
    },
    []
  )

  useEffect(() => {
    const checkIsActive = draftNormsRecords
      ?.filter((current) => current.active)
      .map((item) => item.productId)
    setSelectedNorm([...selectedNorms, checkIsActive])
  }, [])

  const handleAdd = () => {
    const newData = {
      id: getRandomId(),
      productId: '',
      active: false,
      records: [],
      status: 'disabledButtonSend',
    }
    setRecordsNorm(newData)
    setCreatedEmptyRaw(true)
    setCreatedNewRaw(true)
  }

  useEffect(() => {
    if (createdNewRaw) {
      scrollToAndFocus({
        ref: referenceOnRow,
        referenceInput,
        focus: true,
      })
      setCreatedNewRaw(false)
    }
  }, [createdNewRaw])

  const deleteAndClearEmptyRaw = (id) => {
    deleteSelectedRecords(id)
    setCreatedEmptyRaw(false)
  }

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => deleteAndClearEmptyRaw(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const {normAddColumns} = useNormAddTableService({
    setRawId,
    confirmDelete,
    getRowBySelectedId,
    changeAmountInput,
    setCreatedEmptyRaw,
    onSubmit,
    selectedNorm,
    draftNormsRecords,
    data,
    setNameForm,
    form,
    isAdd: !id,
    screens,
    referenceInput,
    createdNewRaw,
  })

  const setSelectedComponent = (id) => {
    setIsActiveCheckbox(id)
    setSelectedNorm(id)
  }

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title={t('Norm')}
        subTitle={<AddButton onClick={handleAdd} disabled={createdEmptyRaw} />}
      />
      <AppTable
        scroll={{x: 'max-content', y: true}}
        dataSource={data}
        columns={normAddColumns}
        isLoading={storesIsLoading || normIsLoading || productsIsLoading}
        style={{height: 'calc(100vh - 19em)', width: '100%'}}
        showSelectRowCheckbox
        showPagination={false}
        typeColumn="widthWithoutPercent"
        selectedRowKeys={selectedNorms}
        setSelectedComponent={setSelectedComponent}
        onRow={() => ({
          ref: referenceOnRow,
        })}
      />
    </div>
  )
}

export default NormAddBody
