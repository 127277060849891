import {CalculatorOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {Button, Tooltip} from 'antd'
import './style.scss'

function RecalculateButton(props) {
  const {tooltipPlacement, tooltipText, className, ...rest} = props
  const {t} = useTranslation()

  return (
    <div className="recalculateButton_wrapper">
      <Tooltip placement={tooltipPlacement} title={tooltipText}>
        <Button
          type="primary"
          className={`${className} recalculate_button`}
          {...rest}
        >
          <CalculatorOutlined />
          {t('Recalculate')}
        </Button>
      </Tooltip>
    </div>
  )
}

export default RecalculateButton
