import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {getRandomId} from '../utils/helpers'
import useStoreBalance from '../services/store-balance/storeBalanceService'
import {storeBalanceTableSettings} from '../redux/selectors/storeBalanceSelector'

const useSetDefaultSearchQuery = () => {
  const {pathname} = useLocation()
  const {setStoreBalanceTableSettings} = useStoreBalance()
  const tableSettingsStoreBalance = useSelector(storeBalanceTableSettings)
  const locationList = [
    {
      key: getRandomId(),
      path: '/storeBalance',
      setTableSettingsAction: setStoreBalanceTableSettings,
      tableSettings: tableSettingsStoreBalance,
    },
  ]

  const setDefaultUrl = (params, setSearchParams, searchParams) => {
    locationList.map((item) => {
      if (pathname.includes(item.path)) {
        if (!Object.entries(params).length) {
          const nextSearchParams = new URLSearchParams(searchParams)
          const getKeyValue = Object.entries(item.tableSettings)
            .map(([key, value]) => ({
              key,
              value,
            }))
            .filter((value) => value.value !== null)
          getKeyValue.map((current) =>
            nextSearchParams.set(current.key, current.value)
          )
          setSearchParams(nextSearchParams)
        }
      }
      return item
    })
  }
  return {
    setDefaultUrl,
  }
}
export default useSetDefaultSearchQuery
