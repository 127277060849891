import {createSlice} from '@reduxjs/toolkit'
import {getLanguage} from '../../utils/helpers'

const initialState = {
  language: getLanguage(),
  currentPath: '',
  isLoading: false,
  isSideBarOpen: false,
  clearTableFilter: false,
  isRefillDrawerOpen: false,
  isOpenModalWindow: false,
  isOpenEditModalWindow: false,
}

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload
    },
    setOpenModalWindow: (state, action) => {
      state.isOpenModalWindow = action.payload
    },
    setIsOpenEditWindow: (state, action) => {
      state.isOpenEditModalWindow = action.payload
    },
    setPath: (state, action) => {
      state.currentPath = action.payload
    },
    setFilter: (state, action) => {
      state.clearTableFilter = action.payload
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSideBarOpen: (state, action) => {
      state.isSideBarOpen = action.payload
    },
  },
})

export const {
  setLanguage,
  setLoading,
  setPath,
  setSideBarOpen,
  setFilter,
  setOpenModalWindow,
  setIsOpenEditWindow,
} = commonSlice.actions
export default commonSlice.reducer
