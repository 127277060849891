import {useEffect} from 'react'
import {useLocation, useParams, Outlet} from 'react-router-dom'

import useCommon from '../services/commonService'

function WithNavigationPathName() {
  const {changePath} = useCommon()
  const {pathname} = useLocation()
  const params = useParams()

  const getRoutePath = (currentPathname, currentParams) => {
    if (!Object.keys(currentParams).length) {
      return currentPathname.replace('/', '')
    }

    let path = currentPathname
    Object.entries(currentParams).forEach(([paramName, paramValue]) => {
      if (paramValue) {
        path = path.replace(paramValue, `:${paramName}`)
      }
    })
    return path.replace('/', '')
  }

  useEffect(() => {
    changePath(getRoutePath(pathname, params))
  }, [pathname, params, changePath])

  return <Outlet />
}

export default WithNavigationPathName
