import {DatePicker, Form} from 'antd'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

dayjs.extend(customParseFormat)

function FormDateTimePickerAntd({
  name,
  placeholder,
  rules,
  label,
  formStyle,
  classNameForm,
  disabledLastDates = false,
  disabledLastAndTodayDates = false,
  initialValue = moment(),
  datePickerWidth = '200px',
  ...props
}) {
  const {t} = useTranslation()

  const disabledDate = (current) => {
    if (current) {
      if (disabledLastDates) return current < dayjs().startOf('day')
      if (disabledLastAndTodayDates) return current < dayjs().endOf('day')
    }
    return null
  }
  return (
    <Form.Item
      name={name}
      label={label}
      style={formStyle}
      className={classNameForm}
      rules={[rules]}
      initialValue={initialValue}
    >
      <DatePicker
        placeholder={t(placeholder)}
        onClick={(e) => e.stopPropagation()}
        {...props}
        disabledDate={disabledDate}
        style={{
          minWidth: datePickerWidth,
        }}
      />
    </Form.Item>
  )
}

export default FormDateTimePickerAntd
