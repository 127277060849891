import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getNotification = createAsyncThunk(
  'GET_NOTIFICATION',
  async ({params = {}}, {getState}) => {
    try {
      const response = await API.get('/notifications', {
        params,
      })
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
      const state = getState()
      return {
        notifications: state.messageNotifications.notifications,
        totalCount: state.messageNotifications.totalCount,
      }
    }
    return null
  }
)

export const updateReadNotification = createAsyncThunk(
  'UPDATE_READ_NOTIFICATION',
  async (id) => {
    try {
      const response = await API.put(`/notifications/${id}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const updateReadAllNotifications = createAsyncThunk(
  'UPDATE_READ_ALL_NOTIFICATION',
  async () => {
    try {
      const response = await API.put(`/notifications`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const getCountNotification = createAsyncThunk(
  'GET_COUNT_NOTIFICATION',
  async () => {
    try {
      const response = await API.get(`/notifications/count`)
      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)
