import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import '../../pages/main/accounting/style.scss'
import moment from 'moment'
import {getColumnSorter} from '../../utils/helpers'
import {accountingCategoryTableSettings} from '../../redux/selectors/accountingCategorySelector'
import TagsComponent from '../../components/antd-components/tags/TagsComponent'
import {formattedDate} from '../../constants'
import {useDateRangeTableSettings} from '../../hooks'
import useAccountingCategoryService from './accountingCategoryService'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const useAccountingCategoryTable = ({handleEdit, onDelete}) => {
  const tableSettings = useSelector(accountingCategoryTableSettings)

  const {setAccountingCategoryTableSettings} = useAccountingCategoryService()

  const createdDateFilterProps = useDateRangeTableSettings({
    setTableSettings: setAccountingCategoryTableSettings,
    tableSettings,
  })

  const {t} = useTranslation()

  const accountingCategoryColumns = [
    {
      title: t('Name'),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      width: '30%',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'title'),
    },
    {
      title: t('For store'),
      dataIndex: 'isStoreCategory',
      key: 'isStoreCategory',
      align: 'center',
      width: '10%',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'isStoreCategory'),
      render: (_, record) =>
        record.isStoreCategory ? (
          <TagsComponent color="green" keys={record.id} tagText={t('Yes')} />
        ) : (
          <TagsComponent color="red" keys={record.id} tagText={t('No')} />
        ),
    },
    {
      title: t('Type'),
      dataIndex: 'credit',
      align: 'center',
      key: 'f_credit',
      width: '12%',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'credit'),
      filteredValue: tableSettings.f_credit,
      filters: [
        {text: t('Expenses'), value: true},
        {text: t('Income'), value: false},
      ],
      render: (_, record) => {
        if (record.credit) {
          return (
            <TagsComponent
              color="red"
              keys={record.id}
              tagText={t('Expenses')}
            />
          )
        }
        return (
          <TagsComponent color="green" keys={record.id} tagText={t('Income')} />
        )
      },
    },
    {
      title: t('Default'),
      dataIndex: 'default',
      key: 'default',
      align: 'center',
      width: '11%',
      // sorter: true,
      // sortOrder: getColumnSorter(tableSettings, 'default'),
      render: (_, record) => {
        if (record.default === false) {
          return (
            <TagsComponent color="red" keys={record.id} tagText={t('No')} />
          )
        }
        return (
          <TagsComponent color="green" keys={record.id} tagText={t('Yes')} />
        )
      },
    },
    {
      title: t('Create date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      width: '30%',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'createdDate'),
      ...createdDateFilterProps,
      filteredValue: '',
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'left',
      width: '7%',
      render: (_, record) => {
        const isDeleteButtonVisible = !record.default
        return (
          <div>
            <EditActionButton
              onClick={() => {
                handleEdit(record)
              }}
            />
            {isDeleteButtonVisible && (
              <DeleteActionButton
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete(record.id)
                }}
              />
            )}
          </div>
        )
      },
    },
  ]
  return {accountingCategoryColumns}
}

export default useAccountingCategoryTable
