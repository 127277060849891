import {useSelector} from 'react-redux'
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import useLanguageInUrl from '../hooks/useLanguageInUrl'

function PrivateOutlet() {
  const isAuth = useSelector((state) => state.auth.isAuth)
  const location = useLocation()
  const {languagePath} = useLanguageInUrl()
  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={`${languagePath}/login`} replace state={{from: location}} />
  )
}

export default PrivateOutlet
