import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getIncasations = createAsyncThunk(
  'GET_INCASATIONS',
  async ({params = {}}) => {
    try {
      const response = await API.get('/incasation', {params})

      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  }
)

export const getIncasationByIdApi = createAsyncThunk(
  'GET_INCASATION_BY_ID',
  async ({id}) => {
    if (id) {
      try {
        const response = await API.get(`/incasation/${id}`)
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  }
)

export const confirmIncasation = createAsyncThunk(
  'CONFIRM_INCASATION_BY_ID',
  async ({id}) => {
    if (id) {
      try {
        const response = await API.post(`/incasation/${id}/confirm`)
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  }
)
