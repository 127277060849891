import {Button, Dropdown} from 'antd'
import './style.scss'
import {useTranslation} from 'react-i18next'
import {UserOutlined} from '@ant-design/icons'
import {useSelector} from 'react-redux'
import useAuth from '../../services/authService'
import LanguageMenu from '../LanguageMenu'
import {
  BellFilled,
  GlobalOutlined,
  LoginOutlined,
  MoreOutlined,
} from '../antd-components/icon'
import BadgeComponent from '../BadgeComponent'
import useCustomNavigate from '../../hooks/useCustomNavigate'

function MobileMenu({handleVisibleChange, visible, count}) {
  const {logout} = useAuth()
  const navigate = useCustomNavigate()
  const user = useSelector((state) => state.auth.user)
  const {t} = useTranslation()
  const showAllNotification = () => {
    navigate('/messageNotifications')
  }
  const items = [
    {
      key: '1',
      label: user.name,
      icon: <UserOutlined className="mobile_menu_icon" />,
    },
    {
      key: '2',
      label: t('Notification'),
      icon: (
        <BadgeComponent count={count}>
          <BellFilled className="mobile_menu_icon" />
        </BadgeComponent>
      ),
      onClick: () => showAllNotification(),
    },

    {
      key: '3',
      label: <LanguageMenu className="mobile_menu_language_menu" />,
      icon: <GlobalOutlined className="mobile_menu_icon" />,
    },
    {
      key: '4',
      icon: <LoginOutlined className="mobile_menu_login" />,
      label: t('Logout'),
      onClick: () => logout(),
    },
  ]
  return (
    <Dropdown
      menu={{items}}
      onOpenChange={handleVisibleChange}
      open={visible}
      trigger={['click']}
    >
      <Button type="link" className="mobile_menu_dropdown_button">
        <MoreOutlined className="mobile_menu_icon_drop_down_more_outlined" />
      </Button>
    </Dropdown>
  )
}

export default MobileMenu
