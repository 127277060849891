import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Col, Form, Row} from 'antd'
import useCategory from '../../../../../../../../services/category/categoryService'
import CategoryValidation from '../../../../../../categories/validation/CategoryValidaton'
import {createNestedItems} from '../../../../../../../../utils/helpers'
import {
  InputForm,
  TreeSelectForm,
} from '../../../../../../../../components/antd-components/form'
import CancelButton from '../../../../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../../../../../components/antd-components/main-buttons/save-button/SaveButton'
import {formProps} from '../../../../../../../../constants'

function CategoryForm({onClose}) {
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const {addCategory, getCategoriesData} = useCategory()
  const [selectedParentId, setSelectedParentId] = useState('')
  const isLoading = useSelector(
    (state) => state.accounting.isLoading === 'setAccounting'
  )

  const categoryValidation = CategoryValidation()
  const onSubmit = async (values) => {
    const {name} = values
    await addCategory(
      {
        id: null,
        name,
        type: 'product',
        parentId: selectedParentId || null,
        initialParentId: selectedParentId || null,
      },
      onClose
    )
    getCategoriesData({f_type: ['product']})
  }

  const categories = useSelector((state) => state.categories.categories)
  const categoryWithChild = createNestedItems(categories)

  const treeData = (nodes) => {
    if (!Array.isArray(nodes.children) || !nodes.children.length) {
      return {
        value: nodes.id,
        title: nodes.name,
      }
    }
    return {
      value: nodes.id,
      title: nodes.name,
      children: nodes.children.map((node) => treeData(node)),
    }
  }
  const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 24},
  }
  return (
    <Form form={form} onFinish={onSubmit} {...layout}>
      <Row gutter={24}>
        <Col className="gutter-row" {...formProps.modalProps.colScreenProps}>
          <InputForm
            placeholder={t('Name')}
            name="name"
            label={t('Name')}
            rules={{...categoryValidation, required: true}}
            style={{marginBottom: 8}}
          />
        </Col>
        <Col className="gutter-row" {...formProps.modalProps.colScreenProps}>
          <TreeSelectForm
            placeholder={t('Parent category')}
            onChange={(e) => setSelectedParentId(e)}
            label={t('Type')}
            treeData={categoryWithChild.map((item) => treeData(item))}
            rules={[]}
          />
        </Col>
        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>

          <Col className="button">
            <SaveButton loading={isLoading} htmlType="submit" />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default CategoryForm
