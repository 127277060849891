import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {PageHeaderWithMobileMenu} from '../../../../components'
import useCommon from '../../../../services/commonService'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import {incasationsTableSettingsSelector} from '../../../../redux/selectors/incasationSelector'
import ResetFiltersButton from '../../../../components/antd-components/main-buttons/resetFilters-button/ResetFiltersButton'
import {incasationInitialState} from '../../../../redux/slices/incasationSlice'
import useIncasationsService from '../../../../services/incasation/incasationService'

function IncasationHeader() {
  const {t} = useTranslation()
  const {exportExcel} = useCommon()
  const {resetIncasationsTableSettings} = useIncasationsService()
  const tableSettings = useSelector(incasationsTableSettingsSelector)

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `incasation`,
      tableSettings,
    })
  }

  const actionIncasationHeader = [
    <DownloadButton onClick={downloadButtonHandler} />,
  ]

  return (
    <div>
      <PageHeaderWithMobileMenu
        showHeaderMenu={false}
        title={t('Incasation')}
        subTitle={
          <ResetFiltersButton
            clearFiltersHandler={resetIncasationsTableSettings}
            currentTableSettings={tableSettings}
            initialTableSettings={incasationInitialState.tableSettings}
          />
        }
        extra={actionIncasationHeader}
      />
    </div>
  )
}

export default IncasationHeader
