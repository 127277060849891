import {useSelector} from 'react-redux'
import {t} from 'i18next'
import {Form} from 'antd'
import {useMemo} from 'react'
import AutoCompleteComponent from '../../../../../../../../components/antd-components/form/form-select-auto-complete/AutoCompleteComponent'
import {
  getTableColumnId,
  restrictInputValueAfterDot,
} from '../../../../../../../../utils/helpers'
import useTableRenderItems from '../../../../../../../../services/useTableRenderItems'
import ProductIdFieldValidation from './validation/ProductIdFieldValidation'
import AmountFieldValidation from './validation/AmountFieldValidation'
import DeleteActionButton from '../../../../../../../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'
import TextInputCenter from '../../../../../../../../components/antd-components/form/input-form/TextInputCenter'
import {ITEM_TYPES} from '../../../../../../../../constants'

const useRefillItemsTable = (props) => {
  const {
    deleteItemHandler,
    itemsList,
    onFieldValueChange,
    isPageReadOnly,
    activeTab,
  } = props

  const {renderNameById} = useTableRenderItems()
  const productIdFieldValidation = ProductIdFieldValidation()
  const products = useSelector((state) => state.products.products)
  const rawMaterials = useSelector((state) => state.rawMaterials.rawMaterials)
  const units = useSelector((state) => state.units.units)
  const rawOptions = rawMaterials?.map((rawData) => ({
    id: rawData.id,
    name: rawData.name,
    value: rawData.id,
  }))
  const productsTypeFinal = products
    .map((item) => item)
    .filter((item) => item.type !== 'semi')

  const productsTypeSemi = products
    .map((item) => item)
    .filter((item) => item.type !== 'final')
  const findUnit = (id) => {
    if (activeTab !== ITEM_TYPES.RAW) {
      const product = products.find((item) => item.id === id)
      return !product ? product : product?.unitId
    }
    const raw = rawMaterials.find((item) => item.id === id)
    return !raw ? raw : raw?.unit?.id
  }

  const itemsOptionsData = useMemo(() => {
    if (activeTab === ITEM_TYPES.PRODUCT) {
      return productsTypeFinal
    }
    if (activeTab === ITEM_TYPES.SEMI_FINISHED) {
      return productsTypeSemi
    }
    if (activeTab === ITEM_TYPES.RAW) {
      return rawOptions
    }
    return []
  }, [activeTab, products])

  const disabledOptionItems = useMemo(
    () =>
      itemsList
        .filter((item) => item.itemType === activeTab)
        .map((item) => item.itemId),
    [itemsList, activeTab]
  )

  const getColumns = [
    {
      ...getTableColumnId({dataIndex: 'itemId'}),
      render: (_, record, index) => index + 1,
      className: 'columns id_column',
    },
    {
      title: t('Name'),
      dataIndex: 'itemId',
      key: 'name',
      align: 'center',
      editable: true,
      className: 'columns item_name',
      render: (_, record) => (
        <AutoCompleteComponent
          disabled={isPageReadOnly}
          initialValue={record.itemId}
          rules={productIdFieldValidation}
          options={itemsOptionsData}
          name={`itemId${record.id}`}
          disabledId={disabledOptionItems}
          placeholder={t('Items')}
          onChange={(value) => {
            onFieldValueChange(record.id, 'itemId', value)
          }}
        />
      ),
    },
    {
      title: t('Quantity'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      className: 'columns quantity_column',
      render: (_, record) => {
        const isCurrentProductPiece =
          activeTab === ITEM_TYPES.RAW
            ? false
            : products.find((product) => product.id === record.itemId)?.piece

        return (
          <Form.Item
            name={`amount${record.id}`}
            rules={[AmountFieldValidation(isCurrentProductPiece, t)]}
            initialValue={record.amount}
            normalize={restrictInputValueAfterDot}
          >
            <TextInputCenter
              disabled={isPageReadOnly}
              addonAfter={renderNameById({id: findUnit(record.itemId)}, units)}
              onChange={(e) => {
                const correctValue = restrictInputValueAfterDot(e.target.value)
                onFieldValueChange(record.id, 'amount', correctValue)
              }}
            />
          </Form.Item>
        )
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align: 'center',
      className: 'columns action_column',
      render: (_, record) => (
        <div>
          <DeleteActionButton
            disabled={isPageReadOnly}
            onClick={() => {
              deleteItemHandler(record.id)
            }}
          />
        </div>
      ),
    },
  ]
  return {refillItemsColumns: getColumns}
}

export default useRefillItemsTable
