import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {
  convertToFractionalNumber,
  crossedOutText,
  getColumnSorter,
} from '../../utils/helpers'
import {formattedDate, incasationStatuses} from '../../constants'
import {
  useAmountRangeTableSetting,
  useDateRangeTableSettings,
} from '../../hooks'
import IsCardIconIndicator from '../../components/table-components/IsCardIcon'
import {TagsComponent} from '../../components/antd-components/tags'
import ConfirmActionButton from '../../components/antd-components/tableAction-buttons/confirm-button/ConfirmActionButton'

const useIncasationsTable = ({
  tableSettings,
  setIncasationsTableSettings,
  onShowDetails,
}) => {
  const {t} = useTranslation()

  const stores = useSelector((state) => state.stores.stores)
  const users = useSelector((state) => state.users.users)

  const amountFilterProps = useAmountRangeTableSetting({
    rangeFromAmountFilterName: 'f_amountFrom',
    rangeToAmountFilterName: 'f_amountTo',
    setTableSettings: setIncasationsTableSettings,
    tableSettings,
  })

  const handleOpenConfirmIncasation = (id) => {
    onShowDetails(id)
  }

  const submissionDateFilterProps = useDateRangeTableSettings({
    dateFromFilterName: 'f_submissionDateFrom',
    dateToFilterName: 'f_submissionDateTo',
    tableSettings,
    setTableSettings: setIncasationsTableSettings,
  })

  const confirmationDateFilterProps = useDateRangeTableSettings({
    dateFromFilterName: 'f_confirmationDateFrom',
    dateToFilterName: 'f_confirmationDateTo',
    tableSettings,
    setTableSettings: setIncasationsTableSettings,
  })

  return [
    {
      title: t('Store'),
      dataIndex: 'storeId',
      key: 'f_storeId',
      width: '15%',
      sorter: true,
      filteredValue: tableSettings.f_storeId,
      sortOrder: getColumnSorter(tableSettings, 'storeId'),
      filters: stores.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      render: (_, record) => {
        if (record.store.deletedDate) {
          return crossedOutText(record.store.name)
        }
        return <span>{record.store.name}</span>
      },
    },
    {
      title: t('Submission date'),
      dataIndex: 'submissionDate',
      width: '15%',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'submissionDate'),
      ...submissionDateFilterProps,
      filteredValue: '',
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'f_statuses',
      width: 130,
      align: 'center',
      filteredValue: tableSettings.f_statuses,
      sortOrder: getColumnSorter(tableSettings, 'status'),
      sorter: true,
      filters: [
        {
          text: t('PendingStatus'),
          value: incasationStatuses.pending,
        },
        {
          text: t('DeliveringStatus'),
          value: incasationStatuses.delivering,
        },
        {
          text: t('ConfirmedStatus'),
          value: incasationStatuses.confirmed,
        },
      ],
      render: (status) => {
        if (status === incasationStatuses.confirmed) {
          return <TagsComponent color="green" tagText={t('ConfirmedStatus')} />
        }
        if (status === incasationStatuses.delivering) {
          return (
            <TagsComponent color="yellow" tagText={t('DeliveringStatus')} />
          )
        }
        if (status === incasationStatuses.pending) {
          return <TagsComponent color="red" tagText={t('PendingStatus')} />
        }
        return 'Uknown status'
      },
    },
    {
      title: t('Sum'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: '8%',
      ...amountFilterProps,
      sortOrder: getColumnSorter(tableSettings, 'amount'),
      sorter: true,
      render: (value) => convertToFractionalNumber(value, 2),
    },
    {
      title: t('Method'),
      dataIndex: 'isCash',
      key: 'f_isCash',
      align: 'center',
      width: '10%',
      filteredValue: tableSettings.f_isCash,
      sortOrder: getColumnSorter(tableSettings, 'isCash'),
      sorter: true,
      filters: [
        {text: t('Card'), value: false},
        {text: t('Cash'), value: true},
      ],
      render: (isCash) => IsCardIconIndicator({isCash}),
    },
    {
      title: t('Author'),
      dataIndex: 'submittedById',
      key: 'f_submittedById',
      width: '15%',
      filteredValue: tableSettings.f_submittedById,
      sortOrder: getColumnSorter(tableSettings, 'submittedById'),
      filters: users.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      sorter: true,
      render: (_, record) => record.submittedBy.name,
    },
    {
      title: t('Confirmed'),
      dataIndex: 'confirmedById',
      width: '15%',
      sorter: true,
      filteredValue: '',
      sortOrder: getColumnSorter(tableSettings, 'confirmedById'),
      render: (_, record) =>
        record.confirmedBy ? record?.confirmedBy?.name : '-',
    },
    {
      title: t('Confirmation date'),
      dataIndex: 'confirmationDate',
      width: '15%',
      sorter: true,
      ...confirmationDateFilterProps,
      filteredValue: '',
      sortOrder: getColumnSorter(tableSettings, 'confirmationDate'),
      render: (value) =>
        value !== null ? moment(value).format(formattedDate.DateWithTime) : '-',
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 55,
      render: (_, record) => (
        <div>
          <ConfirmActionButton
            onClick={() => handleOpenConfirmIncasation(record.id)}
          />
        </div>
      ),
    },
  ]
}

export default useIncasationsTable
