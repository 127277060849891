import {useTranslation} from 'react-i18next'
import {Button, Tooltip} from 'antd'
import {DownloadOutlined} from '@ant-design/icons'
import './style.scss'

function DownloadButton(props) {
  const {tooltipPlacement, tooltipText, className, ...rest} = props
  const {t} = useTranslation()

  return (
    <div className="downloadButton_wrapper">
      <Tooltip placement={tooltipPlacement} title={tooltipText}>
        <Button
          type="primary"
          className={`${className} download_button`}
          {...rest}
        >
          <DownloadOutlined />
          {t('Download')}
        </Button>
      </Tooltip>
    </div>
  )
}

export default DownloadButton
