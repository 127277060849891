import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import PlanningHeader from './components/PlanningHeader'
import PlanningBody from './components/PlanningBody'
import {
  lastUpdatedPlanning,
  planningTableSettings,
} from '../../../redux/selectors/planningSelecor'
import usePlanningService from '../../../services/plunning/planningService'
import useStore from '../../../services/storeServices/storeService'

function PlanningPage() {
  const lastUpdated = useSelector(lastUpdatedPlanning)
  const tableSettings = useSelector(planningTableSettings)

  const {getStoresData} = useStore()
  const {getPlanningData, clearPlanningSelectedRecords} = usePlanningService()

  useEffect(() => {
    getPlanningData(tableSettings)
    getStoresData()
    clearPlanningSelectedRecords()
  }, [lastUpdated])

  return (
    <div>
      <PlanningHeader />
      <PlanningBody />
    </div>
  )
}

export default PlanningPage
