import {createSlice} from '@reduxjs/toolkit'
import {resetReduxStore} from '../resetStore'

const initialState = {
  message: null,
  variant: null,
  messageHistory: [],
  lastUpdated: null,
}

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.message = action.payload.message
      state.variant = action.payload.variant
      state.messageHistory = [...state.messageHistory, {...action.payload}]
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {setNotification, setLastUpdated} = notificationSlice.actions
export default notificationSlice.reducer
