import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import IncasationModalDetail from './IncasationConfirm'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'
import {LoadingSpinner} from '../../../../components/antd-components/loading-spinner'
import {ButtonDefault} from '../../../../components/antd-components/button'
import useIncasationsService from '../../../../services/incasation/incasationService'
import useIncasationsTable from '../../../../services/incasation/incasationTableService'
import {
  incasationLastUpdatedSelector,
  incasationsListLoadingSelector,
  incasationsListSelector,
  incasationsTableSettingsSelector,
  incasationsTotalCountSelector,
  isSelectedIncasationLoadingSelector,
  selectedIncasationSelector,
} from '../../../../redux/selectors/incasationSelector'

import './style.scss'
import {incasationStatuses} from '../../../../constants'

function IncasationBody() {
  const {t} = useTranslation()

  const [isEditDialogOpen, setEditDialogOpen] = useState(false)
  const {setSelectedIncasationData} = useIncasationsService()
  const incasationsList = useSelector(incasationsListSelector)
  const totalCount = useSelector(incasationsTotalCountSelector)
  const tableSettings = useSelector(incasationsTableSettingsSelector)
  const isLoading = useSelector(incasationsListLoadingSelector)
  const lastUpdated = useSelector(incasationLastUpdatedSelector)
  const isLoadingGetById = useSelector(isSelectedIncasationLoadingSelector)
  const selectedIncasation = useSelector(selectedIncasationSelector)

  const {
    setIncasationsTableSettings,
    getIncasationsList,
    getIncasationById,
    handleConfirmIncasation,
  } = useIncasationsService()

  useEffect(() => {
    if (!isEditDialogOpen && selectedIncasation) {
      setSelectedIncasationData(null)
    }
  }, [isEditDialogOpen])

  const handleOpenShowIncasationDetails = (id) => {
    getIncasationById(id)
    setEditDialogOpen(true)
  }

  const incasationColumns = useIncasationsTable({
    tableSettings,
    setIncasationsTableSettings,
    onShowDetails: handleOpenShowIncasationDetails,
  })

  const onConfirm = async () => {
    try {
      await handleConfirmIncasation(selectedIncasation.id)
      setEditDialogOpen(false)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getIncasationsList(tableSettings)
  }, [lastUpdated])

  return (
    <div>
      <AntdTableActionModal
        title={
          isLoadingGetById ? (
            <LoadingSpinner />
          ) : (
            <span>
              <span>{t('Incasation')} </span>
            </span>
          )
        }
        handleCancel={() => setEditDialogOpen(false)}
        width={1100}
        style={{top: 1}}
        className="customCloseComponent antdBody modal incasationModalBody"
        open={isEditDialogOpen}
        footer={
          selectedIncasation?.status !== incasationStatuses.confirmed && (
            <ButtonDefault
              text={t('Confirm')}
              key={4}
              className="success"
              onClick={onConfirm}
            />
          )
        }
      >
        <IncasationModalDetail />
      </AntdTableActionModal>
      <AppTable
        dataSource={incasationsList}
        columns={incasationColumns}
        totalCount={totalCount}
        tableSettings={tableSettings}
        setTableSettings={setIncasationsTableSettings}
        isLoading={isLoading}
      />
    </div>
  )
}

export default IncasationBody
