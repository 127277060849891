import {navbarList} from './navlist'
import userTypeList from './userTypeList'

export const LOG_OUT = 'LOG_OUT'
export const en = 'en'
export const ua = 'ua'
export const ru = 'ru'

export const phoneRegex = /^[+][0-9]{12}$/
export const passwordRegexp = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/

export {navbarList, userTypeList}

export const taskType = [
  {id: 'ingredient', name: 'Ingredients'},
  {id: 'semi', name: 'Semi-finished'},
]

export const dataFunction = [{id: 1, name: 'Summarize', label: 'sum'}]

export const productType = {
  final: 'final',
  semi: 'semi',
}

export const accountingType = [
  {id: 'income', name: 'Income'},
  {id: 'expenses', name: 'Expenses'},
]

export const paymentStatus = {
  PAID: 'Paid',
  PARTIALLY: 'Partially paid',
  UNPAID: 'UNPAID',
}

export const formProps = {
  modalProps: {
    colScreenProps: {
      span: 24,
      xs: 24,
      sm: 24,
      md: 24,
      xl: 24,
    },
  },
}

export const formattedDate = {
  Date: 'DD-MM-YYYY',
  DateWithTime: 'DD-MM-YYYY HH:mm',
  DateWithTimeForPicker: 'YYYY-MM-DD hh:mm',
  Time: 'HH:mm',
  DateForFilterWithIncludeTodayStart: 'YYYY-MM-DD 00:00:00-00',
  DateForFilterWithIncludeTodayEnd: 'YYYY-MM-DD 23:59:59-00',
}

export const incasationStatuses = {
  confirmed: 'confirmed',
  pending: 'pending',
  delivering: 'delivering',
}

export const tableRowClassNames = {
  IS_ERROR: 'isWarning isSortWarning',
}

export const URL_PARAMS = {
  PURCHASE_ID: 'purchaseId',
  TABLE_SETTINGS: 'tableSettings',
}

export const MAX_FULL_NAME_LENGTH = 30

export const ITEM_TYPES = {
  PRODUCT: 'product',
  SEMI_FINISHED: 'semi',
  RAW: 'raw',
}

export const REFILL_STATUS = {
  SENT: 'sent',
  ERROR: 'error',
  RECEIVED: 'received',
  DRAFT: 'draft',
}

export const ACCOUNTING_TYPES = {
  EXPENSES: 'expenses',
  INCOME: 'income',
}
