import {useTranslation} from 'react-i18next'
import {getRandomId} from '../../../../../../../../utils/helpers'
import './style.scss'
import MovementItemsTable from '../items-table/MovementItemsTable'
import AddButton from '../../../../../../../../components/antd-components/main-buttons/add-button/AddButton'
import ItemsTabs from '../../../../../../../../components/itemsTabs-component/ItemsTabs'

function MovementItemsTabs(props) {
  const {
    movementForm,
    itemsList,
    setItemsList,
    onFormSubmit,
    isPageReadOnly,
    selectedFromIdStore,
    isLoading,
    activeTab,
    setActiveTab,
  } = props
  const {t} = useTranslation()

  const addItemHandler = () => {
    const emptyItem = {
      id: getRandomId(),
      itemId: '',
      itemType: activeTab,
      amount: '',
      isNewItem: true,
    }
    setItemsList([emptyItem, ...itemsList])
  }

  const ItemsTable = (
    <MovementItemsTable
      isLoading={isLoading}
      movementForm={movementForm}
      itemsList={itemsList}
      setItemsList={setItemsList}
      onFormSubmit={onFormSubmit}
      isPageReadOnly={isPageReadOnly}
      selectedFromIdStore={selectedFromIdStore}
      activeTab={activeTab}
    />
  )
  return (
    <div className="items_tabs">
      <ItemsTabs
        itemsList={itemsList}
        itemsTable={ItemsTable}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        tabBarExtraContent={
          <AddButton
            disabled={isPageReadOnly || !selectedFromIdStore || isLoading}
            onClick={addItemHandler}
            tooltipText={
              !selectedFromIdStore &&
              t('Please choose first which store you want to move from')
            }
          />
        }
      />
    </div>
  )
}

export default MovementItemsTabs
