import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import moment from 'moment'
import currency from 'currency.js'
import {
  cropStringToFixedLength,
  crossedOutText,
  getColumnSorter,
  getTableColumnId,
} from '../../utils/helpers'
import {formattedDate, MAX_FULL_NAME_LENGTH} from '../../constants'
import {useDateRangeTableSettings} from '../../hooks'
import usePurchaseService from './purchaseService'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'

const usePurchaseTable = ({openPurchaseDetailModal}) => {
  const {t} = useTranslation()
  const {tableSettings} = useSelector((state) => state.purchase)
  const stores = useSelector((state) => state.stores.stores)
  const users = useSelector((state) => state.users.users)

  const {setPurchaseTableSettings} = usePurchaseService()

  const createdDateFilterProps = useDateRangeTableSettings({
    tableSettings,
    setTableSettings: setPurchaseTableSettings,
  })

  const purchaseColumns = [
    getTableColumnId({dataIndex: 'checkId', width: 120}),
    {
      title: t('Create date'),
      dataIndex: 'createdDate',
      key: 'f_createdDateFrom',
      align: 'center',
      width: 160,
      ...createdDateFilterProps,
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'createdDate'),
      filteredValue: tableSettings.f_createdDateFrom,
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },
    {
      title: t('Author'),
      dataIndex: 'createdBy',
      key: 'f_user',
      align: 'center',
      filteredValue: tableSettings.f_user,
      sortOrder: getColumnSorter(tableSettings, 'createdBy'),
      width: 160,
      sorter: true,
      filters: users?.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      render: (_, record) => {
        const {name} = record.createdBy
        return cropStringToFixedLength(name, MAX_FULL_NAME_LENGTH)
      },
    },
    {
      title: t('Store'),
      dataIndex: 'storeName',
      key: 'f_store',
      align: 'center',
      width: 350,
      filteredValue: tableSettings.f_store,
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'storeName'),
      filters: stores?.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      render: (_, record) => {
        if (record?.stores?.deletedDate) {
          return crossedOutText(record?.stores?.name)
        }
        return <span>{record?.stores?.name}</span>
      },
    },
    {
      title: t('Cash'),
      dataIndex: 'cashAmount',
      key: 'f_cashAmount',
      align: 'right',
      width: 110,
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'cashAmount'),
      render: (value) => `${currency(value)}`,
    },
    {
      title: t('Card'),
      dataIndex: 'cardAmount',
      key: 'f_cardAmount',
      align: 'right',
      width: 110,
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'cardAmount'),
      render: (value) => `${currency(value)}`,
    },
    {
      title: t('Total price'),
      dataIndex: 'totalAmount',
      key: 'f_totalAmount',
      align: 'right',
      width: 120,
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'totalAmount'),
      render: (value) => `${currency(value)}`,
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 67,
      render: (_, record) => (
        <div>
          <DetailsActionButton
            onClick={() => {
              openPurchaseDetailModal(record.checkId)
            }}
          />
        </div>
      ),
    },
  ]

  return {purchaseColumns}
}

export default usePurchaseTable
