import {useTranslation} from 'react-i18next'
import notify from '../../../components/antd-components/notification/notify'
import useCustomNavigate from '../../../hooks/useCustomNavigate'
import {ordersApi} from '../../../services/api/ordersApiService'
import OrderForm from './components/form/OrderForm'
import {checkIsResponseSuccess} from '../../../utils/helpers'

function CreateOrderPage() {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()

  const handleSubmit = async (values) => {
    const {
      name,
      phone,
      deliveryDate,
      deliveryDateTo,
      deliveryType,
      deliveryFrom,
      deliveryPrice,
      deliveryAddress,
      externalId,
      total,
      orderProducts,
    } = values

    try {
      const response = await ordersApi
        .createOrder({
          name,
          phone,
          deliveryDate,
          deliveryDateTo,
          deliveryType,
          deliveryFrom,
          deliveryPrice: deliveryPrice ? Number(deliveryPrice) : 0,
          deliveryAddress,
          externalId,
          total,
          orderProducts: orderProducts.map(
            ({id, productId, name, amount, price, total, comment}) => ({
              id: id.toString().includes('local') ? undefined : id,
              productId,
              name,
              amount: Number(amount),
              price: Number(price),
              total,
              comment,
            })
          ),
          delivered: values.delivered,
        })
        .then((response) => {
          if (checkIsResponseSuccess(response?.status)) {
            notify({
              description: t('Success create order'),
              type: 'success',
            })
            navigate('/orders')
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  return <OrderForm onSubmit={handleSubmit} />
}

export default CreateOrderPage
