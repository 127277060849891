import {Button} from 'antd'
import {DeleteOutlined} from '@ant-design/icons'
import './style.scss'

function DeleteActionButton(props) {
  const {className, ...rest} = props

  return (
    <Button
      size="small"
      shape="circle"
      type="danger"
      icon={<DeleteOutlined />}
      className={`deleteAction_button ${className}`}
      {...rest}
    />
  )
}

export default DeleteActionButton
