import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {useSelector} from 'react-redux'
import '../../pages/main/accounting/style.scss'
import {EditOutlined} from '@ant-design/icons'
import useTableRenderItems from '../useTableRenderItems'
import {IconButtonWithTooltip} from '../../components/antd-components/button'
import {convertToFractionalNumber, getColumnSorter} from '../../utils/helpers'
import TagsComponent from '../../components/antd-components/tags/TagsComponent'
import {
  companyAccountingCategoriesSelector,
  storeAccountingCategoriesSelector,
} from '../../redux/selectors/accountingCategorySelector'
import {
  useAmountRangeTableSetting,
  useDateRangeTableSettings,
} from '../../hooks'
import {formattedDate} from '../../constants'
import useAccounting from './accountingService'
import {selectedAccountingTableSettings} from '../../redux/selectors/accountingSelector'
import IsCardIconIndicator from '../../components/table-components/IsCardIcon'
import {ToolTipComponent} from '../../components'

const useAccountingTable = ({handleEdit, isStoreAccounting}) => {
  const users = useSelector((state) => state.users.users)
  const accountingTableSettings = useSelector(selectedAccountingTableSettings)
  const {renderNameById} = useTableRenderItems()
  const {t} = useTranslation()
  const usersList = isStoreAccounting
    ? users
    : users.filter((item) => item.type === 'admin')
  const accCategory = useSelector(
    isStoreAccounting
      ? storeAccountingCategoriesSelector
      : companyAccountingCategoriesSelector
  )

  const {setAccountingTableSettings} = useAccounting()

  const createdDateFilterProps = useDateRangeTableSettings({
    setTableSettings: setAccountingTableSettings,
    tableSettings: accountingTableSettings,
  })

  const totalAmountFilterProps = useAmountRangeTableSetting({
    rangeFromAmountFilterName: 'f_fromTotalAmount',
    rangeToAmountFilterName: 'f_toTotalAmount',
    setTableSettings: setAccountingTableSettings,
    tableSettings: accountingTableSettings,
  })

  const cashAmountFilterProps = useAmountRangeTableSetting({
    rangeFromAmountFilterName: 'f_fromCashAmount',
    rangeToAmountFilterName: 'f_toCashAmount',
    setTableSettings: setAccountingTableSettings,
    tableSettings: accountingTableSettings,
  })

  const cardAmountFilterProps = useAmountRangeTableSetting({
    rangeFromAmountFilterName: 'f_fromCardAmount',
    rangeToAmountFilterName: 'f_toCardAmount',
    setTableSettings: setAccountingTableSettings,
    tableSettings: accountingTableSettings,
  })

  const amountFilterProps = useAmountRangeTableSetting({
    rangeFromAmountFilterName: 'f_fromAmount',
    rangeToAmountFilterName: 'f_toAmount',
    setTableSettings: setAccountingTableSettings,
    tableSettings: accountingTableSettings,
  })

  const accountingCategoryFilter = accCategory
    ?.filter((category) => isStoreAccounting === category.isStoreCategory)
    ?.map((item) => ({
      key: item.id,
      value: item.id,
      text: item.title,
    }))

  const accountingColumns = {
    comment: {
      title: t('Comment'),
      dataIndex: 'comment',
      key: 'f_comment',
      align: 'center',
      width: '15%',
      filteredValue: accountingTableSettings.f_comment,
      sortOrder: getColumnSorter(accountingTableSettings, 'comment'),
      sorter: true,
      render: (value) => (
        <ToolTipComponent title={value}>{value}</ToolTipComponent>
      ),
    },
    method: {
      title: t('Method'),
      dataIndex: 'isCash',
      key: 'f_type',
      align: 'center',
      width: '15%',
      filteredValue: accountingTableSettings.f_type,
      sortOrder: getColumnSorter(accountingTableSettings, 'isCash'),
      sorter: true,
      filters: [
        {text: t('Card'), value: 'card'},
        {text: t('Cash'), value: 'cash'},
      ],
      render: (isCash) => IsCardIconIndicator({isCash}),
    },
    createdBy: {
      title: t('Author'),
      dataIndex: 'createdById',
      key: 'f_createdById',
      align: 'center',
      width: '12%',
      filteredValue: accountingTableSettings.f_createdById,
      sortOrder: getColumnSorter(accountingTableSettings, 'createdById'),
      filters: usersList.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      sorter: true,
      render: (value) => <p>{renderNameById({id: value}, users)}</p>,
    },
    category: {
      title: t('Category'),
      dataIndex: 'accountingCategoryId',
      key: 'f_accountingCategoryId',
      align: 'center',
      width: '15%',
      filteredValue: accountingTableSettings.f_accountingCategoryId,
      sortOrder: getColumnSorter(
        accountingTableSettings,
        'accountingCategoryId'
      ),
      filters: accountingCategoryFilter,
      sorter: true,
      render: (_, record) => record?.accountingCategory?.title,
    },
    createDate: {
      title: t('Create date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      width: 150,
      sorter: true,
      ...createdDateFilterProps,
      sortOrder: getColumnSorter(accountingTableSettings, 'createdDate'),
      filteredValue: '',
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },
    totalAmount: {
      title: t('Total'),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      align: 'right',
      width: '8%',
      ...totalAmountFilterProps,
      sortOrder: getColumnSorter(accountingTableSettings, 'totalAmount'),
      sorter: true,
      render: (value) => convertToFractionalNumber(value, 2),
    },
    cashAmount: {
      title: t('Cash'),
      dataIndex: 'cashAmount',
      key: 'cashAmount',
      align: 'right',
      width: '8%',
      ...cashAmountFilterProps,
      sortOrder: getColumnSorter(accountingTableSettings, 'cashAmount'),
      sorter: true,
      render: (value) => convertToFractionalNumber(value, 2),
    },
    cardAmount: {
      title: t('Card'),
      dataIndex: 'cardAmount',
      key: 'cardAmount',
      align: 'right',
      width: '8%',
      ...cardAmountFilterProps,
      sortOrder: getColumnSorter(accountingTableSettings, 'cardAmount'),
      sorter: true,
      render: (value) => convertToFractionalNumber(value, 2),
    },
    amount: {
      title: t('Price'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: '8%',
      ...amountFilterProps,
      sortOrder: getColumnSorter(accountingTableSettings, 'amount'),
      filteredValue: '',
      sorter: true,
      render: (value) => convertToFractionalNumber(value, 2),
    },
    type: {
      title: t('Type'),
      align: 'center',
      width: 100,
      filteredValue: accountingTableSettings.f_credit,
      key: 'f_credit',
      filters: [
        {
          text: t('Expenses'),
          value: 'true',
        },
        {
          text: t('Income'),
          value: 'false',
        },
      ],
      render: (_, record) => {
        const amount = isStoreAccounting ? record?.totalAmount : record?.amount
        if (amount < 0) {
          return (
            <TagsComponent
              color="red"
              keys={record.id}
              tagText={t('Expenses')}
            />
          )
        }
        return (
          <TagsComponent color="green" keys={record.id} tagText={t('Income')} />
        )
      },
    },
    actions: {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (_, record) => {
        const isActionButtonVisible =
          !record?.store?.deletedDate && !isStoreAccounting
        return (
          <div>
            {isActionButtonVisible && (
              <div>
                <IconButtonWithTooltip
                  size="small"
                  style={{margin: '3px'}}
                  shape="circle"
                  type="primary"
                  placement="topLeft"
                  icon={<EditOutlined />}
                  onClick={() => {
                    handleEdit(record)
                  }}
                />
              </div>
            )}
          </div>
        )
      },
    },
  }

  return {
    accountingColumns: isStoreAccounting
      ? [
          accountingColumns.createDate,
          accountingColumns.createdBy,
          accountingColumns.category,
          accountingColumns.comment,
          accountingColumns.type,
          accountingColumns.cashAmount,
          accountingColumns.cardAmount,
          accountingColumns.totalAmount,
          // accountingColumns.actions,
        ]
      : [
          accountingColumns.createDate,
          accountingColumns.createdBy,
          accountingColumns.category,
          accountingColumns.type,
          accountingColumns.method,
          accountingColumns.comment,
          accountingColumns.amount,
          accountingColumns.actions,
        ],
  }
}

export default useAccountingTable
