import {useDispatch, useSelector} from 'react-redux'
import {
  isGetByIdLoadingSelector,
  selectedOrderSelector,
} from '../../redux/selectors/orderSelector'
import {
  downloadOrderThunk,
  getOrderById,
} from '../../redux/asyncServices/ordersService'

const useOrderByIdService = () => {
  const dispatch = useDispatch()

  const order = useSelector(selectedOrderSelector)
  const isOrderLoading = useSelector(isGetByIdLoadingSelector)

  const getOrder = (id) => dispatch(getOrderById(id))

  const downloadOrder = (id) => dispatch(downloadOrderThunk(id))

  return {
    order,
    isOrderLoading,
    getOrder,
    downloadOrder,
  }
}

export default useOrderByIdService
