import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import {AppTable} from '../../../../components/antd-components/app-table'
import usePurchaseTable from '../../../../services/purchase/purchaseTableService'
import {URL_PARAMS} from '../../../../constants'
import usePurchaseService from '../../../../services/purchase/purchaseService'
import {filterAndRemoveFields} from '../../../../utils/helpers'

function PurchaseTable() {
  const {setPurchaseTableSettings, setTableSettingsFromUrl} =
    usePurchaseService()
  const [searchParams, setSearchParams] = useSearchParams()
  const {purchases} = useSelector((state) => state.purchase)
  const {totalCount, tableSettings} = useSelector((state) => state.purchase)
  const isLoading = useSelector(
    (state) => state.purchase.isLoading === 'getPurchases'
  )
  const screens = useBreakpoint()

  useEffect(() => {
    const tableSettingsFromUrl = searchParams.get(URL_PARAMS.TABLE_SETTINGS)
    if (tableSettingsFromUrl) {
      setTableSettingsFromUrl(tableSettingsFromUrl)
    }
  }, [])

  useEffect(() => {
    const tableSettingsUrlParams = JSON.stringify(
      filterAndRemoveFields(tableSettings)
    )
    searchParams.set(URL_PARAMS.TABLE_SETTINGS, tableSettingsUrlParams)
    setSearchParams(searchParams)
  }, [tableSettings])

  const openPurchaseDetailModal = (checkId) => {
    searchParams.set(URL_PARAMS.PURCHASE_ID, checkId)
    setSearchParams(searchParams)
  }

  const {purchaseColumns} = usePurchaseTable({
    openPurchaseDetailModal,
  })

  return (
    <AppTable
      columns={purchaseColumns}
      dataSource={purchases}
      setTableSettings={setPurchaseTableSettings}
      tableSettings={tableSettings}
      isLoading={isLoading}
      rowKey={(record) => record.number}
      scroll={{
        x: 750,
        y: 'auto',
      }}
      style={{
        height:
          screens?.xs || (screens.sm && !screens.xl && !screens.md)
            ? 'calc(100vh - 19em)'
            : 'calc(100vh - 16em)',
      }}
      totalCount={totalCount}
    />
  )
}

export default PurchaseTable
