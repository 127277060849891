import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import WriteOffHeader from './components/WriteOffHeader'
import WriteOffBody from './components/WriteOffBody'

import {useWriteOff} from '../../../services/write-off-reason/writeOffReasonsService'

function WriteOffReason() {
  const writeOffProps = useWriteOff()
  const {
    setWriteOffReasonTableSettings,
    deleteWriteOffReasonData,
    getWriteOffData,
  } = writeOffProps
  const writeOffLastUpdated = useSelector((state) => state.writeOff.lastUpdated)
  const writeOffTableSettings = useSelector(
    (state) => state.writeOff.tableSettings
  )

  useEffect(() => {
    getWriteOffData(writeOffTableSettings)
  }, [writeOffLastUpdated])

  return (
    <>
      <WriteOffHeader
        setWriteOffReasonTableSettings={setWriteOffReasonTableSettings}
        deleteWriteOffReasonData={deleteWriteOffReasonData}
      />
      <WriteOffBody
        {...writeOffProps}
        writeOffTableSettings={writeOffTableSettings}
      />
    </>
  )
}

export default WriteOffReason
