import {useDispatch} from 'react-redux'

import {
  getCountNotification,
  getNotification,
  updateReadAllNotifications,
  updateReadNotification,
} from '../../redux/asyncServices/notificationMessageService'
import {
  setLastUpdated,
  setSelectedNotificationId,
  setTableSettings,
} from '../../redux/slices/notificationMessageSlice'

function useNotification() {
  const dispatch = useDispatch()
  const notificationLastUpdated = () => dispatch(setLastUpdated(Date.now()))
  const getNotificationData = (params) => {
    dispatch(getNotification({params}))
  }

  const setNotificationTableSettings = (tableSettings = {}) => {
    dispatch(setTableSettings({tableSettings}))
    notificationLastUpdated()
  }
  const updateNotification = async (id) => {
    await dispatch(updateReadNotification(id))
    notificationLastUpdated()
  }
  const updateAllNotification = async () => {
    await dispatch(updateReadAllNotifications())
    notificationLastUpdated()
  }
  const getCountNotifications = async () => {
    await dispatch(getCountNotification())
  }

  const setSelectedNotification = (data) =>
    dispatch(setSelectedNotificationId(data))

  return {
    notificationLastUpdated,
    getNotificationData,
    setNotificationTableSettings,
    updateNotification,
    updateAllNotification,
    getCountNotifications,
    setSelectedNotification,
  }
}

export default useNotification
