function useTableRenderItems() {
  const renderNameById = ({id = null}, data = []) => {
    if (id && !!data.length) {
      const dataItem = data.find(
        (item) => Number(item.id) === Number(id) || item.id === id
      )
      return dataItem ? dataItem.name || dataItem.title : ''
    }
    return ''
  }

  return {
    renderNameById,
  }
}

export default useTableRenderItems
