import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {useSelector} from 'react-redux'
import '../../pages/main/accounting/style.scss'
import currency from 'currency.js'
import useTableRenderItems from '../useTableRenderItems'
import {getColumnSorter} from '../../utils/helpers'
import {formattedDate} from '../../constants'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const useSuppliesTable = ({onEdit, onDelete}) => {
  const suppliesTableSettings = useSelector(
    (state) => state.supplies.tableSettings
  )
  const suppliers = useSelector((state) => state.suppliers.suppliers)
  const {renderNameById} = useTableRenderItems()
  const {t} = useTranslation()

  const getColumns = () => [
    {
      title: t('Supplier'),
      dataIndex: 'supplierId',
      key: 'f_supplierId',
      align: 'center',
      width: '25%',
      filteredValue: suppliesTableSettings.f_supplierId,
      sortOrder: getColumnSorter(suppliesTableSettings, 'supplierId'),
      filters: suppliers.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      sorter: true,
      render: (value) => renderNameById({id: value}, suppliers),
    },
    {
      title: t('Total price'),
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      width: '18%',
      sortOrder: getColumnSorter(suppliesTableSettings, 'total'),
      filteredValue: '',
      sorter: true,
      render: (value) => <span>{currency(value, {precision: 2}).value}</span>,
    },
    {
      title: t('Payment'),
      dataIndex: 'cardPayment',
      key: 'f_cardPayment',
      align: 'center',
      width: '20%',
      sortOrder: getColumnSorter(suppliesTableSettings, 'cardPayment'),
      filteredValue: suppliesTableSettings.f_cardPayment,
      filters: [
        {text: t('Card'), value: true},
        {text: t('Cash'), value: false},
      ],
      sorter: true,
      render: (value) => (value ? t('Card') : t('Cash')),
    },
    {
      title: t('Create date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      width: '15%',
      sorter: true,
      sortOrder: getColumnSorter(suppliesTableSettings, 'createdDate'),
      filteredValue: '',
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },
    {
      title: t('Supply date'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: '15%',
      sorter: true,
      sortOrder: getColumnSorter(suppliesTableSettings, 'date'),
      filteredValue: '',
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: '7%',
      render: (_, record) => (
        <div>
          <EditActionButton
            onClick={(e) => {
              e.stopPropagation()
              onEdit(record.id)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDelete(record.id)
            }}
          />
        </div>
      ),
    },
  ]
  return {suppliesColumns: getColumns()}
}

export default useSuppliesTable
