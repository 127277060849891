import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useTableRenderItems from '../useTableRenderItems'
import TagsComponent from '../../components/antd-components/tags/TagsComponent'
import {crossedOutText, getColumnSorter} from '../../utils/helpers'
import {formattedDate} from '../../constants'
import {useDateRangeTableSettings} from '../../hooks'
import useMovements from './movementsService'

import './style.scss'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const useMovementsTable = ({
  handleEdit,
  getInfoSentMovement,
  confirmDeleteMovement,
}) => {
  const {t} = useTranslation()
  const movementsTableSettings = useSelector(
    (state) => state.movements.tableSettings
  )

  const stores = useSelector((state) => state.stores.stores)
  const users = useSelector((state) => state.users.users)
  const isAuth = useSelector((state) => state.auth.user)
  const userType = isAuth.type

  const {setMovementTableSettings} = useMovements()

  const createdDateFilterProps = useDateRangeTableSettings({
    tableSettings: movementsTableSettings,
    setTableSettings: setMovementTableSettings,
  })
  const updatedDateFilterProps = useDateRangeTableSettings({
    dateFromFilterName: 'f_updatedDateFrom',
    dateToFilterName: 'f_updatedDateTo',
    tableSettings: movementsTableSettings,
    setTableSettings: setMovementTableSettings,
  })

  const {renderNameById} = useTableRenderItems()

  const getColumns = () => [
    {
      title: t('Store from'),
      dataIndex: 'fromStoreId',
      key: 'f_fromStoreId',
      align: 'center',
      width: '20%',
      sorter: true,
      filteredValue: movementsTableSettings.f_fromStoreId,
      sortOrder: getColumnSorter(movementsTableSettings, 'fromStoreId'),
      filters: stores.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      render: (_, record) => {
        if (record?.fromStore?.deletedDate) {
          return crossedOutText(record?.fromStore?.name)
        }
        return <span>{record?.fromStore?.name}</span>
      },
    },
    {
      title: t('Store to'),
      dataIndex: 'toStoreId',
      key: 'f_toStoreId',
      align: 'center',
      width: '20%',
      sorter: true,
      filteredValue: movementsTableSettings.f_toStoreId,
      sortOrder: getColumnSorter(movementsTableSettings, 'toStoreId'),
      filters: stores.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      render: (_, record) => {
        if (record?.toStore?.deletedDate) {
          return crossedOutText(record?.toStore?.name)
        }
        return <span>{record?.toStore?.name}</span>
      },
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'f_status',
      align: 'center',
      width: '15%',
      filteredValue: movementsTableSettings.f_status,
      sorter: true,
      sortOrder: getColumnSorter(movementsTableSettings, 'status'),
      filters: [
        {
          text: t('Sent'),
          value: 'sent',
        },
        {
          text: t('Draft'),
          value: 'draft',
        },
        {
          text: t('processed'),
          value: 'processed',
        },
        {
          text: t('error'),
          value: 'error',
        },
      ],
      render: (_, record) => {
        if (record.status === 'error') {
          return (
            <TagsComponent
              keys={record.id}
              color="red"
              tagText={t(record.status)}
            />
          )
        }
        if (record.status === 'draft') {
          return (
            <TagsComponent
              keys={record.id}
              color="default"
              tagText={t(record.status)}
            />
          )
        }
        if (record.status === 'sent') {
          return (
            <TagsComponent
              keys={record.id}
              color="orange"
              tagText={t(record.status)}
            />
          )
        }
        return (
          <TagsComponent
            keys={record.id}
            color="green"
            tagText={t(record.status)}
          />
        )
      },
    },
    {
      title: t('Author'),
      dataIndex: 'createdBy',
      key: 'f_createdBy',
      align: 'center',
      width: '15%',
      sorter: true,
      filteredValue: movementsTableSettings.f_createdBy,
      sortOrder: getColumnSorter(movementsTableSettings, 'createdBy'),
      render: (_, record) => renderNameById({id: record.createdBy}, users),
    },
    {
      title: t('Create date'),
      dataIndex: 'createdDate',
      width: '20%',
      key: 'f_toCreatedDate',
      align: 'center',
      sorter: true,
      ...createdDateFilterProps,
      filteredValue: movementsTableSettings.f_toCreatedDate,
      sortOrder: getColumnSorter(movementsTableSettings, 'createdDate'),
      render: (_, record) =>
        moment(record.createdDate).format(formattedDate.DateWithTime),
    },
    {
      title: t('Editor'),
      dataIndex: 'updatedBy',
      key: 'f_updatedBy',
      align: 'center',
      width: '15%',
      sorter: true,
      sortOrder: getColumnSorter(movementsTableSettings, 'updatedBy'),
      filteredValue: movementsTableSettings.f_updatedBy,
      render: (_, record) => renderNameById({id: record.updatedBy}, users),
    },
    {
      title: t('Update date'),
      dataIndex: 'updatedDate',
      key: 'f_toUpdatedDate',
      align: 'center',
      width: '25%',
      sorter: true,
      sortOrder: getColumnSorter(movementsTableSettings, 'updatedDate'),
      ...updatedDateFilterProps,
      filteredValue: movementsTableSettings.f_toUpdatedDate,
      render: (_, record) =>
        record.updatedDate !== null
          ? moment(record.updatedDate).format(formattedDate.DateWithTime)
          : null,
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      className: 'actions-column',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      filteredValue: '',
      width: '10%',
      render: (_, record) => {
        const isStoreFromOrStoreToDeleted =
          record?.toStore?.deletedDate || record?.fromStore?.deletedDate
        const isEditButtonVisible = !isStoreFromOrStoreToDeleted
        const isShowDetailsButtonVisible = record.status === 'processed'
        const isDeleteButtonVisible = record.status !== 'processed'
        return (
          <div>
            {isEditButtonVisible && (
              <EditActionButton
                onClick={() => {
                  handleEdit(record)
                }}
              />
            )}
            {isShowDetailsButtonVisible && (
              <DetailsActionButton
                onClick={() => {
                  getInfoSentMovement(record.id)
                }}
              />
            )}
            {isDeleteButtonVisible && (
              <DeleteActionButton
                onClick={() => {
                  confirmDeleteMovement(record.id)
                }}
              />
            )}
          </div>
        )
      },
    },
  ]
  return {
    movementsColumns:
      userType === 'admin'
        ? getColumns()
        : getColumns().filter((item) => item.dataIndex !== 'actions'),
  }
}

export default useMovementsTable
