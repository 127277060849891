import {useForm} from 'antd/es/form/Form'
import {Form} from 'antd'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'
import {
  AutoCompleteComponent,
  InputForm,
} from '../../../../../../../../components/antd-components/form'
import AddActionButton from '../../../../../../../../components/antd-components/tableAction-buttons/add-button/AddActionButton'
import {
  currencyDivide,
  currencyMultiply,
  getRandomId,
  restrictInputValueAfterDot,
} from '../../../../../../../../utils/helpers'
import CancelButton from '../../../../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../../../../../components/antd-components/main-buttons/save-button/SaveButton'
import './style.scss'
import IngredientValidationSchema from './validation'

function AddIngredientForm(props) {
  const {
    onClose,
    selectedIngredient,
    setCurrentProductData,
    currentProductData,
    openCreateRawModalHandler,
  } = props
  const [ingredientForm] = useForm()
  const {t} = useTranslation()
  const rawMaterials = useSelector((state) => state.rawMaterials.rawMaterials)
  const validation = IngredientValidationSchema()

  // newRawMaterial -> raw created from subModal
  const newRawMaterial = useSelector(
    (state) => state.rawMaterials.newRawMaterial
  )

  useEffect(() => {
    if (newRawMaterial) {
      ingredientForm.setFieldsValue({
        rawId: newRawMaterial.id,
        gross: newRawMaterial.gross,
        net: newRawMaterial.net,
      })
    }
  }, [newRawMaterial])

  useEffect(() => {
    if (selectedIngredient) {
      ingredientForm.setFieldsValue(selectedIngredient)
    } else {
      ingredientForm.resetFields()
    }
  }, [selectedIngredient])

  const onSubmit = (ingredientData) => {
    const {gross, net, rawId} = ingredientData
    const currentRaw = rawMaterials.find((raw) => raw.id === rawId)
    if (selectedIngredient) {
      const updatedIngredientsList = currentProductData?.ingredients.map(
        (ingredient) => {
          if (ingredient.id === selectedIngredient.id) {
            const isDataChanged =
              ingredient.rawId !== rawId ||
              ingredient.gross !== Number(gross) ||
              ingredient.net !== Number(net)
            const {changed} = ingredient
            return {
              ...selectedIngredient,
              rawId,
              gross: Number(gross),
              net: Number(net),
              ingredient: currentRaw,
              changed: changed || isDataChanged,
            }
          }
          return ingredient
        }
      )
      setCurrentProductData({
        ...currentProductData,
        ingredients: updatedIngredientsList,
      })
    } else {
      const newIngredientData = {
        id: getRandomId(),
        rawId,
        gross: Number(gross),
        net: Number(net),
        ingredient: currentRaw,
        changed: true,
      }
      setCurrentProductData({
        ...currentProductData,
        ingredients: [...currentProductData.ingredients, newIngredientData],
      })
    }
    onClose()
  }

  const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
  }
  const findNet = (id) => {
    const netRawMaterial = rawMaterials?.find((item) => item.id === id)
    return !netRawMaterial ? netRawMaterial : netRawMaterial?.net
  }
  const findGross = (id) => {
    const netRawMaterial = rawMaterials?.find((item) => item.id === id)
    return !netRawMaterial ? netRawMaterial : netRawMaterial?.gross
  }

  const rawFieldLabel = (
    <div className="rawField_label">
      <span>{t('Raw')}</span>
      <AddActionButton
        className="rawLabel_icon"
        onClick={openCreateRawModalHandler}
      />
    </div>
  )

  const onRawIdChange = (selectedRawId) => {
    if (selectedRawId) {
      const rawGross = findGross(selectedRawId) || 0
      const rawNet = findNet(selectedRawId) || 0
      ingredientForm.setFieldsValue({gross: rawGross, net: rawNet})
    } else {
      ingredientForm.resetFields()
    }
  }
  const onGrossValueChange = (grossValue) => {
    if (grossValue) {
      const selectedRawId = ingredientForm.getFieldValue('rawId')
      const rawGross = findGross(selectedRawId) || 0
      const rawNet = findNet(selectedRawId) || 0
      const grossCoefficient = currencyDivide(rawNet, rawGross, 6)
      const updatedNetValue = grossCoefficient
        ? currencyMultiply(
            grossCoefficient,
            restrictInputValueAfterDot(grossValue),
            6
          )
        : 0
      ingredientForm.setFieldValue('net', updatedNetValue)
    } else {
      ingredientForm.setFieldValue('net', undefined)
    }
    ingredientForm.validateFields()
  }

  const onNetValueChange = (netValue) => {
    if (netValue) {
      const selectedRawId = ingredientForm.getFieldValue('rawId')
      const rawGross = findGross(selectedRawId) || 0
      const rawNet = findNet(selectedRawId) || 0
      const netCoefficient = currencyDivide(rawGross, rawNet, 6)
      const updatedGrossValue = netCoefficient
        ? currencyMultiply(
            netCoefficient,
            restrictInputValueAfterDot(netValue),
            6
          )
        : 0
      ingredientForm.setFieldValue('gross', updatedGrossValue)
    } else {
      ingredientForm.setFieldValue('gross', undefined)
    }
    ingredientForm.validateFields()
  }

  return (
    <Form
      className="addIngredient_form"
      name="basic"
      {...layout}
      form={ingredientForm}
      onFinish={onSubmit}
      autoComplete="off"
    >
      <AutoCompleteComponent
        classNameForm="form_field"
        label={rawFieldLabel}
        options={rawMaterials}
        name="rawId"
        onChange={(e) => onRawIdChange(e)}
        placeholder={t('Raw')}
        rules={{...validation, required: true}}
      />
      <InputForm
        name="gross"
        label={t('Gross')}
        placeholder={t('Gross')}
        formClassName="form_field"
        onChange={(e) => {
          onGrossValueChange(e.target.value)
        }}
        normalize={restrictInputValueAfterDot}
        autoComplete="off"
        rules={{...validation, required: true}}
      />
      <InputForm
        name="net"
        label={t('Net')}
        formClassName="form_field"
        placeholder={t('Net')}
        normalize={restrictInputValueAfterDot}
        onChange={(e) => {
          onNetValueChange(e.target.value)
        }}
        autoComplete="off"
        rules={{...validation, required: true}}
      />
      <div className="form_footer">
        <CancelButton className="footer_button" onClick={onClose} />
        <SaveButton
          className="footer_button"
          onClick={() => {
            ingredientForm.submit()
          }}
        />
      </div>
    </Form>
  )
}

export default AddIngredientForm
