import {createSlice} from '@reduxjs/toolkit'
import {
  copyNorms,
  deleteNorm,
  getNormById,
  getNorms,
  setNorm,
  updateNorm,
} from '../asyncServices/normService'
import {getRandomId, restrictInputValueAfterDot} from '../../utils/helpers'
import {resetReduxStore} from '../resetStore'

export const normInitialState = {
  norms: [],
  draftNorms: [
    {
      rowId: getRandomId(),
      normTitle: '',
      products: [],
    },
  ],
  selectedNorm: null,
  totalCount: 0,
  selectedRow: null,
  selectedNormInTable: [],
  tableSettings: {
    s_field: 'title',
    s_direction: 'DESC',
    f_title: '',
    page: 1,
    pageSize: 100,
    f_createdDateFrom: null,
    f_createdDateTo: null,
  },
  lastUpdated: null,
  isLoading: null,
  selectedId: null,
}

const normSlice = createSlice({
  name: 'normSlice',
  initialState: normInitialState,
  reducers: {
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
      state.lastUpdated = Date.now()
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload
    },
    setDraftNorms: (state, action) => {
      state.draftNorms = action.payload
    },
    setDraftRecords: (state, action) => {
      state.draftNorms[0].products.push(action.payload)
    },
    setSelectedNormForDelete: (state, action) => {
      state.selectedNormInTable = action.payload
    },
    clearDraftRecords: (state, action) => {
      state.selectedNorm = []
      state.selectedId = null
      state.draftNorms = [
        {
          productId: getRandomId(),
          normTitle: '',
          products: [],
        },
      ]
    },
    deleteRecords: (state, action) => {
      state.draftNorms[0].products = state.draftNorms[0].products.filter(
        (item) =>
          item.id
            ? item.id !== action.payload
            : item.productId !== action.payload
      )
    },
    setIsActive: (state, action) => {
      const {id} = action.payload
      const findSelectedId = id.map((current) => current)

      const changedProductId = state.draftNorms[0].products?.map((item) => {
        if (
          findSelectedId.includes(
            item.id !== undefined ? item.id : item.productId
          )
        ) {
          return {
            ...item,
            active: true,
          }
        }
        return {
          ...item,
          active: false,
        }
      })

      state.draftNorms[0].products = [...changedProductId]
    },
    setDraftRawId: (state, action) => {
      const changedProductId = state.draftNorms[0].products?.map((item) => {
        const {id, rawId} = action.payload

        if (item.id === id || item.productId === id) {
          return {
            ...item,
            productId: rawId,
          }
        }
        return item
      })

      state.draftNorms[0].products = [...changedProductId]
    },

    changeAmountStore: (state, action) => {
      const {id, storeId, value, name} = action.payload
      const changedProductId = state.draftNorms[0].products.map((item) => {
        const selectedStoreId = item.records.findIndex(
          (el) => el.storeId === storeId
        )

        if (item.id === id || item.productId === id) {
          if (selectedStoreId === -1) {
            item.records.push({
              storeId,
              storeName: name,
              necessaryAmount: restrictInputValueAfterDot(value.toString()),
            })
          } else {
            item.records[selectedStoreId].necessaryAmount =
              restrictInputValueAfterDot(value.toString())
          }
        }
        return {
          ...item,
          status: 'allowButtonSend',
        }
      })

      state.draftNorms[0].products = [...changedProductId]
    },
    getRecordById: (state, action) => {
      state.selectedRow = state.draftNorms[0]?.products.filter(
        (item) => item.productId === action.payload.id
      )
    },
    clearNormTableSettings: (state) => {
      state.tableSettings = normInitialState.tableSettings
      state.lastUpdated = Date.now()
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNorms.pending, (state) => {
      state.isLoading = 'getNorm'
    })
    builder.addCase(getNorms.fulfilled, (state, action) => {
      state.norms = action.payload.storeNorms
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getNormById.pending, (state) => {
      state.isLoading = 'getNormById'
    })
    builder.addCase(getNormById.fulfilled, (state, action) => {
      state.selectedNorm = action.payload
      state.draftNorms[0].products = action.payload?.products?.map((item) => ({
        ...item,
        records: item.records,
      }))

      state.totalCount = action?.payload?.totalCount
      state.isLoading = null
    })
    builder.addCase(getNormById.rejected, (state) => {
      state.isLoading = null
    })
    builder.addCase(setNorm.pending, (state) => {
      state.isLoading = 'setNorm'
    })
    builder.addCase(setNorm.fulfilled, (state, action) => {
      state.isLoading = null
      state.lastUpdated = Date.now()
    })
    builder.addCase(deleteNorm.pending, (state) => {
      state.isLoading = 'deleteNorm'
    })
    builder.addCase(deleteNorm.fulfilled, (state, action) => {
      state.isLoading = null
      state.selectedNormInTable = []
      state.lastUpdated = Date.now()
    })
    builder.addCase(updateNorm.pending, (state) => {
      state.isLoading = 'updateNorm'
    })
    builder.addCase(updateNorm.fulfilled, (state, action) => {
      state.isLoading = null
    })
    // Copy norms
    builder.addCase(copyNorms.pending, (state) => {
      state.isLoading = 'getNorm'
    })
    builder.addCase(copyNorms.fulfilled, (state, action) => {
      state.isLoading = null
      state.selectedNormInTable = []
      state.lastUpdated = Date.now()
    })
    builder.addCase(copyNorms.rejected, (state) => {
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = normInitialState
    })
  },
})

export const {
  setTableSettings,
  setLastUpdated,
  setDraftNorms,
  setDraftRecords,
  changeAmountStore,
  setDraftRawId,
  setFormName,
  deleteRecords,
  getRecordById,
  setIsActive,
  setSelectedNormForDelete,
  clearDraftRecords,
  setSelectedId,
  clearNormTableSettings,
} = normSlice.actions
export default normSlice.reducer
